import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { HiddenFeeServiceClient } from '@api/price/web_partnership/hidden_fee_grpc_web_pb';
import {
  ListHiddenFeeReq,
  ListHiddenFeeRes,
  ListHiddenFeeFilter,
  DetailHiddenFeeReq,
  DetailHiddenFeeRes,
  CreateHiddenFeeReq,
  CreateHiddenFeeRes,
  UpdateHiddenFeeReq,
  UpdateHiddenFeeRes,
  DeleteHiddenFeeReq,
  ImportHiddenFeeReq,
  ImportHiddenFeeRes,
} from '@api/price/web_partnership/hidden_fee_pb';
import { BasicRes, Empty, PaginationReq } from '@api/base/base_pb';

class PriceHiddenFeeServiceApi extends BaseApi<HiddenFeeServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HiddenFeeServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  listHiddenFee = ({
    filter,
    pagination,
  }: ListHiddenFeeReq.AsObject): Promise<ListHiddenFeeRes.AsObject> => {
    const req = new ListHiddenFeeReq();
    const filterObj = new ListHiddenFeeFilter();
    const paginationObj = new PaginationReq();
    if (filter) {
      filterObj.setOfficeId(filter.officeId);
      filterObj.setType(filter.type);
      filterObj.setLocation(filter.location);
      filterObj.setAccommodationTypeList(filter.accommodationTypeList);
      filterObj.setRatingList(filter.ratingList);
      filterObj.setCountryCodeList(filter.countryCodeList);
      filterObj.setKeywords(filter.keywords);
      req.setFilter(filterObj);
    }

    req.setFilter(filterObj);
    if (pagination) {
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
      req.setPagination(paginationObj);
    }

    return this.grpc<
      ListHiddenFeeReq,
      ListHiddenFeeRes,
      ListHiddenFeeRes.AsObject
    >(this.serviceClient.listHiddenFee, req, {});
  };

  detailHiddenFee = ({
    id,
  }: DetailHiddenFeeReq.AsObject): Promise<DetailHiddenFeeRes.AsObject> => {
    const req = new DetailHiddenFeeReq();
    id && req.setId(id);

    return this.grpc<
      DetailHiddenFeeReq,
      DetailHiddenFeeRes,
      DetailHiddenFeeRes.AsObject
    >(this.serviceClient.detailHiddenFee, req, {});
  };

  createHiddenFee = ({
    officeId,
    provider,
    type,
    location,
    accommodationTypeList,
    ratingList,
    countryCodeList,
    hotelName,
    amount,
    percent,
    hotelId,
  }: CreateHiddenFeeReq.AsObject): Promise<CreateHiddenFeeRes.AsObject> => {
    const req = new CreateHiddenFeeReq();
    officeId && req.setOfficeId(officeId);
    provider && req.setProvider(provider);
    type && req.setType(type);
    location && req.setLocation(location);
    accommodationTypeList &&
      req.setAccommodationTypeList(accommodationTypeList);
    ratingList && req.setRatingList(ratingList);
    countryCodeList && req.setCountryCodeList(countryCodeList);
    hotelName && req.setHotelName(hotelName);
    amount && req.setAmount(amount);
    percent && req.setPercent(percent);
    hotelId && req.setHotelId(hotelId);

    return this.grpc<
      CreateHiddenFeeReq,
      CreateHiddenFeeRes,
      CreateHiddenFeeRes.AsObject
    >(this.serviceClient.createHiddenFee, req, {});
  };

  updateHiddenFee = ({
    id,
    officeId,
    provider,
    type,
    location,
    accommodationTypeList,
    ratingList,
    countryCodeList,
    hotelName,
    amount,
    percent,
    hotelId,
  }: UpdateHiddenFeeReq.AsObject): Promise<UpdateHiddenFeeRes.AsObject> => {
    const req = new UpdateHiddenFeeReq();
    id && req.setId(id);
    officeId && req.setOfficeId(officeId);
    provider && req.setProvider(provider);
    type && req.setType(type);
    location && req.setLocation(location);
    accommodationTypeList &&
      req.setAccommodationTypeList(accommodationTypeList);
    ratingList && req.setRatingList(ratingList);
    countryCodeList && req.setCountryCodeList(countryCodeList);
    hotelName && req.setHotelName(hotelName);
    amount && req.setAmount(amount);
    percent && req.setPercent(percent);
    hotelId && req.setHotelId(hotelId);

    return this.grpc<
      UpdateHiddenFeeReq,
      UpdateHiddenFeeRes,
      UpdateHiddenFeeRes.AsObject
    >(this.serviceClient.updateHiddenFee, req, {});
  };

  deleteHidenFee = ({
    idsList,
  }: DeleteHiddenFeeReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new DeleteHiddenFeeReq();
    idsList && req.setIdsList(idsList);

    return this.grpc<DeleteHiddenFeeReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.deleteHiddenFee,
      req,
      {}
    );
  };

  //   importHiddenFee =
  //     ({}: ImportHiddenFeeReq.AsObject): Promise<ImportHiddenFeeRes.AsObject> => {
  //       const req = new ImportHiddenFeeReq();
  //       return this.grpc<
  //         ImportHiddenFeeReq,
  //         ImportHiddenFeeRes,
  //         ImportHiddenFeeRes.AsObject
  //       >(this.serviceClient.importHiddenFee, req, {});
  //     };
}

export const priceConfigHiddenFeeServiceApi = new PriceHiddenFeeServiceApi();
