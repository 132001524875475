import { ArrowDown, ArrowUp } from '@icon';
import { IBookingDetail } from '@tixlabs/grpc-client/web-partnership';
import { useCurrency } from '@vemaybay-admin/hooks/internals';
import React, { useEffect, useState } from 'react';
interface Props {
  bookingData: IBookingDetail;
}
export const RefundInfo = ({ bookingData }: Props) => {
  const { formatCurrencyCustom } = useCurrency();
  const [refundTitle, setRefundTitle] = useState('');

  useEffect(() => {
    const totalPrice =
      (bookingData.priceInfo?.totalPrice) || 0;
    if (totalPrice > bookingData.refundAmount) {
      setRefundTitle('Hoàn 1 phần');
    } else {
      setRefundTitle('Hoàn toàn bộ');
    }
  }, [bookingData]);

  return (
    <div className='bg-white border border-neutral-3 p-4 rounded text-md flex flex-col gap-0.5'>
      <b>Thông tin hoàn tiền</b>
      <b>
        Số tiền hoàn:{' '}
        {formatCurrencyCustom(
          bookingData.refundAmount || 0,
          bookingData.priceInfo?.currency || ''
        )}
      </b>
      <b>Loại: {refundTitle}</b>
      <div className='flex gap-1'>
        <b>Phương thức hoàn: </b>
        <span>Chuyển khoản</span>
      </div>
      {bookingData.refundInfo?.paymentAccountInfo ? (
        <ul>
          <li>- Tên tài khoản: {bookingData.refundInfo.paymentAccountInfo.accountName}</li>
          <li>- Số tài khoản: {bookingData.refundInfo.paymentAccountInfo.accountNumber}</li>
          <li>- Ngân hàng: {bookingData.refundInfo.paymentAccountInfo.bankName}</li>
          <li>- Chi nhánh: {bookingData.refundInfo.paymentAccountInfo.bankBranch}</li>
        </ul>
      ) : null}
    </div>
  );
};
