import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partnership';
import { EPaxType } from '@tixlabs/grpc-client/';
import React from 'react';
import { useCurrency } from '@access-trade-admin/hooks/internals';

type Props = {
  bookingFlight: IRetrieveBookingFlightData;
};

export const PaymentInfo = ({ bookingFlight }: Props) => {
  const { formatPrice } = useCurrency();

  const counts = {
    [EPaxType.ADT]: 0,
    [EPaxType.CHD]: 0,
    [EPaxType.INF]: 0,
  };

  bookingFlight.passengersList.forEach((passenger) => {
    counts[passenger.type]++;
  });

  const ticketPrice =
    bookingFlight.fee?.itemsList.reduce((sum: number, pax) => {
      return sum + pax.totalPrice;
    }, 0) || 0;

  const contentArr: string[] = [];
  if (counts[EPaxType.ADT] > 0) {
    contentArr.push(`${counts[EPaxType.ADT]} người lớn`);
  }
  if (counts[EPaxType.CHD] > 0) {
    contentArr.push(`${counts[EPaxType.CHD]} trẻ em`);
  }
  if (counts[EPaxType.INF] > 0) {
    contentArr.push(`${counts[EPaxType.INF]} trẻ sơ sinh`);
  }

  return (
    <div className='flex flex-col rounded-lg bg-white'>
      <h3 className='text-lg font-semibold py-3 px-4 mb-0'>
        Thông tin thanh toán
      </h3>
      <div className='flex flex-col gap-4 px-4 py-3'>
        <div className='flex flex-col gap-1'>
          <span className='text-md font-semibold'>Vé máy bay</span>
          <div className='flex items-center gap-2'>
            <span className='flex-1 text-neutral-gray-60'>{`Hành khách (${contentArr.join(
              ', '
            )})`}</span>
            <span className='text-neutral-gray-60'>
              {formatPrice(ticketPrice)} {bookingFlight.currency}
            </span>
          </div>
        </div>
        {/* <div className='flex flex-col gap-1'>
          <span className='text-md font-semibold'>Dịch vụ bổ sung</span>
          <div className='flex items-center gap-2'>
            <span className='flex-1 text-neutral-gray-60'>Hành lý thêm</span>
            <span className='text-neutral-gray-60'>
              {formatPrice(0)} {bookingFlight.currency}
            </span>
          </div>
          <div className='flex items-center gap-2'>
            <span className='flex-1 text-neutral-gray-60'>Chọn chỗ ngồi</span>
            <span className='text-neutral-gray-60'>
              {formatPrice(0)} {bookingFlight.currency}
            </span>
          </div>
        </div> */}

        {(bookingFlight.paymentInfo ||
          (bookingFlight.fee?.vat !== undefined &&
            bookingFlight.fee?.vat > 0)) && (
          <div className='flex flex-col gap-1'>
            <span className='text-md font-semibold'>Khác</span>
            {bookingFlight.paymentInfo && (
              <div className='flex items-center gap-2'>
                <span className='flex-1 text-neutral-gray-60'>
                  Phí tiện ích
                </span>
                <span className='text-neutral-gray-60'>
                  {formatPrice(bookingFlight.paymentInfo?.feeAmount)}{' '}
                  {bookingFlight.currency}
                </span>
              </div>
            )}
            {bookingFlight.fee?.vat !== undefined &&
              bookingFlight.fee?.vat > 0 && (
                <div className='flex items-center gap-2'>
                  <span className='flex-1 text-neutral-gray-60'>Phí VAT</span>
                  <span className='text-neutral-gray-60'>
                    {formatPrice(bookingFlight.fee?.vat)}{' '}
                    {bookingFlight.currency}
                  </span>
                </div>
              )}
          </div>
        )}

        <div className='h-px bg-neutral-gray-30'></div>
        <div className='flex items-center'>
          <span className='flex-1 text-lg font-semibold'>Tổng thanh toán</span>
          <span className='flex-1 text-right text-lg font-semibold'>
            {formatPrice(
              bookingFlight.paymentInfo?.totalPrice ||
                bookingFlight.fee?.totalPrice ||
                0
            )}{' '}
            {bookingFlight.currency}
          </span>
        </div>
        {bookingFlight.paymentInfo && (
          <div className='flex items-center'>
            <span className='flex-1 text-neutral-6'>
              Phương thức thanh toán
            </span>
            <span className='flex-1 text-right text-neutral-6'>
              {bookingFlight.paymentInfo.method?.name || ''}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
