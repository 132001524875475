import { Button, ButtonLoading, Form, FormInput } from '@common-ui';
import { getApiErrorMessages } from '@core/utils';
import { serviceConfigApi } from '@tixlabs/grpc-client/web-partnership';
import { useToast } from '@access-trade-admin/hooks/internals';
import { useAppMutation } from '@access-trade-admin/hooks/internals';
import { useErrorMessageData } from '@access-trade-admin/hooks/stores';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type Props = {
  //
};

type TConfigVATForm = {
  vat: number;
};

export function VATConfig({}: Props) {
  const toast = useToast();
  const { getErrorMessage } = useErrorMessageData();

  const { mutateAsync: updateVATConfig } = useAppMutation({
    mutationKey: ['serviceConfigApi', 'updateConfig'],
    mutationFn: serviceConfigApi.updateConfig,
    onSuccess: ({ errorCode, isSuccess }) => {
      if (isSuccess) {
        toast.success('Cập nhật thành công');
      } else {
        console.log('errorCode', errorCode);
      }
    },
  });

  const { mutateAsync: getConfig } = useAppMutation({
    mutationKey: ['serviceConfigApi', 'getConfig'],
    mutationFn: serviceConfigApi.getConfig,
  });

  const methods = useForm<TConfigVATForm>({
    defaultValues: {
      vat: 0,
    },
  });

  const {
    formState: { isSubmitting },
  } = methods;

  async function handleSubmit(data: TConfigVATForm) {
    try {
      await updateVATConfig({ vat: data.vat });
    } catch (error) {}
  }

  const getConfigVat = async () => {
    try {
      const { isSuccess, errorCode, data } = await getConfig({ asd: 'vzxcas' });
      if (!isSuccess) {
        throw new Error(errorCode);
      }
      methods.reset({ vat: data?.vat });
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      toast.error(getErrorMessage(msg));
    }
  };

  useEffect(() => {
    getConfigVat();
  }, []);

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <div className='p-4 bg-theme-white space-y-2 rounded-sm'>
        <FormInput
          name='vat'
          inputProps={{
            type: 'number',
            customFormat: (value: string) => {
              if (Number(value) <= 100) return `${value}`;
              return '100';
            },
          }}
          label='Thuế VAT (%)'
          rules={{
            required: 'Vui lòng nhập VAT',
          }}
          placeholder='Nhập thuế VAT'
        />
        <ButtonLoading
          isShowChildWhenLoading
          loading={isSubmitting}
          variant='solid'
          theme='primary'
          type='submit'>
          Lưu thông tin
        </ButtonLoading>
      </div>
    </Form>
  );
}

export default VATConfig;
