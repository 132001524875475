import {
  ClipBoardLineIcon,
  GroupLineIcon,
  HistoryFillIcon,
  Home5LineIcon,
  MenuLineIcon,
  MoneyDollarCircleLineIcon,
  UserLineIcon,
  TicketIcon,
} from '@icon';
import { MenuHeader } from '@vemaybay-admin/components/Header';
import { AppLogicProvider } from '@vemaybay-admin/containers';
import { useAuth } from '@vemaybay-admin/hooks/apps';
import { useUserStore } from '@vemaybay-admin/hooks/stores';
import { Button, Layout, Menu } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
const { Header, Content, Sider } = Layout;

const items: ItemType<MenuItemType>[] = [
  {
    key: 'dashboard',
    label: 'Trang chủ',
    icon: <Home5LineIcon className='shrink-0 w-4 h-4 ' />,
  },
  {
    key: '/',
    label: 'Quản lý đặt chỗ',
    icon: <ClipBoardLineIcon className='shrink-0 w-4 h-4' />,
    children: [
      {
        key: 'order-management',
        label: 'Đặt chỗ chuyến bay',
        // icon: <ClipBoardLineIcon className='shrink-0 w-4 h-4' />,
      },
      {
        key: 'order-management-hotel',
        label: 'Đặt chỗ khách sạn',
        // icon: <ClipBoardLineIcon className='shrink-0 w-4 h-4' />,
      },
      {
        key: 'refund-management-hotel',
        label: 'Quản lý hoàn tiền',
        // icon: <ClipBoardLineIcon className='shrink-0 w-4 h-4' />,
      },
    ],
  },
  {
    key: 'ticket-coordination',
    label: 'Điều phối vé',
    icon: <TicketIcon className='shrink-0 w-4 h-4 ' />,
  },
  // {
  //   key: 'user-management',
  //   label: 'Quản lý khách hàng',
  //   icon: <UserLineIcon className='shrink-0 w-4 h-4' />,
  // },
  // {
  //   key: 'profit',
  //   label: 'Lợi nhuận',
  //   icon: <MoneyDollarCircleLineIcon className='shrink-0 w-4 h-4' />,
  // },
  // {
  //   key: 'crosscheck',
  //   label: 'Đối soát',
  //   icon: <HistoryFillIcon className='shrink-0 w-4 h-4' />,
  // },
  // {
  //   key: 'referral',
  //   label: 'Giới thiệu bạn bè',
  //   icon: <GroupLineIcon className='shrink-0 w-4 h-4' />,
  // },
  // {
  //   key: 'service-fee-setting',
  //   label: 'Phí dịch vụ',
  //   icon: <MoneyDollarCircleLineIcon className='shrink-0 w-4 h-4' />,
  // },
  // {
  //   key: 'finance-setting',
  //   label: 'Cấu hình tài chính',
  //   icon: <MoneyDollarCircleLineIcon className='shrink-0 w-4 h-4' />,
  // },
  // {
  //   key: 'account',
  //   label: 'Tài khoản',
  //   icon: <AccountCircleLineIcon className='shrink-0 w-4 h-4' />,
  // },
  // {
  //   key: 'setting',
  //   label: 'CÀI ĐẶT',
  //   type: 'group',
  //   children: [
  //     // {
  //     // key: 'payment-history',
  //     //   label: 'Lịch sử giao dịch',
  //     //   icon: <HistoryFillIcon className='shrink-0 w-4 h-4' />,
  //     // },
  //   ],
  // },
];

export const DashboardLayout = () => {
  useAuth();
  const { userData, walletBallance } = useUserStore();
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <AppLogicProvider>
      <Layout
        style={{
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
        }}>
        <Header>
          <div className='flex justify-between h-full items-center text-base'>
            <div className='flex space-x-5 items-center'>
              <div>
                <img src='assets/images/logo.png' alt='logo' className='h-6' />
              </div>

              <Button
                type='text'
                icon={<MenuLineIcon className='' />}
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
            <MenuHeader
              name={userData?.name || 'Admin'}
              wallet={walletBallance}
              imgUrl={userData?.profilePicture || ''}
            />
          </div>
        </Header>
        <Layout>
          <Sider
            breakpoint='lg'
            onBreakpoint={(isBroken) => {
              if (isBroken) {
                setCollapsed(true);
              }
            }}
            collapsed={collapsed}
            style={{ padding: '20px 10px' }}
            collapsedWidth={80}
            width={250}>
            <Menu
              mode='inline'
              defaultSelectedKeys={['home']}
              defaultOpenKeys={['home']}
              items={items}
              selectedKeys={pathname.split('/')}
              onClick={(info) => {
                // navigate(info.keyPath.join('/'));
                navigate(info.keyPath[0]);
              }}
              style={{ borderInlineEnd: '0px' }}
            />
          </Sider>

          <Content
            className='overflow-x-hidden overflow-y-auto'
            rootClassName='bg-[#F4F5F6]'>
            <div className='p-5 pb-10 min-h-[calc(100vh-64px-89px)]'>
              <Outlet />
            </div>
            <div className='px-2.5 py-5 flex flex-col justify-end text-end text-md border-t border-neutral-3'>
              <span>
                Copyright © {new Date().getFullYear()} vemaybay.vn. All rights
                reserved
              </span>
              <span>Site Operator: BiziTrip</span>
            </div>
          </Content>
        </Layout>
      </Layout>
    </AppLogicProvider>
  );
};
export default DashboardLayout;
