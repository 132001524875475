import {
  UserInfo,
  UserBasicInfo,
  UserStatus as UserStatusPartner,
  PartnerShopBasicInfo,
  OfficeInfo,
  OfficeStatus,
} from '@api/partner/base_pb';
import { GetOfficesRes } from '@api/partner/web_partnership/partner_pb';
import { CAItem } from '@api/partner/web_partnership/partner_pb';
export interface IUserInfo extends UserInfo.AsObject { }
export interface IUserBasicInfo extends UserBasicInfo.AsObject { }

export type EUserStatusPartner = UserStatusPartner;
export type EOfficeStatusPartner = OfficeStatus;
export const EOfficeStatusPartner = { ...OfficeStatus } as const;
export const EUserStatusPartner = { ...UserStatusPartner } as const;
export interface IPartnerShopBasicInfo extends PartnerShopBasicInfo.AsObject { }
export interface IOfficeInfo extends OfficeInfo.AsObject { }
export interface IListMyOfficesInfo extends GetOfficesRes.OfficeInfo.AsObject { }export interface ICAItem extends CAItem.AsObject { }
export interface ICAItem extends CAItem.AsObject { }