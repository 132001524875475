import { ETransactionType } from '@tixlabs/grpc-client';
import {
  ETransactionHotelType,
  EPaymentMethoddHotelType,
} from '@tixlabs/grpc-client/types/hotel';
import { SelectOptionItem } from '@tixlabs/types';
export const transactionTypeTopUpManual = 'Nạp tiền thủ công';
export const transactionTypeTopUpAutomatic = 'Nạp tiền tự động';

export const transactionTypeLabel = {
  [ETransactionType.TRANSACTIONTYPENONE]: 'Tất cả',
  [ETransactionType.TRANSACTIONTYPEPAY]: 'Thanh toán',
  [ETransactionType.TRANSACTIONTYPEREFUND]: 'Hoàn tiền',
  [ETransactionType.TRANSACTIONTYPETOPUP]: transactionTypeTopUpAutomatic,
  [ETransactionType.TRANSACTIONTYPEWITHDRAW]: 'Rút tiền',
  [ETransactionType.TRANSACTIONTYPETOPUPMANUAL]: transactionTypeTopUpManual,
};

export const colorTransactionType = {
  [ETransactionType.TRANSACTIONTYPEREFUND]: 'text-red-6',
};

export const TransactionSourceByTypeTopUp = {
  [transactionTypeTopUpManual]: 'top_up_manual',
  [transactionTypeTopUpAutomatic]: 'top_up_automatic',
};

export const SELECT_TRANSACTION_TYPE_OPTIONS: SelectOptionItem[] = [
  {
    value: ETransactionType.TRANSACTIONTYPENONE,
    label: 'Tất cả',
  },
  {
    value: ETransactionType.TRANSACTIONTYPEPAY,
    label: 'Thanh toán',
  },
  {
    value: ETransactionType.TRANSACTIONTYPEREFUND,
    label: 'Hoàn tiền',
  },
  {
    value: ETransactionType.TRANSACTIONTYPETOPUPMANUAL,
    label: transactionTypeTopUpManual,
  },
  {
    value: ETransactionType.TRANSACTIONTYPETOPUP,
    label: transactionTypeTopUpAutomatic,
  },
];

export const transactionHotelTypeLabel = {
  [ETransactionHotelType.TRANSACTIONTYPEINVALID]: 'Tất cả',
  [ETransactionHotelType.PAY]: 'Thanh toán',
  [ETransactionHotelType.REFUND]: 'Hoàn tiền',
  [ETransactionHotelType.TOPUP]: transactionTypeTopUpAutomatic,
  [ETransactionHotelType.WITHDRAW]: 'Rút tiền',
  [ETransactionHotelType.TOPUPMANUAL]: transactionTypeTopUpManual,
};

export const TransactionHotelSourceByTypeTopUp = {
  [transactionTypeTopUpManual]: 'top_up_manual',
  [transactionTypeTopUpAutomatic]: 'top_up_automatic',
};

export const SELECT_TRANSACTION_HOTEL_TYPE_OPTIONS: SelectOptionItem[] = [
  {
    value: ETransactionHotelType.TRANSACTIONTYPEINVALID,
    label: 'Tất cả',
  },
  {
    value: ETransactionHotelType.PAY,
    label: 'Thanh toán',
  },
  {
    value: ETransactionHotelType.REFUND,
    label: 'Hoàn tiền',
  },
  {
    value: ETransactionHotelType.TOPUPMANUAL,
    label: transactionTypeTopUpManual,
  },
  {
    value: ETransactionHotelType.TOPUP,
    label: transactionTypeTopUpAutomatic,
  },
];

export const SELECT_TRANSACTION_HOTEL_TYPE_OPTIONS_SUB_AGENT: SelectOptionItem[] = [
  {
    value: ETransactionHotelType.TRANSACTIONTYPEINVALID,
    label: 'Tất cả',
  },
  {
    value: ETransactionHotelType.PAY,
    label: 'Thanh toán',
  },
  {
    value: ETransactionHotelType.REFUND,
    label: 'Hoàn tiền',
  },
];

export const transactionPaymentMethodHotelLabel = {
  [EPaymentMethoddHotelType.METHODNONE]: 'Không có phương thức',
  [EPaymentMethoddHotelType.PAYPAL]: 'PayPal',
  [EPaymentMethoddHotelType.VNPAYEWALLET]: 'Ví điện tử VNPay',
  [EPaymentMethoddHotelType.VNPAYCREDITCARD]: 'Thẻ tín dụng VNPay',
  [EPaymentMethoddHotelType.VNPAYBANKTRANSFER]: 'Chuyển khoản VNPay',
  [EPaymentMethoddHotelType.VNPAYQRCODE]: 'QR VNPay',
  [EPaymentMethoddHotelType.MANUALBANKTRANSFER]:
    'Chuyển khoản ngân hàng thủ công',
  [EPaymentMethoddHotelType.MOMO]: 'Momo',
  [EPaymentMethoddHotelType.TWOCHECKOUT]: '2Checkout',
  [EPaymentMethoddHotelType.PAYOOCREDITCARD]: 'Thẻ tín dụng Payoo',
  [EPaymentMethoddHotelType.PAYOODOMESTICCARD]: 'Thẻ nội địa Payoo',
  [EPaymentMethoddHotelType.PAYOOQRCODE]: 'QR Payoo',
  [EPaymentMethoddHotelType.ONEPAYCREDITCARD]: 'Thẻ tín dụng OnePay',
  [EPaymentMethoddHotelType.ONEPAYCREDITCARDDOMESTICISSUE]:
    'Thẻ phát hành nội địa OnePay',
  [EPaymentMethoddHotelType.ONEPAYDOMESTICCARD]: 'Thẻ nội địa OnePay',
  [EPaymentMethoddHotelType.ONEPAYQRCODE]: 'QR OnePay',
  [EPaymentMethoddHotelType.APPLEPAY]: 'Apple Pay',
  [EPaymentMethoddHotelType.STRIPE]: 'Stripe',
  [EPaymentMethoddHotelType.BIDVQR]: 'QR BIDV',
  [EPaymentMethoddHotelType.EGIFTCARD]: 'Thẻ quà tặng điện tử',
  [EPaymentMethoddHotelType.WALLET]: 'Quỹ xuất vé',
  [EPaymentMethoddHotelType.NINEPAY]: 'Thanh toán qua 9Pay',
  [EPaymentMethoddHotelType.NINEPAYCREDITCARD]: 'Thẻ tín dụng 9Pay',
  [EPaymentMethoddHotelType.NINEPAYBANKTRANSFER]: 'Chuyển khoản ngân hàng 9Pay',
  [EPaymentMethoddHotelType.NINEPAYATMCARD]: 'Thẻ ATM 9Pay',
  [EPaymentMethoddHotelType.ONEFIN]: 'OneFin',
};
