/**
 * @fileoverview gRPC-Web generated client stub for airplane.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web_partnership/flight.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var airplane_base_pb = require('../../airplane/base_pb.js')

var airplane_hub_pb = require('../../airplane/hub_pb.js')

var airplane_flight_pb = require('../../airplane/flight_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web_partnership = require('./flight_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partnership.FlightServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partnership.FlightServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.SearchAirlinesReq,
 *   !proto.airplane.web_partnership.SearchAirlinesRes>}
 */
const methodDescriptor_FlightService_SearchAirlines = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/SearchAirlines',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.SearchAirlinesReq,
  proto.airplane.web_partnership.SearchAirlinesRes,
  /**
   * @param {!proto.airplane.web_partnership.SearchAirlinesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.SearchAirlinesRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.SearchAirlinesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.SearchAirlinesRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.SearchAirlinesRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.searchAirlines =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/SearchAirlines',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirlines,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.SearchAirlinesReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.SearchAirlinesRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.searchAirlines =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/SearchAirlines',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirlines);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.RetrieveBookingFlightReq,
 *   !proto.airplane.web_partnership.RetrieveBookingFlightRes>}
 */
const methodDescriptor_FlightService_RetrieveBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/RetrieveBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.RetrieveBookingFlightReq,
  proto.airplane.web_partnership.RetrieveBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partnership.RetrieveBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.RetrieveBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.RetrieveBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.RetrieveBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.retrieveBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/RetrieveBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.RetrieveBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.retrieveBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/RetrieveBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ListBookingFlightReq,
 *   !proto.airplane.web_partnership.ListBookingFlightRes>}
 */
const methodDescriptor_FlightService_ListBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/ListBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ListBookingFlightReq,
  proto.airplane.web_partnership.ListBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partnership.ListBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ListBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ListBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ListBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ListBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.listBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/ListBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ListBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ListBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.listBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/ListBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ExportBookingFlightReq,
 *   !proto.airplane.web_partnership.ExportBookingFlightRes>}
 */
const methodDescriptor_FlightService_ExportBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/ExportBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ExportBookingFlightReq,
  proto.airplane.web_partnership.ExportBookingFlightRes,
  /**
   * @param {!proto.airplane.web_partnership.ExportBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ExportBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ExportBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ExportBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ExportBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.exportBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/ExportBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ExportBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ExportBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ExportBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.exportBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/ExportBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ExportBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.RefundBookingReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_FlightService_RefundBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/RefundBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.RefundBookingReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partnership.RefundBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.RefundBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.refundBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/RefundBooking',
      request,
      metadata || {},
      methodDescriptor_FlightService_RefundBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.RefundBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.refundBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/RefundBooking',
      request,
      metadata || {},
      methodDescriptor_FlightService_RefundBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.GetAirportsReq,
 *   !proto.airplane.web_partnership.GetAirportsRes>}
 */
const methodDescriptor_FlightService_GetAirports = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/GetAirports',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.GetAirportsReq,
  proto.airplane.web_partnership.GetAirportsRes,
  /**
   * @param {!proto.airplane.web_partnership.GetAirportsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.GetAirportsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.GetAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.GetAirportsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.GetAirportsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.getAirports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/GetAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetAirports,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.GetAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.GetAirportsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.getAirports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/GetAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetAirports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ListTransferBookingRequestReq,
 *   !proto.airplane.web_partnership.ListTransferBookingRequestRes>}
 */
const methodDescriptor_FlightService_ListTransferBookingRequest = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/ListTransferBookingRequest',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ListTransferBookingRequestReq,
  proto.airplane.web_partnership.ListTransferBookingRequestRes,
  /**
   * @param {!proto.airplane.web_partnership.ListTransferBookingRequestReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ListTransferBookingRequestRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ListTransferBookingRequestRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ListTransferBookingRequestRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.listTransferBookingRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/ListTransferBookingRequest',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListTransferBookingRequest,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ListTransferBookingRequestRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.listTransferBookingRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/ListTransferBookingRequest',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListTransferBookingRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ListBookingFlightPartialRoundtripReq,
 *   !proto.airplane.web_partnership.ListBookingFlightPartialRoundtripRes>}
 */
const methodDescriptor_FlightService_ListBookingFlightPartialRoundtrip = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/ListBookingFlightPartialRoundtrip',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ListBookingFlightPartialRoundtripReq,
  proto.airplane.web_partnership.ListBookingFlightPartialRoundtripRes,
  /**
   * @param {!proto.airplane.web_partnership.ListBookingFlightPartialRoundtripReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ListBookingFlightPartialRoundtripRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ListBookingFlightPartialRoundtripReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ListBookingFlightPartialRoundtripRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ListBookingFlightPartialRoundtripRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.listBookingFlightPartialRoundtrip =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/ListBookingFlightPartialRoundtrip',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlightPartialRoundtrip,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ListBookingFlightPartialRoundtripReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ListBookingFlightPartialRoundtripRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.listBookingFlightPartialRoundtrip =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/ListBookingFlightPartialRoundtrip',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlightPartialRoundtrip);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.UpdateBookingFlightPartialRoundtripReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_FlightService_UpdateBookingFlightPartialRoundtrip = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/UpdateBookingFlightPartialRoundtrip',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.UpdateBookingFlightPartialRoundtripReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partnership.UpdateBookingFlightPartialRoundtripReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.UpdateBookingFlightPartialRoundtripReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.updateBookingFlightPartialRoundtrip =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/UpdateBookingFlightPartialRoundtrip',
      request,
      metadata || {},
      methodDescriptor_FlightService_UpdateBookingFlightPartialRoundtrip,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.UpdateBookingFlightPartialRoundtripReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.updateBookingFlightPartialRoundtrip =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/UpdateBookingFlightPartialRoundtrip',
      request,
      metadata || {},
      methodDescriptor_FlightService_UpdateBookingFlightPartialRoundtrip);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsReq,
 *   !proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsRes>}
 */
const methodDescriptor_FlightService_GetBookingFlightPartialRoundtripDetails = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.FlightService/GetBookingFlightPartialRoundtripDetails',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsReq,
  proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsRes,
  /**
   * @param {!proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.FlightServiceClient.prototype.getBookingFlightPartialRoundtripDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/GetBookingFlightPartialRoundtripDetails',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetBookingFlightPartialRoundtripDetails,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.GetBookingFlightPartialRoundtripDetailsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.FlightServicePromiseClient.prototype.getBookingFlightPartialRoundtripDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.FlightService/GetBookingFlightPartialRoundtripDetails',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetBookingFlightPartialRoundtripDetails);
};


module.exports = proto.airplane.web_partnership;

