import { IPaginationRes } from '@tixlabs/grpc-client';
import { IReferenceItem } from '@tixlabs/grpc-client/web-partnership';
import { useTime } from '@access-trade-admin/hooks/internals';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import cn from 'classnames';
import { Status } from './Status';

type Props = {
  listReference: IReferenceItem[];
  isLoading: boolean;
  pagination?: IPaginationRes;
  isExistPagination?: boolean;
  onPageChange?: (page: number) => void;
};

const TitleColumns = ({ title }: { title: string }) => {
  return <span className='text-neutral-black'>{title}</span>;
};

const TableContent = ({
  isLoading,
  listReference,
  pagination,
  isExistPagination = true,
  onPageChange,
}: Props) => {
  const { formatDateTime } = useTime();

  const columns: ColumnProps<IReferenceItem>[] = [
    {
      title: () => {
        return <TitleColumns title='Thời gian' />;
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, referenceItem) => {
        return <span>{formatDateTime(referenceItem.createdAt)}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Tên' />;
      },
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: () => {
        return <TitleColumns title='Website' />;
      },
      dataIndex: 'website',
      key: 'website',
      render: (_, referenceItem) => {
        return (
          <a href={referenceItem.website} target='_blank' rel='noreferrer'>
            {referenceItem.website}
          </a>
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Trạng thái' />;
      },
      dataIndex: 'status',
      key: 'status',
      render: (_, referenceItem) => {
        return <Status status={referenceItem.status} />;
      },
    },
  ];

  return (
    <div className='p-2.5 bg-white rounded-lg'>
      <Table
        dataSource={listReference.map((order, index) => {
          return {
            ...order,
            key: index,
          };
        })}
        columns={columns}
        size='small'
        rowClassName={(record, index) => {
          return cn('font-semibold whitespace-nowrap', {
            'bg-[#F8F8FB]': index % 2 === 0,
          });
        }}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: () => {
        //       navigate(`./${record.bookingCode}`);
        //     },
        //   };
        // }}
        scroll={{ x: 1000 }}
        pagination={
          isExistPagination
            ? {
                pageSize: pagination?.pageLimit || 1,
                current: pagination?.pageCurrent || 1,
                total: pagination?.totalRecord || 1,
                className: 'space-x-2.5',
                onChange: onPageChange,
              }
            : false
        }
        loading={isLoading}
      />
    </div>
  );
};

export default TableContent;
