import { IBookingPassenger } from '@tixlabs/grpc-client/';
import { useCountry } from '@vemaybay-admin/hooks/apps';
import { useTime } from '@vemaybay-admin/hooks/internals';
import { GENDER_TYPE_LABEL, PAX_TYPE_LABEL } from '@vemaybay-admin/utils';

type Props = {
  passengersList: IBookingPassenger[];
};

export const PassengerInfoItemCoordination = ({ passengersList }: Props) => {
  const { formatDate } = useTime();
  const { countriesObjectByCode } = useCountry();
  return (
    <div className='flex flex-col px-4 py-3'>
      {passengersList.map((passenger, index) => {
        return (
          <div key={index} className='grid grid-cols-4'>
            <div className='flex flex-col gap-0.5 py-2'>
              <h4 className='mb-0 font-semibold text-neutral-black uppercase'>
                {`${passenger.lastName}/${passenger.firstName}`}
              </h4>
              <span className='text-xs text-neutral-gray-50'>
                {PAX_TYPE_LABEL[passenger.type]}
              </span>
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-col gap-0.5 py-2'>
                <span className='text-xs text-neutral-gray-50'>Ngày sinh</span>
                <span className='text-neutral-black'>
                  {formatDate(passenger.dob)}
                </span>
              </div>
              {passenger.passportNumber ? (
                <div className='flex flex-col gap-0.5 py-2'>
                  <span className='text-xs text-neutral-gray-50'>
                    Số hộ chiếu
                  </span>
                  <span className='text-neutral-black'>
                    {passenger.passportNumber}
                  </span>
                </div>
              ) : null}
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-col gap-0.5 py-2'>
                <span className='text-xs text-neutral-gray-50'>Giới tính</span>
                <span className='text-neutral-black'>
                  {GENDER_TYPE_LABEL[passenger.gender]}
                </span>
              </div>
              {passenger.expirationDate && passenger.expirationDate > 0 ? (
                <div className='flex flex-col gap-0.5 py-2'>
                  <span className='text-xs text-neutral-gray-50'>
                    Ngày hết hạn
                  </span>
                  <span className='text-neutral-black'>
                    {formatDate(passenger.expirationDate)}
                  </span>
                </div>
              ) : null}
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-col gap-0.5 py-2'>
                <span className='text-xs text-neutral-gray-50'>Quốc tịch</span>
                <span className='text-neutral-black'>
                  {countriesObjectByCode[passenger.nationality || ''].country}
                </span>
              </div>
              {passenger.idIssueCountry ? (
                <div className='flex flex-col gap-0.5 py-2'>
                  <span className='text-xs text-neutral-gray-50'>Nơi cấp</span>
                  <span className='text-neutral-black'>
                    {
                      countriesObjectByCode[passenger.idIssueCountry || '']
                        .country
                    }
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};
