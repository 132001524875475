import { Breadcrumb } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusOrder } from './StatusOrder';
import {
  IRetrieveBookingFlightData,
  flightApiService,
  IBookingFlightPartialRoundtripRes,
} from '@tixlabs/grpc-client/web-partnership';
import { useAppMutation } from '@vemaybay-admin/hooks/internals';
import { Form, LoadingLogoFullPage } from '@common-ui';
import {
  ContactInfo,
  InvoiceInfoView,
  ItineraryList,
  PassengerInfoList,
  PaymentInfo,
  ProcessingOrder,
} from '@vemaybay-admin/components/Flight';
import useAirport from '@vemaybay-admin/hooks/stores/useAirport';
import { useForm, useWatch } from 'react-hook-form';
import {
  ContactInfoCoordination,
  InvoiceInfoViewCoordination,
  ItineraryListCoordination,
  PassengerInfoListCoordination,
  PaymentInfoCoordination,
} from '@vemaybay-admin/components/TicketCoordination';
import { ECoordinationStatus } from '@tixlabs/grpc-client';

export const DetailTicketCoordination = () => {
  const { bookingCode } = useParams();
  const { fetchAirports } = useAirport();
  const [bookingFlight, setBookingFlight] =
    useState<IBookingFlightPartialRoundtripRes>();
  const navigate = useNavigate();

  const { mutateAsync: getBookingFlightPartialRoundtripDetails, isLoading } =
    useAppMutation({
      mutationKey: [
        'flightApiService',
        'getBookingFlightPartialRoundtripDetails',
      ],
      mutationFn: flightApiService.getBookingFlightPartialRoundtripDetails,
      onSuccess: async ({ isSuccess, data, errorCode }) => {
        if (isSuccess && data) {
          // filter order has itinerariesList empty
          setBookingFlight(data);
          methods.setValue('note', data.note);
          methods.setValue('status', data.status);
          const tempSetListAirport = new Set<string>();
          data.itinerariesList.forEach((itinerary) => {
            itinerary.segmentsList.forEach((segment) => {
              tempSetListAirport.add(segment.departPlace);
              tempSetListAirport.add(segment.arrivalPlace);
            });
          });
          await fetchAirports([...Array.from(tempSetListAirport)]);
        } else {
          console.log('error', errorCode);
        }
      },
      onError: (err) => {
        console.log('oops...', err);
      },
    });

  const {
    mutateAsync: updateBookingFlightPartialRoundtrip,
    isLoading: isLoadingUpte,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'updateBookingFlightPartialRoundtrip'],
    mutationFn: flightApiService.updateBookingFlightPartialRoundtrip,
    onSuccess: async ({ isSuccess, errorCode }) => {
      await getBookingFlightInfo();
    },
    onError: (err) => {
      console.log('oops...', err);
    },
  });

  const handleUpdateBookingFlightPartialRoundtrip = useCallback(async () => {
    await updateBookingFlightPartialRoundtrip({
      bookingCode: bookingCode || '',
      status: Number(methods.getValues('status')),
      note: methods.getValues('note'),
    });
  }, []);

  const handleRefundBookingSuccess = async () => {
    await getBookingFlightInfo();
  };

  const getBookingFlightInfo = useCallback(async () => {
    await getBookingFlightPartialRoundtripDetails({
      bookingCode: bookingCode || '',
    });
  }, []);

  useEffect(() => {
    getBookingFlightInfo();
  }, []);

  /////////////////////////////
  const [checkLengthNote, setCheckLengthNote] = useState<boolean>(false);
  const methods = useForm({
    defaultValues: {
      status: ECoordinationStatus.COORDINATIONSTATUSNONE,
      note: '',
    },
  });

  const noteValue = useWatch({
    name: 'note',
    control: methods.control,
  });

  const handleSubmit = (data: any) => {
    handleUpdateBookingFlightPartialRoundtrip();
  };

  // useEffect(() => {
  //   if (noteValue.length > 200) {
  //     setCheckLengthNote(true);
  //     methods.setValue('note', noteValue.slice(0, -1));
  //   } else {
  //     setCheckLengthNote(false);
  //   }
  // }, [noteValue]);

  return isLoading ? (
    <LoadingLogoFullPage />
  ) : (
    <div className='flex flex-col gap-2.5'>
      <div className='flex flex-col gap-1 5 py-3'>
        <h3 className='text-xl font-bold text-neutral-black mb-0'>
          Chi tiết đặt chỗ
        </h3>
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className='text-primary-6 cursor-pointer hover:text-primary-5'
                  onClick={() => {
                    navigate('/');
                  }}>
                  Trang chủ
                </span>
              ),
            },
            {
              title: (
                <span
                  className='text-primary-6 font-semibold cursor-pointer hover:text-primary-5'
                  onClick={() => {
                    navigate('/ticket-coordination');
                  }}>
                  Điều phối vé
                </span>
              ),
            },
          ]}
        />
      </div>
      <div className='grid grid-cols-12 gap-4'>
        <div className='flex flex-col gap-2 col-span-9'>
          {bookingFlight && (
            <>
              <StatusOrder
                bookingFlight={bookingFlight}
                handleRefundBookingSuccess={handleRefundBookingSuccess}
              />
              <ItineraryListCoordination bookingFlight={bookingFlight} />
              <PassengerInfoListCoordination bookingFlight={bookingFlight} />
              <ContactInfoCoordination bookingFlight={bookingFlight} />

              {bookingFlight.invoicingInformation && (
                <InvoiceInfoViewCoordination
                  data={bookingFlight.invoicingInformation}
                />
              )}

              <PaymentInfoCoordination bookingFlight={bookingFlight} />
            </>
          )}
        </div>
        <div className='flex flex-col col-span-3'>
          <Form methods={methods} onSubmit={handleSubmit}>
            <ProcessingOrder checkLenghtNote={checkLengthNote} />
          </Form>
        </div>
      </div>
    </div>
  );
};
