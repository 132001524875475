import { BasicRes, PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { HotelServiceClient } from '@api/hotel/web_partnership/hotel_grpc_web_pb';
import {
  ExportBookingsReq,
  ExportBookingsRes,
  GetBookingDetailRequest,
  GetBookingDetailResponse,
  ListBookingsFilter,
  ListBookingsRequest,
  ListBookingsResponse,
  OccupancyUpdateInfo,
  UpdateBookingStatusRequest,
  UpdateBookingStatusResponse,
  ConfirmRefundReq,
  ListCancelBookingsRequest,
  ListCancelBookingsFilter,
  ExportCancelBookingsReq,
} from '@api/hotel/web_partnership/hotel_pb';

export class HotelServiceApi extends BaseApi<HotelServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HotelServiceClient(this.url, null, this.optsDev);
  }

  getBookingDetail = ({
    orderCode,
  }: GetBookingDetailRequest.AsObject): Promise<GetBookingDetailResponse.AsObject> => {
    const req = new GetBookingDetailRequest();
    req.setOrderCode(orderCode);
    return this.grpc<
      GetBookingDetailRequest,
      GetBookingDetailResponse,
      GetBookingDetailResponse.AsObject
    >(this.serviceClient.getBookingDetail, req, {});
  };

  confirmRefund = ({
    orderCode,
    note,
  }: ConfirmRefundReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new ConfirmRefundReq();
    req.setOrderCode(orderCode);
    req.setNote(note);
    return this.grpc<ConfirmRefundReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.confirmRefund,
      req,
      {}
    );
  };

  listBookings = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingsRequest.AsObject): Promise<ListBookingsResponse.AsObject> => {
    const req = new ListBookingsRequest();
    const filterObj = new ListBookingsFilter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.to &&
        filterObj.setTo(new Date(filter.to).setHours(23, 59, 59, 999));
      filter.from &&
        filterObj.setFrom(new Date(filter.from).setHours(0, 0, 0, 0));
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setStatusList(filter.statusList);
      filterObj.setNotInStatusesList(filter.notInStatusesList);
      filter.partnerShopId && filterObj.setPartnerShopId(filter.partnerShopId);
      filter.bookerId && filterObj.setBookerId(filter.bookerId);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    return this.grpc<
      ListBookingsRequest,
      ListBookingsResponse,
      ListBookingsResponse.AsObject
    >(this.serviceClient.listBookings, req, {});
  };

  listCancelBookings = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListCancelBookingsRequest.AsObject): Promise<ListBookingsResponse.AsObject> => {
    const req = new ListCancelBookingsRequest();
    const filterObj = new ListCancelBookingsFilter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.to &&
        filterObj.setTo(new Date(filter.to).setHours(23, 59, 59, 999));
      filter.from &&
        filterObj.setFrom(new Date(filter.from).setHours(0, 0, 0, 0));
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setStatusList(filter.statusList);
      filterObj.setNotInStatusesList(filter.notInStatusesList);
      filter.partnerShopId && filterObj.setPartnerShopId(filter.partnerShopId);
      filter.bookerId && filterObj.setBookerId(filter.bookerId);
      if (filter.refunded !== undefined) {
        filterObj.setRefunded(filter.refunded);
      }
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    console.log('req list cancel:', req);

    return this.grpc<
      ListCancelBookingsRequest,
      ListBookingsResponse,
      ListBookingsResponse.AsObject
    >(this.serviceClient.listCancelBookings, req, {});
  };

  updateBookingStatus = ({
    actualAmount,
    orderCode,
    cancelReason,
    status,
    occupancyUpdatesList,
    source,
  }: UpdateBookingStatusRequest.AsObject): Promise<UpdateBookingStatusResponse.AsObject> => {
    const req = new UpdateBookingStatusRequest();
    req.setActualAmount(actualAmount);
    req.setOrderCode(orderCode);
    req.setStatus(status);
    req.setCancelReason(cancelReason);
    req.setSource(source);

    const occupancyUpdatesListObj: OccupancyUpdateInfo[] = [];
    for (let i = 0; i < occupancyUpdatesList.length; i++) {
      const occupancyUpdatesListItem = new OccupancyUpdateInfo();
      occupancyUpdatesListItem.setOccupancyIndex(
        occupancyUpdatesList[i].occupancyIndex
      );
      occupancyUpdatesListItem.setConfirmationId(
        occupancyUpdatesList[i].confirmationId
      );
      occupancyUpdatesListObj.push(occupancyUpdatesListItem);
    }
    req.setOccupancyUpdatesList(occupancyUpdatesListObj);
    return this.grpc<
      UpdateBookingStatusRequest,
      UpdateBookingStatusResponse,
      UpdateBookingStatusResponse.AsObject
    >(this.serviceClient.updateBookingStatus, req, {});
  };

  exportBookings = ({
    filter,
  }: ExportBookingsReq.AsObject): Promise<ExportBookingsRes.AsObject> => {
    const req = new ExportBookingsReq();
    const filterObj = new ListBookingsFilter();
    if (filter) {
      filter.to && filterObj.setTo(filter.to);
      filter.from && filterObj.setFrom(filter.from);
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setStatusList(filter.statusList);
      filterObj.setNotInStatusesList(filter.notInStatusesList);
      filter.partnerShopId && filterObj.setPartnerShopId(filter.partnerShopId);
      filter.bookerId && filterObj.setBookerId(filter.bookerId);
    }

    req.setFilter(filterObj);

    return this.grpc<
      ExportBookingsReq,
      ExportBookingsRes,
      ExportBookingsRes.AsObject
    >(this.serviceClient?.exportBookings, req, {});
  };

  exportCancelBookings = ({
    filter,
  }: ExportCancelBookingsReq.AsObject): Promise<ExportBookingsRes.AsObject> => {
    const req = new ExportCancelBookingsReq();
    const filterObj = new ListCancelBookingsFilter();
    if (filter) {
      filter.to &&
        filterObj.setTo(new Date(filter.to).setHours(23, 59, 59, 999));
      filter.from &&
        filterObj.setFrom(new Date(filter.from).setHours(0, 0, 0, 0));
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setStatusList(filter.statusList);
      filterObj.setNotInStatusesList(filter.notInStatusesList);
      filter.partnerShopId && filterObj.setPartnerShopId(filter.partnerShopId);
      filter.bookerId && filterObj.setBookerId(filter.bookerId);
      if (filter.refunded !== undefined) {
        filterObj.setRefunded(filter.refunded);
      }
    }

    req.setFilter(filterObj);

    console.log('req export cancel: ', req)

    return this.grpc<
      ExportCancelBookingsReq,
      ExportBookingsRes,
      ExportBookingsRes.AsObject
    >(this.serviceClient?.exportCancelBookings, req, {});
  };

  // cancelBooking = ({
  //   orderCode,
  // }: CancelBookingReq.AsObject): Promise<CancelBookingRes.AsObject> => {
  //   const req = new CancelBookingReq();
  //   req.setOrderCode(orderCode);

  //   return this.grpc<
  //     CancelBookingReq,
  //     CancelBookingRes,
  //     CancelBookingRes.AsObject
  //   >(this.serviceClient?.cancelBooking, req, {});
  // };
}

export const hotelApiServiceWebPartnership = new HotelServiceApi();
export default hotelApiServiceWebPartnership;
