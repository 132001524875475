import { StatisticCardInfo } from '@common-ui/sky-booking';
import { useAppMutation } from '@access-trade-admin/hooks/internals';
import {
  IFilterCommissionReq,
  IReportCommissionStatsData,
  reportService,
} from '@tixlabs/grpc-client/web-partnership';
import { useCurrency } from '@access-trade-admin/hooks/internals';
import React, { useEffect, useState } from 'react';

type Props = {
  filterCommissionStats: IFilterCommissionReq;
};

const CommissionStats = ({ filterCommissionStats }: Props) => {
  const { formatCurrency } = useCurrency();

  const [reportCommissionStatsData, setReportCommissionStatsData] =
    useState<IReportCommissionStatsData>({
      totalCommission: 0,
      approved: 0,
      profit: 0,
      serviceFee: 0,
    });

  const { mutateAsync: reportCommissionStats, isLoading } = useAppMutation({
    mutationKey: ['reportServicePartnerShip', 'reportCommissionStats'],
    mutationFn: reportService.reportCommissionStats,
    onSuccess(data) {
      if (data.isSuccess && data.data) {
        setReportCommissionStatsData(data.data);
      }
    },
  });

  useEffect(() => {
    reportCommissionStats(filterCommissionStats);
  }, [
    filterCommissionStats.bookingCode,
    filterCommissionStats.from,
    filterCommissionStats.to,
    filterCommissionStats.status,
  ]);

  return (
    <div className='grid grid-cols-3 gap-3 w-full pb-5'>
      {isLoading ? (
        <>
          {[1, 2, 3].map((item, index) => (
            <div
              key={index}
              className='flex-1 rounded-lg h-[90px] animate-pulse bg-neutral-3'></div>
          ))}
        </>
      ) : (
        <>
          <StatisticCardInfo
            className='border-none bg-white'
            title='Tổng phí dịch vụ'
            value={
              <span className='text-primary'>
                {formatCurrency(reportCommissionStatsData.serviceFee)}
              </span>
            }
            iconKey='booking'
          />
          <StatisticCardInfo
            className='border-none bg-white'
            title='Tổng hoa hồng đã xác nhận'
            value={
              <span className='text-primary'>
                {formatCurrency(reportCommissionStatsData.approved)}
              </span>
            }
            iconKey='booking'
          />
          <StatisticCardInfo
            className='border-none bg-white'
            title='Tổng lợi nhuận'
            value={
              <span className='text-green-6'>
                {formatCurrency(reportCommissionStatsData.profit)}
              </span>
            }
            iconKey='booking'
          />
        </>
      )}
    </div>
  );
};

export default CommissionStats;
