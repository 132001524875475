import { EBookingStatus } from '@tixlabs/grpc-client';
import { BOOKING_STATUS_LABEL } from '@vemaybay-admin/utils';
import cn from 'classnames';

type Props = {
  //
  status: EBookingStatus;
  className?: string;
  classNameText?: string;
};

// const objIcon = {
//   [EBookingStatus.BOOKINGSTATUSEXPIRED]: (
//     <ForbidFillIcon className='text-red-6 w-4 h-4' />
//   ),
//   [EBookingStatus.BOOKINGSTATUSCANCELLED]: (
//     <ForbidFillIcon className='text-red-6 w-4 h-4' />
//   ),
//   [EBookingStatus.BOOKINGSTATUSFAILED]: (
//     <Ticket2FillIcon className='text-neutral-40 w-4 h-4' />
//   ),
//   [EBookingStatus.INPROGRESS]: (
//     <CheckFillIcon className='text-secondary-6 w-4 h-4' />
//   ),
//   [EBookingStatus.BOOKINGSTATUSPAID]: (
//     <Ticket2FillIcon className='text-success w-4 h-4' />
//   ),
//   [EBookingStatus.COMPLETE]: (
//     <Ticket2FillIcon className='text-success w-4 h-4' />
//   ),
// };

const objClassName = {
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: 'text-neutral-40',
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: 'text-neutral-40',
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'text-neutral-40',
  [EBookingStatus.INPROGRESS]: 'text-common-warning',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'text-common-warning',
  [EBookingStatus.COMPLETE]: 'text-common-success',
  [EBookingStatus.BOOKINGSTATUSREFUND]: 'text-neutral-40',
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: 'text-common-error',
  [EBookingStatus.BOOKINGSTATUSNOTCOMPLETE]: 'text-common-warning',
};

export const Status = ({ status, className, classNameText }: Props) => {
  return (
    <div className={cn('', className)}>
      <span
        className={cn('font-semibold', objClassName[status], classNameText)}>
        {/* {status} */}
        {BOOKING_STATUS_LABEL[status]}
      </span>
    </div>
  );
};
