// source: price/web_partnership/hidden_fee.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var price_base_pb = require('../../price/base_pb.js');
goog.object.extend(proto, price_base_pb);
var price_hidden_fee_pb = require('../../price/hidden_fee_pb.js');
goog.object.extend(proto, price_hidden_fee_pb);
goog.exportSymbol('proto.price.web_partnership.CreateHiddenFeeReq', null, global);
goog.exportSymbol('proto.price.web_partnership.CreateHiddenFeeRes', null, global);
goog.exportSymbol('proto.price.web_partnership.DeleteHiddenFeeReq', null, global);
goog.exportSymbol('proto.price.web_partnership.DetailHiddenFeeReq', null, global);
goog.exportSymbol('proto.price.web_partnership.DetailHiddenFeeRes', null, global);
goog.exportSymbol('proto.price.web_partnership.ImportHiddenFeeReq', null, global);
goog.exportSymbol('proto.price.web_partnership.ImportHiddenFeeRes', null, global);
goog.exportSymbol('proto.price.web_partnership.ListHiddenFeeFilter', null, global);
goog.exportSymbol('proto.price.web_partnership.ListHiddenFeeReq', null, global);
goog.exportSymbol('proto.price.web_partnership.ListHiddenFeeRes', null, global);
goog.exportSymbol('proto.price.web_partnership.UpdateHiddenFeeReq', null, global);
goog.exportSymbol('proto.price.web_partnership.UpdateHiddenFeeRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ListHiddenFeeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.ListHiddenFeeFilter.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.ListHiddenFeeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ListHiddenFeeFilter.displayName = 'proto.price.web_partnership.ListHiddenFeeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ListHiddenFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.ListHiddenFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ListHiddenFeeReq.displayName = 'proto.price.web_partnership.ListHiddenFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.DetailHiddenFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.DetailHiddenFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.DetailHiddenFeeReq.displayName = 'proto.price.web_partnership.DetailHiddenFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.CreateHiddenFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.CreateHiddenFeeReq.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.CreateHiddenFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.CreateHiddenFeeReq.displayName = 'proto.price.web_partnership.CreateHiddenFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.DeleteHiddenFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.DeleteHiddenFeeReq.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.DeleteHiddenFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.DeleteHiddenFeeReq.displayName = 'proto.price.web_partnership.DeleteHiddenFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ImportHiddenFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.ImportHiddenFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ImportHiddenFeeReq.displayName = 'proto.price.web_partnership.ImportHiddenFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.UpdateHiddenFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.UpdateHiddenFeeReq.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.UpdateHiddenFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.UpdateHiddenFeeReq.displayName = 'proto.price.web_partnership.UpdateHiddenFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ListHiddenFeeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.ListHiddenFeeRes.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.ListHiddenFeeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ListHiddenFeeRes.displayName = 'proto.price.web_partnership.ListHiddenFeeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.DetailHiddenFeeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.DetailHiddenFeeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.DetailHiddenFeeRes.displayName = 'proto.price.web_partnership.DetailHiddenFeeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.CreateHiddenFeeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.CreateHiddenFeeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.CreateHiddenFeeRes.displayName = 'proto.price.web_partnership.CreateHiddenFeeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ImportHiddenFeeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.ImportHiddenFeeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ImportHiddenFeeRes.displayName = 'proto.price.web_partnership.ImportHiddenFeeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.UpdateHiddenFeeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.UpdateHiddenFeeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.UpdateHiddenFeeRes.displayName = 'proto.price.web_partnership.UpdateHiddenFeeRes';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.ListHiddenFeeFilter.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ListHiddenFeeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ListHiddenFeeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListHiddenFeeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    officeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    location: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accommodationTypeList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    ratingList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f,
    countryCodeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    keywords: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter}
 */
proto.price.web_partnership.ListHiddenFeeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ListHiddenFeeFilter;
  return proto.price.web_partnership.ListHiddenFeeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ListHiddenFeeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter}
 */
proto.price.web_partnership.ListHiddenFeeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 2:
      var value = /** @type {!proto.price.HiddenFeeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.price.HotelLocationType} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccommodationType(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRating(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addCountryCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ListHiddenFeeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ListHiddenFeeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListHiddenFeeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAccommodationTypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getRatingList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
  f = message.getCountryCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getKeywords();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string office_id = 1;
 * @return {string}
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional price.HiddenFeeType type = 2;
 * @return {!proto.price.HiddenFeeType}
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.getType = function() {
  return /** @type {!proto.price.HiddenFeeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.price.HiddenFeeType} value
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional price.HotelLocationType location = 3;
 * @return {!proto.price.HotelLocationType}
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.getLocation = function() {
  return /** @type {!proto.price.HotelLocationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.price.HotelLocationType} value
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string accommodation_type = 4;
 * @return {!Array<string>}
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.getAccommodationTypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.setAccommodationTypeList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.addAccommodationType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.clearAccommodationTypeList = function() {
  return this.setAccommodationTypeList([]);
};


/**
 * repeated double rating = 5;
 * @return {!Array<number>}
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.getRatingList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.setRatingList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.addRating = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.clearRatingList = function() {
  return this.setRatingList([]);
};


/**
 * repeated string country_code = 6;
 * @return {!Array<string>}
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.getCountryCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.setCountryCodeList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.addCountryCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.clearCountryCodeList = function() {
  return this.setCountryCodeList([]);
};


/**
 * optional string keywords = 7;
 * @return {string}
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.getKeywords = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.ListHiddenFeeFilter} returns this
 */
proto.price.web_partnership.ListHiddenFeeFilter.prototype.setKeywords = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ListHiddenFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ListHiddenFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ListHiddenFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListHiddenFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.price.web_partnership.ListHiddenFeeFilter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ListHiddenFeeReq}
 */
proto.price.web_partnership.ListHiddenFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ListHiddenFeeReq;
  return proto.price.web_partnership.ListHiddenFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ListHiddenFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ListHiddenFeeReq}
 */
proto.price.web_partnership.ListHiddenFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.price.web_partnership.ListHiddenFeeFilter;
      reader.readMessage(value,proto.price.web_partnership.ListHiddenFeeFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ListHiddenFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ListHiddenFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ListHiddenFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListHiddenFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.price.web_partnership.ListHiddenFeeFilter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional ListHiddenFeeFilter filter = 1;
 * @return {?proto.price.web_partnership.ListHiddenFeeFilter}
 */
proto.price.web_partnership.ListHiddenFeeReq.prototype.getFilter = function() {
  return /** @type{?proto.price.web_partnership.ListHiddenFeeFilter} */ (
    jspb.Message.getWrapperField(this, proto.price.web_partnership.ListHiddenFeeFilter, 1));
};


/**
 * @param {?proto.price.web_partnership.ListHiddenFeeFilter|undefined} value
 * @return {!proto.price.web_partnership.ListHiddenFeeReq} returns this
*/
proto.price.web_partnership.ListHiddenFeeReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.ListHiddenFeeReq} returns this
 */
proto.price.web_partnership.ListHiddenFeeReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.ListHiddenFeeReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.price.web_partnership.ListHiddenFeeReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.price.web_partnership.ListHiddenFeeReq} returns this
*/
proto.price.web_partnership.ListHiddenFeeReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.ListHiddenFeeReq} returns this
 */
proto.price.web_partnership.ListHiddenFeeReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.ListHiddenFeeReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.DetailHiddenFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.DetailHiddenFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.DetailHiddenFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DetailHiddenFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.DetailHiddenFeeReq}
 */
proto.price.web_partnership.DetailHiddenFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.DetailHiddenFeeReq;
  return proto.price.web_partnership.DetailHiddenFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.DetailHiddenFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.DetailHiddenFeeReq}
 */
proto.price.web_partnership.DetailHiddenFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.DetailHiddenFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.DetailHiddenFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.DetailHiddenFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DetailHiddenFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.price.web_partnership.DetailHiddenFeeReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.DetailHiddenFeeReq} returns this
 */
proto.price.web_partnership.DetailHiddenFeeReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.CreateHiddenFeeReq.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.CreateHiddenFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.CreateHiddenFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.CreateHiddenFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    officeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: jspb.Message.getFieldWithDefault(msg, 4, 0),
    accommodationTypeList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    ratingList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    countryCodeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    hotelName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    hotelId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq}
 */
proto.price.web_partnership.CreateHiddenFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.CreateHiddenFeeReq;
  return proto.price.web_partnership.CreateHiddenFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.CreateHiddenFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq}
 */
proto.price.web_partnership.CreateHiddenFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 3:
      var value = /** @type {!proto.price.HiddenFeeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.price.HotelLocationType} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccommodationType(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRating(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addCountryCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.CreateHiddenFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.CreateHiddenFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.CreateHiddenFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAccommodationTypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getRatingList();
  if (f.length > 0) {
    writer.writePackedDouble(
      6,
      f
    );
  }
  f = message.getCountryCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getHotelName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string office_id = 1;
 * @return {string}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string provider = 2;
 * @return {string}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional price.HiddenFeeType type = 3;
 * @return {!proto.price.HiddenFeeType}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getType = function() {
  return /** @type {!proto.price.HiddenFeeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.price.HiddenFeeType} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional price.HotelLocationType location = 4;
 * @return {!proto.price.HotelLocationType}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getLocation = function() {
  return /** @type {!proto.price.HotelLocationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.price.HotelLocationType} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string accommodation_type = 5;
 * @return {!Array<string>}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getAccommodationTypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setAccommodationTypeList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.addAccommodationType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.clearAccommodationTypeList = function() {
  return this.setAccommodationTypeList([]);
};


/**
 * repeated double rating = 6;
 * @return {!Array<number>}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getRatingList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setRatingList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.addRating = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.clearRatingList = function() {
  return this.setRatingList([]);
};


/**
 * repeated string country_code = 7;
 * @return {!Array<string>}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getCountryCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setCountryCodeList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.addCountryCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.clearCountryCodeList = function() {
  return this.setCountryCodeList([]);
};


/**
 * optional string hotel_name = 8;
 * @return {string}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getHotelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setHotelName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double amount = 9;
 * @return {number}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double percent = 10;
 * @return {number}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string hotel_id = 11;
 * @return {string}
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeReq} returns this
 */
proto.price.web_partnership.CreateHiddenFeeReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.DeleteHiddenFeeReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.DeleteHiddenFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.DeleteHiddenFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.DeleteHiddenFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DeleteHiddenFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.DeleteHiddenFeeReq}
 */
proto.price.web_partnership.DeleteHiddenFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.DeleteHiddenFeeReq;
  return proto.price.web_partnership.DeleteHiddenFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.DeleteHiddenFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.DeleteHiddenFeeReq}
 */
proto.price.web_partnership.DeleteHiddenFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.DeleteHiddenFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.DeleteHiddenFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.DeleteHiddenFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DeleteHiddenFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.price.web_partnership.DeleteHiddenFeeReq.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.DeleteHiddenFeeReq} returns this
 */
proto.price.web_partnership.DeleteHiddenFeeReq.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.DeleteHiddenFeeReq} returns this
 */
proto.price.web_partnership.DeleteHiddenFeeReq.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.DeleteHiddenFeeReq} returns this
 */
proto.price.web_partnership.DeleteHiddenFeeReq.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ImportHiddenFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ImportHiddenFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ImportHiddenFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ImportHiddenFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileChunk: msg.getFileChunk_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ImportHiddenFeeReq}
 */
proto.price.web_partnership.ImportHiddenFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ImportHiddenFeeReq;
  return proto.price.web_partnership.ImportHiddenFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ImportHiddenFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ImportHiddenFeeReq}
 */
proto.price.web_partnership.ImportHiddenFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFileChunk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ImportHiddenFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ImportHiddenFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ImportHiddenFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ImportHiddenFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileChunk_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes file_chunk = 1;
 * @return {!(string|Uint8Array)}
 */
proto.price.web_partnership.ImportHiddenFeeReq.prototype.getFileChunk = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file_chunk = 1;
 * This is a type-conversion wrapper around `getFileChunk()`
 * @return {string}
 */
proto.price.web_partnership.ImportHiddenFeeReq.prototype.getFileChunk_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFileChunk()));
};


/**
 * optional bytes file_chunk = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFileChunk()`
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ImportHiddenFeeReq.prototype.getFileChunk_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFileChunk()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.price.web_partnership.ImportHiddenFeeReq} returns this
 */
proto.price.web_partnership.ImportHiddenFeeReq.prototype.setFileChunk = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.UpdateHiddenFeeReq.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.UpdateHiddenFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.UpdateHiddenFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.UpdateHiddenFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    officeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    location: jspb.Message.getFieldWithDefault(msg, 5, 0),
    accommodationTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    ratingList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    countryCodeList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    hotelName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    hotelId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.UpdateHiddenFeeReq;
  return proto.price.web_partnership.UpdateHiddenFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.UpdateHiddenFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {!proto.price.HiddenFeeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.price.HotelLocationType} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccommodationType(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRating(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addCountryCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.UpdateHiddenFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.UpdateHiddenFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.UpdateHiddenFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAccommodationTypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getRatingList();
  if (f.length > 0) {
    writer.writePackedDouble(
      7,
      f
    );
  }
  f = message.getCountryCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getHotelName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string office_id = 2;
 * @return {string}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string provider = 3;
 * @return {string}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional price.HiddenFeeType type = 4;
 * @return {!proto.price.HiddenFeeType}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getType = function() {
  return /** @type {!proto.price.HiddenFeeType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.price.HiddenFeeType} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional price.HotelLocationType location = 5;
 * @return {!proto.price.HotelLocationType}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getLocation = function() {
  return /** @type {!proto.price.HotelLocationType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.price.HotelLocationType} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated string accommodation_type = 6;
 * @return {!Array<string>}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getAccommodationTypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setAccommodationTypeList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.addAccommodationType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.clearAccommodationTypeList = function() {
  return this.setAccommodationTypeList([]);
};


/**
 * repeated double rating = 7;
 * @return {!Array<number>}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getRatingList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setRatingList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.addRating = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.clearRatingList = function() {
  return this.setRatingList([]);
};


/**
 * repeated string country_code = 8;
 * @return {!Array<string>}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getCountryCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setCountryCodeList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.addCountryCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.clearCountryCodeList = function() {
  return this.setCountryCodeList([]);
};


/**
 * optional string hotel_name = 9;
 * @return {string}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getHotelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setHotelName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double amount = 10;
 * @return {number}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double percent = 11;
 * @return {number}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string hotel_id = 12;
 * @return {string}
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeReq} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.ListHiddenFeeRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ListHiddenFeeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ListHiddenFeeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListHiddenFeeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hiddenFeesList: jspb.Message.toObjectList(msg.getHiddenFeesList(),
    price_hidden_fee_pb.HiddenFee.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ListHiddenFeeRes}
 */
proto.price.web_partnership.ListHiddenFeeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ListHiddenFeeRes;
  return proto.price.web_partnership.ListHiddenFeeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ListHiddenFeeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ListHiddenFeeRes}
 */
proto.price.web_partnership.ListHiddenFeeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new price_hidden_fee_pb.HiddenFee;
      reader.readMessage(value,price_hidden_fee_pb.HiddenFee.deserializeBinaryFromReader);
      msg.addHiddenFees(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ListHiddenFeeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ListHiddenFeeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListHiddenFeeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHiddenFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      price_hidden_fee_pb.HiddenFee.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.ListHiddenFeeRes} returns this
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.ListHiddenFeeRes} returns this
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated price.HiddenFee hidden_fees = 3;
 * @return {!Array<!proto.price.HiddenFee>}
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.getHiddenFeesList = function() {
  return /** @type{!Array<!proto.price.HiddenFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, price_hidden_fee_pb.HiddenFee, 3));
};


/**
 * @param {!Array<!proto.price.HiddenFee>} value
 * @return {!proto.price.web_partnership.ListHiddenFeeRes} returns this
*/
proto.price.web_partnership.ListHiddenFeeRes.prototype.setHiddenFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.price.HiddenFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.price.HiddenFee}
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.addHiddenFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.price.HiddenFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.ListHiddenFeeRes} returns this
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.clearHiddenFeesList = function() {
  return this.setHiddenFeesList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.price.web_partnership.ListHiddenFeeRes} returns this
*/
proto.price.web_partnership.ListHiddenFeeRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.ListHiddenFeeRes} returns this
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.ListHiddenFeeRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.DetailHiddenFeeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.DetailHiddenFeeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.DetailHiddenFeeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DetailHiddenFeeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hiddenFee: (f = msg.getHiddenFee()) && price_hidden_fee_pb.HiddenFee.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.DetailHiddenFeeRes}
 */
proto.price.web_partnership.DetailHiddenFeeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.DetailHiddenFeeRes;
  return proto.price.web_partnership.DetailHiddenFeeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.DetailHiddenFeeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.DetailHiddenFeeRes}
 */
proto.price.web_partnership.DetailHiddenFeeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new price_hidden_fee_pb.HiddenFee;
      reader.readMessage(value,price_hidden_fee_pb.HiddenFee.deserializeBinaryFromReader);
      msg.setHiddenFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.DetailHiddenFeeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.DetailHiddenFeeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.DetailHiddenFeeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DetailHiddenFeeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHiddenFee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      price_hidden_fee_pb.HiddenFee.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.DetailHiddenFeeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.DetailHiddenFeeRes} returns this
 */
proto.price.web_partnership.DetailHiddenFeeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.DetailHiddenFeeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.DetailHiddenFeeRes} returns this
 */
proto.price.web_partnership.DetailHiddenFeeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional price.HiddenFee hidden_fee = 3;
 * @return {?proto.price.HiddenFee}
 */
proto.price.web_partnership.DetailHiddenFeeRes.prototype.getHiddenFee = function() {
  return /** @type{?proto.price.HiddenFee} */ (
    jspb.Message.getWrapperField(this, price_hidden_fee_pb.HiddenFee, 3));
};


/**
 * @param {?proto.price.HiddenFee|undefined} value
 * @return {!proto.price.web_partnership.DetailHiddenFeeRes} returns this
*/
proto.price.web_partnership.DetailHiddenFeeRes.prototype.setHiddenFee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.DetailHiddenFeeRes} returns this
 */
proto.price.web_partnership.DetailHiddenFeeRes.prototype.clearHiddenFee = function() {
  return this.setHiddenFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.DetailHiddenFeeRes.prototype.hasHiddenFee = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.CreateHiddenFeeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.CreateHiddenFeeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.CreateHiddenFeeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.CreateHiddenFeeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.CreateHiddenFeeRes}
 */
proto.price.web_partnership.CreateHiddenFeeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.CreateHiddenFeeRes;
  return proto.price.web_partnership.CreateHiddenFeeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.CreateHiddenFeeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.CreateHiddenFeeRes}
 */
proto.price.web_partnership.CreateHiddenFeeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.CreateHiddenFeeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.CreateHiddenFeeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.CreateHiddenFeeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.CreateHiddenFeeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.CreateHiddenFeeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeRes} returns this
 */
proto.price.web_partnership.CreateHiddenFeeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.CreateHiddenFeeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeRes} returns this
 */
proto.price.web_partnership.CreateHiddenFeeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.price.web_partnership.CreateHiddenFeeRes.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateHiddenFeeRes} returns this
 */
proto.price.web_partnership.CreateHiddenFeeRes.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ImportHiddenFeeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ImportHiddenFeeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ImportHiddenFeeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ImportHiddenFeeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ImportHiddenFeeRes}
 */
proto.price.web_partnership.ImportHiddenFeeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ImportHiddenFeeRes;
  return proto.price.web_partnership.ImportHiddenFeeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ImportHiddenFeeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ImportHiddenFeeRes}
 */
proto.price.web_partnership.ImportHiddenFeeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ImportHiddenFeeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ImportHiddenFeeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ImportHiddenFeeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ImportHiddenFeeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.ImportHiddenFeeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.ImportHiddenFeeRes} returns this
 */
proto.price.web_partnership.ImportHiddenFeeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.ImportHiddenFeeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.ImportHiddenFeeRes} returns this
 */
proto.price.web_partnership.ImportHiddenFeeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.UpdateHiddenFeeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.UpdateHiddenFeeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.UpdateHiddenFeeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hiddenFee: (f = msg.getHiddenFee()) && price_hidden_fee_pb.HiddenFee.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.UpdateHiddenFeeRes}
 */
proto.price.web_partnership.UpdateHiddenFeeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.UpdateHiddenFeeRes;
  return proto.price.web_partnership.UpdateHiddenFeeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.UpdateHiddenFeeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.UpdateHiddenFeeRes}
 */
proto.price.web_partnership.UpdateHiddenFeeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new price_hidden_fee_pb.HiddenFee;
      reader.readMessage(value,price_hidden_fee_pb.HiddenFee.deserializeBinaryFromReader);
      msg.setHiddenFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.UpdateHiddenFeeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.UpdateHiddenFeeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.UpdateHiddenFeeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHiddenFee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      price_hidden_fee_pb.HiddenFee.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeRes} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeRes} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional price.HiddenFee hidden_fee = 3;
 * @return {?proto.price.HiddenFee}
 */
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.getHiddenFee = function() {
  return /** @type{?proto.price.HiddenFee} */ (
    jspb.Message.getWrapperField(this, price_hidden_fee_pb.HiddenFee, 3));
};


/**
 * @param {?proto.price.HiddenFee|undefined} value
 * @return {!proto.price.web_partnership.UpdateHiddenFeeRes} returns this
*/
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.setHiddenFee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.UpdateHiddenFeeRes} returns this
 */
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.clearHiddenFee = function() {
  return this.setHiddenFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.UpdateHiddenFeeRes.prototype.hasHiddenFee = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.price.web_partnership);
