import {
  Discount,
  DiscountType,
} from '@api/price/discount_pb';

import {
  HiddenFee,
  HiddenFeeType,
} from '@api/price/hidden_fee_pb';

import {
  HotelLocationType,
} from '@api/price/base_pb';
export type EDiscountType = DiscountType;
export const EDiscountType = { ...DiscountType } as const;

export type EHiddenFeeType = HiddenFeeType;
export const EHiddenFeeType = { ...HiddenFeeType } as const;

export interface IDiscount extends Discount.AsObject { }

export type EHotelLocationType = HotelLocationType;
export const EHotelLocationType = { ...HotelLocationType } as const;
export interface IHiddenFee extends HiddenFee.AsObject { }