import { IBookingItinerary, IBookingPassenger } from '@tixlabs/grpc-client';
import { getBaggageForPaxByItinerary } from '@vemaybay-admin/utils';

type Props = {
  passengersList: IBookingPassenger[];
  itinerary: IBookingItinerary;
};

export const PassengerServiceInfoCoordination = ({ passengersList, itinerary }: Props) => {
  return (
    <div className='flex flex-col'>
      {passengersList.map((passenger, index) => {
        const { checkedBaggage, handBaggage } = getBaggageForPaxByItinerary(
          itinerary,
          passenger.type
        );
        return (
          <div key={index} className='grid grid-cols-4'>
            <h4 className='mb-0 font-semibold text-neutral-black uppercase py-2'>
              {`${passenger.lastName}/${passenger.firstName}`}
            </h4>
            <div className='flex flex-col py-2'>
              <span className='text-xs text-neutral-gray-50'>
                Hành lý xách tay
              </span>
              <span className='text-neutral-black'>
                {handBaggage
                  .map((item) => `${item.quantity} kiện ${item.name}`)
                  .join(', ') || 'Không bao gồm'}
              </span>
            </div>
            <div className='flex flex-col py-2'>
              <span className='text-xs text-neutral-gray-50'>
                Hành lý ký gửi
              </span>
              <span className='text-neutral-black'>
                {checkedBaggage
                  .map((item) => `${item.quantity} kiện ${item.name}`)
                  .join(', ') || 'Không bao gồm'}
              </span>
            </div>
            <div className='flex flex-col py-2'>
              <span className='text-xs text-neutral-gray-50'>Số ghế</span>
              <span className='text-neutral-black'>Không bao gồm</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
