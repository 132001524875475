import { FlightTakeOffIcon } from '@icon';
import { IBookingItinerary } from '@tixlabs/grpc-client/';
import { useCities } from '@access-trade-admin/hooks/apps';
import { useTime } from '@access-trade-admin/hooks/internals';
import useAirport from '@access-trade-admin/hooks/stores/useAirport';
import React from 'react';

type Props = {
  //
  itinerary: IBookingItinerary;
};

export const ItineraryFromTo = ({ itinerary }: Props) => {
  const { airportsObjectByCode } = useAirport();
  const { formatDateUTCDayMonthYear } = useTime();
  return (
    <div className='flex flex-col gap-1 px-4 py-3 bg-primary-1 rounded'>
      <div className='flex items-center gap-3'>
        <span className='text-xl text-neutral-gray-100 font-semibold'>
          {airportsObjectByCode?.[itinerary.departPlace]?.city}
        </span>
        <FlightTakeOffIcon className='text-neutral-gray-50' />
        <span className='text-xl text-neutral-gray-100 font-semibold'>
          {airportsObjectByCode?.[itinerary.arrivalPlace]?.city}
        </span>
      </div>
      <span className='text-md font-semibold'>
        {formatDateUTCDayMonthYear(itinerary.departDate)}
      </span>
      {itinerary.reservationCode && (
        <div className='flex w-fit items-center gap-x-2.5 rounded bg-white px-1.5 py-0.5'>
          <span className='text-xs sm:text-base'>Mã đặt chỗ:</span>
          <span className='text-base font-bold sm:text-md'>
            {itinerary.reservationCode}
          </span>
        </div>
      )}
    </div>
  );
};
