import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { HiddenFeeServiceClient } from '@api/skyhub/web_partnership/hidden_fee_grpc_web_pb';
import {
  GetConfigOptionsRes,
} from '@api/skyhub/web_partnership/hidden_fee_pb';
import { Empty } from '@api/base/base_pb';

class SkyHubHiddenFeeServiceApi extends BaseApi<HiddenFeeServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HiddenFeeServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getConfigOptions = (): Promise<GetConfigOptionsRes.AsObject> => {
    const req = new Empty();
    return this.grpc<Empty, GetConfigOptionsRes, GetConfigOptionsRes.AsObject>(
      this.serviceClient.getConfigOptions,
      req,
      {}
    );
  };


}

export const configHiddenFeeServiceApi = new SkyHubHiddenFeeServiceApi();
