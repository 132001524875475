import { HotelServiceClient } from '@api/skyhub/web_partnership/hotel_grpc_web_pb';
import { BaseApi } from '../base-api';
import { BaseApiOptions } from '@core/grpc-client';
import { ExportOrdersReq, ExportOrdersRes, GetOrderDetailRequest, GetOrderDetailResponse, ListOrdersFilter, ListOrdersRequest, ListOrdersResponse } from '@api/skyhub/web_partnership/hotel_pb';
import { PaginationReq } from '@api/base/base_pb';

export class HubHotelServiceApi extends BaseApi<HotelServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HotelServiceClient(this.url, null, this.optsDev);
  }

  getOrderDetail = ({
    id,
  }: GetOrderDetailRequest.AsObject): Promise<GetOrderDetailResponse.AsObject> => {
    const req = new GetOrderDetailRequest();
    req.setId(id);
    return this.grpc<
      GetOrderDetailRequest,
      GetOrderDetailResponse,
      GetOrderDetailResponse.AsObject
    >(this.serviceClient.getOrderDetail, req, {});
  };

  listOrders = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListOrdersRequest.AsObject): Promise<ListOrdersResponse.AsObject> => {
    const req = new ListOrdersRequest();
    const filterObj = new ListOrdersFilter();
    const paginationObj = new PaginationReq();
    if (filter) {
      filter.to &&
        filterObj.setTo(new Date(filter.to).setHours(23, 59, 59, 999));
      filter.from &&
        filterObj.setFrom(new Date(filter.from).setHours(0, 0, 0, 0));
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setBookingStatusesList(filter.bookingStatusesList);
      filterObj.setNotInBookingStatusesList(filter.notInBookingStatusesList);
      filter.officeId && filterObj.setOfficeId(filter.officeId);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    return this.grpc<
      ListOrdersRequest,
      ListOrdersResponse,
      ListOrdersResponse.AsObject
    >(this.serviceClient.listOrders, req, {});
  };

  exportOrders = ({
    filter,
  }: ExportOrdersReq.AsObject): Promise<ExportOrdersRes.AsObject> => {
    const req = new ExportOrdersReq();
    const filterObj = new ListOrdersFilter();
    if (filter) {
      filter.to && filterObj.setTo(filter.to);
      filter.from && filterObj.setFrom(filter.from);
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setBookingStatusesList(filter.bookingStatusesList);
      filterObj.setNotInBookingStatusesList(filter.notInBookingStatusesList);
      filter.officeId && filterObj.setOfficeId(filter.officeId);
    }

    req.setFilter(filterObj);

    return this.grpc<
      ExportOrdersReq,
      ExportOrdersRes,
      ExportOrdersRes.AsObject
    >(this.serviceClient?.exportOrders, req, {});
  };

}

export const hubHotelApiServiceWebPartnership = new HubHotelServiceApi();
export default hubHotelApiServiceWebPartnership;
