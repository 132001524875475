import { EBookingStatus, ECoordinationStatus } from '@tixlabs/grpc-client';
import { BOOKING_COORDINATION_STATUS_LABEL, BOOKING_STATUS_LABEL } from '@vemaybay-admin/utils';
import cn from 'classnames';

type Props = {
  //
  status: ECoordinationStatus;
  className?: string;
  classNameText?: string;
};

// const objIcon = {
//   [EBookingStatus.BOOKINGSTATUSEXPIRED]: (
//     <ForbidFillIcon className='text-red-6 w-4 h-4' />
//   ),
//   [EBookingStatus.BOOKINGSTATUSCANCELLED]: (
//     <ForbidFillIcon className='text-red-6 w-4 h-4' />
//   ),
//   [EBookingStatus.BOOKINGSTATUSFAILED]: (
//     <Ticket2FillIcon className='text-neutral-40 w-4 h-4' />
//   ),
//   [EBookingStatus.INPROGRESS]: (
//     <CheckFillIcon className='text-secondary-6 w-4 h-4' />
//   ),
//   [EBookingStatus.BOOKINGSTATUSPAID]: (
//     <Ticket2FillIcon className='text-success w-4 h-4' />
//   ),
//   [EBookingStatus.COMPLETE]: (
//     <Ticket2FillIcon className='text-success w-4 h-4' />
//   ),
// };

const objClassName = {
  [ECoordinationStatus.COORDINATIONSTATUSNONE]: 'text-neutral-warning',
  [ECoordinationStatus.COORDINATIONSTATUSPENDING]: 'text-common-warning',
  [ECoordinationStatus.COORDINATIONSTATUSRESERVED]: 'text-[#0000FF]',
  [ECoordinationStatus.COORDINATIONSTATUSCANCELLEDPENDINGREFUND]: 'text-common-success',
  [ECoordinationStatus.COORDINATIONSTATUSEXPIRED]: 'text-common-error',
};

export const Status = ({ status, className, classNameText }: Props) => {
  return (
    <div className={cn('', className)}>
      <span
        className={cn('font-semibold', objClassName[status], classNameText)}>
        {/* {status} */}
        {BOOKING_COORDINATION_STATUS_LABEL[status]}
      </span>
    </div>
  );
};
