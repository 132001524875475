import {
  Button,
  FileExcelLineIcon,
  Form,
  FormInput,
  FormInputDateFromTo,
  FormSelect,
  ONE_DAY_TIMESTAMP,
  SearchLineIcon,
  TFromToDate,
  TicketIcon,
} from '@common-ui';
import { useExportData } from '@core/hooks';
import { addDay, getUserName, subDay } from '@core/utils';
import { EBookingStatus, ECoordinationStatus, IPaginationRes } from '@tixlabs/grpc-client';
import {
  IBookingFlightPartialRoundtripRes,
  IListBookingFlightPartialRoundtripReq,
  IListBookingFlightReq,
  IRetrieveBookingFlightData,
  flightApiService,
} from '@tixlabs/grpc-client/web-partnership';

import { useAppMutation, useTime } from '@vemaybay-admin/hooks/internals';
import {
  BOOKING_COORDINATION_STATUS_LABEL,
  BOOKING_STATUS_LABEL,
  mapReservationCode,
  SELECT_STATUS_BOOKING_COORDINATION_OPTIONS,
} from '@vemaybay-admin/utils';
import { SELECT_STATUS_BOOKING_OPTIONS } from '@vemaybay-admin/utils/';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import TableContent from './TableContent';
import { useAirlines } from '@vemaybay-admin/hooks/stores';

const DEFAULT_PAGE_LIMIT = 10;

const MAX_FILTER_DAY = 30;

export const ListTicketCoordination = () => {
  const [searchParams] = useSearchParams();
  const DEFAULT_ORDER_FILTER: Pick<IListBookingFlightPartialRoundtripReq, 'filter'> & {
    filterDate: TFromToDate;
    status: ECoordinationStatus;
  } = {
    filter: {
      bookingCode: '',
      fromDate: new Date().setHours(0, 0, 0, 0) - ONE_DAY_TIMESTAMP * 7,
      toDate: new Date().setHours(23, 59, 59, 999),
      statusesList: [],
    },
    filterDate: {
      startDate: new Date(
        new Date(Date.now() - ONE_DAY_TIMESTAMP * 7).setHours(0, 0, 0, 0)
      ),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    },
    status: ECoordinationStatus.COORDINATIONSTATUSNONE,
  };

  const { formatDateTime, formatDate, formatDateUTCOnlyTime } = useTime();
  const methods = useForm<
    Pick<IListBookingFlightPartialRoundtripReq, 'filter'> & {
      filterDate: TFromToDate;
      status: ECoordinationStatus;
    }
  >({
    defaultValues: DEFAULT_ORDER_FILTER,
  });

  const { getAirlineNameByCode } = useAirlines();

  const { handleExport, isProcessing } =
    useExportData<IRetrieveBookingFlightData>({
      columns: [
        {
          name: 'Ngày giờ đặt',
          key: 'createdAt',
          formatValue: (createdAt: IRetrieveBookingFlightData['createdAt']) => {
            return formatDateTime(createdAt);
          },
        },
        {
          name: 'Mã đơn hàng',
          key: 'bookingCode',
        },
        {
          name: 'Mã đặt chỗ',
          key: 'reservationCode',
        },
        {
          name: 'Hãng bay',
          key: 'itinerariesList',
          formatValue: (
            itinerariesList: IRetrieveBookingFlightData['itinerariesList']
          ) => {
            if (!itinerariesList) {
              return '-';
            }
            const airlineNames = itinerariesList.map((item) =>
              getAirlineNameByCode(item.carrierMarketing)
            );
            const uniqueAirlines = Array.from(new Set(airlineNames));

            return `${uniqueAirlines.join(', ')}`;
          },
        },
        {
          name: 'Ngày bay',
          key: 'itinerariesList',
          formatValue: (
            itinerariesList: IRetrieveBookingFlightData['itinerariesList']
          ) => {
            return `${`${formatDateUTCOnlyTime(
              itinerariesList[0].departDate
            )} ${formatDate(itinerariesList[0].departDate)} `}`;
          },
        },
        {
          name: 'Khách hàng',
          key: 'contactInfo',
          formatValue: (
            contactInfo: IRetrieveBookingFlightData['contactInfo']
          ) => {
            return getUserName({
              firstName: contactInfo?.firstName || '',
              lastName: contactInfo?.lastName || '',
            });
          },
        },
        {
          name: 'Số điện thoại',
          key: 'contactInfo',
          formatValue: (
            contactInfo: IRetrieveBookingFlightData['contactInfo']
          ) => {
            return `+${contactInfo?.phoneCode} ${contactInfo?.phoneNumber}`;
          },
        },
        {
          name: 'Email',
          key: 'contactInfo',
          formatValue: (
            contactInfo: IRetrieveBookingFlightData['contactInfo']
          ) => {
            return `${contactInfo?.email || '-'}`;
          },
        },
        {
          name: 'Tổng giá',
          key: 'fee',
          formatValue: (fee: IRetrieveBookingFlightData['fee'], value) => {
            return (
              value.paymentInfo?.totalPrice ||
              value.fee?.priceAfterPromo ||
              0 ||
              0
            );
          },
        },
        {
          name: 'Trạng thái',
          key: 'status',
          formatValue: (value: ECoordinationStatus) => BOOKING_COORDINATION_STATUS_LABEL[value],
        },
      ],
      filename: `order_${formatDate(Date.now())}`,
    });

  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });

  const [filterReq, setFilterReq] = useState<
    Pick<IListBookingFlightPartialRoundtripReq, 'filter'>
  >({
    filter: DEFAULT_ORDER_FILTER.filter,
  });

  const [listOrder, setListOrder] = useState<IBookingFlightPartialRoundtripRes[]>([]);

  const { mutateAsync: listBookingFlightPartialRoundtrip, isLoading } = useAppMutation({
    mutationKey: ['flightApiService', 'listBookingFlightPartialRoundtrip'],
    mutationFn: flightApiService.listBookingFlightPartialRoundtrip,
    onSuccess: ({ isSuccess, data, errorCode }) => {
      if (isSuccess && data) {
        data.pagination && setPaginationRes(data.pagination);
        // filter order has itinerariesList empty
        setListOrder(data.itemsList);
      } else {
        console.log('error', errorCode);
      }
    },
    onError: (err) => {
      console.log('oops...', err);
    },
  });

  const { mutateAsync: exportBookingFlight } = useAppMutation({
    mutationKey: ['flightApiService', 'exportBookingFlight'],
    mutationFn: flightApiService.exportBookingFlight,
    onSuccess: ({ isSuccess, errorCode }) => {
      if (isSuccess) {
      } else {
        console.log('error', errorCode);
      }
    },
    onError: (err) => {
      console.log('oops...', err);
    },
  });

  const handleSubmit = async (
    data: Pick<IListBookingFlightPartialRoundtripReq, 'filter'> & {
      filterDate: TFromToDate;
      status: ECoordinationStatus;
    }
  ) => {
    const formatStatusList: ECoordinationStatus[] = [];

    if (data.status !== ECoordinationStatus.COORDINATIONSTATUSNONE) {
      formatStatusList.push(data.status);
    }
    // if (data.status === ECoordinationStatus.BOOKINGSTATUSCANCELLED) {
    //   formatStatusList.push(EBookingStatus.BOOKINGSTATUSEXPIRED);
    // }

    if (data.filter) {
      setFilterReq({
        filter: {
          ...data.filter,
          fromDate: data.filterDate.startDate
            ? new Date(data.filterDate.startDate).getTime()
            : data.filter.fromDate,
          toDate: data.filterDate.endDate
            ? new Date(data.filterDate.endDate).getTime()
            : data.filter.toDate,
          statusesList: formatStatusList,
        },
      });
      await listBookingFlightPartialRoundtrip({
        filter: {
          ...data.filter,
          fromDate: data.filterDate.startDate
            ? new Date(data.filterDate.startDate).getTime()
            : data.filter.fromDate,
          toDate: data.filterDate.endDate
            ? new Date(data.filterDate.endDate).getTime()
            : data.filter.toDate,
          statusesList: formatStatusList,
        },
        pagination: {
          pageLimit: DEFAULT_PAGE_LIMIT,
          pageNumber: 1,
        },
        sortsList: [],
      });
    }
  };

  const handlePagination = async (page: number) => {
    await listBookingFlightPartialRoundtrip({
      filter: filterReq.filter,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
      sortsList: [],
    });
  };

  const getListBookingFlight = useCallback(async () => {
    await listBookingFlightPartialRoundtrip({
      filter: DEFAULT_ORDER_FILTER.filter,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
      sortsList: [],
    });
  }, []);

  // const handleExportListBookingFlight = async () => {
  //   try {
  //     const { isSuccess, itemsList } = await exportBookingFlight({
  //       filter: {
  //         bookingCode: filterReq.filter?.bookingCode || '',
  //         fromDate: filterReq.filter?.fromDate || 0,
  //         toDate: filterReq.filter?.toDate || 0,
  //         statusesList: filterReq.filter?.statusesList || [],
  //       },
  //       sortsList: [],
  //     });
  //     if (isSuccess) {
  //       await handleExport(
  //         itemsList.map((item) => ({
  //           ...item,
  //           reservationCode: mapReservationCode(item.itinerariesList),
  //         }))
  //       );
  //     }
  //   } catch (error) {
  //     console.error('error', error);
  //   }
  // };

  useEffect(() => {
    getListBookingFlight();
  }, []);

  const { startDate: fromDate, endDate: toDate } = methods.watch('filterDate');

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <TicketIcon className='w-5 h-5 shrink-0' />
        <span className='font-semibold text-lg'>Điều phối vé </span>
      </div>
      <div className='w-full bg-white border rounded p-3.5'>
        <Form
          methods={methods}
          onSubmit={handleSubmit}
          className='flex justify-between pb-4 border-b mb-4 flex-col md-lg:flex-row gap-3'>
          <div className='md-lg:flex md-lg:space-x-2.5 grid grid-cols-2 gap-4'>
            {/* className='flex justify-between pb-4 border-b mb-4'>
          <div className='flex space-x-2.5'> */}
            <FormInputDateFromTo
              inputProps={{
                placeholderStart: 'Từ ngày',
                placeholderEnd: 'Đến ngày',
                showFilter: true,
                inputSize: 'sm',
                minStartDate: toDate
                  ? subDay(toDate, MAX_FILTER_DAY)
                  : undefined,
                maxStartDate: new Date(),
                maxEndDate: fromDate
                  ? addDay(fromDate, MAX_FILTER_DAY) < new Date()
                    ? addDay(fromDate, MAX_FILTER_DAY)
                    : new Date()
                  : new Date(),
                isFullTime: true,
              }}
              label='Thời gian'
              name='filterDate'
              className='max-w-[225px]'
            />
            <FormInput
              name='filter.bookingCode'
              label='Mã đơn hàng/đặt chỗ'
              placeholder='Nhập mã đơn hàng/đặt chỗ'
              className='w-[230px] max-w-[230px]'
            />
            <FormSelect
              name='status'
              selectOptions={SELECT_STATUS_BOOKING_COORDINATION_OPTIONS}
              label={'Trạng thái'}
              inputProps={{
                isOnlyValue: true,
              }}
              className='w-[250px]'
            />
            <div className='flex flex-col justify-end'>
              <Button
                type='submit'
                theme='secondary'
                size='sm'
                prefixIcon={<SearchLineIcon />}
                className='font-semibold !px-5 h-[40px] w-[150px]'>
                Tìm kiếm
              </Button>
            </div>
          </div>
          {/* <div className='flex space-x-2.5'>
            <div className='flex flex-col justify-end'>
              <Button
                size='sm'
                theme='green'
                onClick={handleExportListBookingFlight}
                disabled={isProcessing}
                prefixIcon={<FileExcelLineIcon />}
                className='!px-5'>
                <span className='whitespace-nowrap'>
                  {isProcessing ? 'Tải về...' : 'Tải về'}
                </span>
              </Button>
            </div>
          </div> */}
        </Form>
        <TableContent
          listOrder={listOrder}
          isLoading={isLoading}
          pagination={paginationRes}
          onPageChange={handlePagination}
        />
      </div>
    </div>
  );
};

export default ListTicketCoordination;
