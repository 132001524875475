import { BasicRes, Empty } from '@api/base/base_pb';
import { AuthServiceClient } from '@api/partner/web_partner/auth_grpc_web_pb';
import {
  ActiveOfficeByTokenReq,
  ActiveOfficeByTokenRes,
  GetChangePasswordTokenReq,
  GetChangePasswordTokenRes,
  LoginReq,
  LoginRes,
  RegisterAgentReq,
  RegisterAgentRes,
  ResendActiveOfficeTokenReq,
  UpdatePasswordFirstTimeReq,
  UpdatePasswordFirstTimeRes,
} from '@api/partner/web_partner/auth_pb';

import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class AuthApi extends BaseApi<AuthServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new AuthServiceClient(this.url, null, this.optsDev);
  }

  login = ({
    agentCode,
    password,
    userName,
  }: LoginReq.AsObject): Promise<LoginRes.AsObject> => {
    const req = new LoginReq();
    req.setAgentCode(agentCode);
    req.setUserName(userName);
    req.setPassword(password);
    return this.grpc<LoginReq, LoginRes, LoginRes.AsObject>(
      this.serviceClient.login,
      req,
      {}
    );
  };

  loginV2 = ({
    agentCode,
    password,
    userName,
  }: LoginReq.AsObject): Promise<LoginRes.AsObject> => {
    const req = new LoginReq();
    req.setAgentCode(agentCode);
    req.setUserName(userName);
    req.setPassword(password);
    return this.grpc<LoginReq, LoginRes, LoginRes.AsObject>(
      this.serviceClient.loginV2,
      req,
      {}
    );
  };

  getChangePasswordToken = ({
    agentCode,
    password,
    userName,
  }: GetChangePasswordTokenReq.AsObject): Promise<GetChangePasswordTokenRes.AsObject> => {
    const req = new GetChangePasswordTokenReq();
    req.setAgentCode(agentCode);
    req.setUserName(userName);
    req.setPassword(password);
    return this.grpc<
      GetChangePasswordTokenReq,
      GetChangePasswordTokenRes,
      GetChangePasswordTokenRes.AsObject
    >(this.serviceClient.getChangePasswordToken, req, {});
  };

  updatePasswordFirstTime = ({
    updateToken,
    newPassword,
    username,
    agentCode,
  }: UpdatePasswordFirstTimeReq.AsObject): Promise<UpdatePasswordFirstTimeRes.AsObject> => {
    const req = new UpdatePasswordFirstTimeReq();
    req.setUpdateToken(updateToken);
    req.setNewPassword(newPassword);
    req.setUsername(username);
    req.setAgentCode(agentCode);

    return this.grpc<
      UpdatePasswordFirstTimeReq,
      UpdatePasswordFirstTimeRes,
      UpdatePasswordFirstTimeRes.AsObject
    >(this.serviceClient.updatePasswordFirstTime, req, {});
  };

  activeOfficeByToken = ({
    token,
    username,
    agentCode,
  }: ActiveOfficeByTokenReq.AsObject): Promise<ActiveOfficeByTokenRes.AsObject> => {
    const req = new ActiveOfficeByTokenReq();
    req.setToken(token);
    req.setUsername(username);
    req.setAgentCode(agentCode);

    return this.grpc<
      ActiveOfficeByTokenReq,
      ActiveOfficeByTokenRes,
      ActiveOfficeByTokenRes.AsObject
    >(this.serviceClient.activeOfficeByToken, req, {});
  };

  resendActiveOfficeToken = ({
    oldToken,
    username,
    agentCode,
    redirectUrl,
    loginUrl,
  }: ResendActiveOfficeTokenReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new ResendActiveOfficeTokenReq();
    req.setOldToken(oldToken);
    req.setUsername(username);
    req.setAgentCode(agentCode);
    req.setRedirectUrl(redirectUrl);
    req.setLoginUrl(loginUrl);

    return this.grpc<ResendActiveOfficeTokenReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.resendActiveOfficeToken,
      req,
      {}
    );
  };

  registerAgent = ({
    agentName,
    contactName,
    contactEmail,
    phoneCode,
    phoneNumber,
    saleCode,
    referralCode,
    loginUrl,
  }: RegisterAgentReq.AsObject): Promise<RegisterAgentRes.AsObject> => {
    const req = new RegisterAgentReq();
    req.setAgentName(agentName);
    req.setContactEmail(contactEmail);
    req.setContactName(contactName);
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);
    req.setSaleCode(saleCode);
    req.setReferralCode(referralCode);
    req.setLoginUrl(loginUrl);
    return this.grpc<
      RegisterAgentReq,
      RegisterAgentRes,
      RegisterAgentRes.AsObject
    >(this.serviceClient.registerAgent, req, {});
  };

  logout = (): Promise<BasicRes.AsObject> => {
    const req = new Empty();
    return this.grpc<Empty, BasicRes, BasicRes.AsObject>(
      this.serviceClient.logout,
      req,
      {
        noNoty: true,
      }
    );
  };

  issuerLogin = ({
    agentCode,
    password,
    userName,
  }: LoginReq.AsObject): Promise<LoginRes.AsObject> => {
    const req = new LoginReq();
    req.setAgentCode(agentCode);
    req.setUserName(userName);
    req.setPassword(password);
    return this.grpc<LoginReq, LoginRes, LoginRes.AsObject>(
      this.serviceClient.issuerLogin,
      req,
      {}
    );
  };
}

export const authServiceClientApi = new AuthApi();
export default authServiceClientApi;
