/**
 * @fileoverview gRPC-Web generated client stub for price.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: price/web_partnership/discount.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var price_base_pb = require('../../price/base_pb.js')

var price_discount_pb = require('../../price/discount_pb.js')
const proto = {};
proto.price = {};
proto.price.web_partnership = require('./discount_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.price.web_partnership.DiscountServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.price.web_partnership.DiscountServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.price.web_partnership.ListDiscountsReq,
 *   !proto.price.web_partnership.ListDiscountsRes>}
 */
const methodDescriptor_DiscountService_ListDiscounts = new grpc.web.MethodDescriptor(
  '/price.web_partnership.DiscountService/ListDiscounts',
  grpc.web.MethodType.UNARY,
  proto.price.web_partnership.ListDiscountsReq,
  proto.price.web_partnership.ListDiscountsRes,
  /**
   * @param {!proto.price.web_partnership.ListDiscountsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.price.web_partnership.ListDiscountsRes.deserializeBinary
);


/**
 * @param {!proto.price.web_partnership.ListDiscountsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.price.web_partnership.ListDiscountsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.price.web_partnership.ListDiscountsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.price.web_partnership.DiscountServiceClient.prototype.listDiscounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/price.web_partnership.DiscountService/ListDiscounts',
      request,
      metadata || {},
      methodDescriptor_DiscountService_ListDiscounts,
      callback);
};


/**
 * @param {!proto.price.web_partnership.ListDiscountsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.price.web_partnership.ListDiscountsRes>}
 *     Promise that resolves to the response
 */
proto.price.web_partnership.DiscountServicePromiseClient.prototype.listDiscounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/price.web_partnership.DiscountService/ListDiscounts',
      request,
      metadata || {},
      methodDescriptor_DiscountService_ListDiscounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.price.web_partnership.DetailDiscountReq,
 *   !proto.price.web_partnership.DetailDiscountRes>}
 */
const methodDescriptor_DiscountService_DetailDiscount = new grpc.web.MethodDescriptor(
  '/price.web_partnership.DiscountService/DetailDiscount',
  grpc.web.MethodType.UNARY,
  proto.price.web_partnership.DetailDiscountReq,
  proto.price.web_partnership.DetailDiscountRes,
  /**
   * @param {!proto.price.web_partnership.DetailDiscountReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.price.web_partnership.DetailDiscountRes.deserializeBinary
);


/**
 * @param {!proto.price.web_partnership.DetailDiscountReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.price.web_partnership.DetailDiscountRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.price.web_partnership.DetailDiscountRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.price.web_partnership.DiscountServiceClient.prototype.detailDiscount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/price.web_partnership.DiscountService/DetailDiscount',
      request,
      metadata || {},
      methodDescriptor_DiscountService_DetailDiscount,
      callback);
};


/**
 * @param {!proto.price.web_partnership.DetailDiscountReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.price.web_partnership.DetailDiscountRes>}
 *     Promise that resolves to the response
 */
proto.price.web_partnership.DiscountServicePromiseClient.prototype.detailDiscount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/price.web_partnership.DiscountService/DetailDiscount',
      request,
      metadata || {},
      methodDescriptor_DiscountService_DetailDiscount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.price.web_partnership.CreateDiscountReq,
 *   !proto.price.web_partnership.CreateDiscountRes>}
 */
const methodDescriptor_DiscountService_CreateDiscount = new grpc.web.MethodDescriptor(
  '/price.web_partnership.DiscountService/CreateDiscount',
  grpc.web.MethodType.UNARY,
  proto.price.web_partnership.CreateDiscountReq,
  proto.price.web_partnership.CreateDiscountRes,
  /**
   * @param {!proto.price.web_partnership.CreateDiscountReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.price.web_partnership.CreateDiscountRes.deserializeBinary
);


/**
 * @param {!proto.price.web_partnership.CreateDiscountReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.price.web_partnership.CreateDiscountRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.price.web_partnership.CreateDiscountRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.price.web_partnership.DiscountServiceClient.prototype.createDiscount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/price.web_partnership.DiscountService/CreateDiscount',
      request,
      metadata || {},
      methodDescriptor_DiscountService_CreateDiscount,
      callback);
};


/**
 * @param {!proto.price.web_partnership.CreateDiscountReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.price.web_partnership.CreateDiscountRes>}
 *     Promise that resolves to the response
 */
proto.price.web_partnership.DiscountServicePromiseClient.prototype.createDiscount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/price.web_partnership.DiscountService/CreateDiscount',
      request,
      metadata || {},
      methodDescriptor_DiscountService_CreateDiscount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.price.web_partnership.UpdateDiscountReq,
 *   !proto.price.web_partnership.UpdateDiscountRes>}
 */
const methodDescriptor_DiscountService_UpdateDiscount = new grpc.web.MethodDescriptor(
  '/price.web_partnership.DiscountService/UpdateDiscount',
  grpc.web.MethodType.UNARY,
  proto.price.web_partnership.UpdateDiscountReq,
  proto.price.web_partnership.UpdateDiscountRes,
  /**
   * @param {!proto.price.web_partnership.UpdateDiscountReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.price.web_partnership.UpdateDiscountRes.deserializeBinary
);


/**
 * @param {!proto.price.web_partnership.UpdateDiscountReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.price.web_partnership.UpdateDiscountRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.price.web_partnership.UpdateDiscountRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.price.web_partnership.DiscountServiceClient.prototype.updateDiscount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/price.web_partnership.DiscountService/UpdateDiscount',
      request,
      metadata || {},
      methodDescriptor_DiscountService_UpdateDiscount,
      callback);
};


/**
 * @param {!proto.price.web_partnership.UpdateDiscountReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.price.web_partnership.UpdateDiscountRes>}
 *     Promise that resolves to the response
 */
proto.price.web_partnership.DiscountServicePromiseClient.prototype.updateDiscount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/price.web_partnership.DiscountService/UpdateDiscount',
      request,
      metadata || {},
      methodDescriptor_DiscountService_UpdateDiscount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.price.web_partnership.DeleteDiscountReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_DiscountService_DeleteDiscount = new grpc.web.MethodDescriptor(
  '/price.web_partnership.DiscountService/DeleteDiscount',
  grpc.web.MethodType.UNARY,
  proto.price.web_partnership.DeleteDiscountReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.price.web_partnership.DeleteDiscountReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.price.web_partnership.DeleteDiscountReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.price.web_partnership.DiscountServiceClient.prototype.deleteDiscount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/price.web_partnership.DiscountService/DeleteDiscount',
      request,
      metadata || {},
      methodDescriptor_DiscountService_DeleteDiscount,
      callback);
};


/**
 * @param {!proto.price.web_partnership.DeleteDiscountReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.price.web_partnership.DiscountServicePromiseClient.prototype.deleteDiscount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/price.web_partnership.DiscountService/DeleteDiscount',
      request,
      metadata || {},
      methodDescriptor_DiscountService_DeleteDiscount);
};


module.exports = proto.price.web_partnership;

