import { EReferenceStatus } from '@tixlabs/grpc-client/web-partnership';
import { STATUS_REFERENCE_LABEL } from '@access-trade-admin/utils';
import cn from 'classnames';

type Props = {
  status: EReferenceStatus;
  className?: string;
  classNameText?: string;
};

const objClassName = {
  [EReferenceStatus.ACTIVE]: 'text-common-success',
  [EReferenceStatus.IDLE]: 'text-common-warning',
  [EReferenceStatus.INACTIVE]: 'text-common-error',
};

export const Status = ({ status, className, classNameText }: Props) => {
  return (
    <div className={cn('', className)}>
      <span
        className={cn('font-semibold', objClassName[status], classNameText)}>
        {STATUS_REFERENCE_LABEL[status]}
      </span>
    </div>
  );
};
