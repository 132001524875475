import {
  Button,
  FileExcelLineIcon,
  Form,
  FormInput,
  FormInputDateFromTo,
  FormSelect,
  OPTION_DATE_RANGE_LIST,
  SearchLineIcon,
} from '@common-ui';
import { useExportData } from '@core/hooks';
import { addDay, subDay } from '@core/utils';
import { IPaginationRes } from '@tixlabs/grpc-client';
import {
  ICommissionItem,
  IFilterCommissionReq,
  reportService,
} from '@tixlabs/grpc-client/web-partnership';
import { useCurrency, useTime } from '@access-trade-admin/hooks/internals';
import { useAppMutation } from '@access-trade-admin/hooks/internals';
import { TFormFilterCommission } from '@access-trade-admin/types';
import {
  DEFAULT_COMMISSION_FILTER_FORM,
  SELECT_STATUS_COMMISSION_OPTIONS,
  STATUS_COMMISSION_LABEL,
} from '@access-trade-admin/utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import CommissionStats from './CommissionStats';
import TableContent from './TableContent';

type Props = {
  //
};

const DEFAULT_PAGE_LIMIT = 20;

const MAX_FILTER_DAY = 30;

export const CommissionCtn = (props: Props) => {
  const [searchParams] = useSearchParams();

  const filterDateParams = searchParams.get('filterDate');

  const { formatDateTime, formatDate } = useTime();

  const { formatPrice } = useCurrency();

  const methods = useForm<TFormFilterCommission>({
    defaultValues: DEFAULT_COMMISSION_FILTER_FORM,
  });

  const { startDate: fromDate, endDate: toDate } = methods.watch('date');

  const { handleExport, isProcessing } = useExportData<ICommissionItem>({
    columns: [
      {
        name: 'Ngày đặt',
        key: 'createdAt',
        formatValue: (value: ICommissionItem['createdAt']) => {
          return formatDateTime(value);
        },
      },
      {
        name: 'Mã đơn hàng',
        key: 'bookingCode',
      },
      {
        name: 'Tổng tiền',
        key: 'totalMoney',
        formatValue: (value: ICommissionItem['totalMoney']) => {
          return formatPrice(value);
        },
      },
      {
        name: 'Phí dịch vụ',
        key: 'serviceFee',
        formatValue: (value: ICommissionItem['serviceFee']) => {
          return formatPrice(value);
        },
      },
      {
        name: 'Hoa hồng',
        key: 'commission',
        formatValue: (value: ICommissionItem['commission']) => {
          return formatPrice(value);
        },
      },
      {
        name: 'Trạng thái hoa hồng',
        key: 'status',
        formatValue: (value: ICommissionItem['status']) =>
          STATUS_COMMISSION_LABEL[value],
      },
      {
        name: 'Tổng lợi nhuận',
        key: 'profit',
        formatValue: (value: ICommissionItem['profit']) => {
          return formatPrice(value);
        },
      },
    ],
    filename: `commission_${formatDate(Date.now())}`,
  });

  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });

  const [listCommissionOrder, setListCommissionOrder] = useState<
    ICommissionItem[]
  >([]);

  const [filterReq, setFilterReq] = useState<IFilterCommissionReq>({
    ...DEFAULT_COMMISSION_FILTER_FORM,
    from: new Date(DEFAULT_COMMISSION_FILTER_FORM.date.startDate).getTime(),
    to: new Date(DEFAULT_COMMISSION_FILTER_FORM.date.endDate).getTime(),
  });

  const { mutateAsync: reportCommission, isLoading } = useAppMutation({
    mutationKey: ['reportService', 'reportCommission'],
    mutationFn: reportService.reportCommission,
    onSuccess: ({ isSuccess, data }) => {
      if (isSuccess && data) {
        const { itemsList, pagination } = data;
        pagination && setPaginationRes(pagination);
        setListCommissionOrder(itemsList);
      }
    },
  });

  const { mutateAsync: downloadReportCommission } = useAppMutation({
    mutationKey: ['reportService', 'downloadReportCommission'],
    mutationFn: reportService.downloadReportCommission,
  });

  const handlePagination = async (page: number) => {
    await reportCommission({
      filter: filterReq,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
    });
  };

  const handleSubmit = async (data: TFormFilterCommission) => {
    const filterObj: IFilterCommissionReq = {
      bookingCode: data.bookingCode,
      status: data.status,
      from: new Date(data.date.startDate).getTime(),
      to: new Date(data.date.endDate).getTime(),
    };

    setFilterReq(filterObj);

    await reportCommission({
      filter: filterObj,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
    });
  };

  const handleExportCommissionReport = async () => {
    try {
      const { isSuccess, itemsList } = await downloadReportCommission({
        ...filterReq,
      });
      if (isSuccess) {
        await handleExport(itemsList);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    if (!filterDateParams) {
      handleSubmit(DEFAULT_COMMISSION_FILTER_FORM);
      return;
    }

    const date = new Date(parseInt(filterDateParams));

    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    console.log(date);

    const tempEndDate = date.setDate(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    );

    const endDate =
      tempEndDate > Date.now() ? new Date() : new Date(tempEndDate);

    methods.setValue('date.startDate', startDate);
    methods.setValue('date.endDate', endDate);
    handleSubmit({
      ...DEFAULT_COMMISSION_FILTER_FORM,
      date: {
        startDate,
        endDate,
      },
    });
  }, [filterDateParams]);

  return (
    <div className='flex flex-col gap-y-2.5'>
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        className='flex justify-between pb-4 border-b mb-4 flex-col md-lg:flex-row gap-3 bg-white rounded-lg px-4 py-3'>
        <div className='md-lg:flex md-lg:space-x-2.5 grid grid-cols-2 gap-4'>
          <FormInputDateFromTo
            className='flex-1 min-w-[200px] shrink-0'
            inputProps={{
              placeholderStart: 'Từ ngày',
              placeholderEnd: 'Đến ngày',
              showFilter: true,
              inputSize: 'sm',
              minStartDate: toDate ? subDay(toDate, MAX_FILTER_DAY) : undefined,
              maxStartDate: new Date(),
              maxEndDate: fromDate
                ? addDay(fromDate, MAX_FILTER_DAY) < new Date()
                  ? addDay(fromDate, MAX_FILTER_DAY)
                  : new Date()
                : new Date(),
              isFullTime: true,
              defaultFilterOption: filterDateParams
                ? OPTION_DATE_RANGE_LIST[2]
                : undefined,
            }}
            label='Thời gian'
            name='date'
          />
          <FormInput
            name='bookingCode'
            label='Mã đơn hàng'
            placeholder='Nhập mã đơn hàng'
          />
          <FormSelect
            name='status'
            selectOptions={SELECT_STATUS_COMMISSION_OPTIONS}
            label={'Trạng thái'}
            inputProps={{
              isOnlyValue: true,
            }}
          />
          <div className='flex flex-col justify-end'>
            <Button
              type='submit'
              theme='secondary'
              size='sm'
              prefixIcon={<SearchLineIcon />}
              className='font-semibold !px-5'>
              Tìm kiếm
            </Button>
          </div>
        </div>
        <div className='flex space-x-2.5'>
          <div className='flex flex-col justify-end'>
            <Button
              size='sm'
              theme='green'
              onClick={handleExportCommissionReport}
              disabled={isProcessing}
              prefixIcon={<FileExcelLineIcon />}
              className='!px-5'>
              <span className='whitespace-nowrap'>
                {isProcessing ? 'Tải về...' : 'Tải về'}
              </span>
            </Button>
          </div>
        </div>
      </Form>

      <CommissionStats filterCommissionStats={filterReq} />

      <TableContent
        listCommissionOrder={listCommissionOrder}
        isLoading={isLoading}
        pagination={paginationRes}
        onPageChange={handlePagination}
      />
    </div>
  );
};

export default CommissionCtn;
