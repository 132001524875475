// source: skyhub/web_partnership/hotel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var skyhub_hotel_pb = require('../../skyhub/hotel_pb.js');
goog.object.extend(proto, skyhub_hotel_pb);
var skyhub_base_pb = require('../../skyhub/base_pb.js');
goog.object.extend(proto, skyhub_base_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.skyhub.web_partnership.ExportOrdersReq', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.ExportOrdersRes', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.GetOrderDetailRequest', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.GetOrderDetailResponse', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.ListOrdersFilter', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.ListOrdersRequest', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.ListOrdersResponse', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.SearchHotelReq', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.SearchHotelRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.SearchHotelReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.SearchHotelReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.SearchHotelReq.displayName = 'proto.skyhub.web_partnership.SearchHotelReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.SearchHotelRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.web_partnership.SearchHotelRes.repeatedFields_, null);
};
goog.inherits(proto.skyhub.web_partnership.SearchHotelRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.SearchHotelRes.displayName = 'proto.skyhub.web_partnership.SearchHotelRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.ListOrdersFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.web_partnership.ListOrdersFilter.repeatedFields_, null);
};
goog.inherits(proto.skyhub.web_partnership.ListOrdersFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.ListOrdersFilter.displayName = 'proto.skyhub.web_partnership.ListOrdersFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.ListOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.ListOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.ListOrdersRequest.displayName = 'proto.skyhub.web_partnership.ListOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.ListOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.web_partnership.ListOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.skyhub.web_partnership.ListOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.ListOrdersResponse.displayName = 'proto.skyhub.web_partnership.ListOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.ExportOrdersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.ExportOrdersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.ExportOrdersReq.displayName = 'proto.skyhub.web_partnership.ExportOrdersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.ExportOrdersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.web_partnership.ExportOrdersRes.repeatedFields_, null);
};
goog.inherits(proto.skyhub.web_partnership.ExportOrdersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.ExportOrdersRes.displayName = 'proto.skyhub.web_partnership.ExportOrdersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.GetOrderDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.GetOrderDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.GetOrderDetailRequest.displayName = 'proto.skyhub.web_partnership.GetOrderDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.GetOrderDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.GetOrderDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.GetOrderDetailResponse.displayName = 'proto.skyhub.web_partnership.GetOrderDetailResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.SearchHotelReq.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.SearchHotelReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.SearchHotelReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.SearchHotelReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    keywords: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.SearchHotelReq}
 */
proto.skyhub.web_partnership.SearchHotelReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.SearchHotelReq;
  return proto.skyhub.web_partnership.SearchHotelReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.SearchHotelReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.SearchHotelReq}
 */
proto.skyhub.web_partnership.SearchHotelReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeywords(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.SearchHotelReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.SearchHotelReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.SearchHotelReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.SearchHotelReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeywords();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional string keywords = 1;
 * @return {string}
 */
proto.skyhub.web_partnership.SearchHotelReq.prototype.getKeywords = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.SearchHotelReq} returns this
 */
proto.skyhub.web_partnership.SearchHotelReq.prototype.setKeywords = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string language = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.SearchHotelReq.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.SearchHotelReq} returns this
 */
proto.skyhub.web_partnership.SearchHotelReq.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional base.PaginationReq pagination = 3;
 * @return {?proto.base.PaginationReq}
 */
proto.skyhub.web_partnership.SearchHotelReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 3));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.skyhub.web_partnership.SearchHotelReq} returns this
*/
proto.skyhub.web_partnership.SearchHotelReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.SearchHotelReq} returns this
 */
proto.skyhub.web_partnership.SearchHotelReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.SearchHotelReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.web_partnership.SearchHotelRes.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.SearchHotelRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.SearchHotelRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.SearchHotelRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    skyhub_hotel_pb.HotelInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.SearchHotelRes}
 */
proto.skyhub.web_partnership.SearchHotelRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.SearchHotelRes;
  return proto.skyhub.web_partnership.SearchHotelRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.SearchHotelRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.SearchHotelRes}
 */
proto.skyhub.web_partnership.SearchHotelRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = new skyhub_hotel_pb.HotelInfo;
      reader.readMessage(value,skyhub_hotel_pb.HotelInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.SearchHotelRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.SearchHotelRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.SearchHotelRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      skyhub_hotel_pb.HotelInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.web_partnership.SearchHotelRes} returns this
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.SearchHotelRes} returns this
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional base.PaginationRes pagination = 3;
 * @return {?proto.base.PaginationRes}
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 3));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.skyhub.web_partnership.SearchHotelRes} returns this
*/
proto.skyhub.web_partnership.SearchHotelRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.SearchHotelRes} returns this
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated skyhub.HotelInfo items = 4;
 * @return {!Array<!proto.skyhub.HotelInfo>}
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.skyhub.HotelInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, skyhub_hotel_pb.HotelInfo, 4));
};


/**
 * @param {!Array<!proto.skyhub.HotelInfo>} value
 * @return {!proto.skyhub.web_partnership.SearchHotelRes} returns this
*/
proto.skyhub.web_partnership.SearchHotelRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.skyhub.HotelInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.HotelInfo}
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.skyhub.HotelInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.web_partnership.SearchHotelRes} returns this
 */
proto.skyhub.web_partnership.SearchHotelRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.web_partnership.ListOrdersFilter.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.ListOrdersFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.ListOrdersFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ListOrdersFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingStatusesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    orderCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    from: jspb.Message.getFieldWithDefault(msg, 3, 0),
    to: jspb.Message.getFieldWithDefault(msg, 4, 0),
    notInBookingStatusesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    officeId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter}
 */
proto.skyhub.web_partnership.ListOrdersFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.ListOrdersFilter;
  return proto.skyhub.web_partnership.ListOrdersFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.ListOrdersFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter}
 */
proto.skyhub.web_partnership.ListOrdersFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.skyhub.HotelBookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBookingStatuses(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.skyhub.HotelBookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotInBookingStatuses(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.ListOrdersFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.ListOrdersFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ListOrdersFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNotInBookingStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated skyhub.HotelBookingStatus booking_statuses = 1;
 * @return {!Array<!proto.skyhub.HotelBookingStatus>}
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.getBookingStatusesList = function() {
  return /** @type {!Array<!proto.skyhub.HotelBookingStatus>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.skyhub.HotelBookingStatus>} value
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.setBookingStatusesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.skyhub.HotelBookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.addBookingStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.clearBookingStatusesList = function() {
  return this.setBookingStatusesList([]);
};


/**
 * optional string order_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 from = 3;
 * @return {number}
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 to = 4;
 * @return {number}
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated skyhub.HotelBookingStatus not_in_booking_statuses = 5;
 * @return {!Array<!proto.skyhub.HotelBookingStatus>}
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.getNotInBookingStatusesList = function() {
  return /** @type {!Array<!proto.skyhub.HotelBookingStatus>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.skyhub.HotelBookingStatus>} value
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.setNotInBookingStatusesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.skyhub.HotelBookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.addNotInBookingStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.clearNotInBookingStatusesList = function() {
  return this.setNotInBookingStatusesList([]);
};


/**
 * optional string office_id = 6;
 * @return {string}
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.setOfficeId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.skyhub.web_partnership.ListOrdersFilter} returns this
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.clearOfficeId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.ListOrdersFilter.prototype.hasOfficeId = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.ListOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.ListOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.ListOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ListOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.skyhub.web_partnership.ListOrdersFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.ListOrdersRequest}
 */
proto.skyhub.web_partnership.ListOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.ListOrdersRequest;
  return proto.skyhub.web_partnership.ListOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.ListOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.ListOrdersRequest}
 */
proto.skyhub.web_partnership.ListOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.skyhub.web_partnership.ListOrdersFilter;
      reader.readMessage(value,proto.skyhub.web_partnership.ListOrdersFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.ListOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.ListOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.ListOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ListOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skyhub.web_partnership.ListOrdersFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.PaginationReq pagination = 1;
 * @return {?proto.base.PaginationReq}
 */
proto.skyhub.web_partnership.ListOrdersRequest.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 1));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.skyhub.web_partnership.ListOrdersRequest} returns this
*/
proto.skyhub.web_partnership.ListOrdersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.ListOrdersRequest} returns this
 */
proto.skyhub.web_partnership.ListOrdersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.ListOrdersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ListOrdersFilter filter = 2;
 * @return {?proto.skyhub.web_partnership.ListOrdersFilter}
 */
proto.skyhub.web_partnership.ListOrdersRequest.prototype.getFilter = function() {
  return /** @type{?proto.skyhub.web_partnership.ListOrdersFilter} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.web_partnership.ListOrdersFilter, 2));
};


/**
 * @param {?proto.skyhub.web_partnership.ListOrdersFilter|undefined} value
 * @return {!proto.skyhub.web_partnership.ListOrdersRequest} returns this
*/
proto.skyhub.web_partnership.ListOrdersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.ListOrdersRequest} returns this
 */
proto.skyhub.web_partnership.ListOrdersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.ListOrdersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.web_partnership.ListOrdersResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.ListOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.ListOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ListOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    skyhub_hotel_pb.OrderBasicInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.ListOrdersResponse}
 */
proto.skyhub.web_partnership.ListOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.ListOrdersResponse;
  return proto.skyhub.web_partnership.ListOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.ListOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.ListOrdersResponse}
 */
proto.skyhub.web_partnership.ListOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new skyhub_hotel_pb.OrderBasicInfo;
      reader.readMessage(value,skyhub_hotel_pb.OrderBasicInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.ListOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.ListOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ListOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      skyhub_hotel_pb.OrderBasicInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.web_partnership.ListOrdersResponse} returns this
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.ListOrdersResponse} returns this
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated skyhub.OrderBasicInfo items = 3;
 * @return {!Array<!proto.skyhub.OrderBasicInfo>}
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.skyhub.OrderBasicInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, skyhub_hotel_pb.OrderBasicInfo, 3));
};


/**
 * @param {!Array<!proto.skyhub.OrderBasicInfo>} value
 * @return {!proto.skyhub.web_partnership.ListOrdersResponse} returns this
*/
proto.skyhub.web_partnership.ListOrdersResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skyhub.OrderBasicInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.OrderBasicInfo}
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skyhub.OrderBasicInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.web_partnership.ListOrdersResponse} returns this
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.skyhub.web_partnership.ListOrdersResponse} returns this
*/
proto.skyhub.web_partnership.ListOrdersResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.ListOrdersResponse} returns this
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.ListOrdersResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.ExportOrdersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.ExportOrdersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.ExportOrdersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ExportOrdersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.skyhub.web_partnership.ListOrdersFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.ExportOrdersReq}
 */
proto.skyhub.web_partnership.ExportOrdersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.ExportOrdersReq;
  return proto.skyhub.web_partnership.ExportOrdersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.ExportOrdersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.ExportOrdersReq}
 */
proto.skyhub.web_partnership.ExportOrdersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.skyhub.web_partnership.ListOrdersFilter;
      reader.readMessage(value,proto.skyhub.web_partnership.ListOrdersFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.ExportOrdersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.ExportOrdersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.ExportOrdersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ExportOrdersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skyhub.web_partnership.ListOrdersFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ListOrdersFilter filter = 2;
 * @return {?proto.skyhub.web_partnership.ListOrdersFilter}
 */
proto.skyhub.web_partnership.ExportOrdersReq.prototype.getFilter = function() {
  return /** @type{?proto.skyhub.web_partnership.ListOrdersFilter} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.web_partnership.ListOrdersFilter, 2));
};


/**
 * @param {?proto.skyhub.web_partnership.ListOrdersFilter|undefined} value
 * @return {!proto.skyhub.web_partnership.ExportOrdersReq} returns this
*/
proto.skyhub.web_partnership.ExportOrdersReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.ExportOrdersReq} returns this
 */
proto.skyhub.web_partnership.ExportOrdersReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.ExportOrdersReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.web_partnership.ExportOrdersRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.ExportOrdersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.ExportOrdersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.ExportOrdersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ExportOrdersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    skyhub_hotel_pb.OrderBasicInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.ExportOrdersRes}
 */
proto.skyhub.web_partnership.ExportOrdersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.ExportOrdersRes;
  return proto.skyhub.web_partnership.ExportOrdersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.ExportOrdersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.ExportOrdersRes}
 */
proto.skyhub.web_partnership.ExportOrdersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new skyhub_hotel_pb.OrderBasicInfo;
      reader.readMessage(value,skyhub_hotel_pb.OrderBasicInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.ExportOrdersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.ExportOrdersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.ExportOrdersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.ExportOrdersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      skyhub_hotel_pb.OrderBasicInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.skyhub.web_partnership.ExportOrdersRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.web_partnership.ExportOrdersRes} returns this
 */
proto.skyhub.web_partnership.ExportOrdersRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.ExportOrdersRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.ExportOrdersRes} returns this
 */
proto.skyhub.web_partnership.ExportOrdersRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated skyhub.OrderBasicInfo items = 3;
 * @return {!Array<!proto.skyhub.OrderBasicInfo>}
 */
proto.skyhub.web_partnership.ExportOrdersRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.skyhub.OrderBasicInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, skyhub_hotel_pb.OrderBasicInfo, 3));
};


/**
 * @param {!Array<!proto.skyhub.OrderBasicInfo>} value
 * @return {!proto.skyhub.web_partnership.ExportOrdersRes} returns this
*/
proto.skyhub.web_partnership.ExportOrdersRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skyhub.OrderBasicInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.OrderBasicInfo}
 */
proto.skyhub.web_partnership.ExportOrdersRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skyhub.OrderBasicInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.web_partnership.ExportOrdersRes} returns this
 */
proto.skyhub.web_partnership.ExportOrdersRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.GetOrderDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.GetOrderDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.GetOrderDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetOrderDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.GetOrderDetailRequest}
 */
proto.skyhub.web_partnership.GetOrderDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.GetOrderDetailRequest;
  return proto.skyhub.web_partnership.GetOrderDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.GetOrderDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.GetOrderDetailRequest}
 */
proto.skyhub.web_partnership.GetOrderDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.GetOrderDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.GetOrderDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.GetOrderDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetOrderDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skyhub.web_partnership.GetOrderDetailRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.GetOrderDetailRequest} returns this
 */
proto.skyhub.web_partnership.GetOrderDetailRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.GetOrderDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.GetOrderDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && skyhub_hotel_pb.OrderDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.GetOrderDetailResponse}
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.GetOrderDetailResponse;
  return proto.skyhub.web_partnership.GetOrderDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.GetOrderDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.GetOrderDetailResponse}
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new skyhub_hotel_pb.OrderDetail;
      reader.readMessage(value,skyhub_hotel_pb.OrderDetail.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.GetOrderDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.GetOrderDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      skyhub_hotel_pb.OrderDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.web_partnership.GetOrderDetailResponse} returns this
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.GetOrderDetailResponse} returns this
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional skyhub.OrderDetail data = 3;
 * @return {?proto.skyhub.OrderDetail}
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.getData = function() {
  return /** @type{?proto.skyhub.OrderDetail} */ (
    jspb.Message.getWrapperField(this, skyhub_hotel_pb.OrderDetail, 3));
};


/**
 * @param {?proto.skyhub.OrderDetail|undefined} value
 * @return {!proto.skyhub.web_partnership.GetOrderDetailResponse} returns this
*/
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.GetOrderDetailResponse} returns this
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.GetOrderDetailResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.skyhub.web_partnership);
