// source: price/web_partnership/discount.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var price_base_pb = require('../../price/base_pb.js');
goog.object.extend(proto, price_base_pb);
var price_discount_pb = require('../../price/discount_pb.js');
goog.object.extend(proto, price_discount_pb);
goog.exportSymbol('proto.price.web_partnership.CreateDiscountReq', null, global);
goog.exportSymbol('proto.price.web_partnership.CreateDiscountRes', null, global);
goog.exportSymbol('proto.price.web_partnership.DeleteDiscountReq', null, global);
goog.exportSymbol('proto.price.web_partnership.DetailDiscountReq', null, global);
goog.exportSymbol('proto.price.web_partnership.DetailDiscountRes', null, global);
goog.exportSymbol('proto.price.web_partnership.GetConfigOptionsRes', null, global);
goog.exportSymbol('proto.price.web_partnership.ImportDiscountReq', null, global);
goog.exportSymbol('proto.price.web_partnership.ImportDiscountRes', null, global);
goog.exportSymbol('proto.price.web_partnership.ListDiscountsFilter', null, global);
goog.exportSymbol('proto.price.web_partnership.ListDiscountsReq', null, global);
goog.exportSymbol('proto.price.web_partnership.ListDiscountsRes', null, global);
goog.exportSymbol('proto.price.web_partnership.UpdateDiscountReq', null, global);
goog.exportSymbol('proto.price.web_partnership.UpdateDiscountRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ListDiscountsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.ListDiscountsFilter.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.ListDiscountsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ListDiscountsFilter.displayName = 'proto.price.web_partnership.ListDiscountsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ListDiscountsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.ListDiscountsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ListDiscountsReq.displayName = 'proto.price.web_partnership.ListDiscountsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.DetailDiscountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.DetailDiscountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.DetailDiscountReq.displayName = 'proto.price.web_partnership.DetailDiscountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.CreateDiscountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.CreateDiscountReq.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.CreateDiscountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.CreateDiscountReq.displayName = 'proto.price.web_partnership.CreateDiscountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.DeleteDiscountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.DeleteDiscountReq.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.DeleteDiscountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.DeleteDiscountReq.displayName = 'proto.price.web_partnership.DeleteDiscountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ImportDiscountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.ImportDiscountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ImportDiscountReq.displayName = 'proto.price.web_partnership.ImportDiscountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.UpdateDiscountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.UpdateDiscountReq.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.UpdateDiscountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.UpdateDiscountReq.displayName = 'proto.price.web_partnership.UpdateDiscountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ListDiscountsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.ListDiscountsRes.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.ListDiscountsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ListDiscountsRes.displayName = 'proto.price.web_partnership.ListDiscountsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.DetailDiscountRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.DetailDiscountRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.DetailDiscountRes.displayName = 'proto.price.web_partnership.DetailDiscountRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.CreateDiscountRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.CreateDiscountRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.CreateDiscountRes.displayName = 'proto.price.web_partnership.CreateDiscountRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.ImportDiscountRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.ImportDiscountRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.ImportDiscountRes.displayName = 'proto.price.web_partnership.ImportDiscountRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.GetConfigOptionsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.price.web_partnership.GetConfigOptionsRes.repeatedFields_, null);
};
goog.inherits(proto.price.web_partnership.GetConfigOptionsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.GetConfigOptionsRes.displayName = 'proto.price.web_partnership.GetConfigOptionsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.price.web_partnership.UpdateDiscountRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.price.web_partnership.UpdateDiscountRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.price.web_partnership.UpdateDiscountRes.displayName = 'proto.price.web_partnership.UpdateDiscountRes';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.ListDiscountsFilter.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ListDiscountsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ListDiscountsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListDiscountsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    officeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    location: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accommodationTypeList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    ratingList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f,
    countryCodeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    keywords: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ListDiscountsFilter}
 */
proto.price.web_partnership.ListDiscountsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ListDiscountsFilter;
  return proto.price.web_partnership.ListDiscountsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ListDiscountsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ListDiscountsFilter}
 */
proto.price.web_partnership.ListDiscountsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 2:
      var value = /** @type {!proto.price.DiscountType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.price.HotelLocationType} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccommodationType(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRating(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addCountryCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ListDiscountsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ListDiscountsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListDiscountsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAccommodationTypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getRatingList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
  f = message.getCountryCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getKeywords();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string office_id = 1;
 * @return {string}
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional price.DiscountType type = 2;
 * @return {!proto.price.DiscountType}
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.getType = function() {
  return /** @type {!proto.price.DiscountType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.price.DiscountType} value
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional price.HotelLocationType location = 3;
 * @return {!proto.price.HotelLocationType}
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.getLocation = function() {
  return /** @type {!proto.price.HotelLocationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.price.HotelLocationType} value
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string accommodation_type = 4;
 * @return {!Array<string>}
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.getAccommodationTypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.setAccommodationTypeList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.addAccommodationType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.clearAccommodationTypeList = function() {
  return this.setAccommodationTypeList([]);
};


/**
 * repeated double rating = 5;
 * @return {!Array<number>}
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.getRatingList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.setRatingList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.addRating = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.clearRatingList = function() {
  return this.setRatingList([]);
};


/**
 * repeated string country_code = 6;
 * @return {!Array<string>}
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.getCountryCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.setCountryCodeList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.addCountryCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.clearCountryCodeList = function() {
  return this.setCountryCodeList([]);
};


/**
 * optional string keywords = 7;
 * @return {string}
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.getKeywords = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.ListDiscountsFilter} returns this
 */
proto.price.web_partnership.ListDiscountsFilter.prototype.setKeywords = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ListDiscountsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ListDiscountsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ListDiscountsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListDiscountsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.price.web_partnership.ListDiscountsFilter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ListDiscountsReq}
 */
proto.price.web_partnership.ListDiscountsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ListDiscountsReq;
  return proto.price.web_partnership.ListDiscountsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ListDiscountsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ListDiscountsReq}
 */
proto.price.web_partnership.ListDiscountsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.price.web_partnership.ListDiscountsFilter;
      reader.readMessage(value,proto.price.web_partnership.ListDiscountsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ListDiscountsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ListDiscountsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ListDiscountsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListDiscountsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.price.web_partnership.ListDiscountsFilter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional ListDiscountsFilter filter = 1;
 * @return {?proto.price.web_partnership.ListDiscountsFilter}
 */
proto.price.web_partnership.ListDiscountsReq.prototype.getFilter = function() {
  return /** @type{?proto.price.web_partnership.ListDiscountsFilter} */ (
    jspb.Message.getWrapperField(this, proto.price.web_partnership.ListDiscountsFilter, 1));
};


/**
 * @param {?proto.price.web_partnership.ListDiscountsFilter|undefined} value
 * @return {!proto.price.web_partnership.ListDiscountsReq} returns this
*/
proto.price.web_partnership.ListDiscountsReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.ListDiscountsReq} returns this
 */
proto.price.web_partnership.ListDiscountsReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.ListDiscountsReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.price.web_partnership.ListDiscountsReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.price.web_partnership.ListDiscountsReq} returns this
*/
proto.price.web_partnership.ListDiscountsReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.ListDiscountsReq} returns this
 */
proto.price.web_partnership.ListDiscountsReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.ListDiscountsReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.DetailDiscountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.DetailDiscountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.DetailDiscountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DetailDiscountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.DetailDiscountReq}
 */
proto.price.web_partnership.DetailDiscountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.DetailDiscountReq;
  return proto.price.web_partnership.DetailDiscountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.DetailDiscountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.DetailDiscountReq}
 */
proto.price.web_partnership.DetailDiscountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.DetailDiscountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.DetailDiscountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.DetailDiscountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DetailDiscountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.price.web_partnership.DetailDiscountReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.DetailDiscountReq} returns this
 */
proto.price.web_partnership.DetailDiscountReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.CreateDiscountReq.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.CreateDiscountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.CreateDiscountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.CreateDiscountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    officeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    location: jspb.Message.getFieldWithDefault(msg, 4, 0),
    accommodationTypeList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    ratingList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    countryCodeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    hotelName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    hotelId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.CreateDiscountReq}
 */
proto.price.web_partnership.CreateDiscountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.CreateDiscountReq;
  return proto.price.web_partnership.CreateDiscountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.CreateDiscountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.CreateDiscountReq}
 */
proto.price.web_partnership.CreateDiscountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 3:
      var value = /** @type {!proto.price.DiscountType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.price.HotelLocationType} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccommodationType(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRating(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addCountryCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.CreateDiscountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.CreateDiscountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.CreateDiscountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAccommodationTypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getRatingList();
  if (f.length > 0) {
    writer.writePackedDouble(
      6,
      f
    );
  }
  f = message.getCountryCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getHotelName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string office_id = 1;
 * @return {string}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string provider = 2;
 * @return {string}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional price.DiscountType type = 3;
 * @return {!proto.price.DiscountType}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getType = function() {
  return /** @type {!proto.price.DiscountType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.price.DiscountType} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional price.HotelLocationType location = 4;
 * @return {!proto.price.HotelLocationType}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getLocation = function() {
  return /** @type {!proto.price.HotelLocationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.price.HotelLocationType} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string accommodation_type = 5;
 * @return {!Array<string>}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getAccommodationTypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setAccommodationTypeList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.addAccommodationType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.clearAccommodationTypeList = function() {
  return this.setAccommodationTypeList([]);
};


/**
 * repeated double rating = 6;
 * @return {!Array<number>}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getRatingList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setRatingList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.addRating = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.clearRatingList = function() {
  return this.setRatingList([]);
};


/**
 * repeated string country_code = 7;
 * @return {!Array<string>}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getCountryCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setCountryCodeList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.addCountryCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.clearCountryCodeList = function() {
  return this.setCountryCodeList([]);
};


/**
 * optional string hotel_name = 8;
 * @return {string}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getHotelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setHotelName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double amount = 9;
 * @return {number}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double percent = 10;
 * @return {number}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string hotel_id = 11;
 * @return {string}
 */
proto.price.web_partnership.CreateDiscountReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateDiscountReq} returns this
 */
proto.price.web_partnership.CreateDiscountReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.DeleteDiscountReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.DeleteDiscountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.DeleteDiscountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.DeleteDiscountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DeleteDiscountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.DeleteDiscountReq}
 */
proto.price.web_partnership.DeleteDiscountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.DeleteDiscountReq;
  return proto.price.web_partnership.DeleteDiscountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.DeleteDiscountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.DeleteDiscountReq}
 */
proto.price.web_partnership.DeleteDiscountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.DeleteDiscountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.DeleteDiscountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.DeleteDiscountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DeleteDiscountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.price.web_partnership.DeleteDiscountReq.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.DeleteDiscountReq} returns this
 */
proto.price.web_partnership.DeleteDiscountReq.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.DeleteDiscountReq} returns this
 */
proto.price.web_partnership.DeleteDiscountReq.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.DeleteDiscountReq} returns this
 */
proto.price.web_partnership.DeleteDiscountReq.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ImportDiscountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ImportDiscountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ImportDiscountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ImportDiscountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileChunk: msg.getFileChunk_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ImportDiscountReq}
 */
proto.price.web_partnership.ImportDiscountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ImportDiscountReq;
  return proto.price.web_partnership.ImportDiscountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ImportDiscountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ImportDiscountReq}
 */
proto.price.web_partnership.ImportDiscountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFileChunk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ImportDiscountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ImportDiscountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ImportDiscountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ImportDiscountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileChunk_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes file_chunk = 1;
 * @return {!(string|Uint8Array)}
 */
proto.price.web_partnership.ImportDiscountReq.prototype.getFileChunk = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file_chunk = 1;
 * This is a type-conversion wrapper around `getFileChunk()`
 * @return {string}
 */
proto.price.web_partnership.ImportDiscountReq.prototype.getFileChunk_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFileChunk()));
};


/**
 * optional bytes file_chunk = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFileChunk()`
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ImportDiscountReq.prototype.getFileChunk_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFileChunk()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.price.web_partnership.ImportDiscountReq} returns this
 */
proto.price.web_partnership.ImportDiscountReq.prototype.setFileChunk = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.UpdateDiscountReq.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.UpdateDiscountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.UpdateDiscountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.UpdateDiscountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    officeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    location: jspb.Message.getFieldWithDefault(msg, 5, 0),
    accommodationTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    ratingList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    countryCodeList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    hotelName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    hotelId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.UpdateDiscountReq}
 */
proto.price.web_partnership.UpdateDiscountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.UpdateDiscountReq;
  return proto.price.web_partnership.UpdateDiscountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.UpdateDiscountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.UpdateDiscountReq}
 */
proto.price.web_partnership.UpdateDiscountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {!proto.price.DiscountType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.price.HotelLocationType} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccommodationType(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRating(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addCountryCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.UpdateDiscountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.UpdateDiscountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.UpdateDiscountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAccommodationTypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getRatingList();
  if (f.length > 0) {
    writer.writePackedDouble(
      7,
      f
    );
  }
  f = message.getCountryCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getHotelName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string office_id = 2;
 * @return {string}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string provider = 3;
 * @return {string}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional price.DiscountType type = 4;
 * @return {!proto.price.DiscountType}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getType = function() {
  return /** @type {!proto.price.DiscountType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.price.DiscountType} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional price.HotelLocationType location = 5;
 * @return {!proto.price.HotelLocationType}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getLocation = function() {
  return /** @type {!proto.price.HotelLocationType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.price.HotelLocationType} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated string accommodation_type = 6;
 * @return {!Array<string>}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getAccommodationTypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setAccommodationTypeList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.addAccommodationType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.clearAccommodationTypeList = function() {
  return this.setAccommodationTypeList([]);
};


/**
 * repeated double rating = 7;
 * @return {!Array<number>}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getRatingList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setRatingList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.addRating = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.clearRatingList = function() {
  return this.setRatingList([]);
};


/**
 * repeated string country_code = 8;
 * @return {!Array<string>}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getCountryCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setCountryCodeList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.addCountryCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.clearCountryCodeList = function() {
  return this.setCountryCodeList([]);
};


/**
 * optional string hotel_name = 9;
 * @return {string}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getHotelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setHotelName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double amount = 10;
 * @return {number}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double percent = 11;
 * @return {number}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string hotel_id = 12;
 * @return {string}
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateDiscountReq} returns this
 */
proto.price.web_partnership.UpdateDiscountReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.ListDiscountsRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ListDiscountsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ListDiscountsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ListDiscountsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListDiscountsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    discountsList: jspb.Message.toObjectList(msg.getDiscountsList(),
    price_discount_pb.Discount.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ListDiscountsRes}
 */
proto.price.web_partnership.ListDiscountsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ListDiscountsRes;
  return proto.price.web_partnership.ListDiscountsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ListDiscountsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ListDiscountsRes}
 */
proto.price.web_partnership.ListDiscountsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new price_discount_pb.Discount;
      reader.readMessage(value,price_discount_pb.Discount.deserializeBinaryFromReader);
      msg.addDiscounts(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ListDiscountsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ListDiscountsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ListDiscountsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ListDiscountsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDiscountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      price_discount_pb.Discount.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.ListDiscountsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.ListDiscountsRes} returns this
 */
proto.price.web_partnership.ListDiscountsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.ListDiscountsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.ListDiscountsRes} returns this
 */
proto.price.web_partnership.ListDiscountsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated price.Discount discounts = 3;
 * @return {!Array<!proto.price.Discount>}
 */
proto.price.web_partnership.ListDiscountsRes.prototype.getDiscountsList = function() {
  return /** @type{!Array<!proto.price.Discount>} */ (
    jspb.Message.getRepeatedWrapperField(this, price_discount_pb.Discount, 3));
};


/**
 * @param {!Array<!proto.price.Discount>} value
 * @return {!proto.price.web_partnership.ListDiscountsRes} returns this
*/
proto.price.web_partnership.ListDiscountsRes.prototype.setDiscountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.price.Discount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.price.Discount}
 */
proto.price.web_partnership.ListDiscountsRes.prototype.addDiscounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.price.Discount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.ListDiscountsRes} returns this
 */
proto.price.web_partnership.ListDiscountsRes.prototype.clearDiscountsList = function() {
  return this.setDiscountsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.price.web_partnership.ListDiscountsRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.price.web_partnership.ListDiscountsRes} returns this
*/
proto.price.web_partnership.ListDiscountsRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.ListDiscountsRes} returns this
 */
proto.price.web_partnership.ListDiscountsRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.ListDiscountsRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.DetailDiscountRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.DetailDiscountRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.DetailDiscountRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DetailDiscountRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    discount: (f = msg.getDiscount()) && price_discount_pb.Discount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.DetailDiscountRes}
 */
proto.price.web_partnership.DetailDiscountRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.DetailDiscountRes;
  return proto.price.web_partnership.DetailDiscountRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.DetailDiscountRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.DetailDiscountRes}
 */
proto.price.web_partnership.DetailDiscountRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new price_discount_pb.Discount;
      reader.readMessage(value,price_discount_pb.Discount.deserializeBinaryFromReader);
      msg.setDiscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.DetailDiscountRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.DetailDiscountRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.DetailDiscountRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.DetailDiscountRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDiscount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      price_discount_pb.Discount.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.DetailDiscountRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.DetailDiscountRes} returns this
 */
proto.price.web_partnership.DetailDiscountRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.DetailDiscountRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.DetailDiscountRes} returns this
 */
proto.price.web_partnership.DetailDiscountRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional price.Discount discount = 3;
 * @return {?proto.price.Discount}
 */
proto.price.web_partnership.DetailDiscountRes.prototype.getDiscount = function() {
  return /** @type{?proto.price.Discount} */ (
    jspb.Message.getWrapperField(this, price_discount_pb.Discount, 3));
};


/**
 * @param {?proto.price.Discount|undefined} value
 * @return {!proto.price.web_partnership.DetailDiscountRes} returns this
*/
proto.price.web_partnership.DetailDiscountRes.prototype.setDiscount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.DetailDiscountRes} returns this
 */
proto.price.web_partnership.DetailDiscountRes.prototype.clearDiscount = function() {
  return this.setDiscount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.DetailDiscountRes.prototype.hasDiscount = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.CreateDiscountRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.CreateDiscountRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.CreateDiscountRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.CreateDiscountRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.CreateDiscountRes}
 */
proto.price.web_partnership.CreateDiscountRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.CreateDiscountRes;
  return proto.price.web_partnership.CreateDiscountRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.CreateDiscountRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.CreateDiscountRes}
 */
proto.price.web_partnership.CreateDiscountRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.CreateDiscountRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.CreateDiscountRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.CreateDiscountRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.CreateDiscountRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.CreateDiscountRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.CreateDiscountRes} returns this
 */
proto.price.web_partnership.CreateDiscountRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.CreateDiscountRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateDiscountRes} returns this
 */
proto.price.web_partnership.CreateDiscountRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.price.web_partnership.CreateDiscountRes.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.CreateDiscountRes} returns this
 */
proto.price.web_partnership.CreateDiscountRes.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.ImportDiscountRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.ImportDiscountRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.ImportDiscountRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ImportDiscountRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.ImportDiscountRes}
 */
proto.price.web_partnership.ImportDiscountRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.ImportDiscountRes;
  return proto.price.web_partnership.ImportDiscountRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.ImportDiscountRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.ImportDiscountRes}
 */
proto.price.web_partnership.ImportDiscountRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.ImportDiscountRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.ImportDiscountRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.ImportDiscountRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.ImportDiscountRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.ImportDiscountRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.ImportDiscountRes} returns this
 */
proto.price.web_partnership.ImportDiscountRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.ImportDiscountRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.ImportDiscountRes} returns this
 */
proto.price.web_partnership.ImportDiscountRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.price.web_partnership.GetConfigOptionsRes.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.GetConfigOptionsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.GetConfigOptionsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.GetConfigOptionsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    price_discount_pb.HotelProviderDiscount.toObject, includeInstance),
    accommodationTypesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.GetConfigOptionsRes}
 */
proto.price.web_partnership.GetConfigOptionsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.GetConfigOptionsRes;
  return proto.price.web_partnership.GetConfigOptionsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.GetConfigOptionsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.GetConfigOptionsRes}
 */
proto.price.web_partnership.GetConfigOptionsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new price_discount_pb.HotelProviderDiscount;
      reader.readMessage(value,price_discount_pb.HotelProviderDiscount.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccommodationTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.GetConfigOptionsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.GetConfigOptionsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.GetConfigOptionsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      price_discount_pb.HotelProviderDiscount.serializeBinaryToWriter
    );
  }
  f = message.getAccommodationTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.GetConfigOptionsRes} returns this
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.GetConfigOptionsRes} returns this
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated price.HotelProviderDiscount providers = 3;
 * @return {!Array<!proto.price.HotelProviderDiscount>}
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.price.HotelProviderDiscount>} */ (
    jspb.Message.getRepeatedWrapperField(this, price_discount_pb.HotelProviderDiscount, 3));
};


/**
 * @param {!Array<!proto.price.HotelProviderDiscount>} value
 * @return {!proto.price.web_partnership.GetConfigOptionsRes} returns this
*/
proto.price.web_partnership.GetConfigOptionsRes.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.price.HotelProviderDiscount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.price.HotelProviderDiscount}
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.price.HotelProviderDiscount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.GetConfigOptionsRes} returns this
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * repeated string accommodation_types = 4;
 * @return {!Array<string>}
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.getAccommodationTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.price.web_partnership.GetConfigOptionsRes} returns this
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.setAccommodationTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.price.web_partnership.GetConfigOptionsRes} returns this
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.addAccommodationTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.price.web_partnership.GetConfigOptionsRes} returns this
 */
proto.price.web_partnership.GetConfigOptionsRes.prototype.clearAccommodationTypesList = function() {
  return this.setAccommodationTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.price.web_partnership.UpdateDiscountRes.prototype.toObject = function(opt_includeInstance) {
  return proto.price.web_partnership.UpdateDiscountRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.price.web_partnership.UpdateDiscountRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.UpdateDiscountRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    discount: (f = msg.getDiscount()) && price_discount_pb.Discount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.price.web_partnership.UpdateDiscountRes}
 */
proto.price.web_partnership.UpdateDiscountRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.price.web_partnership.UpdateDiscountRes;
  return proto.price.web_partnership.UpdateDiscountRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.price.web_partnership.UpdateDiscountRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.price.web_partnership.UpdateDiscountRes}
 */
proto.price.web_partnership.UpdateDiscountRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new price_discount_pb.Discount;
      reader.readMessage(value,price_discount_pb.Discount.deserializeBinaryFromReader);
      msg.setDiscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.price.web_partnership.UpdateDiscountRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.price.web_partnership.UpdateDiscountRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.price.web_partnership.UpdateDiscountRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.price.web_partnership.UpdateDiscountRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDiscount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      price_discount_pb.Discount.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.price.web_partnership.UpdateDiscountRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.price.web_partnership.UpdateDiscountRes} returns this
 */
proto.price.web_partnership.UpdateDiscountRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.price.web_partnership.UpdateDiscountRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.price.web_partnership.UpdateDiscountRes} returns this
 */
proto.price.web_partnership.UpdateDiscountRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional price.Discount discount = 3;
 * @return {?proto.price.Discount}
 */
proto.price.web_partnership.UpdateDiscountRes.prototype.getDiscount = function() {
  return /** @type{?proto.price.Discount} */ (
    jspb.Message.getWrapperField(this, price_discount_pb.Discount, 3));
};


/**
 * @param {?proto.price.Discount|undefined} value
 * @return {!proto.price.web_partnership.UpdateDiscountRes} returns this
*/
proto.price.web_partnership.UpdateDiscountRes.prototype.setDiscount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.price.web_partnership.UpdateDiscountRes} returns this
 */
proto.price.web_partnership.UpdateDiscountRes.prototype.clearDiscount = function() {
  return this.setDiscount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.price.web_partnership.UpdateDiscountRes.prototype.hasDiscount = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.price.web_partnership);
