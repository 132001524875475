/**
 * @fileoverview gRPC-Web generated client stub for wallet.web_partner
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: wallet/web_partner/wallet.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var wallet_base_pb = require('../../wallet/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')

var wallet_transaction_pb = require('../../wallet/transaction_pb.js')
const proto = {};
proto.wallet = {};
proto.wallet.web_partner = require('./wallet_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.wallet.web_partner.WalletServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.wallet.web_partner.WalletServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.wallet.web_partner.ListTransactionReq,
 *   !proto.wallet.web_partner.ListTransactionRes>}
 */
const methodDescriptor_WalletService_ListTransaction = new grpc.web.MethodDescriptor(
  '/wallet.web_partner.WalletService/ListTransaction',
  grpc.web.MethodType.UNARY,
  proto.wallet.web_partner.ListTransactionReq,
  proto.wallet.web_partner.ListTransactionRes,
  /**
   * @param {!proto.wallet.web_partner.ListTransactionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wallet.web_partner.ListTransactionRes.deserializeBinary
);


/**
 * @param {!proto.wallet.web_partner.ListTransactionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.wallet.web_partner.ListTransactionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.wallet.web_partner.ListTransactionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.wallet.web_partner.WalletServiceClient.prototype.listTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/wallet.web_partner.WalletService/ListTransaction',
      request,
      metadata || {},
      methodDescriptor_WalletService_ListTransaction,
      callback);
};


/**
 * @param {!proto.wallet.web_partner.ListTransactionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.wallet.web_partner.ListTransactionRes>}
 *     Promise that resolves to the response
 */
proto.wallet.web_partner.WalletServicePromiseClient.prototype.listTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/wallet.web_partner.WalletService/ListTransaction',
      request,
      metadata || {},
      methodDescriptor_WalletService_ListTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.wallet.web_partner.ExportTransactionReq,
 *   !proto.wallet.web_partner.ExportTransactionRes>}
 */
const methodDescriptor_WalletService_ExportTransactions = new grpc.web.MethodDescriptor(
  '/wallet.web_partner.WalletService/ExportTransactions',
  grpc.web.MethodType.UNARY,
  proto.wallet.web_partner.ExportTransactionReq,
  proto.wallet.web_partner.ExportTransactionRes,
  /**
   * @param {!proto.wallet.web_partner.ExportTransactionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wallet.web_partner.ExportTransactionRes.deserializeBinary
);


/**
 * @param {!proto.wallet.web_partner.ExportTransactionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.wallet.web_partner.ExportTransactionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.wallet.web_partner.ExportTransactionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.wallet.web_partner.WalletServiceClient.prototype.exportTransactions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/wallet.web_partner.WalletService/ExportTransactions',
      request,
      metadata || {},
      methodDescriptor_WalletService_ExportTransactions,
      callback);
};


/**
 * @param {!proto.wallet.web_partner.ExportTransactionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.wallet.web_partner.ExportTransactionRes>}
 *     Promise that resolves to the response
 */
proto.wallet.web_partner.WalletServicePromiseClient.prototype.exportTransactions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/wallet.web_partner.WalletService/ExportTransactions',
      request,
      metadata || {},
      methodDescriptor_WalletService_ExportTransactions);
};


module.exports = proto.wallet.web_partner;

