import { Button, Form, LogoTripBookingIcon } from '@common-ui';
import { getApiErrorMessages, validatePasswordByCase } from '@core/utils';
import { ILoginForm } from '@tixlabs/grpc-client/web-partnership';

import { FormInput, FormPhoneCode } from '@common-ui';
import { useToast } from '@access-trade-admin/hooks/internals';
import { useAppDispatch } from '@access-trade-admin/stores';
import { userAuthenticate } from '@access-trade-admin/stores/reducers/user';
import { validatePhoneNumber } from '@access-trade-admin/utils';
import { useForm } from 'react-hook-form';

export const LoginCtn = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();

  const methods = useForm<ILoginForm>({
    defaultValues: { password: '', phoneCode: '+84', phoneNumber: '' },
  });

  const handleLogin = async (data: ILoginForm) => {
    try {
      await dispatch(userAuthenticate(data)).unwrap();
      methods.reset();
    } catch (error) {
      const [message] = getApiErrorMessages(error);
      toast.error(message);
    }
  };

  return (
    <div className='p-10  pr-[150px] flex space-x-10 bg-white shadow-auth-ctn rounded-lg relative'>
      <Form
        onSubmit={handleLogin}
        methods={methods}
        className='w-[350px] shrink-0 z-10'>
        <div className='space-y-[30px]'>
          <div className='space-y-2.5'>
            <div className='text-2xl font-semibold'>Đăng nhập</div>
            <div className='text-neutral-8'>Chào mừng bạn quay lại</div>
          </div>
          <div className='space-y-5 '>
            <FormPhoneCode
              name='phoneCode'
              label='Mã vùng'
              placeholder='Chọn mã vùng'
              labelProps={{ className: 'text-neutral-7' }}
              rules={{ required: 'Chọn mã vùng' }}
            />
            <FormInput
              name='phoneNumber'
              label='Điện thoại'
              placeholder='Nhập số điện thoại'
              labelProps={{ className: 'text-neutral-7' }}
              inputProps={{
                customFormat(value) {
                  return value.replace(/[^0-9]/g, '');
                },
                minLength: 4,
                maxLength: 13,
              }}
              rules={{
                required: 'Nhập số điện thoại',
                validate: {
                  phoneNumber: (v) => validatePhoneNumber(v),
                },
              }}
            />

            <FormInput
              name='password'
              label='Mật khẩu'
              placeholder='Nhập mật khẩu'
              inputProps={{ type: 'password' }}
              labelProps={{ className: 'text-neutral-7' }}
              rules={{
                required: 'Nhập mật khẩu',
                minLength: {
                  value: 8,
                  message: 'Mật khẩu phải bao gồm tối thiểu 8 kí tự',
                },
                validate: {
                  checkValid: (v) => {
                    const { hasNumeric, hasCharacter } =
                      validatePasswordByCase(v);
                    return (
                      (hasNumeric && hasCharacter) ||
                      'Mật khẩu phải bao gồm tối thiểu 8 kí tự, bao gồm cả chữ và số'
                    );
                  },
                },
              }}
            />
            <Button
              className='w-full'
              type='submit'
              disabled={methods.formState.isSubmitting}>
              Đăng nhập
            </Button>
            {/* <div className='py-2.5 flex justify-center'>
              <Link
                to='../forgot-password'
                className='text-md font-semibold text-primary'>
                Quên mât khẩu?
              </Link>
            </div> */}
          </div>
        </div>
      </Form>
      <div className='space-y-10 z-10 w-[340px]'>
        <div className='flex flex-col'>
          <img src='assets/images/logo.png' alt='logo' />
          {/* <span className='text-3xl font-semibold'>WEXPLORE</span> */}
          {/* <LogoTripBookingIcon className='w-[137px] h-[44px] text-primary' /> */}
          {/* <span>Nền tảng đặt vé máy bay toàn diện</span> */}
        </div>
        {/* <div className='space-y-4'>
          <div>Lý do bạn nên trở thành đối tác của SKYBooking:</div>
          <div className='space-y-2.5'>
            <div className='flex space-x-1'>
              <CheckFillIcon className='text-green-6' />
              <span>Vốn đầu tư thấp, thu nhập hấp dẫn</span>
            </div>
            <div className='flex space-x-1'>
              <CheckFillIcon className='text-green-6' />
              <span>Kinh doanh nhàn rỗi không cần nghiệp vụ</span>
            </div>
            <div className='flex space-x-1'>
              <CheckFillIcon className='text-green-6' />
              <span>Nền tảng website thông minh</span>
            </div>
            <div className='flex space-x-1'>
              <CheckFillIcon className='text-green-6' />
              <span>Đối tác uy tín, dịch vụ chất lượng</span>
            </div>
          </div>
          <button className='px-4 py-2.5 rounded bg-green-1 text-green-6 text-md font-semibold hover:bg-green-2 active:bg-green-3'>
            Đăng ký ngay
          </button>
        </div> */}
      </div>
      <img
        src='./assets/images/img-auth.png'
        alt='background'
        className='absolute right-0 top-0 bottom-0 z-0 h-full'
      />
    </div>
  );
};

export default LoginCtn;
