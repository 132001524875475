/**
 * @fileoverview gRPC-Web generated client stub for skyhub.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: skyhub/web_partnership/hotel.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var skyhub_hotel_pb = require('../../skyhub/hotel_pb.js')

var skyhub_base_pb = require('../../skyhub/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.skyhub = {};
proto.skyhub.web_partnership = require('./hotel_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.HotelServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.HotelServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.GetOrderDetailRequest,
 *   !proto.skyhub.web_partnership.GetOrderDetailResponse>}
 */
const methodDescriptor_HotelService_GetOrderDetail = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HotelService/GetOrderDetail',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.GetOrderDetailRequest,
  proto.skyhub.web_partnership.GetOrderDetailResponse,
  /**
   * @param {!proto.skyhub.web_partnership.GetOrderDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.GetOrderDetailResponse.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.GetOrderDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.GetOrderDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HotelServiceClient.prototype.getOrderDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetOrderDetail,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.GetOrderDetailResponse>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HotelServicePromiseClient.prototype.getOrderDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetOrderDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.SearchHotelReq,
 *   !proto.skyhub.web_partnership.SearchHotelRes>}
 */
const methodDescriptor_HotelService_SearchHotel = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HotelService/SearchHotel',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.SearchHotelReq,
  proto.skyhub.web_partnership.SearchHotelRes,
  /**
   * @param {!proto.skyhub.web_partnership.SearchHotelReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.SearchHotelRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.SearchHotelReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.SearchHotelRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.SearchHotelRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HotelServiceClient.prototype.searchHotel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/SearchHotel',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchHotel,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.SearchHotelReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.SearchHotelRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HotelServicePromiseClient.prototype.searchHotel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/SearchHotel',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchHotel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.ListOrdersRequest,
 *   !proto.skyhub.web_partnership.ListOrdersResponse>}
 */
const methodDescriptor_HotelService_ListOrders = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HotelService/ListOrders',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.ListOrdersRequest,
  proto.skyhub.web_partnership.ListOrdersResponse,
  /**
   * @param {!proto.skyhub.web_partnership.ListOrdersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.ListOrdersResponse.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.ListOrdersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.ListOrdersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HotelServiceClient.prototype.listOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/ListOrders',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListOrders,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.ListOrdersResponse>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HotelServicePromiseClient.prototype.listOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/ListOrders',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.ExportOrdersReq,
 *   !proto.skyhub.web_partnership.ExportOrdersRes>}
 */
const methodDescriptor_HotelService_ExportOrders = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HotelService/ExportOrders',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.ExportOrdersReq,
  proto.skyhub.web_partnership.ExportOrdersRes,
  /**
   * @param {!proto.skyhub.web_partnership.ExportOrdersReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.ExportOrdersRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.ExportOrdersReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.ExportOrdersRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.ExportOrdersRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HotelServiceClient.prototype.exportOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/ExportOrders',
      request,
      metadata || {},
      methodDescriptor_HotelService_ExportOrders,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.ExportOrdersReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.ExportOrdersRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HotelServicePromiseClient.prototype.exportOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/ExportOrders',
      request,
      metadata || {},
      methodDescriptor_HotelService_ExportOrders);
};


module.exports = proto.skyhub.web_partnership;

