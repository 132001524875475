import { FlightServiceClient } from '@api/airplane/web_partnership/flight_grpc_web_pb';
import {
  ExportBookingFlightReq,
  ExportBookingFlightRes,
  GetAirportsReq,
  GetAirportsRes,
  ListBookingFlightReq,
  ListBookingFlightRes,
  ListTransferBookingRequestReq,
  ListTransferBookingRequestRes,
  RefundBookingReq,
  RetrieveBookingFlightReq,
  RetrieveBookingFlightRes,
  SearchAirlinesReq,
  SearchAirlinesRes,
  SortItem,
  ListBookingFlightPartialRoundtripReq,
  ListBookingFlightPartialRoundtripRes,
  UpdateBookingFlightPartialRoundtripReq,
  GetBookingFlightPartialRoundtripDetailsReq,
  GetBookingFlightPartialRoundtripDetailsRes,
} from '@api/airplane/web_partnership/flight_pb';

import { BasicRes, PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class FlightApi extends BaseApi<FlightServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);

    this.serviceClient = new FlightServiceClient(this.url, null, this.optsDev);
  }

  retrieveBookingFlight = ({
    bookingCode,
  }: RetrieveBookingFlightReq.AsObject): Promise<RetrieveBookingFlightRes.AsObject> => {
    const req = new RetrieveBookingFlightReq();

    req.setBookingCode(bookingCode);

    return this.grpc<
      RetrieveBookingFlightReq,
      RetrieveBookingFlightRes,
      RetrieveBookingFlightRes.AsObject
    >(this.serviceClient.retrieveBookingFlight, req, {});
  };

  getBookingFlightPartialRoundtripDetails = ({
    bookingCode,
  }: GetBookingFlightPartialRoundtripDetailsReq.AsObject): Promise<GetBookingFlightPartialRoundtripDetailsRes.AsObject> => {
    const req = new GetBookingFlightPartialRoundtripDetailsReq();

    req.setBookingCode(bookingCode);

    return this.grpc<
      GetBookingFlightPartialRoundtripDetailsReq,
      GetBookingFlightPartialRoundtripDetailsRes,
      GetBookingFlightPartialRoundtripDetailsRes.AsObject
    >(this.serviceClient.getBookingFlightPartialRoundtripDetails, req, {});
  };

  updateBookingFlightPartialRoundtrip = ({
    bookingCode,
    status,
    note
  }: UpdateBookingFlightPartialRoundtripReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new UpdateBookingFlightPartialRoundtripReq();
    req.setBookingCode(bookingCode);
    req.setStatus(status);
    note && req.setNote(note);

    return this.grpc<UpdateBookingFlightPartialRoundtripReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateBookingFlightPartialRoundtrip,
      req,
      {}
    );
  };

  listBookingFlight = ({
    sortsList = [],
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingFlightReq.AsObject): Promise<ListBookingFlightRes.AsObject> => {
    const req = new ListBookingFlightReq();
    const filterObj = new ListBookingFlightReq.Filter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.toDate &&
        filterObj.setToDate(new Date(filter.toDate).setHours(23, 59, 59, 999));
      filter.fromDate &&
        filterObj.setFromDate(new Date(filter.fromDate).setHours(0, 0, 0, 0));
      filter.passengerName &&
        filterObj.setPassengerName(filter.passengerName?.trim() || '');
      filter.contactPhone &&
        filterObj.setContactPhone(filter.contactPhone?.trim() || '');
      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode?.trim() || '');
      filter.ownerId && filterObj.setOwnerId(filter.ownerId?.trim() || '');
      filter.partnershopId &&
        filterObj.setPartnershopId(filter.partnershopId?.trim() || '');
      filterObj.setStatusesList(filter.statusesList);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc);
        sortItemObj.setType(s.type);
        return sortItemObj;
      })
    );

    return this.grpc<
      ListBookingFlightReq,
      ListBookingFlightRes,
      ListBookingFlightRes.AsObject
    >(this.serviceClient?.listBookingFlight, req, {});
  };

  listBookingFlightPartialRoundtrip = ({
    sortsList = [],
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingFlightPartialRoundtripReq.AsObject): Promise<ListBookingFlightPartialRoundtripRes.AsObject> => {
    const req = new ListBookingFlightPartialRoundtripReq();
    const filterObj = new ListBookingFlightPartialRoundtripReq.Filter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.toDate &&
        filterObj.setToDate(new Date(filter.toDate).setHours(23, 59, 59, 999));
      filter.fromDate &&
        filterObj.setFromDate(new Date(filter.fromDate).setHours(0, 0, 0, 0));
      // filter.passengerName &&
      //   filterObj.setPassengerName(filter.passengerName?.trim() || '');
      // filter.contactPhone &&
      //   filterObj.setContactPhone(filter.contactPhone?.trim() || '');
      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode?.trim() || '');
      filter.ownerId && filterObj.setOwnerId(filter.ownerId?.trim() || '');
      filter.partnershopId &&
        filterObj.setPartnershopId(filter.partnershopId?.trim() || '');
      filterObj.setStatusesList(filter.statusesList);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc);
        sortItemObj.setType(s.type);
        return sortItemObj;
      })
    );

    return this.grpc<
      ListBookingFlightPartialRoundtripReq,
      ListBookingFlightPartialRoundtripRes,
      ListBookingFlightPartialRoundtripRes.AsObject
    >(this.serviceClient?.listBookingFlightPartialRoundtrip, req, {});
  };

  exportBookingFlight = ({
    sortsList,
    filter,
  }: ExportBookingFlightReq.AsObject): Promise<ExportBookingFlightRes.AsObject> => {
    const req = new ExportBookingFlightReq();
    const filterObj = new ExportBookingFlightReq.Filter();

    if (filter) {
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.passengerName &&
        filterObj.setPassengerName(filter.passengerName.trim() || '');
      filter.contactPhone &&
        filterObj.setContactPhone(filter.contactPhone.trim() || '');
      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode.trim() || '');
      filterObj.setStatusesList(filter.statusesList);
    }

    req.setFilter(filterObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc);
        sortItemObj.setType(s.type);
        return sortItemObj;
      })
    );

    return this.grpc<
      ExportBookingFlightReq,
      ExportBookingFlightRes,
      ExportBookingFlightRes.AsObject
    >(this.serviceClient?.exportBookingFlight, req, {});
  };

  refundBooking = ({
    id,
    note,
  }: RefundBookingReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new RefundBookingReq();

    req.setId(id);
    req.setNote(note);

    return this.grpc<RefundBookingReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient?.refundBooking,
      req,
      {}
    );
  };

  getAirports = ({
    codesList,
  }: GetAirportsReq.AsObject): Promise<GetAirportsRes.AsObject> => {
    const req = new GetAirportsReq();

    req.setCodesList(codesList);

    return this.grpc<GetAirportsReq, GetAirportsRes, GetAirportsRes.AsObject>(
      this.serviceClient?.getAirports,
      req,
      {}
    );
  };

  searchAirlines = ({
    filter,
    pagination,
  }: SearchAirlinesReq.AsObject): Promise<SearchAirlinesRes.AsObject> => {
    const req = new SearchAirlinesReq();
    const filterReq = new SearchAirlinesReq.Filter();

    filterReq.setText(filter?.text || '');
    if (pagination) {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(pagination.pageLimit);
      paginationReq.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationReq);
    } else {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(9999);
      paginationReq.setPageNumber(1);
      req.setPagination(paginationReq);
    }
    req.setFilter(filterReq);

    return this.grpc<
      SearchAirlinesReq,
      SearchAirlinesRes,
      SearchAirlinesRes.AsObject
    >(this.serviceClient?.searchAirlines, req, {});
  };

  listTransferBookingRequest = ({
    filter,
    pagination,
  }: ListTransferBookingRequestReq.AsObject): Promise<ListTransferBookingRequestRes.AsObject> => {
    const req = new ListTransferBookingRequestReq();
    const filterReq = new ListTransferBookingRequestReq.Filter();

    filterReq.setIssuerName(filter?.issuerName || '');
    filterReq.setAgentCode(filter?.agentCode || '');
    filterReq.setRequesterName(filter?.requesterName || '');
    filterReq.setRequestId(filter?.requestId || '');
    filterReq.setStatus(filter?.status || 0);

    if (pagination) {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(pagination.pageLimit);
      paginationReq.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationReq);
    } else {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(9999);
      paginationReq.setPageNumber(1);
      req.setPagination(paginationReq);
    }
    req.setFilter(filterReq);

    return this.grpc<
      ListTransferBookingRequestReq,
      ListTransferBookingRequestRes,
      ListTransferBookingRequestRes.AsObject
    >(this.serviceClient?.listTransferBookingRequest, req, {});
  };
}

export const flightApiService = new FlightApi();
export default flightApiService;
