import { Button } from '@common-ui';
import { getUserName } from '@core/utils';
import { IPaginationRes } from '@tixlabs/grpc-client';
import { ICustomerInfo, IUserInfo } from '@tixlabs/grpc-client/web-partnership';
import { useCountry } from '@access-trade-admin/hooks/apps';
import { useCurrency } from '@access-trade-admin/hooks/internals';

import { EUserStatus } from '@access-trade-admin/types';
import { USER_STATUS_LABEL } from '@access-trade-admin/utils';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

type Props = {
  //
  pagination: IPaginationRes;
  listCustomer: ICustomerInfo[];
  isLoading: boolean;
  onPageChange: (page: number) => void;
};

const TitleColumns = ({ title }: { title: string }) => {
  return <span className='text-neutral-black'>{title}</span>;
};

export const TableContent = ({
  pagination,
  listCustomer,
  isLoading,
  onPageChange,
}: Props) => {
  const navigate = useNavigate();
  const { formatPrice } = useCurrency();
  const { countriesObjectByCode } = useCountry();

  const mappedListCustomer = listCustomer.map((customer) => ({
    ...customer,
    name: getUserName({
      firstName: customer.firstName,
      lastName: customer.lastName,
    }),
  }));

  const columns: ColumnProps<ICustomerInfo>[] = [
    {
      title: () => {
        return <TitleColumns title={'Tên khách hàng'} />;
      },
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: () => {
        return <TitleColumns title={'Số điện thoại'} />;
      },
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (phoneNumber, customer) => {
        return <span>{`(+${customer.phoneCode}) ${phoneNumber}`}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title={'Email'} />;
      },
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: () => {
        return <TitleColumns title={'Quốc gia'} />;
      },
      dataIndex: 'country',
      key: 'country',
      render: (country) => {
        return <span>{countriesObjectByCode?.[country]?.country}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title={'Tổng đơn hàng'} />;
      },
      dataIndex: 'totalBooking',
      key: 'totalBooking',
    },
    {
      title: () => {
        return <TitleColumns title={'Tổng chi tiêu'} />;
      },
      dataIndex: 'totalExpense',
      key: 'totalExpense',

      render: (expense, customer) => {
        return `${formatPrice(expense)} VND`;
      },
    },
    {
      title: () => {
        return <TitleColumns title={'Thao tác'} />;
      },
      dataIndex: 'id',
      key: 'action',
      render: (id) => {
        return (
          <Button
            onClick={() => {
              navigate(`/user-management/${id}`);
            }}
            variant='ghost'>
            Chi tiết
          </Button>
        );
      },
      className: 'flex justify-end',
    },
  ];

  return (
    <Table
      dataSource={mappedListCustomer}
      columns={columns}
      size='small'
      pagination={{
        pageSize: pagination.pageLimit,
        current: pagination.pageCurrent,
        total: pagination.totalRecord,
        className: 'space-x-2.5',
        onChange: onPageChange,
      }}
      rowClassName={'text-neutral-black'}
      loading={isLoading}
    />
  );
};

export default TableContent;
