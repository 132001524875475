import { IBookingItinerary, IBookingPassenger } from '@tixlabs/grpc-client/';
import React from 'react';
import {
  ItineraryFromToCoordination,
  AirlineInfoCoordination,
  ItinerarySegmentInfoCoordination,
  PassengerServiceInfoCoordination,
} from '.';

type Props = {
  //
  passengersList: IBookingPassenger[];
  itinerary: IBookingItinerary;
};

export const ItineraryItemCoordination = ({ passengersList, itinerary }: Props) => {
  return (
    <div className='px-4 py-3'>
      <div className='flex flex-col gap-3'>
        <ItineraryFromToCoordination itinerary={itinerary} />
        <AirlineInfoCoordination
          code={itinerary.carrierMarketing}
          flightNumber={itinerary.flightNumber}
        />
        <ItinerarySegmentInfoCoordination segmentsList={itinerary.segmentsList} />
        <PassengerServiceInfoCoordination
          passengersList={passengersList}
          itinerary={itinerary}
        />
      </div>
    </div>
  );
};
