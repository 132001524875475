// source: partner/web_partner/partner_agent_group.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var partner_base_pb = require('../../partner/base_pb.js');
goog.object.extend(proto, partner_base_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.partner.web_partner.AgentGroupTransaction', null, global);
goog.exportSymbol('proto.partner.web_partner.Company', null, global);
goog.exportSymbol('proto.partner.web_partner.CreateAgentGroupReq', null, global);
goog.exportSymbol('proto.partner.web_partner.DepositGroupBalanceReq', null, global);
goog.exportSymbol('proto.partner.web_partner.GetAgentGroupDetailReq', null, global);
goog.exportSymbol('proto.partner.web_partner.GetAgentGroupDetailRes', null, global);
goog.exportSymbol('proto.partner.web_partner.GetAgentGroupsReq', null, global);
goog.exportSymbol('proto.partner.web_partner.GetAgentGroupsReqFilter', null, global);
goog.exportSymbol('proto.partner.web_partner.GetAgentGroupsRes', null, global);
goog.exportSymbol('proto.partner.web_partner.GetAgentGroupsResData', null, global);
goog.exportSymbol('proto.partner.web_partner.GetGroupTransactionHistoriesReq', null, global);
goog.exportSymbol('proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter', null, global);
goog.exportSymbol('proto.partner.web_partner.GetGroupTransactionHistoriesRes', null, global);
goog.exportSymbol('proto.partner.web_partner.GetGroupTransactionHistoriesResData', null, global);
goog.exportSymbol('proto.partner.web_partner.InvoicingInformation', null, global);
goog.exportSymbol('proto.partner.web_partner.PartnerAgentGroup', null, global);
goog.exportSymbol('proto.partner.web_partner.PartnerAgentGroupCommission', null, global);
goog.exportSymbol('proto.partner.web_partner.PartnerAgentGroupContact', null, global);
goog.exportSymbol('proto.partner.web_partner.PartnerAgentGroupStatus', null, global);
goog.exportSymbol('proto.partner.web_partner.Receiver', null, global);
goog.exportSymbol('proto.partner.web_partner.TransactionType', null, global);
goog.exportSymbol('proto.partner.web_partner.UpdateAgentGroupReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.AgentGroupTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.AgentGroupTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.AgentGroupTransaction.displayName = 'proto.partner.web_partner.AgentGroupTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.PartnerAgentGroupCommission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.PartnerAgentGroupCommission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.PartnerAgentGroupCommission.displayName = 'proto.partner.web_partner.PartnerAgentGroupCommission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.PartnerAgentGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.PartnerAgentGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.PartnerAgentGroup.displayName = 'proto.partner.web_partner.PartnerAgentGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetAgentGroupsReqFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.GetAgentGroupsReqFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetAgentGroupsReqFilter.displayName = 'proto.partner.web_partner.GetAgentGroupsReqFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetAgentGroupsResData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partner.GetAgentGroupsResData.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partner.GetAgentGroupsResData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetAgentGroupsResData.displayName = 'proto.partner.web_partner.GetAgentGroupsResData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partner.GetGroupTransactionHistoriesResData.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partner.GetGroupTransactionHistoriesResData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetGroupTransactionHistoriesResData.displayName = 'proto.partner.web_partner.GetGroupTransactionHistoriesResData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.PartnerAgentGroupContact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.PartnerAgentGroupContact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.PartnerAgentGroupContact.displayName = 'proto.partner.web_partner.PartnerAgentGroupContact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.DepositGroupBalanceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.DepositGroupBalanceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.DepositGroupBalanceReq.displayName = 'proto.partner.web_partner.DepositGroupBalanceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.CreateAgentGroupReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.CreateAgentGroupReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.CreateAgentGroupReq.displayName = 'proto.partner.web_partner.CreateAgentGroupReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.UpdateAgentGroupReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.UpdateAgentGroupReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.UpdateAgentGroupReq.displayName = 'proto.partner.web_partner.UpdateAgentGroupReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetAgentGroupDetailReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.GetAgentGroupDetailReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetAgentGroupDetailReq.displayName = 'proto.partner.web_partner.GetAgentGroupDetailReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetAgentGroupDetailRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.GetAgentGroupDetailRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetAgentGroupDetailRes.displayName = 'proto.partner.web_partner.GetAgentGroupDetailRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetAgentGroupsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partner.GetAgentGroupsReq.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partner.GetAgentGroupsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetAgentGroupsReq.displayName = 'proto.partner.web_partner.GetAgentGroupsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.displayName = 'proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.GetGroupTransactionHistoriesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetGroupTransactionHistoriesReq.displayName = 'proto.partner.web_partner.GetGroupTransactionHistoriesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetAgentGroupsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.GetAgentGroupsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetAgentGroupsRes.displayName = 'proto.partner.web_partner.GetAgentGroupsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.GetGroupTransactionHistoriesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.GetGroupTransactionHistoriesRes.displayName = 'proto.partner.web_partner.GetGroupTransactionHistoriesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.Company.displayName = 'proto.partner.web_partner.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.Receiver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.Receiver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.Receiver.displayName = 'proto.partner.web_partner.Receiver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partner.InvoicingInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partner.InvoicingInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partner.InvoicingInformation.displayName = 'proto.partner.web_partner.InvoicingInformation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.AgentGroupTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.AgentGroupTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.AgentGroupTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    groupCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    depositAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    transactionCode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.AgentGroupTransaction}
 */
proto.partner.web_partner.AgentGroupTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.AgentGroupTransaction;
  return proto.partner.web_partner.AgentGroupTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.AgentGroupTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.AgentGroupTransaction}
 */
proto.partner.web_partner.AgentGroupTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDepositAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.AgentGroupTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.AgentGroupTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.AgentGroupTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGroupCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepositAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTransactionCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 created_at = 1;
 * @return {number}
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.web_partner.AgentGroupTransaction} returns this
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string group_code = 2;
 * @return {string}
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.getGroupCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.AgentGroupTransaction} returns this
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.setGroupCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string created_by = 3;
 * @return {string}
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.AgentGroupTransaction} returns this
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double deposit_amount = 4;
 * @return {number}
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.getDepositAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.partner.web_partner.AgentGroupTransaction} returns this
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.setDepositAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string transaction_code = 5;
 * @return {string}
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.getTransactionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.AgentGroupTransaction} returns this
 */
proto.partner.web_partner.AgentGroupTransaction.prototype.setTransactionCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.PartnerAgentGroupCommission.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.PartnerAgentGroupCommission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.PartnerAgentGroupCommission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.PartnerAgentGroupCommission.toObject = function(includeInstance, msg) {
  var f, obj = {
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.PartnerAgentGroupCommission}
 */
proto.partner.web_partner.PartnerAgentGroupCommission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.PartnerAgentGroupCommission;
  return proto.partner.web_partner.PartnerAgentGroupCommission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.PartnerAgentGroupCommission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.PartnerAgentGroupCommission}
 */
proto.partner.web_partner.PartnerAgentGroupCommission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.PartnerAgentGroupCommission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.PartnerAgentGroupCommission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.PartnerAgentGroupCommission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.PartnerAgentGroupCommission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double percent = 1;
 * @return {number}
 */
proto.partner.web_partner.PartnerAgentGroupCommission.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.partner.web_partner.PartnerAgentGroupCommission} returns this
 */
proto.partner.web_partner.PartnerAgentGroupCommission.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.partner.web_partner.PartnerAgentGroupCommission.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.partner.web_partner.PartnerAgentGroupCommission} returns this
 */
proto.partner.web_partner.PartnerAgentGroupCommission.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.PartnerAgentGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.PartnerAgentGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.PartnerAgentGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactInfo: (f = msg.getContactInfo()) && proto.partner.web_partner.PartnerAgentGroupContact.toObject(includeInstance, f),
    commission: (f = msg.getCommission()) && proto.partner.web_partner.PartnerAgentGroupCommission.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ownerUsername: jspb.Message.getFieldWithDefault(msg, 6, ""),
    usedAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    balance: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    logo: jspb.Message.getFieldWithDefault(msg, 9, ""),
    invoicingInfo: (f = msg.getInvoicingInfo()) && proto.partner.web_partner.InvoicingInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.PartnerAgentGroup}
 */
proto.partner.web_partner.PartnerAgentGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.PartnerAgentGroup;
  return proto.partner.web_partner.PartnerAgentGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.PartnerAgentGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.PartnerAgentGroup}
 */
proto.partner.web_partner.PartnerAgentGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.partner.web_partner.PartnerAgentGroupContact;
      reader.readMessage(value,proto.partner.web_partner.PartnerAgentGroupContact.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 4:
      var value = new proto.partner.web_partner.PartnerAgentGroupCommission;
      reader.readMessage(value,proto.partner.web_partner.PartnerAgentGroupCommission.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 5:
      var value = /** @type {!proto.partner.web_partner.PartnerAgentGroupStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerUsername(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUsedAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBalance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 10:
      var value = new proto.partner.web_partner.InvoicingInformation;
      reader.readMessage(value,proto.partner.web_partner.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.PartnerAgentGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.PartnerAgentGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.PartnerAgentGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partner.PartnerAgentGroupContact.serializeBinaryToWriter
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.partner.web_partner.PartnerAgentGroupCommission.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getOwnerUsername();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsedAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInvoicingInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.partner.web_partner.InvoicingInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PartnerAgentGroupContact contact_info = 3;
 * @return {?proto.partner.web_partner.PartnerAgentGroupContact}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getContactInfo = function() {
  return /** @type{?proto.partner.web_partner.PartnerAgentGroupContact} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.PartnerAgentGroupContact, 3));
};


/**
 * @param {?proto.partner.web_partner.PartnerAgentGroupContact|undefined} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
*/
proto.partner.web_partner.PartnerAgentGroup.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PartnerAgentGroupCommission commission = 4;
 * @return {?proto.partner.web_partner.PartnerAgentGroupCommission}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getCommission = function() {
  return /** @type{?proto.partner.web_partner.PartnerAgentGroupCommission} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.PartnerAgentGroupCommission, 4));
};


/**
 * @param {?proto.partner.web_partner.PartnerAgentGroupCommission|undefined} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
*/
proto.partner.web_partner.PartnerAgentGroup.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PartnerAgentGroupStatus status = 5;
 * @return {!proto.partner.web_partner.PartnerAgentGroupStatus}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getStatus = function() {
  return /** @type {!proto.partner.web_partner.PartnerAgentGroupStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.partner.web_partner.PartnerAgentGroupStatus} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string owner_username = 6;
 * @return {string}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getOwnerUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.setOwnerUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double used_amount = 7;
 * @return {number}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getUsedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.setUsedAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double balance = 8;
 * @return {number}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.setBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string logo = 9;
 * @return {string}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional InvoicingInformation invoicing_info = 10;
 * @return {?proto.partner.web_partner.InvoicingInformation}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.getInvoicingInfo = function() {
  return /** @type{?proto.partner.web_partner.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.InvoicingInformation, 10));
};


/**
 * @param {?proto.partner.web_partner.InvoicingInformation|undefined} value
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
*/
proto.partner.web_partner.PartnerAgentGroup.prototype.setInvoicingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.PartnerAgentGroup} returns this
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.clearInvoicingInfo = function() {
  return this.setInvoicingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.PartnerAgentGroup.prototype.hasInvoicingInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetAgentGroupsReqFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetAgentGroupsReqFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    showBalance: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetAgentGroupsReqFilter}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetAgentGroupsReqFilter;
  return proto.partner.web_partner.GetAgentGroupsReqFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetAgentGroupsReqFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetAgentGroupsReqFilter}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 3:
      var value = /** @type {!proto.partner.web_partner.PartnerAgentGroupStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetAgentGroupsReqFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetAgentGroupsReqFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getShowBalance();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.GetAgentGroupsReqFilter} returns this
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partner.GetAgentGroupsReqFilter} returns this
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.clearCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.hasCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.GetAgentGroupsReqFilter} returns this
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.setQuery = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partner.GetAgentGroupsReqFilter} returns this
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.clearQuery = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PartnerAgentGroupStatus status = 3;
 * @return {!proto.partner.web_partner.PartnerAgentGroupStatus}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.getStatus = function() {
  return /** @type {!proto.partner.web_partner.PartnerAgentGroupStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.partner.web_partner.PartnerAgentGroupStatus} value
 * @return {!proto.partner.web_partner.GetAgentGroupsReqFilter} returns this
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool show_balance = 4;
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.getShowBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partner.GetAgentGroupsReqFilter} returns this
 */
proto.partner.web_partner.GetAgentGroupsReqFilter.prototype.setShowBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partner.GetAgentGroupsResData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetAgentGroupsResData.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetAgentGroupsResData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetAgentGroupsResData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupsResData.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.partner.web_partner.PartnerAgentGroup.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetAgentGroupsResData}
 */
proto.partner.web_partner.GetAgentGroupsResData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetAgentGroupsResData;
  return proto.partner.web_partner.GetAgentGroupsResData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetAgentGroupsResData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetAgentGroupsResData}
 */
proto.partner.web_partner.GetAgentGroupsResData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partner.web_partner.PartnerAgentGroup;
      reader.readMessage(value,proto.partner.web_partner.PartnerAgentGroup.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetAgentGroupsResData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetAgentGroupsResData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetAgentGroupsResData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupsResData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.partner.web_partner.PartnerAgentGroup.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PartnerAgentGroup items = 1;
 * @return {!Array<!proto.partner.web_partner.PartnerAgentGroup>}
 */
proto.partner.web_partner.GetAgentGroupsResData.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partner.web_partner.PartnerAgentGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.web_partner.PartnerAgentGroup, 1));
};


/**
 * @param {!Array<!proto.partner.web_partner.PartnerAgentGroup>} value
 * @return {!proto.partner.web_partner.GetAgentGroupsResData} returns this
*/
proto.partner.web_partner.GetAgentGroupsResData.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.partner.web_partner.PartnerAgentGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partner.PartnerAgentGroup}
 */
proto.partner.web_partner.GetAgentGroupsResData.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.partner.web_partner.PartnerAgentGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partner.GetAgentGroupsResData} returns this
 */
proto.partner.web_partner.GetAgentGroupsResData.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.partner.web_partner.GetAgentGroupsResData.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.partner.web_partner.GetAgentGroupsResData} returns this
*/
proto.partner.web_partner.GetAgentGroupsResData.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.GetAgentGroupsResData} returns this
 */
proto.partner.web_partner.GetAgentGroupsResData.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupsResData.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetGroupTransactionHistoriesResData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesResData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.partner.web_partner.AgentGroupTransaction.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesResData}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetGroupTransactionHistoriesResData;
  return proto.partner.web_partner.GetGroupTransactionHistoriesResData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesResData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesResData}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partner.web_partner.AgentGroupTransaction;
      reader.readMessage(value,proto.partner.web_partner.AgentGroupTransaction.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetGroupTransactionHistoriesResData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesResData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.partner.web_partner.AgentGroupTransaction.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AgentGroupTransaction items = 1;
 * @return {!Array<!proto.partner.web_partner.AgentGroupTransaction>}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partner.web_partner.AgentGroupTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.web_partner.AgentGroupTransaction, 1));
};


/**
 * @param {!Array<!proto.partner.web_partner.AgentGroupTransaction>} value
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesResData} returns this
*/
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.partner.web_partner.AgentGroupTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partner.AgentGroupTransaction}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.partner.web_partner.AgentGroupTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesResData} returns this
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesResData} returns this
*/
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesResData} returns this
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesResData.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.PartnerAgentGroupContact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.PartnerAgentGroupContact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.PartnerAgentGroupContact.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.PartnerAgentGroupContact}
 */
proto.partner.web_partner.PartnerAgentGroupContact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.PartnerAgentGroupContact;
  return proto.partner.web_partner.PartnerAgentGroupContact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.PartnerAgentGroupContact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.PartnerAgentGroupContact}
 */
proto.partner.web_partner.PartnerAgentGroupContact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.PartnerAgentGroupContact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.PartnerAgentGroupContact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.PartnerAgentGroupContact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.PartnerAgentGroupContact} returns this
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.PartnerAgentGroupContact} returns this
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.PartnerAgentGroupContact} returns this
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.PartnerAgentGroupContact} returns this
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.PartnerAgentGroupContact} returns this
 */
proto.partner.web_partner.PartnerAgentGroupContact.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.DepositGroupBalanceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.DepositGroupBalanceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.DepositGroupBalanceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.DepositGroupBalanceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    depositAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.DepositGroupBalanceReq}
 */
proto.partner.web_partner.DepositGroupBalanceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.DepositGroupBalanceReq;
  return proto.partner.web_partner.DepositGroupBalanceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.DepositGroupBalanceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.DepositGroupBalanceReq}
 */
proto.partner.web_partner.DepositGroupBalanceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDepositAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.DepositGroupBalanceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.DepositGroupBalanceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.DepositGroupBalanceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.DepositGroupBalanceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepositAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.partner.web_partner.DepositGroupBalanceReq.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.DepositGroupBalanceReq} returns this
 */
proto.partner.web_partner.DepositGroupBalanceReq.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double deposit_amount = 2;
 * @return {number}
 */
proto.partner.web_partner.DepositGroupBalanceReq.prototype.getDepositAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.partner.web_partner.DepositGroupBalanceReq} returns this
 */
proto.partner.web_partner.DepositGroupBalanceReq.prototype.setDepositAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.CreateAgentGroupReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.CreateAgentGroupReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.CreateAgentGroupReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactInfo: (f = msg.getContactInfo()) && proto.partner.web_partner.PartnerAgentGroupContact.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    commission: (f = msg.getCommission()) && proto.partner.web_partner.PartnerAgentGroupCommission.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    logo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    invoicingInfo: (f = msg.getInvoicingInfo()) && proto.partner.web_partner.InvoicingInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.CreateAgentGroupReq}
 */
proto.partner.web_partner.CreateAgentGroupReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.CreateAgentGroupReq;
  return proto.partner.web_partner.CreateAgentGroupReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.CreateAgentGroupReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.CreateAgentGroupReq}
 */
proto.partner.web_partner.CreateAgentGroupReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = new proto.partner.web_partner.PartnerAgentGroupContact;
      reader.readMessage(value,proto.partner.web_partner.PartnerAgentGroupContact.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new proto.partner.web_partner.PartnerAgentGroupCommission;
      reader.readMessage(value,proto.partner.web_partner.PartnerAgentGroupCommission.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 6:
      var value = /** @type {!proto.partner.web_partner.PartnerAgentGroupStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 8:
      var value = new proto.partner.web_partner.InvoicingInformation;
      reader.readMessage(value,proto.partner.web_partner.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.CreateAgentGroupReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.CreateAgentGroupReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.CreateAgentGroupReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partner.PartnerAgentGroupContact.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.partner.web_partner.PartnerAgentGroupCommission.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInvoicingInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.partner.web_partner.InvoicingInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PartnerAgentGroupContact contact_info = 3;
 * @return {?proto.partner.web_partner.PartnerAgentGroupContact}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.getContactInfo = function() {
  return /** @type{?proto.partner.web_partner.PartnerAgentGroupContact} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.PartnerAgentGroupContact, 3));
};


/**
 * @param {?proto.partner.web_partner.PartnerAgentGroupContact|undefined} value
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
*/
proto.partner.web_partner.CreateAgentGroupReq.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional PartnerAgentGroupCommission commission = 5;
 * @return {?proto.partner.web_partner.PartnerAgentGroupCommission}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.getCommission = function() {
  return /** @type{?proto.partner.web_partner.PartnerAgentGroupCommission} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.PartnerAgentGroupCommission, 5));
};


/**
 * @param {?proto.partner.web_partner.PartnerAgentGroupCommission|undefined} value
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
*/
proto.partner.web_partner.CreateAgentGroupReq.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PartnerAgentGroupStatus status = 6;
 * @return {!proto.partner.web_partner.PartnerAgentGroupStatus}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.getStatus = function() {
  return /** @type {!proto.partner.web_partner.PartnerAgentGroupStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.partner.web_partner.PartnerAgentGroupStatus} value
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string logo = 7;
 * @return {string}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional InvoicingInformation invoicing_info = 8;
 * @return {?proto.partner.web_partner.InvoicingInformation}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.getInvoicingInfo = function() {
  return /** @type{?proto.partner.web_partner.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.InvoicingInformation, 8));
};


/**
 * @param {?proto.partner.web_partner.InvoicingInformation|undefined} value
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
*/
proto.partner.web_partner.CreateAgentGroupReq.prototype.setInvoicingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.CreateAgentGroupReq} returns this
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.clearInvoicingInfo = function() {
  return this.setInvoicingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.CreateAgentGroupReq.prototype.hasInvoicingInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.UpdateAgentGroupReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.UpdateAgentGroupReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.UpdateAgentGroupReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactInfo: (f = msg.getContactInfo()) && proto.partner.web_partner.PartnerAgentGroupContact.toObject(includeInstance, f),
    commission: (f = msg.getCommission()) && proto.partner.web_partner.PartnerAgentGroupCommission.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    logo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    invoicingInfo: (f = msg.getInvoicingInfo()) && proto.partner.web_partner.InvoicingInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq}
 */
proto.partner.web_partner.UpdateAgentGroupReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.UpdateAgentGroupReq;
  return proto.partner.web_partner.UpdateAgentGroupReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.UpdateAgentGroupReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq}
 */
proto.partner.web_partner.UpdateAgentGroupReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.partner.web_partner.PartnerAgentGroupContact;
      reader.readMessage(value,proto.partner.web_partner.PartnerAgentGroupContact.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 4:
      var value = new proto.partner.web_partner.PartnerAgentGroupCommission;
      reader.readMessage(value,proto.partner.web_partner.PartnerAgentGroupCommission.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 5:
      var value = /** @type {!proto.partner.web_partner.PartnerAgentGroupStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 8:
      var value = new proto.partner.web_partner.InvoicingInformation;
      reader.readMessage(value,proto.partner.web_partner.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.UpdateAgentGroupReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.UpdateAgentGroupReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.UpdateAgentGroupReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partner.PartnerAgentGroupContact.serializeBinaryToWriter
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.partner.web_partner.PartnerAgentGroupCommission.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInvoicingInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.partner.web_partner.InvoicingInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PartnerAgentGroupContact contact_info = 3;
 * @return {?proto.partner.web_partner.PartnerAgentGroupContact}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.getContactInfo = function() {
  return /** @type{?proto.partner.web_partner.PartnerAgentGroupContact} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.PartnerAgentGroupContact, 3));
};


/**
 * @param {?proto.partner.web_partner.PartnerAgentGroupContact|undefined} value
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
*/
proto.partner.web_partner.UpdateAgentGroupReq.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PartnerAgentGroupCommission commission = 4;
 * @return {?proto.partner.web_partner.PartnerAgentGroupCommission}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.getCommission = function() {
  return /** @type{?proto.partner.web_partner.PartnerAgentGroupCommission} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.PartnerAgentGroupCommission, 4));
};


/**
 * @param {?proto.partner.web_partner.PartnerAgentGroupCommission|undefined} value
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
*/
proto.partner.web_partner.UpdateAgentGroupReq.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PartnerAgentGroupStatus status = 5;
 * @return {!proto.partner.web_partner.PartnerAgentGroupStatus}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.getStatus = function() {
  return /** @type {!proto.partner.web_partner.PartnerAgentGroupStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.partner.web_partner.PartnerAgentGroupStatus} value
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string logo = 6;
 * @return {string}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional InvoicingInformation invoicing_info = 8;
 * @return {?proto.partner.web_partner.InvoicingInformation}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.getInvoicingInfo = function() {
  return /** @type{?proto.partner.web_partner.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.InvoicingInformation, 8));
};


/**
 * @param {?proto.partner.web_partner.InvoicingInformation|undefined} value
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
*/
proto.partner.web_partner.UpdateAgentGroupReq.prototype.setInvoicingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.UpdateAgentGroupReq} returns this
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.clearInvoicingInfo = function() {
  return this.setInvoicingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.UpdateAgentGroupReq.prototype.hasInvoicingInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetAgentGroupDetailReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetAgentGroupDetailReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetAgentGroupDetailReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupDetailReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    showBalance: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetAgentGroupDetailReq}
 */
proto.partner.web_partner.GetAgentGroupDetailReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetAgentGroupDetailReq;
  return proto.partner.web_partner.GetAgentGroupDetailReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetAgentGroupDetailReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetAgentGroupDetailReq}
 */
proto.partner.web_partner.GetAgentGroupDetailReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetAgentGroupDetailReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetAgentGroupDetailReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetAgentGroupDetailReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupDetailReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShowBalance();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.partner.web_partner.GetAgentGroupDetailReq.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.GetAgentGroupDetailReq} returns this
 */
proto.partner.web_partner.GetAgentGroupDetailReq.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool show_balance = 2;
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupDetailReq.prototype.getShowBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partner.GetAgentGroupDetailReq} returns this
 */
proto.partner.web_partner.GetAgentGroupDetailReq.prototype.setShowBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetAgentGroupDetailRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetAgentGroupDetailRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupDetailRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partner.web_partner.PartnerAgentGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetAgentGroupDetailRes}
 */
proto.partner.web_partner.GetAgentGroupDetailRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetAgentGroupDetailRes;
  return proto.partner.web_partner.GetAgentGroupDetailRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetAgentGroupDetailRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetAgentGroupDetailRes}
 */
proto.partner.web_partner.GetAgentGroupDetailRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partner.web_partner.PartnerAgentGroup;
      reader.readMessage(value,proto.partner.web_partner.PartnerAgentGroup.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetAgentGroupDetailRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetAgentGroupDetailRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupDetailRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partner.PartnerAgentGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partner.GetAgentGroupDetailRes} returns this
 */
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.GetAgentGroupDetailRes} returns this
 */
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PartnerAgentGroup data = 3;
 * @return {?proto.partner.web_partner.PartnerAgentGroup}
 */
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.getData = function() {
  return /** @type{?proto.partner.web_partner.PartnerAgentGroup} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.PartnerAgentGroup, 3));
};


/**
 * @param {?proto.partner.web_partner.PartnerAgentGroup|undefined} value
 * @return {!proto.partner.web_partner.GetAgentGroupDetailRes} returns this
*/
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.GetAgentGroupDetailRes} returns this
 */
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupDetailRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partner.GetAgentGroupsReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetAgentGroupsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetAgentGroupsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.partner.web_partner.GetAgentGroupsReqFilter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    orderByList: jspb.Message.toObjectList(msg.getOrderByList(),
    base_base_pb.OrderByItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetAgentGroupsReq}
 */
proto.partner.web_partner.GetAgentGroupsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetAgentGroupsReq;
  return proto.partner.web_partner.GetAgentGroupsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetAgentGroupsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetAgentGroupsReq}
 */
proto.partner.web_partner.GetAgentGroupsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partner.web_partner.GetAgentGroupsReqFilter;
      reader.readMessage(value,proto.partner.web_partner.GetAgentGroupsReqFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new base_base_pb.OrderByItem;
      reader.readMessage(value,base_base_pb.OrderByItem.deserializeBinaryFromReader);
      msg.addOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetAgentGroupsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetAgentGroupsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partner.web_partner.GetAgentGroupsReqFilter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      base_base_pb.OrderByItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAgentGroupsReqFilter filter = 1;
 * @return {?proto.partner.web_partner.GetAgentGroupsReqFilter}
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.getFilter = function() {
  return /** @type{?proto.partner.web_partner.GetAgentGroupsReqFilter} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.GetAgentGroupsReqFilter, 1));
};


/**
 * @param {?proto.partner.web_partner.GetAgentGroupsReqFilter|undefined} value
 * @return {!proto.partner.web_partner.GetAgentGroupsReq} returns this
*/
proto.partner.web_partner.GetAgentGroupsReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.GetAgentGroupsReq} returns this
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.partner.web_partner.GetAgentGroupsReq} returns this
*/
proto.partner.web_partner.GetAgentGroupsReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.GetAgentGroupsReq} returns this
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated base.OrderByItem order_by = 3;
 * @return {!Array<!proto.base.OrderByItem>}
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.getOrderByList = function() {
  return /** @type{!Array<!proto.base.OrderByItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, base_base_pb.OrderByItem, 3));
};


/**
 * @param {!Array<!proto.base.OrderByItem>} value
 * @return {!proto.partner.web_partner.GetAgentGroupsReq} returns this
*/
proto.partner.web_partner.GetAgentGroupsReq.prototype.setOrderByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.base.OrderByItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.base.OrderByItem}
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.addOrderBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.base.OrderByItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partner.GetAgentGroupsReq} returns this
 */
proto.partner.web_partner.GetAgentGroupsReq.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter;
  return proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {!proto.partner.web_partner.TransactionType} */ (reader.readEnum());
      msg.setTransactionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter} returns this
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TransactionType transaction_type = 2;
 * @return {!proto.partner.web_partner.TransactionType}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.prototype.getTransactionType = function() {
  return /** @type {!proto.partner.web_partner.TransactionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.partner.web_partner.TransactionType} value
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter} returns this
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetGroupTransactionHistoriesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReq}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetGroupTransactionHistoriesReq;
  return proto.partner.web_partner.GetGroupTransactionHistoriesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReq}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter;
      reader.readMessage(value,proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetGroupTransactionHistoriesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetGroupTransactionHistoriesReqFilter filter = 1;
 * @return {?proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.getFilter = function() {
  return /** @type{?proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter, 1));
};


/**
 * @param {?proto.partner.web_partner.GetGroupTransactionHistoriesReqFilter|undefined} value
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} returns this
*/
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} returns this
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} returns this
*/
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} returns this
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetAgentGroupsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetAgentGroupsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetAgentGroupsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partner.web_partner.GetAgentGroupsResData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetAgentGroupsRes}
 */
proto.partner.web_partner.GetAgentGroupsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetAgentGroupsRes;
  return proto.partner.web_partner.GetAgentGroupsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetAgentGroupsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetAgentGroupsRes}
 */
proto.partner.web_partner.GetAgentGroupsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partner.web_partner.GetAgentGroupsResData;
      reader.readMessage(value,proto.partner.web_partner.GetAgentGroupsResData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetAgentGroupsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetAgentGroupsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetAgentGroupsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetAgentGroupsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partner.GetAgentGroupsResData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partner.GetAgentGroupsRes} returns this
 */
proto.partner.web_partner.GetAgentGroupsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partner.GetAgentGroupsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.GetAgentGroupsRes} returns this
 */
proto.partner.web_partner.GetAgentGroupsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GetAgentGroupsResData data = 3;
 * @return {?proto.partner.web_partner.GetAgentGroupsResData}
 */
proto.partner.web_partner.GetAgentGroupsRes.prototype.getData = function() {
  return /** @type{?proto.partner.web_partner.GetAgentGroupsResData} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.GetAgentGroupsResData, 3));
};


/**
 * @param {?proto.partner.web_partner.GetAgentGroupsResData|undefined} value
 * @return {!proto.partner.web_partner.GetAgentGroupsRes} returns this
*/
proto.partner.web_partner.GetAgentGroupsRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.GetAgentGroupsRes} returns this
 */
proto.partner.web_partner.GetAgentGroupsRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetAgentGroupsRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.GetGroupTransactionHistoriesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partner.web_partner.GetGroupTransactionHistoriesResData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesRes}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.GetGroupTransactionHistoriesRes;
  return proto.partner.web_partner.GetGroupTransactionHistoriesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesRes}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partner.web_partner.GetGroupTransactionHistoriesResData;
      reader.readMessage(value,proto.partner.web_partner.GetGroupTransactionHistoriesResData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.GetGroupTransactionHistoriesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partner.GetGroupTransactionHistoriesResData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesRes} returns this
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesRes} returns this
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GetGroupTransactionHistoriesResData data = 3;
 * @return {?proto.partner.web_partner.GetGroupTransactionHistoriesResData}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.getData = function() {
  return /** @type{?proto.partner.web_partner.GetGroupTransactionHistoriesResData} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.GetGroupTransactionHistoriesResData, 3));
};


/**
 * @param {?proto.partner.web_partner.GetGroupTransactionHistoriesResData|undefined} value
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesRes} returns this
*/
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.GetGroupTransactionHistoriesRes} returns this
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.GetGroupTransactionHistoriesRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.Company}
 */
proto.partner.web_partner.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.Company;
  return proto.partner.web_partner.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.Company}
 */
proto.partner.web_partner.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.web_partner.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.Company} returns this
 */
proto.partner.web_partner.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tax_code = 2;
 * @return {string}
 */
proto.partner.web_partner.Company.prototype.getTaxCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.Company} returns this
 */
proto.partner.web_partner.Company.prototype.setTaxCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.partner.web_partner.Company.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.Company} returns this
 */
proto.partner.web_partner.Company.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.Receiver.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.Receiver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.Receiver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.Receiver.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    note: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.Receiver}
 */
proto.partner.web_partner.Receiver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.Receiver;
  return proto.partner.web_partner.Receiver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.Receiver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.Receiver}
 */
proto.partner.web_partner.Receiver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.Receiver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.Receiver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.Receiver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.Receiver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.web_partner.Receiver.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.Receiver} returns this
 */
proto.partner.web_partner.Receiver.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.partner.web_partner.Receiver.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.Receiver} returns this
 */
proto.partner.web_partner.Receiver.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.partner.web_partner.Receiver.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.Receiver} returns this
 */
proto.partner.web_partner.Receiver.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.partner.web_partner.Receiver.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.Receiver} returns this
 */
proto.partner.web_partner.Receiver.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string note = 5;
 * @return {string}
 */
proto.partner.web_partner.Receiver.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partner.Receiver} returns this
 */
proto.partner.web_partner.Receiver.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partner.InvoicingInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partner.InvoicingInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partner.InvoicingInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.InvoicingInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyInfo: (f = msg.getCompanyInfo()) && proto.partner.web_partner.Company.toObject(includeInstance, f),
    receiverInfo: (f = msg.getReceiverInfo()) && proto.partner.web_partner.Receiver.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partner.InvoicingInformation}
 */
proto.partner.web_partner.InvoicingInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partner.InvoicingInformation;
  return proto.partner.web_partner.InvoicingInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partner.InvoicingInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partner.InvoicingInformation}
 */
proto.partner.web_partner.InvoicingInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partner.web_partner.Company;
      reader.readMessage(value,proto.partner.web_partner.Company.deserializeBinaryFromReader);
      msg.setCompanyInfo(value);
      break;
    case 2:
      var value = new proto.partner.web_partner.Receiver;
      reader.readMessage(value,proto.partner.web_partner.Receiver.deserializeBinaryFromReader);
      msg.setReceiverInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partner.InvoicingInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partner.InvoicingInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partner.InvoicingInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partner.InvoicingInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partner.web_partner.Company.serializeBinaryToWriter
    );
  }
  f = message.getReceiverInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.partner.web_partner.Receiver.serializeBinaryToWriter
    );
  }
};


/**
 * optional Company company_info = 1;
 * @return {?proto.partner.web_partner.Company}
 */
proto.partner.web_partner.InvoicingInformation.prototype.getCompanyInfo = function() {
  return /** @type{?proto.partner.web_partner.Company} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.Company, 1));
};


/**
 * @param {?proto.partner.web_partner.Company|undefined} value
 * @return {!proto.partner.web_partner.InvoicingInformation} returns this
*/
proto.partner.web_partner.InvoicingInformation.prototype.setCompanyInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.InvoicingInformation} returns this
 */
proto.partner.web_partner.InvoicingInformation.prototype.clearCompanyInfo = function() {
  return this.setCompanyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.InvoicingInformation.prototype.hasCompanyInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Receiver receiver_info = 2;
 * @return {?proto.partner.web_partner.Receiver}
 */
proto.partner.web_partner.InvoicingInformation.prototype.getReceiverInfo = function() {
  return /** @type{?proto.partner.web_partner.Receiver} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partner.Receiver, 2));
};


/**
 * @param {?proto.partner.web_partner.Receiver|undefined} value
 * @return {!proto.partner.web_partner.InvoicingInformation} returns this
*/
proto.partner.web_partner.InvoicingInformation.prototype.setReceiverInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partner.InvoicingInformation} returns this
 */
proto.partner.web_partner.InvoicingInformation.prototype.clearReceiverInfo = function() {
  return this.setReceiverInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partner.InvoicingInformation.prototype.hasReceiverInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.partner.web_partner.TransactionType = {
  TRANSACTION_TYPE_INVALID: 0,
  TRANSACTION_TYPE_TOP_UP: 1,
  TRANSACTION_TYPE_PAY: 2,
  TRANSACTION_TYPE_WITHDRAW: 3,
  TRANSACTION_TYPE_REFUND: 4
};

/**
 * @enum {number}
 */
proto.partner.web_partner.PartnerAgentGroupStatus = {
  NONE: 0,
  ACTIVE: 1,
  INACTIVE: 2
};

goog.object.extend(exports, proto.partner.web_partner);
