import { ICustomerDetailInfo } from '@tixlabs/grpc-client/';
import { FORMAT_FULL_TIME, useTime } from '@access-trade-admin/hooks/internals';

type Props = {
  customerInfo: ICustomerDetailInfo;
};

export const ActivityAccount = ({ customerInfo }: Props) => {
  const { formatDateCustom } = useTime();
  return (
    <div className='flex flex-col rounded-lg bg-white'>
      <h3 className='text-lg font-semibold py-3 px-4 mb-0'>
        Hoạt động tài khoản
      </h3>
      <div className='flex px-4 py-3'>
        <div className='flex-1 flex flex-col py-2 gap-0.5'>
          <span className='text-xs text-neutral-gray-50'>Ngày đăng ký</span>
          <span className='text-neutral-black'>
            {formatDateCustom(FORMAT_FULL_TIME)(customerInfo.createdAt)}
          </span>
        </div>
        <div className='flex-1 flex flex-col py-2 gap-0.5'>
          <span className='text-xs text-neutral-gray-50'>
            Đăng nhập lần cuối
          </span>
          <span className='text-neutral-black'>
            {customerInfo.lastLoginAt > 0 &&
              formatDateCustom(FORMAT_FULL_TIME)(customerInfo.lastLoginAt)}
          </span>
        </div>
      </div>
    </div>
  );
};
