import { ButtonLoading } from '@common-ui';
import { IAirlineHub, IFlightServiceFee } from '@tixlabs/grpc-client';
import { useBoolean, useCurrency } from '@access-trade-admin/hooks/internals';
import { EModalMode } from '@access-trade-admin/types';
import { SERVICE_FEE_LABEL } from '@access-trade-admin/utils';
import ModalContent from '../Modal/ModalContent';

type Props = {
  onCancel: () => void;
  onSubmit: (data: IFlightServiceFee) => Promise<void>;
  airlineObjectByCode: Record<string, IAirlineHub>;
  serviceFee?: IFlightServiceFee;
};

export function ConfirmDelete({
  serviceFee,
  onCancel,
  onSubmit,
  airlineObjectByCode,
}: Props) {
  const { formatPrice } = useCurrency();
  const { value: isLoading, setTrue, setFalse } = useBoolean();
  return (
    <ModalContent
      className='w-full'
      mode={EModalMode.WARNING}
      title='Xóa phí dịch vụ'
      content={
        <div className='flex flex-col gap-4'>
          <div className='mt-5 flex flex-col items-start gap-2'>
            <span className='text-[#121212]'>
              Vui lòng kiểm tra & xác nhận thông tin phí dịch vụ sẽ được xóa.
            </span>
            <div className='flex flex-col items-start gap-3 mt-3 w-full'>
              <div className='flex items-center w-full'>
                <span className='text-neutral-7 font-semibold'>
                  Loại máy bay
                </span>
                <span className='flex-1 text-end'>
                  {serviceFee ? SERVICE_FEE_LABEL[serviceFee.type] : ''}
                </span>
              </div>
              <div className='flex items-center w-full'>
                <span className='text-neutral-7 font-semibold'>
                  Hãng hàng không
                </span>
                <span className='flex-1 text-end'>
                  {serviceFee?.airlineCode
                    ? airlineObjectByCode?.[serviceFee.airlineCode]?.name
                    : 'Tất cả'}
                </span>
              </div>
              <div className='flex items-center w-full'>
                <span className='text-neutral-7 font-semibold'>
                  Phí dịch vụ
                </span>
                <span className='flex-1 text-end'>
                  {formatPrice(serviceFee?.amount || 0)} VND
                </span>
              </div>
            </div>
          </div>
          <div className='flex items-center w-full space-x-2.5'>
            <ButtonLoading
              type='button'
              theme='primary'
              className='flex-1'
              variant='ghost'
              onClick={onCancel}
              disabled={isLoading}>
              Hủy bỏ
            </ButtonLoading>
            <ButtonLoading
              className='flex-1'
              type='button'
              theme='red'
              variant='solid'
              onClick={() => {
                if (serviceFee) {
                  setTrue();
                  onSubmit(serviceFee).finally(() => {
                    setFalse();
                  });
                }
              }}
              loading={isLoading}>
              Xóa
            </ButtonLoading>
          </div>
        </div>
      }
    />
  );
}

export default ConfirmDelete;
