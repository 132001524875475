import { LoadingLogoFullPage } from '@common-ui';
import {
  IListBookingFlightReq,
  IRetrieveBookingFlightData,
  customerServiceApiService,
  flightApiService,
} from '@tixlabs/grpc-client/web-partnership';
import { ICustomerDetailInfo, IPaginationRes } from '@tixlabs/grpc-client';
import {
  ActivityAccount,
  InformationCustomer,
  RecentBooking,
} from '@access-trade-admin/components/Customer';
import { useAppMutation } from '@access-trade-admin/hooks/internals';
import { Breadcrumb } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  //
};
const DEFAULT_PAGE_LIMIT = 10;
export const DetailCustomer = (props: Props) => {
  const { customerId } = useParams();
  const DEFAULT_ORDER_FILTER: Pick<IListBookingFlightReq, 'filter'> = {
    filter: {
      ownerId: customerId,
      statusesList: [],
    },
  };
  const navigate = useNavigate();
  const [customerInfo, setCustomerInfo] = useState<ICustomerDetailInfo>();
  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });

  const [listOrder, setListOrder] = useState<IRetrieveBookingFlightData[]>([]);
  const { mutateAsync: getCustomerByID, isLoading } = useAppMutation({
    mutationKey: ['customerServiceApiService', 'getCustomerByID'],
    mutationFn: customerServiceApiService.getCustomerByID,
    onSuccess: async ({ isSuccess, data, errorCode }) => {
      if (isSuccess && data) {
        // filter order has itinerariesList empty
        setCustomerInfo(data);

        await getListBookingFlight(data.id);
      } else {
        console.log('error', errorCode);
      }
    },
    onError: (err) => {
      console.log('oops...', err);
    },
  });

  const { mutateAsync: listBookingFlight, isLoading: isLoadingListBooking } =
    useAppMutation({
      mutationKey: ['flightApiService', 'listBookingFlight'],
      mutationFn: flightApiService.listBookingFlight,
      onSuccess: ({ isSuccess, data, errorCode }) => {
        if (isSuccess && data) {
          data.pagination && setPaginationRes(data.pagination);
          setListOrder(data.itemsList);
        } else {
          console.log('error', errorCode);
        }
      },
      onError: (err) => {
        console.log('oops...', err);
      },
    });

  const getListBookingFlight = useCallback(async (customerId: string) => {
    if (DEFAULT_ORDER_FILTER.filter) {
      await listBookingFlight({
        filter: {
          ...DEFAULT_ORDER_FILTER.filter,
          ownerId: customerId,
        },
        pagination: {
          pageLimit: DEFAULT_PAGE_LIMIT,
          pageNumber: 1,
        },
        sortsList: [],
      });
    }
  }, []);

  const getCustomerInfo = async () => {
    if (customerId) {
      await getCustomerByID({
        id: customerId,
      });
    }
  };

  const handlePagination = async (page: number) => {
    await listBookingFlight({
      filter: DEFAULT_ORDER_FILTER.filter,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
      sortsList: [],
    });
  };

  useEffect(() => {
    getCustomerInfo();
  }, []);

  return isLoading ? (
    <LoadingLogoFullPage />
  ) : (
    <div className='flex flex-col gap-2.5'>
      <div className='flex flex-col gap-1 5 py-3'>
        <h3 className='text-xl font-bold text-neutral-black mb-0'>
          Chi tiết khách hàng
        </h3>
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className='text-primary-6 cursor-pointer hover:text-primary-5'
                  onClick={() => {
                    navigate('/');
                  }}>
                  Trang chủ
                </span>
              ),
            },
            {
              title: (
                <span
                  className='text-primary-6 font-semibold cursor-pointer hover:text-primary-5'
                  onClick={() => {
                    navigate('/user-management');
                  }}>
                  Quản lý khách hàng
                </span>
              ),
            },
          ]}
        />
      </div>
      <div className='flex flex-col gap-2 lg:max-w-[70%]'>
        {customerInfo && (
          <>
            <InformationCustomer customerInfo={customerInfo} />
            <ActivityAccount customerInfo={customerInfo} />
            <RecentBooking
              isLoading={isLoadingListBooking}
              listOrder={listOrder}
              customerInfo={customerInfo}
              pagination={paginationRes}
              handlePagination={handlePagination}
            />
          </>
        )}
      </div>
    </div>
  );
};
