import React, { useCallback, useEffect, useState } from 'react';
import { TextFieldProps } from './TextField';
import { SelectSearchProps } from '../UI/SelectSearch';
import FormSelectSearch from './FormSelectSearch';
import { useAppMutation } from '@web-booker/hooks/internals';
import { flightApiService } from '@tixlabs/grpc-client/web-partner';
import { Empty } from '@api/base/base_pb';
import { IListCAItem } from '@tixlabs/index';

export const FormCACode = ({
  inputProps = {},
  displayCode = true,
  displayName = true,
  listCAItem,
  typeFormCA,
  ...rest
}: {
  displayCode?: boolean;
  displayName?: boolean;
  listCAItem?: IListCAItem[];
  typeFormCA?: string;
} & TextFieldProps & {
    inputProps?: Pick<
      SelectSearchProps,
      | 'className'
      | 'disabled'
      | 'inputSize'
      | 'inputSearchClassName'
      | 'optionSelectSearchClassName'
      | 'optionGroupSelectSearchClassName'
      | 'isOnlyValue'
      | 'isRoot'
      | 'renderLabel'
      | 'displayValue'
      | 'compareFunc'
    >;
  }) => {
  let listCA: { value: string; label: string }[] = [];
  if (typeFormCA && typeFormCA === 'hotel') {
    listCA = listCAItem
      ? listCAItem.map((item) => ({
          value: JSON.stringify(item),
          label: `${item.corporateCode} - ${item.name}`,
        }))
      : [];
  } else {
    listCA = listCAItem
      ? listCAItem.map((item) => ({
          value: item.corporateCode,
          label: `${item.corporateCode} - ${item.name}`,
        }))
      : [];
  }
  return (
    <FormSelectSearch
      {...rest}
      inputProps={{
        isOnlyValue: true,
        isAllowUncheck: true,
      }}
      selectOptions={listCA}
    />
  );
};
