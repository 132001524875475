import { useAirlines } from '@vemaybay-admin/hooks/stores';
import cn from 'classnames';

type Props = {
  className?: string;
  showName?: boolean;
  code: string;
  classNameImg?: string;
  flightNumber?: string;
};

export const AirlineInfoCoordination = ({
  code,
  className,
  showName = true,
  flightNumber,
  classNameImg,
}: Props) => {
  const { getAirlineNameByCode, getAirlineByCode } = useAirlines();

  const airlineData = getAirlineByCode(code);

  return (
    <div
      className={cn('flex items-center gap-2', {
        [className || '']: !!className,
      })}>
      <img
        draggable={false}
        className={cn(
          'h-5 max-w-full object-contain object-center',
          classNameImg
        )}
        src={airlineData?.logoUrl}
        alt={airlineData?.code}
      />

      {showName && (
        <div className='flex item-center gap-1 text-neutral-gray-50'>
          <span className='text-neutral-black'>
            {getAirlineNameByCode(code)}
          </span>
          {flightNumber && (
            <>
              <span>•</span>
              <span>
                {code}
                {flightNumber}
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};
