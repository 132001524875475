import {
  Button,
  FileExcelLineIcon,
  Form,
  FormInput,
  FormInputDateFromTo,
  FormSelect,
  ONE_DAY_TIMESTAMP,
  OrdersIcon,
  SearchLineIcon,
  TFromToDate,
} from '@common-ui';
import { useExportData } from '@core/hooks';
import { addDay, getUserName, subDay } from '@core/utils';
import { EBookingStatusHotel, IPaginationRes } from '@tixlabs/grpc-client';
import {
  IBookingDetail,
  IListCancelBookingsFilter,
  IListCancelBookingsRequest,
  hotelApiServiceWebPartnership,
} from '@tixlabs/grpc-client/web-partnership';

import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import TableContent from './TableContent';
import {
  BOOKING_STATUS_HOTEL_LABEL,
  SELECT_STATUS_BOOKING_HOTEL_OPTIONS,
} from '@vemaybay-admin/utils';
import { BookingStatus } from '@api/hotel/base_pb';
import {
  useAppMutation,
  useCurrency,
  useTime,
} from '@vemaybay-admin/hooks/internals';
import { SelectOptionItem } from '@tixlabs/index';

type Props = {
  //
};

const DEFAULT_PAGE_LIMIT = 20;

const MAX_FILTER_DAY = 30;

export const SELECT_STATUS_REFUND_BOOKING_HOTEL_OPTIONS: SelectOptionItem[] = [
  {
    value: 0,
    label: 'Tất cả',
  },
  {
    value: 1,
    label: 'Chờ hoàn tiền',
  },
  {
    value: 2,
    label: 'Đã hoàn tiền',
  },
];

export const ListRefundHotel = (props: Props) => {
  const [searchParams, _] = useSearchParams();
  const DEFAULT_ORDER_FILTER: Pick<IListCancelBookingsRequest, 'filter'> & {
    filterDate: TFromToDate;
    status: BookingStatus;
    refundedConvert: number;
  } = {
    filter: {
      statusList: [], // chỉ để cho đúng struct, không dùng
      orderCode: '',
      from: new Date().setHours(0, 0, 0, 0) - ONE_DAY_TIMESTAMP * 7,
      to: new Date().setHours(23, 59, 59, 999),
      notInStatusesList: [BookingStatus.DRAFT, BookingStatus.IN_PROCESS],
    },
    filterDate: {
      startDate: new Date(
        new Date(Date.now() - ONE_DAY_TIMESTAMP * 7).setHours(0, 0, 0, 0)
      ),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    },
    status: 0,
    refundedConvert: 0,
  };

  const { formatDate, formatHourDateTime } = useTime();
  const { formatPrice, formatCurrencyCustom } = useCurrency();
  const { handleExport, isProcessing } = useExportData<IBookingDetail>({
    columns: [
      {
        name: 'Ngày giờ hủy',
        key: '',
        formatValue: (_, row: IBookingDetail) => {
          return formatHourDateTime(row?.canceledAt);
        },
      },
      {
        name: 'Mã đơn hàng',
        key: 'orderCode',
      },
      {
        name: 'Tổng tiền',
        key: '',
        formatValue: (_, row: IBookingDetail) => {
          return formatCurrencyCustom(
            (row?.paymentInfo
              ? row.paymentInfo.totalPrice
              : row.priceInfo?.totalPrice) || 0,
            row?.priceInfo?.currency || ''
          );
        },
      },
      {
        name: 'Tiền hoàn',
        key: '',
        formatValue: (_, row: IBookingDetail) => {
          return formatCurrencyCustom(
            row.refundAmount || 0,
            row.priceInfo?.currency || ''
          );
        },
      },
      {
        name: 'Chính sách',
        key: '',
        formatValue: (_, row: IBookingDetail) => {
          const totalPrice =
            (row.priceInfo?.totalPrice) || 0;
          if (totalPrice > row.refundAmount) {
            return 'Hoàn 1 phần';
          } else {
            return 'Hoàn toàn bộ';
          }
        },
      },
      {
        name: 'PTTT',
        key: '',
        formatValue: (_, row: IBookingDetail) =>
          row?.paymentInfo?.method?.name || '',
      },
      {
        name: 'Tên tài khoản',
        key: '',
        formatValue: (_, row: IBookingDetail) => {
          return (
            (row?.refundInfo
              ? row.refundInfo.paymentAccountInfo?.accountName
              : '-') || '-'
          );
        },
      },
      {
        name: 'Số tài khoản',
        key: '',
        formatValue: (_, row: IBookingDetail) => {
          return (
            (row?.refundInfo
              ? row.refundInfo.paymentAccountInfo?.accountNumber
              : '-') || '-'
          );
        },
      },
      {
        name: 'Ngân hàng',
        key: '',
        formatValue: (_, row: IBookingDetail) => {
          return (
            (row?.refundInfo
              ? row.refundInfo.paymentAccountInfo?.bankName
              : '-') || '-'
          );
        },
      },
      {
        name: 'Chi nhánh',
        key: '',
        formatValue: (_, row: IBookingDetail) => {
          return (
            (row?.refundInfo
              ? row.refundInfo.paymentAccountInfo?.bankBranch
              : '-') || '-'
          );
        },
      },
      {
        name: 'Trạng thái',
        key: 'status',
        formatValue: (_, row: IBookingDetail) => {
          if (row.refunded) {
            return 'Đã hoàn tiền';
          } else {
            return 'Chờ hoàn tiền';
          }
        },
      },
    ],
    filename: `refund_hotel_${formatDate(Date.now())}`,
  });

  const methods = useForm<
    Pick<IListCancelBookingsRequest, 'filter'> & {
      filterDate: TFromToDate;
      status: BookingStatus;
      refundedConvert: number;
    }
  >({
    defaultValues: DEFAULT_ORDER_FILTER,
  });

  const {
    formState: { errors },
  } = methods;

  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });

  const [filterReq, setFilterReq] = useState<IListCancelBookingsFilter>({
    statusList: [],
    orderCode: '',
    notInStatusesList: [BookingStatus.DRAFT, BookingStatus.IN_PROCESS],
    from: new Date().setHours(0, 0, 0, 0) - ONE_DAY_TIMESTAMP * 7,
    to: new Date().setHours(23, 59, 59, 999),
  });

  const [listOrderHotel, setListOrder] = useState<IBookingDetail[]>([]);

  const { mutateAsync: listCancelBookingHotel, isLoading } = useAppMutation({
    mutationKey: ['hotelApiService', 'listCancelBookings'],
    mutationFn: hotelApiServiceWebPartnership.listCancelBookings,
    onSuccess: ({ isSuccess, pagination, itemsList, errorCode }) => {
      if (isSuccess && pagination) {
        pagination && setPaginationRes(pagination);
        // filter order has itinerariesList empty
        setListOrder(itemsList);
      } else {
        console.log('error', errorCode);
      }
    },
    onError: (err) => {
      console.log('oops...', err);
    },
  });

  const { mutateAsync: exportCancelBookings } = useAppMutation({
    mutationKey: ['hotelApiService', 'exportCancelBookings'],
    mutationFn: hotelApiServiceWebPartnership.exportCancelBookings,
    onSuccess: ({ isSuccess, errorCode }) => {
      if (isSuccess) {
      } else {
        console.log('error', errorCode);
      }
    },
    onError: (err) => {
      console.log('oops...', err);
    },
  });

  const handleExportListOrderHotel = async () => {
    try {
      const { isSuccess, itemsList } = await exportCancelBookings({
        filter: {
          orderCode: filterReq.orderCode || '',
          from: filterReq.from || 0,
          to: filterReq.to || 0,
          statusList: filterReq.statusList || [],
          notInStatusesList: [
            EBookingStatusHotel.DRAFT,
            EBookingStatusHotel.IN_PROCESS,
          ],
          refunded:
            methods.getValues('refundedConvert') === 1
              ? false
              : methods.getValues('refundedConvert') === 2
              ? true
              : undefined,
        },
      });
      if (isSuccess) {
        await handleExport(itemsList);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleSubmit = async (
    data: Pick<IListCancelBookingsRequest, 'filter'> & {
      filterDate: TFromToDate;
      status: BookingStatus;
      refundedConvert: number;
    }
  ) => {
    let statusList: number[] = [];
    if (data.filter) {
      setFilterReq({
        statusList: statusList,
        orderCode: data?.filter?.orderCode,
        notInStatusesList: data?.filter?.notInStatusesList,
        from: data.filterDate.startDate
          ? new Date(data.filterDate.startDate).getTime()
          : data.filter.from,
        to: data.filterDate.endDate
          ? new Date(data.filterDate.endDate).getTime()
          : data.filter.to,
        refunded:
          data.refundedConvert === 1
            ? false
            : data.refundedConvert === 2
            ? true
            : undefined,
      });
      await listCancelBookingHotel({
        filter: {
          statusList: statusList,
          orderCode: data?.filter?.orderCode,
          notInStatusesList: data?.filter?.notInStatusesList,
          from: data.filterDate.startDate
            ? new Date(data.filterDate.startDate).getTime()
            : data.filter.from,
          to: data.filterDate.endDate
            ? new Date(data.filterDate.endDate).getTime()
            : data.filter.to,
          refunded:
            data.refundedConvert === 1
              ? false
              : data.refundedConvert === 2
              ? true
              : undefined,
        },
        pagination: {
          pageLimit: DEFAULT_PAGE_LIMIT,
          pageNumber: 1,
        },
      });
    }
  };

  const handlePagination = async (page: number) => {
    await listCancelBookingHotel({
      filter: filterReq,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
    });
  };

  const getListCancelBookingFlight = useCallback(async () => {
    // first load
    await listCancelBookingHotel({
      filter: filterReq,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
    });
  }, []);

  useEffect(() => {
    getListCancelBookingFlight();
  }, []);

  const { startDate: fromDate, endDate: toDate } = methods.watch('filterDate');

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <OrdersIcon className='w-5 h-5 shrink-0' />
        <span className='font-semibold text-lg'>Quản lý hoàn tiền </span>
      </div>
      <div className='w-full bg-white border rounded p-3.5'>
        <Form
          methods={methods}
          onSubmit={handleSubmit}
          className='flex justify-between pb-4 border-b mb-4 flex-col md-lg:flex-row gap-3'>
          <div className='md-lg:flex md-lg:space-x-2.5 grid grid-cols-2 gap-4'>
            <FormInputDateFromTo
              rules={{
                required: 'Ngày là bắt buộc', // thông báo lỗi khi trường trống
                validate: {
                  checkDates: (value) =>
                    (value?.startDate && value?.endDate) ||
                    'Chọn cả ngày đi và ngày về',
                },
              }}
              inputProps={{
                placeholderStart: 'Từ ngày',
                placeholderEnd: 'Đến ngày',
                showFilter: true,
                inputSize: 'sm',
                minStartDate: toDate
                  ? subDay(toDate, MAX_FILTER_DAY)
                  : undefined,
                maxStartDate: new Date(),
                maxEndDate: fromDate
                  ? addDay(fromDate, MAX_FILTER_DAY) < new Date()
                    ? addDay(fromDate, MAX_FILTER_DAY)
                    : new Date()
                  : new Date(),
                isFullTime: true,
                required: true,
              }}
              label='Thời gian'
              name='filterDate'
            />
            <FormInput
              name='filter.orderCode'
              label='Mã đơn hàng/ đặt chỗ'
              placeholder='Nhập mã đơn hàng/đặt chỗ'
            />

            <FormSelect
              name='refundedConvert'
              selectOptions={SELECT_STATUS_REFUND_BOOKING_HOTEL_OPTIONS}
              label={'Trạng thái'}
              inputProps={{
                isOnlyValue: true,
              }}
            />
            <div
              className={`flex flex-col ${
                errors.filterDate ? 'justify-center' : 'justify-end'
              }`}>
              <Button
                type='submit'
                theme='primary'
                size='sm'
                prefixIcon={<SearchLineIcon />}
                className='font-semibold !px-5 h-[40px] w-[150px]'>
                Tìm kiếm
              </Button>
            </div>
          </div>
          <div className='flex space-x-2.5'>
            <div
              className={`flex flex-col ${
                errors.filterDate ? 'justify-center' : 'justify-end'
              }`}>
              <Button
                size='sm'
                theme='green'
                onClick={handleExportListOrderHotel}
                // disabled={isProcessing}
                prefixIcon={<FileExcelLineIcon />}
                className='!px-5'>
                <span className='whitespace-nowrap'>
                  {isProcessing ? 'Tải về...' : 'Tải về'}
                </span>
              </Button>
            </div>
          </div>
        </Form>
        <TableContent
          listOrder={listOrderHotel}
          isLoading={isLoading}
          pagination={paginationRes}
          onPageChange={handlePagination}
        />
      </div>
    </div>
  );
};

export default ListRefundHotel;
