import {
  IPassengerQuantity,
  ISearchFlightFilter,
} from '@tixlabs/grpc-client/web-partner';

import { EItineraryType } from '@web-booker/types/ui';
import {
  EFilterDisplayMode,
  EStopPointType,
  IFormFilterFlight,
  IFormSearchFlight,
} from '@web-booker/types/ui/flight';
import { convertTimeRangeToValue } from '../helpers';
import { TModalChooseSeat } from '@web-booker/types/ui/seat-map';
import { ESeatStatus } from '@tixlabs/grpc-client';

export const itineraryTypeLabel = {
  [EItineraryType.ONE_WAY]: 'Một chiều',
  [EItineraryType.ROUND_TRIP]: 'Khứ hồi',
  [EItineraryType.MULTIPLE_TRIP]: 'Đa chặng',
};

export const itineraryTypeOptionList = [
  EItineraryType.ONE_WAY,
  EItineraryType.ROUND_TRIP,
  EItineraryType.MULTIPLE_TRIP,
];

export const itineraryTypeOptions = itineraryTypeOptionList
  // .filter((item) => item !== EItineraryType.MULTIPLE_TRIP) // Remove MULTIPLE_TRIP
  .map((item) => ({
    label: itineraryTypeLabel[item],
    value: item,
  }));

export const defaultSearchFlight: IFormSearchFlight = {
  itineraryType: EItineraryType.ONE_WAY,
  passengerQuantity: {
    adt: 1,
    chd: 0,
    inf: 0,
  },
  flightList: [
    {
      departDate: null,
      endPoint: null,
      startPoint: null,
    },
    {
      departDate: null,
      endPoint: null,
      startPoint: null,
    },
  ],
  searchDate: {
    startDate: new Date(),
    endDate: undefined,
  },
  onlyDirectFlight: false,
  oneWayAndRoundField: {
    departDate: null,
    endPoint: null,
    startPoint: null,
  },
};

export const defaultFilterFlight: IFormFilterFlight = {
  displayMode: EFilterDisplayMode.TOTAL_PASSENGER,
  airlines: [],
  stopPointType: [
    {
      label: 'Bay thẳng',
      value: EStopPointType.STOP_POINT_TYPE_NONE_STOP,
      isSelected: false,
    },
    {
      label: '1 điểm dừng',
      value: EStopPointType.STOP_POINT_TYPE_ONE,
      isSelected: false,
    },
    {
      label: 'Nhiều điểm dừng',
      value: EStopPointType.STOP_POINT_TYPE_OVER_TWO,
      isSelected: false,
    },
  ],
  ticketClass: [
    // {
    //   label: 'Economy',
    //   value: 'Economy',
    //   isSelected: false,
    // },
    // {
    //   label: 'Business',
    //   value: 'Business',
    //   isSelected: false,
    // },
  ],
  landingTimes: [
    {
      label: 'Đêm đến sáng',
      durationLabel: '00:00 - 06:00',
      value: convertTimeRangeToValue(0, 6),
      isSelected: false,
    },
    {
      label: 'Sáng đến trưa',
      durationLabel: '06:00 - 12:00',
      value: convertTimeRangeToValue(6, 12),
      isSelected: false,
    },
    {
      label: 'Trưa đến tối',
      durationLabel: '12:00 - 18:00',
      value: convertTimeRangeToValue(12, 18),
      isSelected: false,
    },
    {
      label: 'Tối đến đêm',
      durationLabel: '18:00 - 24:00',
      value: convertTimeRangeToValue(18, 24),
      isSelected: false,
    },
  ],
  takeOffRanges: [
    {
      label: 'Đêm đến sáng',
      durationLabel: '00:00 - 06:00',
      value: convertTimeRangeToValue(0, 6),
      isSelected: false,
    },
    {
      label: 'Sáng đến trưa',
      durationLabel: '06:00 - 12:00',
      value: convertTimeRangeToValue(6, 12),
      isSelected: false,
    },
    {
      label: 'Trưa đến tối',
      durationLabel: '12:00 - 18:00',
      value: convertTimeRangeToValue(12, 18),
      isSelected: false,
    },
    {
      label: 'Tối đến đêm',
      durationLabel: '18:00 - 24:00',
      value: convertTimeRangeToValue(18, 24),
      isSelected: false,
    },
  ],
};

export const DEFAULT_PASSENGERS_QTY: IPassengerQuantity = {
  adt: 1,
  chd: 0,
  inf: 0,
};

export const DEFAULT_FILTER: ISearchFlightFilter = {
  flightsList: [],
  passengerQuantity: DEFAULT_PASSENGERS_QTY,
};

export const LABEL_SEAT_STATUS = {
  [ESeatStatus.SNONE]: '',
  [ESeatStatus.AVAILABLE]: 'Ghế trống',
  [ESeatStatus.OCCUPIED]: 'Đã được đặt',
  [ESeatStatus.BLOCKED]: 'Ghế khóa',
};

export const defaultModalChooseSeat: TModalChooseSeat = {
  open: false,
  getSeatMapInfo: {
    key: '',
    flightId: '',
    itineraryId: '',
    segmentIndex: 0,
    isDomestic: false,
  },
  passengersList: [],
  segmentInfo: { arrivalPlace: '', departPlace: '' },
};
