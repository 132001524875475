// source: skyhub/hotel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var skyhub_hidden_fee_pb = require('../skyhub/hidden_fee_pb.js');
goog.object.extend(proto, skyhub_hidden_fee_pb);
var skyhub_base_pb = require('../skyhub/base_pb.js');
goog.object.extend(proto, skyhub_base_pb);
goog.exportSymbol('proto.skyhub.Address', null, global);
goog.exportSymbol('proto.skyhub.BedOption', null, global);
goog.exportSymbol('proto.skyhub.BookingRoom', null, global);
goog.exportSymbol('proto.skyhub.HotelBasicInfo', null, global);
goog.exportSymbol('proto.skyhub.HotelInfo', null, global);
goog.exportSymbol('proto.skyhub.HubRateData', null, global);
goog.exportSymbol('proto.skyhub.OccupancyInfo', null, global);
goog.exportSymbol('proto.skyhub.OrderBasicInfo', null, global);
goog.exportSymbol('proto.skyhub.OrderDetail', null, global);
goog.exportSymbol('proto.skyhub.PayAtHotel', null, global);
goog.exportSymbol('proto.skyhub.RoomHolder', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.HotelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.HotelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.HotelInfo.displayName = 'proto.skyhub.HotelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.OrderBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.OrderBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.OrderBasicInfo.displayName = 'proto.skyhub.OrderBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.OrderDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.OrderDetail.repeatedFields_, null);
};
goog.inherits(proto.skyhub.OrderDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.OrderDetail.displayName = 'proto.skyhub.OrderDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.HubRateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.HubRateData.repeatedFields_, null);
};
goog.inherits(proto.skyhub.HubRateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.HubRateData.displayName = 'proto.skyhub.HubRateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.PayAtHotel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.PayAtHotel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.PayAtHotel.displayName = 'proto.skyhub.PayAtHotel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.BedOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.BedOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.BedOption.displayName = 'proto.skyhub.BedOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.HotelBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.HotelBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.HotelBasicInfo.displayName = 'proto.skyhub.HotelBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.Address.displayName = 'proto.skyhub.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.BookingRoom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.BookingRoom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.BookingRoom.displayName = 'proto.skyhub.BookingRoom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.RoomHolder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.RoomHolder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.RoomHolder.displayName = 'proto.skyhub.RoomHolder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.OccupancyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.OccupancyInfo.repeatedFields_, null);
};
goog.inherits(proto.skyhub.OccupancyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.OccupancyInfo.displayName = 'proto.skyhub.OccupancyInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.HotelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.HotelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.HotelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.HotelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: jspb.Message.getFieldWithDefault(msg, 3, 0),
    countryCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.HotelInfo}
 */
proto.skyhub.HotelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.HotelInfo;
  return proto.skyhub.HotelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.HotelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.HotelInfo}
 */
proto.skyhub.HotelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.skyhub.HotelLocationType} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.HotelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.HotelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.HotelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.HotelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skyhub.HotelInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HotelInfo} returns this
 */
proto.skyhub.HotelInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.skyhub.HotelInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HotelInfo} returns this
 */
proto.skyhub.HotelInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional HotelLocationType location = 3;
 * @return {!proto.skyhub.HotelLocationType}
 */
proto.skyhub.HotelInfo.prototype.getLocation = function() {
  return /** @type {!proto.skyhub.HotelLocationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skyhub.HotelLocationType} value
 * @return {!proto.skyhub.HotelInfo} returns this
 */
proto.skyhub.HotelInfo.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string country_code = 4;
 * @return {string}
 */
proto.skyhub.HotelInfo.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HotelInfo} returns this
 */
proto.skyhub.HotelInfo.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.skyhub.HotelInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HotelInfo} returns this
 */
proto.skyhub.HotelInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.OrderBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.OrderBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.OrderBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.OrderBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    confirmationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    refundAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    agentCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    refunded: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.OrderBasicInfo}
 */
proto.skyhub.OrderBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.OrderBasicInfo;
  return proto.skyhub.OrderBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.OrderBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.OrderBasicInfo}
 */
proto.skyhub.OrderBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRefundAmount(value);
      break;
    case 6:
      var value = /** @type {!proto.skyhub.HotelBookingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefunded(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.OrderBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.OrderBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.OrderBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.OrderBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfirmationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRefundAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRefunded();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skyhub.OrderBasicInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderBasicInfo} returns this
 */
proto.skyhub.OrderBasicInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_code = 2;
 * @return {string}
 */
proto.skyhub.OrderBasicInfo.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderBasicInfo} returns this
 */
proto.skyhub.OrderBasicInfo.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string confirmation_id = 3;
 * @return {string}
 */
proto.skyhub.OrderBasicInfo.prototype.getConfirmationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderBasicInfo} returns this
 */
proto.skyhub.OrderBasicInfo.prototype.setConfirmationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double amount = 4;
 * @return {number}
 */
proto.skyhub.OrderBasicInfo.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OrderBasicInfo} returns this
 */
proto.skyhub.OrderBasicInfo.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double refund_amount = 5;
 * @return {number}
 */
proto.skyhub.OrderBasicInfo.prototype.getRefundAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OrderBasicInfo} returns this
 */
proto.skyhub.OrderBasicInfo.prototype.setRefundAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional HotelBookingStatus status = 6;
 * @return {!proto.skyhub.HotelBookingStatus}
 */
proto.skyhub.OrderBasicInfo.prototype.getStatus = function() {
  return /** @type {!proto.skyhub.HotelBookingStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.skyhub.HotelBookingStatus} value
 * @return {!proto.skyhub.OrderBasicInfo} returns this
 */
proto.skyhub.OrderBasicInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string agent_code = 7;
 * @return {string}
 */
proto.skyhub.OrderBasicInfo.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderBasicInfo} returns this
 */
proto.skyhub.OrderBasicInfo.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool refunded = 8;
 * @return {boolean}
 */
proto.skyhub.OrderBasicInfo.prototype.getRefunded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.OrderBasicInfo} returns this
 */
proto.skyhub.OrderBasicInfo.prototype.setRefunded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.skyhub.OrderBasicInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OrderBasicInfo} returns this
 */
proto.skyhub.OrderBasicInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.OrderDetail.repeatedFields_ = [4,14,15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.OrderDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.OrderDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.OrderDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.OrderDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hotelInfo: (f = msg.getHotelInfo()) && proto.skyhub.HotelBasicInfo.toObject(includeInstance, f),
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    proto.skyhub.BookingRoom.toObject, includeInstance),
    totalNights: jspb.Message.getFieldWithDefault(msg, 5, 0),
    rateCf: (f = msg.getRateCf()) && proto.skyhub.HubRateData.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    pendingDeadline: jspb.Message.getFieldWithDefault(msg, 10, 0),
    cancelReason: jspb.Message.getFieldWithDefault(msg, 11, ""),
    bedOption: (f = msg.getBedOption()) && proto.skyhub.BedOption.toObject(includeInstance, f),
    source: jspb.Message.getFieldWithDefault(msg, 13, ""),
    checkInInstructionsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    cancelPoliciesList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    amenitiesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    checkInDate: jspb.Message.getFieldWithDefault(msg, 17, ""),
    checkInTime: jspb.Message.getFieldWithDefault(msg, 18, ""),
    checkOutDate: jspb.Message.getFieldWithDefault(msg, 19, ""),
    checkOutTime: jspb.Message.getFieldWithDefault(msg, 20, ""),
    specialRequest: jspb.Message.getFieldWithDefault(msg, 21, ""),
    pnrCode: jspb.Message.getFieldWithDefault(msg, 22, ""),
    agentCode: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.OrderDetail}
 */
proto.skyhub.OrderDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.OrderDetail;
  return proto.skyhub.OrderDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.OrderDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.OrderDetail}
 */
proto.skyhub.OrderDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 3:
      var value = new proto.skyhub.HotelBasicInfo;
      reader.readMessage(value,proto.skyhub.HotelBasicInfo.deserializeBinaryFromReader);
      msg.setHotelInfo(value);
      break;
    case 4:
      var value = new proto.skyhub.BookingRoom;
      reader.readMessage(value,proto.skyhub.BookingRoom.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNights(value);
      break;
    case 6:
      var value = new proto.skyhub.HubRateData;
      reader.readMessage(value,proto.skyhub.HubRateData.deserializeBinaryFromReader);
      msg.setRateCf(value);
      break;
    case 7:
      var value = /** @type {!proto.skyhub.HotelBookingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPendingDeadline(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCancelReason(value);
      break;
    case 12:
      var value = new proto.skyhub.BedOption;
      reader.readMessage(value,proto.skyhub.BedOption.deserializeBinaryFromReader);
      msg.setBedOption(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addCheckInInstructions(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addCancelPolicies(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addAmenities(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckInDate(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckInTime(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOutDate(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOutTime(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialRequest(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPnrCode(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.OrderDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.OrderDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.OrderDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.OrderDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHotelInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skyhub.HotelBasicInfo.serializeBinaryToWriter
    );
  }
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.skyhub.BookingRoom.serializeBinaryToWriter
    );
  }
  f = message.getTotalNights();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRateCf();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skyhub.HubRateData.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPendingDeadline();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCancelReason();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBedOption();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.skyhub.BedOption.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCheckInInstructionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getCancelPoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getAmenitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getCheckInDate();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCheckInTime();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCheckOutDate();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCheckOutTime();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSpecialRequest();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPnrCode();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_code = 2;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional HotelBasicInfo hotel_info = 3;
 * @return {?proto.skyhub.HotelBasicInfo}
 */
proto.skyhub.OrderDetail.prototype.getHotelInfo = function() {
  return /** @type{?proto.skyhub.HotelBasicInfo} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.HotelBasicInfo, 3));
};


/**
 * @param {?proto.skyhub.HotelBasicInfo|undefined} value
 * @return {!proto.skyhub.OrderDetail} returns this
*/
proto.skyhub.OrderDetail.prototype.setHotelInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.clearHotelInfo = function() {
  return this.setHotelInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.OrderDetail.prototype.hasHotelInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated BookingRoom rooms = 4;
 * @return {!Array<!proto.skyhub.BookingRoom>}
 */
proto.skyhub.OrderDetail.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.skyhub.BookingRoom>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.BookingRoom, 4));
};


/**
 * @param {!Array<!proto.skyhub.BookingRoom>} value
 * @return {!proto.skyhub.OrderDetail} returns this
*/
proto.skyhub.OrderDetail.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.skyhub.BookingRoom=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.BookingRoom}
 */
proto.skyhub.OrderDetail.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.skyhub.BookingRoom, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};


/**
 * optional int32 total_nights = 5;
 * @return {number}
 */
proto.skyhub.OrderDetail.prototype.getTotalNights = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setTotalNights = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional HubRateData rate_cf = 6;
 * @return {?proto.skyhub.HubRateData}
 */
proto.skyhub.OrderDetail.prototype.getRateCf = function() {
  return /** @type{?proto.skyhub.HubRateData} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.HubRateData, 6));
};


/**
 * @param {?proto.skyhub.HubRateData|undefined} value
 * @return {!proto.skyhub.OrderDetail} returns this
*/
proto.skyhub.OrderDetail.prototype.setRateCf = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.clearRateCf = function() {
  return this.setRateCf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.OrderDetail.prototype.hasRateCf = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional HotelBookingStatus status = 7;
 * @return {!proto.skyhub.HotelBookingStatus}
 */
proto.skyhub.OrderDetail.prototype.getStatus = function() {
  return /** @type {!proto.skyhub.HotelBookingStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.skyhub.HotelBookingStatus} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.skyhub.OrderDetail.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 expired_at = 9;
 * @return {number}
 */
proto.skyhub.OrderDetail.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 pending_deadline = 10;
 * @return {number}
 */
proto.skyhub.OrderDetail.prototype.getPendingDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setPendingDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string cancel_reason = 11;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getCancelReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setCancelReason = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional BedOption bed_option = 12;
 * @return {?proto.skyhub.BedOption}
 */
proto.skyhub.OrderDetail.prototype.getBedOption = function() {
  return /** @type{?proto.skyhub.BedOption} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.BedOption, 12));
};


/**
 * @param {?proto.skyhub.BedOption|undefined} value
 * @return {!proto.skyhub.OrderDetail} returns this
*/
proto.skyhub.OrderDetail.prototype.setBedOption = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.clearBedOption = function() {
  return this.setBedOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.OrderDetail.prototype.hasBedOption = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string source = 13;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated string check_in_instructions = 14;
 * @return {!Array<string>}
 */
proto.skyhub.OrderDetail.prototype.getCheckInInstructionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setCheckInInstructionsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.addCheckInInstructions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.clearCheckInInstructionsList = function() {
  return this.setCheckInInstructionsList([]);
};


/**
 * repeated string cancel_policies = 15;
 * @return {!Array<string>}
 */
proto.skyhub.OrderDetail.prototype.getCancelPoliciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setCancelPoliciesList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.addCancelPolicies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.clearCancelPoliciesList = function() {
  return this.setCancelPoliciesList([]);
};


/**
 * repeated string amenities = 16;
 * @return {!Array<string>}
 */
proto.skyhub.OrderDetail.prototype.getAmenitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setAmenitiesList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.addAmenities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.clearAmenitiesList = function() {
  return this.setAmenitiesList([]);
};


/**
 * optional string check_in_date = 17;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getCheckInDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setCheckInDate = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string check_in_time = 18;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getCheckInTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setCheckInTime = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string check_out_date = 19;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getCheckOutDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setCheckOutDate = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string check_out_time = 20;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getCheckOutTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setCheckOutTime = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string special_request = 21;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getSpecialRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setSpecialRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string pnr_code = 22;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getPnrCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setPnrCode = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string agent_code = 23;
 * @return {string}
 */
proto.skyhub.OrderDetail.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OrderDetail} returns this
 */
proto.skyhub.OrderDetail.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.HubRateData.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.HubRateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.HubRateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.HubRateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.HubRateData.toObject = function(includeInstance, msg) {
  var f, obj = {
    rateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    payNow: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalPayAtHotel: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    bedOptionsList: jspb.Message.toObjectList(msg.getBedOptionsList(),
    proto.skyhub.BedOption.toObject, includeInstance),
    payAtHotelList: jspb.Message.toObjectList(msg.getPayAtHotelList(),
    proto.skyhub.PayAtHotel.toObject, includeInstance),
    totalRateBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.HubRateData}
 */
proto.skyhub.HubRateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.HubRateData;
  return proto.skyhub.HubRateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.HubRateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.HubRateData}
 */
proto.skyhub.HubRateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPayNow(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPayAtHotel(value);
      break;
    case 5:
      var value = new proto.skyhub.BedOption;
      reader.readMessage(value,proto.skyhub.BedOption.deserializeBinaryFromReader);
      msg.addBedOptions(value);
      break;
    case 6:
      var value = new proto.skyhub.PayAtHotel;
      reader.readMessage(value,proto.skyhub.PayAtHotel.deserializeBinaryFromReader);
      msg.addPayAtHotel(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalRateBasic(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.HubRateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.HubRateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.HubRateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.HubRateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPayNow();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalPayAtHotel();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBedOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.skyhub.BedOption.serializeBinaryToWriter
    );
  }
  f = message.getPayAtHotelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.skyhub.PayAtHotel.serializeBinaryToWriter
    );
  }
  f = message.getTotalRateBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string rate_id = 1;
 * @return {string}
 */
proto.skyhub.HubRateData.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HubRateData} returns this
 */
proto.skyhub.HubRateData.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.skyhub.HubRateData.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HubRateData} returns this
 */
proto.skyhub.HubRateData.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double pay_now = 3;
 * @return {number}
 */
proto.skyhub.HubRateData.prototype.getPayNow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.HubRateData} returns this
 */
proto.skyhub.HubRateData.prototype.setPayNow = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double total_pay_at_hotel = 4;
 * @return {number}
 */
proto.skyhub.HubRateData.prototype.getTotalPayAtHotel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.HubRateData} returns this
 */
proto.skyhub.HubRateData.prototype.setTotalPayAtHotel = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated BedOption bed_options = 5;
 * @return {!Array<!proto.skyhub.BedOption>}
 */
proto.skyhub.HubRateData.prototype.getBedOptionsList = function() {
  return /** @type{!Array<!proto.skyhub.BedOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.BedOption, 5));
};


/**
 * @param {!Array<!proto.skyhub.BedOption>} value
 * @return {!proto.skyhub.HubRateData} returns this
*/
proto.skyhub.HubRateData.prototype.setBedOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.skyhub.BedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.BedOption}
 */
proto.skyhub.HubRateData.prototype.addBedOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.skyhub.BedOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.HubRateData} returns this
 */
proto.skyhub.HubRateData.prototype.clearBedOptionsList = function() {
  return this.setBedOptionsList([]);
};


/**
 * repeated PayAtHotel pay_at_hotel = 6;
 * @return {!Array<!proto.skyhub.PayAtHotel>}
 */
proto.skyhub.HubRateData.prototype.getPayAtHotelList = function() {
  return /** @type{!Array<!proto.skyhub.PayAtHotel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.PayAtHotel, 6));
};


/**
 * @param {!Array<!proto.skyhub.PayAtHotel>} value
 * @return {!proto.skyhub.HubRateData} returns this
*/
proto.skyhub.HubRateData.prototype.setPayAtHotelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.skyhub.PayAtHotel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.PayAtHotel}
 */
proto.skyhub.HubRateData.prototype.addPayAtHotel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.skyhub.PayAtHotel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.HubRateData} returns this
 */
proto.skyhub.HubRateData.prototype.clearPayAtHotelList = function() {
  return this.setPayAtHotelList([]);
};


/**
 * optional double total_rate_basic = 7;
 * @return {number}
 */
proto.skyhub.HubRateData.prototype.getTotalRateBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.HubRateData} returns this
 */
proto.skyhub.HubRateData.prototype.setTotalRateBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double total_tax_amount = 8;
 * @return {number}
 */
proto.skyhub.HubRateData.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.HubRateData} returns this
 */
proto.skyhub.HubRateData.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.PayAtHotel.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.PayAtHotel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.PayAtHotel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.PayAtHotel.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.PayAtHotel}
 */
proto.skyhub.PayAtHotel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.PayAtHotel;
  return proto.skyhub.PayAtHotel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.PayAtHotel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.PayAtHotel}
 */
proto.skyhub.PayAtHotel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.PayAtHotel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.PayAtHotel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.PayAtHotel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.PayAtHotel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.skyhub.PayAtHotel.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.PayAtHotel} returns this
 */
proto.skyhub.PayAtHotel.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.skyhub.PayAtHotel.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.PayAtHotel} returns this
 */
proto.skyhub.PayAtHotel.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.skyhub.PayAtHotel.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.PayAtHotel} returns this
 */
proto.skyhub.PayAtHotel.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.skyhub.PayAtHotel.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.PayAtHotel} returns this
 */
proto.skyhub.PayAtHotel.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.BedOption.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.BedOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.BedOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.BedOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalBeds: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.BedOption}
 */
proto.skyhub.BedOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.BedOption;
  return proto.skyhub.BedOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.BedOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.BedOption}
 */
proto.skyhub.BedOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalBeds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.BedOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.BedOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.BedOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.BedOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalBeds();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string option_id = 1;
 * @return {string}
 */
proto.skyhub.BedOption.prototype.getOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BedOption} returns this
 */
proto.skyhub.BedOption.prototype.setOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.skyhub.BedOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BedOption} returns this
 */
proto.skyhub.BedOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_beds = 3;
 * @return {number}
 */
proto.skyhub.BedOption.prototype.getTotalBeds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.BedOption} returns this
 */
proto.skyhub.BedOption.prototype.setTotalBeds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.HotelBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.HotelBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.HotelBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.HotelBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto.skyhub.Address.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    phone: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.HotelBasicInfo}
 */
proto.skyhub.HotelBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.HotelBasicInfo;
  return proto.skyhub.HotelBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.HotelBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.HotelBasicInfo}
 */
proto.skyhub.HotelBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.skyhub.Address;
      reader.readMessage(value,proto.skyhub.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRating(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.HotelBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.HotelBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.HotelBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.HotelBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skyhub.Address.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skyhub.HotelBasicInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HotelBasicInfo} returns this
 */
proto.skyhub.HotelBasicInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.skyhub.HotelBasicInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HotelBasicInfo} returns this
 */
proto.skyhub.HotelBasicInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Address address = 3;
 * @return {?proto.skyhub.Address}
 */
proto.skyhub.HotelBasicInfo.prototype.getAddress = function() {
  return /** @type{?proto.skyhub.Address} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.Address, 3));
};


/**
 * @param {?proto.skyhub.Address|undefined} value
 * @return {!proto.skyhub.HotelBasicInfo} returns this
*/
proto.skyhub.HotelBasicInfo.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.HotelBasicInfo} returns this
 */
proto.skyhub.HotelBasicInfo.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.HotelBasicInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.skyhub.HotelBasicInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HotelBasicInfo} returns this
 */
proto.skyhub.HotelBasicInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double rating = 5;
 * @return {number}
 */
proto.skyhub.HotelBasicInfo.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.HotelBasicInfo} returns this
 */
proto.skyhub.HotelBasicInfo.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.skyhub.HotelBasicInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.HotelBasicInfo} returns this
 */
proto.skyhub.HotelBasicInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    line1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.Address}
 */
proto.skyhub.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.Address;
  return proto.skyhub.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.Address}
 */
proto.skyhub.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLine2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string line_1 = 1;
 * @return {string}
 */
proto.skyhub.Address.prototype.getLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Address} returns this
 */
proto.skyhub.Address.prototype.setLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string line_2 = 2;
 * @return {string}
 */
proto.skyhub.Address.prototype.getLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Address} returns this
 */
proto.skyhub.Address.prototype.setLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.skyhub.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Address} returns this
 */
proto.skyhub.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country_code = 4;
 * @return {string}
 */
proto.skyhub.Address.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Address} returns this
 */
proto.skyhub.Address.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.BookingRoom.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.BookingRoom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.BookingRoom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.BookingRoom.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    holder: (f = msg.getHolder()) && proto.skyhub.RoomHolder.toObject(includeInstance, f),
    occupancy: (f = msg.getOccupancy()) && proto.skyhub.OccupancyInfo.toObject(includeInstance, f),
    confirmationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bedOption: (f = msg.getBedOption()) && proto.skyhub.BedOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.BookingRoom}
 */
proto.skyhub.BookingRoom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.BookingRoom;
  return proto.skyhub.BookingRoom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.BookingRoom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.BookingRoom}
 */
proto.skyhub.BookingRoom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.skyhub.RoomHolder;
      reader.readMessage(value,proto.skyhub.RoomHolder.deserializeBinaryFromReader);
      msg.setHolder(value);
      break;
    case 4:
      var value = new proto.skyhub.OccupancyInfo;
      reader.readMessage(value,proto.skyhub.OccupancyInfo.deserializeBinaryFromReader);
      msg.setOccupancy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationId(value);
      break;
    case 6:
      var value = new proto.skyhub.BedOption;
      reader.readMessage(value,proto.skyhub.BedOption.deserializeBinaryFromReader);
      msg.setBedOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.BookingRoom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.BookingRoom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.BookingRoom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.BookingRoom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHolder();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skyhub.RoomHolder.serializeBinaryToWriter
    );
  }
  f = message.getOccupancy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skyhub.OccupancyInfo.serializeBinaryToWriter
    );
  }
  f = message.getConfirmationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBedOption();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skyhub.BedOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.skyhub.BookingRoom.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BookingRoom} returns this
 */
proto.skyhub.BookingRoom.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.skyhub.BookingRoom.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BookingRoom} returns this
 */
proto.skyhub.BookingRoom.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RoomHolder holder = 3;
 * @return {?proto.skyhub.RoomHolder}
 */
proto.skyhub.BookingRoom.prototype.getHolder = function() {
  return /** @type{?proto.skyhub.RoomHolder} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.RoomHolder, 3));
};


/**
 * @param {?proto.skyhub.RoomHolder|undefined} value
 * @return {!proto.skyhub.BookingRoom} returns this
*/
proto.skyhub.BookingRoom.prototype.setHolder = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.BookingRoom} returns this
 */
proto.skyhub.BookingRoom.prototype.clearHolder = function() {
  return this.setHolder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.BookingRoom.prototype.hasHolder = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OccupancyInfo occupancy = 4;
 * @return {?proto.skyhub.OccupancyInfo}
 */
proto.skyhub.BookingRoom.prototype.getOccupancy = function() {
  return /** @type{?proto.skyhub.OccupancyInfo} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.OccupancyInfo, 4));
};


/**
 * @param {?proto.skyhub.OccupancyInfo|undefined} value
 * @return {!proto.skyhub.BookingRoom} returns this
*/
proto.skyhub.BookingRoom.prototype.setOccupancy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.BookingRoom} returns this
 */
proto.skyhub.BookingRoom.prototype.clearOccupancy = function() {
  return this.setOccupancy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.BookingRoom.prototype.hasOccupancy = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string confirmation_id = 5;
 * @return {string}
 */
proto.skyhub.BookingRoom.prototype.getConfirmationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BookingRoom} returns this
 */
proto.skyhub.BookingRoom.prototype.setConfirmationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional BedOption bed_option = 6;
 * @return {?proto.skyhub.BedOption}
 */
proto.skyhub.BookingRoom.prototype.getBedOption = function() {
  return /** @type{?proto.skyhub.BedOption} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.BedOption, 6));
};


/**
 * @param {?proto.skyhub.BedOption|undefined} value
 * @return {!proto.skyhub.BookingRoom} returns this
*/
proto.skyhub.BookingRoom.prototype.setBedOption = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.BookingRoom} returns this
 */
proto.skyhub.BookingRoom.prototype.clearBedOption = function() {
  return this.setBedOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.BookingRoom.prototype.hasBedOption = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.RoomHolder.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.RoomHolder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.RoomHolder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.RoomHolder.toObject = function(includeInstance, msg) {
  var f, obj = {
    givenName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    surname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.RoomHolder}
 */
proto.skyhub.RoomHolder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.RoomHolder;
  return proto.skyhub.RoomHolder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.RoomHolder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.RoomHolder}
 */
proto.skyhub.RoomHolder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSurname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.RoomHolder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.RoomHolder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.RoomHolder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.RoomHolder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSurname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string given_name = 1;
 * @return {string}
 */
proto.skyhub.RoomHolder.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.RoomHolder} returns this
 */
proto.skyhub.RoomHolder.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string surname = 2;
 * @return {string}
 */
proto.skyhub.RoomHolder.prototype.getSurname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.RoomHolder} returns this
 */
proto.skyhub.RoomHolder.prototype.setSurname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.skyhub.RoomHolder.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.RoomHolder} returns this
 */
proto.skyhub.RoomHolder.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.skyhub.RoomHolder.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.RoomHolder} returns this
 */
proto.skyhub.RoomHolder.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.skyhub.RoomHolder.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.RoomHolder} returns this
 */
proto.skyhub.RoomHolder.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.OccupancyInfo.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.OccupancyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.OccupancyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.OccupancyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.OccupancyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    chd: jspb.Message.getFieldWithDefault(msg, 4, 0),
    chdAgesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.OccupancyInfo}
 */
proto.skyhub.OccupancyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.OccupancyInfo;
  return proto.skyhub.OccupancyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.OccupancyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.OccupancyInfo}
 */
proto.skyhub.OccupancyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChd(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChdAges(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.OccupancyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.OccupancyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.OccupancyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.OccupancyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdt();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getChd();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getChdAgesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.skyhub.OccupancyInfo.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OccupancyInfo} returns this
 */
proto.skyhub.OccupancyInfo.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.skyhub.OccupancyInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.OccupancyInfo} returns this
 */
proto.skyhub.OccupancyInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 adt = 3;
 * @return {number}
 */
proto.skyhub.OccupancyInfo.prototype.getAdt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OccupancyInfo} returns this
 */
proto.skyhub.OccupancyInfo.prototype.setAdt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 chd = 4;
 * @return {number}
 */
proto.skyhub.OccupancyInfo.prototype.getChd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.OccupancyInfo} returns this
 */
proto.skyhub.OccupancyInfo.prototype.setChd = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated int32 chd_ages = 5;
 * @return {!Array<number>}
 */
proto.skyhub.OccupancyInfo.prototype.getChdAgesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.skyhub.OccupancyInfo} returns this
 */
proto.skyhub.OccupancyInfo.prototype.setChdAgesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.skyhub.OccupancyInfo} returns this
 */
proto.skyhub.OccupancyInfo.prototype.addChdAges = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.OccupancyInfo} returns this
 */
proto.skyhub.OccupancyInfo.prototype.clearChdAgesList = function() {
  return this.setChdAgesList([]);
};


goog.object.extend(exports, proto.skyhub);
