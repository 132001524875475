/**
 * @fileoverview gRPC-Web generated client stub for hotel.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: hotel/web_partnership/hotel.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var hotel_booking_pb = require('../../hotel/booking_pb.js')

var base_base_pb = require('../../base/base_pb.js')

var hotel_base_pb = require('../../hotel/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.hotel = {};
proto.hotel.web_partnership = require('./hotel_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hotel.web_partnership.HotelServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hotel.web_partnership.HotelServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partnership.GetBookingDetailRequest,
 *   !proto.hotel.web_partnership.GetBookingDetailResponse>}
 */
const methodDescriptor_HotelService_GetBookingDetail = new grpc.web.MethodDescriptor(
  '/hotel.web_partnership.HotelService/GetBookingDetail',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partnership.GetBookingDetailRequest,
  proto.hotel.web_partnership.GetBookingDetailResponse,
  /**
   * @param {!proto.hotel.web_partnership.GetBookingDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partnership.GetBookingDetailResponse.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partnership.GetBookingDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partnership.GetBookingDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partnership.GetBookingDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partnership.HotelServiceClient.prototype.getBookingDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/GetBookingDetail',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingDetail,
      callback);
};


/**
 * @param {!proto.hotel.web_partnership.GetBookingDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partnership.GetBookingDetailResponse>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partnership.HotelServicePromiseClient.prototype.getBookingDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/GetBookingDetail',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partnership.ListBookingsRequest,
 *   !proto.hotel.web_partnership.ListBookingsResponse>}
 */
const methodDescriptor_HotelService_ListBookings = new grpc.web.MethodDescriptor(
  '/hotel.web_partnership.HotelService/ListBookings',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partnership.ListBookingsRequest,
  proto.hotel.web_partnership.ListBookingsResponse,
  /**
   * @param {!proto.hotel.web_partnership.ListBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partnership.ListBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partnership.ListBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partnership.ListBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partnership.ListBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partnership.HotelServiceClient.prototype.listBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ListBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListBookings,
      callback);
};


/**
 * @param {!proto.hotel.web_partnership.ListBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partnership.ListBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partnership.HotelServicePromiseClient.prototype.listBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ListBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partnership.ExportBookingsReq,
 *   !proto.hotel.web_partnership.ExportBookingsRes>}
 */
const methodDescriptor_HotelService_ExportBookings = new grpc.web.MethodDescriptor(
  '/hotel.web_partnership.HotelService/ExportBookings',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partnership.ExportBookingsReq,
  proto.hotel.web_partnership.ExportBookingsRes,
  /**
   * @param {!proto.hotel.web_partnership.ExportBookingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partnership.ExportBookingsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partnership.ExportBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partnership.ExportBookingsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partnership.ExportBookingsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partnership.HotelServiceClient.prototype.exportBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ExportBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ExportBookings,
      callback);
};


/**
 * @param {!proto.hotel.web_partnership.ExportBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partnership.ExportBookingsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partnership.HotelServicePromiseClient.prototype.exportBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ExportBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ExportBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partnership.UpdateBookingStatusRequest,
 *   !proto.hotel.web_partnership.UpdateBookingStatusResponse>}
 */
const methodDescriptor_HotelService_UpdateBookingStatus = new grpc.web.MethodDescriptor(
  '/hotel.web_partnership.HotelService/UpdateBookingStatus',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partnership.UpdateBookingStatusRequest,
  proto.hotel.web_partnership.UpdateBookingStatusResponse,
  /**
   * @param {!proto.hotel.web_partnership.UpdateBookingStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partnership.UpdateBookingStatusResponse.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partnership.UpdateBookingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partnership.UpdateBookingStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partnership.UpdateBookingStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partnership.HotelServiceClient.prototype.updateBookingStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/UpdateBookingStatus',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpdateBookingStatus,
      callback);
};


/**
 * @param {!proto.hotel.web_partnership.UpdateBookingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partnership.UpdateBookingStatusResponse>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partnership.HotelServicePromiseClient.prototype.updateBookingStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/UpdateBookingStatus',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpdateBookingStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partnership.UpdateBookingReq,
 *   !proto.hotel.web_partnership.UpdateBookingRes>}
 */
const methodDescriptor_HotelService_UpdateBooking = new grpc.web.MethodDescriptor(
  '/hotel.web_partnership.HotelService/UpdateBooking',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partnership.UpdateBookingReq,
  proto.hotel.web_partnership.UpdateBookingRes,
  /**
   * @param {!proto.hotel.web_partnership.UpdateBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partnership.UpdateBookingRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partnership.UpdateBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partnership.UpdateBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partnership.UpdateBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partnership.HotelServiceClient.prototype.updateBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpdateBooking,
      callback);
};


/**
 * @param {!proto.hotel.web_partnership.UpdateBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partnership.UpdateBookingRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partnership.HotelServicePromiseClient.prototype.updateBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpdateBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partnership.ConfirmRefundReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_HotelService_ConfirmRefund = new grpc.web.MethodDescriptor(
  '/hotel.web_partnership.HotelService/ConfirmRefund',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partnership.ConfirmRefundReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.hotel.web_partnership.ConfirmRefundReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partnership.ConfirmRefundReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partnership.HotelServiceClient.prototype.confirmRefund =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ConfirmRefund',
      request,
      metadata || {},
      methodDescriptor_HotelService_ConfirmRefund,
      callback);
};


/**
 * @param {!proto.hotel.web_partnership.ConfirmRefundReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partnership.HotelServicePromiseClient.prototype.confirmRefund =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ConfirmRefund',
      request,
      metadata || {},
      methodDescriptor_HotelService_ConfirmRefund);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partnership.ListCancelBookingsRequest,
 *   !proto.hotel.web_partnership.ListBookingsResponse>}
 */
const methodDescriptor_HotelService_ListCancelBookings = new grpc.web.MethodDescriptor(
  '/hotel.web_partnership.HotelService/ListCancelBookings',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partnership.ListCancelBookingsRequest,
  proto.hotel.web_partnership.ListBookingsResponse,
  /**
   * @param {!proto.hotel.web_partnership.ListCancelBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partnership.ListBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partnership.ListCancelBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partnership.ListBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partnership.ListBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partnership.HotelServiceClient.prototype.listCancelBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ListCancelBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListCancelBookings,
      callback);
};


/**
 * @param {!proto.hotel.web_partnership.ListCancelBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partnership.ListBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partnership.HotelServicePromiseClient.prototype.listCancelBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ListCancelBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListCancelBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partnership.ExportCancelBookingsReq,
 *   !proto.hotel.web_partnership.ExportBookingsRes>}
 */
const methodDescriptor_HotelService_ExportCancelBookings = new grpc.web.MethodDescriptor(
  '/hotel.web_partnership.HotelService/ExportCancelBookings',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partnership.ExportCancelBookingsReq,
  proto.hotel.web_partnership.ExportBookingsRes,
  /**
   * @param {!proto.hotel.web_partnership.ExportCancelBookingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partnership.ExportBookingsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partnership.ExportCancelBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partnership.ExportBookingsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partnership.ExportBookingsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partnership.HotelServiceClient.prototype.exportCancelBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ExportCancelBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ExportCancelBookings,
      callback);
};


/**
 * @param {!proto.hotel.web_partnership.ExportCancelBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partnership.ExportBookingsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partnership.HotelServicePromiseClient.prototype.exportCancelBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partnership.HotelService/ExportCancelBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ExportCancelBookings);
};


module.exports = proto.hotel.web_partnership;

