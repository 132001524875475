// source: hotel/base.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.hotel.Address', null, global);
goog.exportSymbol('proto.hotel.AgeCategory', null, global);
goog.exportSymbol('proto.hotel.Area', null, global);
goog.exportSymbol('proto.hotel.BedOption', null, global);
goog.exportSymbol('proto.hotel.BedType', null, global);
goog.exportSymbol('proto.hotel.BookingStatus', null, global);
goog.exportSymbol('proto.hotel.CenterInfo', null, global);
goog.exportSymbol('proto.hotel.Coordinates', null, global);
goog.exportSymbol('proto.hotel.CriteriaRating', null, global);
goog.exportSymbol('proto.hotel.CustomerRating', null, global);
goog.exportSymbol('proto.hotel.Destination', null, global);
goog.exportSymbol('proto.hotel.DistanceRange', null, global);
goog.exportSymbol('proto.hotel.FilterOption', null, global);
goog.exportSymbol('proto.hotel.FilterOptions', null, global);
goog.exportSymbol('proto.hotel.HotelBasicInfo', null, global);
goog.exportSymbol('proto.hotel.HotelFilterRequest', null, global);
goog.exportSymbol('proto.hotel.HotelReview', null, global);
goog.exportSymbol('proto.hotel.HotelSummary', null, global);
goog.exportSymbol('proto.hotel.HotelSummaryPrice', null, global);
goog.exportSymbol('proto.hotel.HubAmenity', null, global);
goog.exportSymbol('proto.hotel.HubGroupAmenity', null, global);
goog.exportSymbol('proto.hotel.ImageData', null, global);
goog.exportSymbol('proto.hotel.ImportantInfo', null, global);
goog.exportSymbol('proto.hotel.InvoiceIssuedStatus', null, global);
goog.exportSymbol('proto.hotel.LinkOptions', null, global);
goog.exportSymbol('proto.hotel.MaxAllowed', null, global);
goog.exportSymbol('proto.hotel.OccupancyRequest', null, global);
goog.exportSymbol('proto.hotel.PartnerInfo', null, global);
goog.exportSymbol('proto.hotel.PayAtHotel', null, global);
goog.exportSymbol('proto.hotel.PriceAdjustmentRequest', null, global);
goog.exportSymbol('proto.hotel.PriceAdjustmentResponse', null, global);
goog.exportSymbol('proto.hotel.PriceAdjustmentType', null, global);
goog.exportSymbol('proto.hotel.PriceForNight', null, global);
goog.exportSymbol('proto.hotel.RegionType', null, global);
goog.exportSymbol('proto.hotel.Review', null, global);
goog.exportSymbol('proto.hotel.RoomOccupancy', null, global);
goog.exportSymbol('proto.hotel.SortItem', null, global);
goog.exportSymbol('proto.hotel.SortItemType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Destination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.Destination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Destination.displayName = 'proto.hotel.Destination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.BedOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.BedOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.BedOption.displayName = 'proto.hotel.BedOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.PriceForNight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.PriceForNight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.PriceForNight.displayName = 'proto.hotel.PriceForNight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Coordinates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.Coordinates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Coordinates.displayName = 'proto.hotel.Coordinates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.ImageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.ImageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.ImageData.displayName = 'proto.hotel.ImageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.LinkOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.LinkOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.LinkOptions.displayName = 'proto.hotel.LinkOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.OccupancyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.OccupancyRequest.repeatedFields_, null);
};
goog.inherits(proto.hotel.OccupancyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.OccupancyRequest.displayName = 'proto.hotel.OccupancyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HotelReview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HotelReview.repeatedFields_, null);
};
goog.inherits(proto.hotel.HotelReview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HotelReview.displayName = 'proto.hotel.HotelReview';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.CriteriaRating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.CriteriaRating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.CriteriaRating.displayName = 'proto.hotel.CriteriaRating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Review = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.Review.repeatedFields_, null);
};
goog.inherits(proto.hotel.Review, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Review.displayName = 'proto.hotel.Review';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.CenterInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.CenterInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.CenterInfo.displayName = 'proto.hotel.CenterInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HotelSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HotelSummary.repeatedFields_, null);
};
goog.inherits(proto.hotel.HotelSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HotelSummary.displayName = 'proto.hotel.HotelSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HotelSummaryPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HotelSummaryPrice.repeatedFields_, null);
};
goog.inherits(proto.hotel.HotelSummaryPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HotelSummaryPrice.displayName = 'proto.hotel.HotelSummaryPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Area = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.Area, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Area.displayName = 'proto.hotel.Area';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.RoomOccupancy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.RoomOccupancy.repeatedFields_, null);
};
goog.inherits(proto.hotel.RoomOccupancy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.RoomOccupancy.displayName = 'proto.hotel.RoomOccupancy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.MaxAllowed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.MaxAllowed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.MaxAllowed.displayName = 'proto.hotel.MaxAllowed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.AgeCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.AgeCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.AgeCategory.displayName = 'proto.hotel.AgeCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Address.displayName = 'proto.hotel.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HotelBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.HotelBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HotelBasicInfo.displayName = 'proto.hotel.HotelBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.FilterOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.FilterOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.FilterOption.displayName = 'proto.hotel.FilterOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.PayAtHotel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.PayAtHotel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.PayAtHotel.displayName = 'proto.hotel.PayAtHotel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.DistanceRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.DistanceRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.DistanceRange.displayName = 'proto.hotel.DistanceRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.FilterOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.FilterOptions.repeatedFields_, null);
};
goog.inherits(proto.hotel.FilterOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.FilterOptions.displayName = 'proto.hotel.FilterOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.ImportantInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.ImportantInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.ImportantInfo.displayName = 'proto.hotel.ImportantInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubAmenity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.HubAmenity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubAmenity.displayName = 'proto.hotel.HubAmenity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubGroupAmenity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HubGroupAmenity.repeatedFields_, null);
};
goog.inherits(proto.hotel.HubGroupAmenity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubGroupAmenity.displayName = 'proto.hotel.HubGroupAmenity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.BedType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.BedType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.BedType.displayName = 'proto.hotel.BedType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.PartnerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.PartnerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.PartnerInfo.displayName = 'proto.hotel.PartnerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.PriceAdjustmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.PriceAdjustmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.PriceAdjustmentRequest.displayName = 'proto.hotel.PriceAdjustmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.PriceAdjustmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.PriceAdjustmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.PriceAdjustmentResponse.displayName = 'proto.hotel.PriceAdjustmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.SortItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.SortItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.SortItem.displayName = 'proto.hotel.SortItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HotelFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HotelFilterRequest.repeatedFields_, null);
};
goog.inherits(proto.hotel.HotelFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HotelFilterRequest.displayName = 'proto.hotel.HotelFilterRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Destination.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Destination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Destination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Destination.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    language: jspb.Message.getFieldWithDefault(msg, 6, ""),
    coordinates: (f = msg.getCoordinates()) && proto.hotel.Coordinates.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Destination}
 */
proto.hotel.Destination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Destination;
  return proto.hotel.Destination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Destination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Destination}
 */
proto.hotel.Destination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 7:
      var value = new proto.hotel.Coordinates;
      reader.readMessage(value,proto.hotel.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Destination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Destination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Destination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Destination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.hotel.Coordinates.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.hotel.Destination.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Destination} returns this
 */
proto.hotel.Destination.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.hotel.Destination.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Destination} returns this
 */
proto.hotel.Destination.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.hotel.Destination.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Destination} returns this
 */
proto.hotel.Destination.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.hotel.Destination.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Destination} returns this
 */
proto.hotel.Destination.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country_code = 5;
 * @return {string}
 */
proto.hotel.Destination.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Destination} returns this
 */
proto.hotel.Destination.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string language = 6;
 * @return {string}
 */
proto.hotel.Destination.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Destination} returns this
 */
proto.hotel.Destination.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Coordinates coordinates = 7;
 * @return {?proto.hotel.Coordinates}
 */
proto.hotel.Destination.prototype.getCoordinates = function() {
  return /** @type{?proto.hotel.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.hotel.Coordinates, 7));
};


/**
 * @param {?proto.hotel.Coordinates|undefined} value
 * @return {!proto.hotel.Destination} returns this
*/
proto.hotel.Destination.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.Destination} returns this
 */
proto.hotel.Destination.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.Destination.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.BedOption.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.BedOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.BedOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BedOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalBeds: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.BedOption}
 */
proto.hotel.BedOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.BedOption;
  return proto.hotel.BedOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.BedOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.BedOption}
 */
proto.hotel.BedOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalBeds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.BedOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.BedOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.BedOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BedOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalBeds();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string option_id = 1;
 * @return {string}
 */
proto.hotel.BedOption.prototype.getOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BedOption} returns this
 */
proto.hotel.BedOption.prototype.setOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hotel.BedOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BedOption} returns this
 */
proto.hotel.BedOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_beds = 3;
 * @return {number}
 */
proto.hotel.BedOption.prototype.getTotalBeds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BedOption} returns this
 */
proto.hotel.BedOption.prototype.setTotalBeds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.PriceForNight.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.PriceForNight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.PriceForNight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PriceForNight.toObject = function(includeInstance, msg) {
  var f, obj = {
    minPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    maxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.PriceForNight}
 */
proto.hotel.PriceForNight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.PriceForNight;
  return proto.hotel.PriceForNight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.PriceForNight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.PriceForNight}
 */
proto.hotel.PriceForNight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.PriceForNight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.PriceForNight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.PriceForNight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PriceForNight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMaxPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float min_price = 1;
 * @return {number}
 */
proto.hotel.PriceForNight.prototype.getMinPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.PriceForNight} returns this
 */
proto.hotel.PriceForNight.prototype.setMinPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float max_price = 2;
 * @return {number}
 */
proto.hotel.PriceForNight.prototype.getMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.PriceForNight} returns this
 */
proto.hotel.PriceForNight.prototype.setMaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Coordinates.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Coordinates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Coordinates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Coordinates.toObject = function(includeInstance, msg) {
  var f, obj = {
    centerLongitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    centerLatitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Coordinates}
 */
proto.hotel.Coordinates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Coordinates;
  return proto.hotel.Coordinates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Coordinates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Coordinates}
 */
proto.hotel.Coordinates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCenterLongitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCenterLatitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Coordinates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Coordinates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Coordinates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Coordinates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCenterLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getCenterLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float center_longitude = 1;
 * @return {number}
 */
proto.hotel.Coordinates.prototype.getCenterLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.Coordinates} returns this
 */
proto.hotel.Coordinates.prototype.setCenterLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float center_latitude = 2;
 * @return {number}
 */
proto.hotel.Coordinates.prototype.getCenterLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.Coordinates} returns this
 */
proto.hotel.Coordinates.prototype.setCenterLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.ImageData.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.ImageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.ImageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.ImageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkOptions: (f = msg.getLinkOptions()) && proto.hotel.LinkOptions.toObject(includeInstance, f),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    heroImage: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.ImageData}
 */
proto.hotel.ImageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.ImageData;
  return proto.hotel.ImageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.ImageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.ImageData}
 */
proto.hotel.ImageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hotel.LinkOptions;
      reader.readMessage(value,proto.hotel.LinkOptions.deserializeBinaryFromReader);
      msg.setLinkOptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHeroImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.ImageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.ImageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.ImageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.ImageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkOptions();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hotel.LinkOptions.serializeBinaryToWriter
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHeroImage();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional LinkOptions link_options = 1;
 * @return {?proto.hotel.LinkOptions}
 */
proto.hotel.ImageData.prototype.getLinkOptions = function() {
  return /** @type{?proto.hotel.LinkOptions} */ (
    jspb.Message.getWrapperField(this, proto.hotel.LinkOptions, 1));
};


/**
 * @param {?proto.hotel.LinkOptions|undefined} value
 * @return {!proto.hotel.ImageData} returns this
*/
proto.hotel.ImageData.prototype.setLinkOptions = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.ImageData} returns this
 */
proto.hotel.ImageData.prototype.clearLinkOptions = function() {
  return this.setLinkOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.ImageData.prototype.hasLinkOptions = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.hotel.ImageData.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.ImageData} returns this
 */
proto.hotel.ImageData.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool hero_image = 3;
 * @return {boolean}
 */
proto.hotel.ImageData.prototype.getHeroImage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.ImageData} returns this
 */
proto.hotel.ImageData.prototype.setHeroImage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.LinkOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.LinkOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.LinkOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.LinkOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    px70: jspb.Message.getFieldWithDefault(msg, 1, ""),
    px350: jspb.Message.getFieldWithDefault(msg, 2, ""),
    px1000: jspb.Message.getFieldWithDefault(msg, 3, ""),
    px200: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.LinkOptions}
 */
proto.hotel.LinkOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.LinkOptions;
  return proto.hotel.LinkOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.LinkOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.LinkOptions}
 */
proto.hotel.LinkOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPx70(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPx350(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPx1000(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPx200(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.LinkOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.LinkOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.LinkOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.LinkOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPx70();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPx350();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPx1000();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPx200();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string px70 = 1;
 * @return {string}
 */
proto.hotel.LinkOptions.prototype.getPx70 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.LinkOptions} returns this
 */
proto.hotel.LinkOptions.prototype.setPx70 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string px350 = 2;
 * @return {string}
 */
proto.hotel.LinkOptions.prototype.getPx350 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.LinkOptions} returns this
 */
proto.hotel.LinkOptions.prototype.setPx350 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string px1000 = 3;
 * @return {string}
 */
proto.hotel.LinkOptions.prototype.getPx1000 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.LinkOptions} returns this
 */
proto.hotel.LinkOptions.prototype.setPx1000 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string px200 = 4;
 * @return {string}
 */
proto.hotel.LinkOptions.prototype.getPx200 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.LinkOptions} returns this
 */
proto.hotel.LinkOptions.prototype.setPx200 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.OccupancyRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.OccupancyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.OccupancyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.OccupancyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.OccupancyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adults: jspb.Message.getFieldWithDefault(msg, 1, 0),
    childAgesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.OccupancyRequest}
 */
proto.hotel.OccupancyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.OccupancyRequest;
  return proto.hotel.OccupancyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.OccupancyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.OccupancyRequest}
 */
proto.hotel.OccupancyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdults(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChildAges(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.OccupancyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.OccupancyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.OccupancyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.OccupancyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdults();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getChildAgesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 adults = 1;
 * @return {number}
 */
proto.hotel.OccupancyRequest.prototype.getAdults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.OccupancyRequest} returns this
 */
proto.hotel.OccupancyRequest.prototype.setAdults = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 child_ages = 2;
 * @return {!Array<number>}
 */
proto.hotel.OccupancyRequest.prototype.getChildAgesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hotel.OccupancyRequest} returns this
 */
proto.hotel.OccupancyRequest.prototype.setChildAgesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hotel.OccupancyRequest} returns this
 */
proto.hotel.OccupancyRequest.prototype.addChildAges = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.OccupancyRequest} returns this
 */
proto.hotel.OccupancyRequest.prototype.clearChildAgesList = function() {
  return this.setChildAgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HotelReview.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HotelReview.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HotelReview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HotelReview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelReview.toObject = function(includeInstance, msg) {
  var f, obj = {
    rate: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    reviewCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rating: (f = msg.getRating()) && proto.hotel.CriteriaRating.toObject(includeInstance, f),
    reviewsList: jspb.Message.toObjectList(msg.getReviewsList(),
    proto.hotel.Review.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HotelReview}
 */
proto.hotel.HotelReview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HotelReview;
  return proto.hotel.HotelReview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HotelReview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HotelReview}
 */
proto.hotel.HotelReview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReviewCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = new proto.hotel.CriteriaRating;
      reader.readMessage(value,proto.hotel.CriteriaRating.deserializeBinaryFromReader);
      msg.setRating(value);
      break;
    case 5:
      var value = new proto.hotel.Review;
      reader.readMessage(value,proto.hotel.Review.deserializeBinaryFromReader);
      msg.addReviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HotelReview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HotelReview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HotelReview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelReview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRate();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getReviewCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRating();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.hotel.CriteriaRating.serializeBinaryToWriter
    );
  }
  f = message.getReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.hotel.Review.serializeBinaryToWriter
    );
  }
};


/**
 * optional double rate = 1;
 * @return {number}
 */
proto.hotel.HotelReview.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelReview} returns this
 */
proto.hotel.HotelReview.prototype.setRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 review_count = 2;
 * @return {number}
 */
proto.hotel.HotelReview.prototype.getReviewCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelReview} returns this
 */
proto.hotel.HotelReview.prototype.setReviewCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.hotel.HotelReview.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelReview} returns this
 */
proto.hotel.HotelReview.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CriteriaRating rating = 4;
 * @return {?proto.hotel.CriteriaRating}
 */
proto.hotel.HotelReview.prototype.getRating = function() {
  return /** @type{?proto.hotel.CriteriaRating} */ (
    jspb.Message.getWrapperField(this, proto.hotel.CriteriaRating, 4));
};


/**
 * @param {?proto.hotel.CriteriaRating|undefined} value
 * @return {!proto.hotel.HotelReview} returns this
*/
proto.hotel.HotelReview.prototype.setRating = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelReview} returns this
 */
proto.hotel.HotelReview.prototype.clearRating = function() {
  return this.setRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelReview.prototype.hasRating = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Review reviews = 5;
 * @return {!Array<!proto.hotel.Review>}
 */
proto.hotel.HotelReview.prototype.getReviewsList = function() {
  return /** @type{!Array<!proto.hotel.Review>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.Review, 5));
};


/**
 * @param {!Array<!proto.hotel.Review>} value
 * @return {!proto.hotel.HotelReview} returns this
*/
proto.hotel.HotelReview.prototype.setReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hotel.Review=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.Review}
 */
proto.hotel.HotelReview.prototype.addReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hotel.Review, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelReview} returns this
 */
proto.hotel.HotelReview.prototype.clearReviewsList = function() {
  return this.setReviewsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.CriteriaRating.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.CriteriaRating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.CriteriaRating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.CriteriaRating.toObject = function(includeInstance, msg) {
  var f, obj = {
    cleanliness: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    service: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    comfort: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    condition: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    location: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    neighborhood: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    quality: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    staffService: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    amenities: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    propertyConditionsFacilities: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    ecoFriendliness: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.CriteriaRating}
 */
proto.hotel.CriteriaRating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.CriteriaRating;
  return proto.hotel.CriteriaRating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.CriteriaRating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.CriteriaRating}
 */
proto.hotel.CriteriaRating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCleanliness(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setService(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setComfort(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCondition(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLocation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNeighborhood(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuality(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStaffService(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmenities(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPropertyConditionsFacilities(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEcoFriendliness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.CriteriaRating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.CriteriaRating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.CriteriaRating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.CriteriaRating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCleanliness();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getService();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getComfort();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCondition();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getNeighborhood();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getQuality();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getStaffService();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getAmenities();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getPropertyConditionsFacilities();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getEcoFriendliness();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
};


/**
 * optional double cleanliness = 1;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getCleanliness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setCleanliness = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double service = 2;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getService = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setService = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double comfort = 3;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getComfort = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setComfort = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double condition = 4;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getCondition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setCondition = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double location = 5;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getLocation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setLocation = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double neighborhood = 6;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getNeighborhood = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double quality = 7;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getQuality = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setQuality = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double value = 8;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double staff_service = 9;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getStaffService = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setStaffService = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double amenities = 10;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getAmenities = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setAmenities = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double property_conditions_facilities = 11;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getPropertyConditionsFacilities = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setPropertyConditionsFacilities = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double eco_friendliness = 12;
 * @return {number}
 */
proto.hotel.CriteriaRating.prototype.getEcoFriendliness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CriteriaRating} returns this
 */
proto.hotel.CriteriaRating.prototype.setEcoFriendliness = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.Review.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Review.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Review.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Review} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Review.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    travelCompanion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateSubmitted: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    text: jspb.Message.getFieldWithDefault(msg, 6, ""),
    content: jspb.Message.getFieldWithDefault(msg, 7, ""),
    night: jspb.Message.getFieldWithDefault(msg, 8, ""),
    likedList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    stayDate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ratingLabel: jspb.Message.getFieldWithDefault(msg, 11, ""),
    verificationSource: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Review}
 */
proto.hotel.Review.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Review;
  return proto.hotel.Review.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Review} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Review}
 */
proto.hotel.Review.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewerName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTravelCompanion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateSubmitted(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRating(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNight(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addLiked(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStayDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRatingLabel(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Review.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Review.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Review} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Review.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTravelCompanion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateSubmitted();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNight();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLikedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getStayDate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRatingLabel();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVerificationSource();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string reviewer_name = 1;
 * @return {string}
 */
proto.hotel.Review.prototype.getReviewerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setReviewerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string travel_companion = 2;
 * @return {string}
 */
proto.hotel.Review.prototype.getTravelCompanion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setTravelCompanion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date_submitted = 4;
 * @return {string}
 */
proto.hotel.Review.prototype.getDateSubmitted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setDateSubmitted = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double rating = 5;
 * @return {number}
 */
proto.hotel.Review.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string text = 6;
 * @return {string}
 */
proto.hotel.Review.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string content = 7;
 * @return {string}
 */
proto.hotel.Review.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string night = 8;
 * @return {string}
 */
proto.hotel.Review.prototype.getNight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setNight = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string liked = 9;
 * @return {!Array<string>}
 */
proto.hotel.Review.prototype.getLikedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setLikedList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.addLiked = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.clearLikedList = function() {
  return this.setLikedList([]);
};


/**
 * optional string stay_date = 10;
 * @return {string}
 */
proto.hotel.Review.prototype.getStayDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setStayDate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string rating_label = 11;
 * @return {string}
 */
proto.hotel.Review.prototype.getRatingLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setRatingLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string verification_source = 12;
 * @return {string}
 */
proto.hotel.Review.prototype.getVerificationSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Review} returns this
 */
proto.hotel.Review.prototype.setVerificationSource = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.CenterInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.CenterInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.CenterInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.CenterInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    centerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    distanceToCenter: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.CenterInfo}
 */
proto.hotel.CenterInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.CenterInfo;
  return proto.hotel.CenterInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.CenterInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.CenterInfo}
 */
proto.hotel.CenterInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistanceToCenter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.CenterInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.CenterInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.CenterInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.CenterInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCenterName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDistanceToCenter();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string center_name = 1;
 * @return {string}
 */
proto.hotel.CenterInfo.prototype.getCenterName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.CenterInfo} returns this
 */
proto.hotel.CenterInfo.prototype.setCenterName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float distance_to_center = 2;
 * @return {number}
 */
proto.hotel.CenterInfo.prototype.getDistanceToCenter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CenterInfo} returns this
 */
proto.hotel.CenterInfo.prototype.setDistanceToCenter = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.hotel.CenterInfo.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.CenterInfo} returns this
 */
proto.hotel.CenterInfo.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HotelSummary.repeatedFields_ = [5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HotelSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HotelSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HotelSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: jspb.Message.getFieldWithDefault(msg, 3, ""),
    review: (f = msg.getReview()) && proto.hotel.HotelReview.toObject(includeInstance, f),
    amenityGroupsList: jspb.Message.toObjectList(msg.getAmenityGroupsList(),
    proto.hotel.HubGroupAmenity.toObject, includeInstance),
    categoryType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    thumbnailUrlList: jspb.Message.toObjectList(msg.getThumbnailUrlList(),
    proto.hotel.ImageData.toObject, includeInstance),
    centerInfo: (f = msg.getCenterInfo()) && proto.hotel.CenterInfo.toObject(includeInstance, f),
    isPremium: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    availableRooms: jspb.Message.getFieldWithDefault(msg, 10, 0),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    price: (f = msg.getPrice()) && proto.hotel.HotelSummaryPrice.toObject(includeInstance, f),
    isHotelPackage: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    matchKey: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HotelSummary}
 */
proto.hotel.HotelSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HotelSummary;
  return proto.hotel.HotelSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HotelSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HotelSummary}
 */
proto.hotel.HotelSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 4:
      var value = new proto.hotel.HotelReview;
      reader.readMessage(value,proto.hotel.HotelReview.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    case 5:
      var value = new proto.hotel.HubGroupAmenity;
      reader.readMessage(value,proto.hotel.HubGroupAmenity.deserializeBinaryFromReader);
      msg.addAmenityGroups(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryType(value);
      break;
    case 7:
      var value = new proto.hotel.ImageData;
      reader.readMessage(value,proto.hotel.ImageData.deserializeBinaryFromReader);
      msg.addThumbnailUrl(value);
      break;
    case 8:
      var value = new proto.hotel.CenterInfo;
      reader.readMessage(value,proto.hotel.CenterInfo.deserializeBinaryFromReader);
      msg.setCenterInfo(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPremium(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailableRooms(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRating(value);
      break;
    case 12:
      var value = new proto.hotel.HotelSummaryPrice;
      reader.readMessage(value,proto.hotel.HotelSummaryPrice.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHotelPackage(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HotelSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HotelSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HotelSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.hotel.HotelReview.serializeBinaryToWriter
    );
  }
  f = message.getAmenityGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.hotel.HubGroupAmenity.serializeBinaryToWriter
    );
  }
  f = message.getCategoryType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getThumbnailUrlList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.hotel.ImageData.serializeBinaryToWriter
    );
  }
  f = message.getCenterInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.hotel.CenterInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsPremium();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAvailableRooms();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.hotel.HotelSummaryPrice.serializeBinaryToWriter
    );
  }
  f = message.getIsHotelPackage();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getMatchKey();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.hotel.HotelSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hotel.HotelSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string location = 3;
 * @return {string}
 */
proto.hotel.HotelSummary.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional HotelReview review = 4;
 * @return {?proto.hotel.HotelReview}
 */
proto.hotel.HotelSummary.prototype.getReview = function() {
  return /** @type{?proto.hotel.HotelReview} */ (
    jspb.Message.getWrapperField(this, proto.hotel.HotelReview, 4));
};


/**
 * @param {?proto.hotel.HotelReview|undefined} value
 * @return {!proto.hotel.HotelSummary} returns this
*/
proto.hotel.HotelSummary.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelSummary.prototype.hasReview = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated HubGroupAmenity amenity_groups = 5;
 * @return {!Array<!proto.hotel.HubGroupAmenity>}
 */
proto.hotel.HotelSummary.prototype.getAmenityGroupsList = function() {
  return /** @type{!Array<!proto.hotel.HubGroupAmenity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubGroupAmenity, 5));
};


/**
 * @param {!Array<!proto.hotel.HubGroupAmenity>} value
 * @return {!proto.hotel.HotelSummary} returns this
*/
proto.hotel.HotelSummary.prototype.setAmenityGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hotel.HubGroupAmenity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubGroupAmenity}
 */
proto.hotel.HotelSummary.prototype.addAmenityGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hotel.HubGroupAmenity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.clearAmenityGroupsList = function() {
  return this.setAmenityGroupsList([]);
};


/**
 * optional string category_type = 6;
 * @return {string}
 */
proto.hotel.HotelSummary.prototype.getCategoryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated ImageData thumbnail_url = 7;
 * @return {!Array<!proto.hotel.ImageData>}
 */
proto.hotel.HotelSummary.prototype.getThumbnailUrlList = function() {
  return /** @type{!Array<!proto.hotel.ImageData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.ImageData, 7));
};


/**
 * @param {!Array<!proto.hotel.ImageData>} value
 * @return {!proto.hotel.HotelSummary} returns this
*/
proto.hotel.HotelSummary.prototype.setThumbnailUrlList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.hotel.ImageData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.ImageData}
 */
proto.hotel.HotelSummary.prototype.addThumbnailUrl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.hotel.ImageData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.clearThumbnailUrlList = function() {
  return this.setThumbnailUrlList([]);
};


/**
 * optional CenterInfo center_info = 8;
 * @return {?proto.hotel.CenterInfo}
 */
proto.hotel.HotelSummary.prototype.getCenterInfo = function() {
  return /** @type{?proto.hotel.CenterInfo} */ (
    jspb.Message.getWrapperField(this, proto.hotel.CenterInfo, 8));
};


/**
 * @param {?proto.hotel.CenterInfo|undefined} value
 * @return {!proto.hotel.HotelSummary} returns this
*/
proto.hotel.HotelSummary.prototype.setCenterInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.clearCenterInfo = function() {
  return this.setCenterInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelSummary.prototype.hasCenterInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_premium = 9;
 * @return {boolean}
 */
proto.hotel.HotelSummary.prototype.getIsPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.setIsPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 available_rooms = 10;
 * @return {number}
 */
proto.hotel.HotelSummary.prototype.getAvailableRooms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.setAvailableRooms = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double rating = 11;
 * @return {number}
 */
proto.hotel.HotelSummary.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional HotelSummaryPrice price = 12;
 * @return {?proto.hotel.HotelSummaryPrice}
 */
proto.hotel.HotelSummary.prototype.getPrice = function() {
  return /** @type{?proto.hotel.HotelSummaryPrice} */ (
    jspb.Message.getWrapperField(this, proto.hotel.HotelSummaryPrice, 12));
};


/**
 * @param {?proto.hotel.HotelSummaryPrice|undefined} value
 * @return {!proto.hotel.HotelSummary} returns this
*/
proto.hotel.HotelSummary.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelSummary.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool is_hotel_package = 13;
 * @return {boolean}
 */
proto.hotel.HotelSummary.prototype.getIsHotelPackage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.setIsHotelPackage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string match_key = 14;
 * @return {string}
 */
proto.hotel.HotelSummary.prototype.getMatchKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelSummary} returns this
 */
proto.hotel.HotelSummary.prototype.setMatchKey = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HotelSummaryPrice.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HotelSummaryPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HotelSummaryPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HotelSummaryPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelSummaryPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    discountPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    originalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    taxIncluded: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    payAtHotelList: jspb.Message.toObjectList(msg.getPayAtHotelList(),
    proto.hotel.PayAtHotel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HotelSummaryPrice}
 */
proto.hotel.HotelSummaryPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HotelSummaryPrice;
  return proto.hotel.HotelSummaryPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HotelSummaryPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HotelSummaryPrice}
 */
proto.hotel.HotelSummaryPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDiscountPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOriginalPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalPrice(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTaxIncluded(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = new proto.hotel.PayAtHotel;
      reader.readMessage(value,proto.hotel.PayAtHotel.deserializeBinaryFromReader);
      msg.addPayAtHotel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HotelSummaryPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HotelSummaryPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HotelSummaryPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelSummaryPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscountPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getOriginalPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTaxIncluded();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPayAtHotelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.hotel.PayAtHotel.serializeBinaryToWriter
    );
  }
};


/**
 * optional float discount_price = 1;
 * @return {number}
 */
proto.hotel.HotelSummaryPrice.prototype.getDiscountPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelSummaryPrice} returns this
 */
proto.hotel.HotelSummaryPrice.prototype.setDiscountPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float original_price = 2;
 * @return {number}
 */
proto.hotel.HotelSummaryPrice.prototype.getOriginalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelSummaryPrice} returns this
 */
proto.hotel.HotelSummaryPrice.prototype.setOriginalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float total_price = 3;
 * @return {number}
 */
proto.hotel.HotelSummaryPrice.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelSummaryPrice} returns this
 */
proto.hotel.HotelSummaryPrice.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool tax_included = 4;
 * @return {boolean}
 */
proto.hotel.HotelSummaryPrice.prototype.getTaxIncluded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.HotelSummaryPrice} returns this
 */
proto.hotel.HotelSummaryPrice.prototype.setTaxIncluded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.hotel.HotelSummaryPrice.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelSummaryPrice} returns this
 */
proto.hotel.HotelSummaryPrice.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated PayAtHotel pay_at_hotel = 6;
 * @return {!Array<!proto.hotel.PayAtHotel>}
 */
proto.hotel.HotelSummaryPrice.prototype.getPayAtHotelList = function() {
  return /** @type{!Array<!proto.hotel.PayAtHotel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.PayAtHotel, 6));
};


/**
 * @param {!Array<!proto.hotel.PayAtHotel>} value
 * @return {!proto.hotel.HotelSummaryPrice} returns this
*/
proto.hotel.HotelSummaryPrice.prototype.setPayAtHotelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.hotel.PayAtHotel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.PayAtHotel}
 */
proto.hotel.HotelSummaryPrice.prototype.addPayAtHotel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.hotel.PayAtHotel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelSummaryPrice} returns this
 */
proto.hotel.HotelSummaryPrice.prototype.clearPayAtHotelList = function() {
  return this.setPayAtHotelList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Area.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Area.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Area} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Area.toObject = function(includeInstance, msg) {
  var f, obj = {
    squareMeters: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    squareFeet: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Area}
 */
proto.hotel.Area.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Area;
  return proto.hotel.Area.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Area} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Area}
 */
proto.hotel.Area.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSquareMeters(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSquareFeet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Area.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Area.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Area} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Area.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSquareMeters();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getSquareFeet();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float square_meters = 1;
 * @return {number}
 */
proto.hotel.Area.prototype.getSquareMeters = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.Area} returns this
 */
proto.hotel.Area.prototype.setSquareMeters = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float square_feet = 2;
 * @return {number}
 */
proto.hotel.Area.prototype.getSquareFeet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.Area} returns this
 */
proto.hotel.Area.prototype.setSquareFeet = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.RoomOccupancy.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.RoomOccupancy.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.RoomOccupancy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.RoomOccupancy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RoomOccupancy.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxAllowed: (f = msg.getMaxAllowed()) && proto.hotel.MaxAllowed.toObject(includeInstance, f),
    ageCategoriesList: jspb.Message.toObjectList(msg.getAgeCategoriesList(),
    proto.hotel.AgeCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.RoomOccupancy}
 */
proto.hotel.RoomOccupancy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.RoomOccupancy;
  return proto.hotel.RoomOccupancy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.RoomOccupancy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.RoomOccupancy}
 */
proto.hotel.RoomOccupancy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hotel.MaxAllowed;
      reader.readMessage(value,proto.hotel.MaxAllowed.deserializeBinaryFromReader);
      msg.setMaxAllowed(value);
      break;
    case 2:
      var value = new proto.hotel.AgeCategory;
      reader.readMessage(value,proto.hotel.AgeCategory.deserializeBinaryFromReader);
      msg.addAgeCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.RoomOccupancy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.RoomOccupancy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.RoomOccupancy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RoomOccupancy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxAllowed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hotel.MaxAllowed.serializeBinaryToWriter
    );
  }
  f = message.getAgeCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.hotel.AgeCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional MaxAllowed max_allowed = 1;
 * @return {?proto.hotel.MaxAllowed}
 */
proto.hotel.RoomOccupancy.prototype.getMaxAllowed = function() {
  return /** @type{?proto.hotel.MaxAllowed} */ (
    jspb.Message.getWrapperField(this, proto.hotel.MaxAllowed, 1));
};


/**
 * @param {?proto.hotel.MaxAllowed|undefined} value
 * @return {!proto.hotel.RoomOccupancy} returns this
*/
proto.hotel.RoomOccupancy.prototype.setMaxAllowed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.RoomOccupancy} returns this
 */
proto.hotel.RoomOccupancy.prototype.clearMaxAllowed = function() {
  return this.setMaxAllowed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.RoomOccupancy.prototype.hasMaxAllowed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AgeCategory age_categories = 2;
 * @return {!Array<!proto.hotel.AgeCategory>}
 */
proto.hotel.RoomOccupancy.prototype.getAgeCategoriesList = function() {
  return /** @type{!Array<!proto.hotel.AgeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.AgeCategory, 2));
};


/**
 * @param {!Array<!proto.hotel.AgeCategory>} value
 * @return {!proto.hotel.RoomOccupancy} returns this
*/
proto.hotel.RoomOccupancy.prototype.setAgeCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hotel.AgeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.AgeCategory}
 */
proto.hotel.RoomOccupancy.prototype.addAgeCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hotel.AgeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.RoomOccupancy} returns this
 */
proto.hotel.RoomOccupancy.prototype.clearAgeCategoriesList = function() {
  return this.setAgeCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.MaxAllowed.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.MaxAllowed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.MaxAllowed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.MaxAllowed.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    children: jspb.Message.getFieldWithDefault(msg, 2, 0),
    adults: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.MaxAllowed}
 */
proto.hotel.MaxAllowed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.MaxAllowed;
  return proto.hotel.MaxAllowed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.MaxAllowed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.MaxAllowed}
 */
proto.hotel.MaxAllowed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChildren(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.MaxAllowed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.MaxAllowed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.MaxAllowed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.MaxAllowed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getChildren();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdults();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.hotel.MaxAllowed.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.MaxAllowed} returns this
 */
proto.hotel.MaxAllowed.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 children = 2;
 * @return {number}
 */
proto.hotel.MaxAllowed.prototype.getChildren = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.MaxAllowed} returns this
 */
proto.hotel.MaxAllowed.prototype.setChildren = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 adults = 3;
 * @return {number}
 */
proto.hotel.MaxAllowed.prototype.getAdults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.MaxAllowed} returns this
 */
proto.hotel.MaxAllowed.prototype.setAdults = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.AgeCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.AgeCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.AgeCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.AgeCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    minimumAge: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.AgeCategory}
 */
proto.hotel.AgeCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.AgeCategory;
  return proto.hotel.AgeCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.AgeCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.AgeCategory}
 */
proto.hotel.AgeCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinimumAge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.AgeCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.AgeCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.AgeCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.AgeCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMinimumAge();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.hotel.AgeCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.AgeCategory} returns this
 */
proto.hotel.AgeCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float minimum_age = 2;
 * @return {number}
 */
proto.hotel.AgeCategory.prototype.getMinimumAge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.AgeCategory} returns this
 */
proto.hotel.AgeCategory.prototype.setMinimumAge = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    line1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Address}
 */
proto.hotel.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Address;
  return proto.hotel.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Address}
 */
proto.hotel.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLine2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string line_1 = 1;
 * @return {string}
 */
proto.hotel.Address.prototype.getLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Address} returns this
 */
proto.hotel.Address.prototype.setLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string line_2 = 2;
 * @return {string}
 */
proto.hotel.Address.prototype.getLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Address} returns this
 */
proto.hotel.Address.prototype.setLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.hotel.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Address} returns this
 */
proto.hotel.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country_code = 4;
 * @return {string}
 */
proto.hotel.Address.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Address} returns this
 */
proto.hotel.Address.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HotelBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HotelBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HotelBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto.hotel.Address.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    phone: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HotelBasicInfo}
 */
proto.hotel.HotelBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HotelBasicInfo;
  return proto.hotel.HotelBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HotelBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HotelBasicInfo}
 */
proto.hotel.HotelBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.hotel.Address;
      reader.readMessage(value,proto.hotel.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRating(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HotelBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HotelBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HotelBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.hotel.Address.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.hotel.HotelBasicInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelBasicInfo} returns this
 */
proto.hotel.HotelBasicInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hotel.HotelBasicInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelBasicInfo} returns this
 */
proto.hotel.HotelBasicInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Address address = 3;
 * @return {?proto.hotel.Address}
 */
proto.hotel.HotelBasicInfo.prototype.getAddress = function() {
  return /** @type{?proto.hotel.Address} */ (
    jspb.Message.getWrapperField(this, proto.hotel.Address, 3));
};


/**
 * @param {?proto.hotel.Address|undefined} value
 * @return {!proto.hotel.HotelBasicInfo} returns this
*/
proto.hotel.HotelBasicInfo.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelBasicInfo} returns this
 */
proto.hotel.HotelBasicInfo.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelBasicInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.hotel.HotelBasicInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelBasicInfo} returns this
 */
proto.hotel.HotelBasicInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double rating = 5;
 * @return {number}
 */
proto.hotel.HotelBasicInfo.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelBasicInfo} returns this
 */
proto.hotel.HotelBasicInfo.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.hotel.HotelBasicInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelBasicInfo} returns this
 */
proto.hotel.HotelBasicInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.FilterOption.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.FilterOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.FilterOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.FilterOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.FilterOption}
 */
proto.hotel.FilterOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.FilterOption;
  return proto.hotel.FilterOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.FilterOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.FilterOption}
 */
proto.hotel.FilterOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.FilterOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.FilterOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.FilterOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.FilterOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.hotel.FilterOption.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.FilterOption} returns this
 */
proto.hotel.FilterOption.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.hotel.FilterOption.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.FilterOption} returns this
 */
proto.hotel.FilterOption.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.PayAtHotel.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.PayAtHotel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.PayAtHotel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PayAtHotel.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.PayAtHotel}
 */
proto.hotel.PayAtHotel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.PayAtHotel;
  return proto.hotel.PayAtHotel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.PayAtHotel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.PayAtHotel}
 */
proto.hotel.PayAtHotel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.PayAtHotel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.PayAtHotel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.PayAtHotel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PayAtHotel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.hotel.PayAtHotel.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.PayAtHotel} returns this
 */
proto.hotel.PayAtHotel.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.hotel.PayAtHotel.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PayAtHotel} returns this
 */
proto.hotel.PayAtHotel.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.hotel.PayAtHotel.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PayAtHotel} returns this
 */
proto.hotel.PayAtHotel.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.hotel.PayAtHotel.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PayAtHotel} returns this
 */
proto.hotel.PayAtHotel.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.DistanceRange.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.DistanceRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.DistanceRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.DistanceRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    minDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    maxDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.DistanceRange}
 */
proto.hotel.DistanceRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.DistanceRange;
  return proto.hotel.DistanceRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.DistanceRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.DistanceRange}
 */
proto.hotel.DistanceRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinDistance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.DistanceRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.DistanceRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.DistanceRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.DistanceRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMinDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMaxDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.hotel.DistanceRange.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.DistanceRange} returns this
 */
proto.hotel.DistanceRange.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float min_distance = 2;
 * @return {number}
 */
proto.hotel.DistanceRange.prototype.getMinDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.DistanceRange} returns this
 */
proto.hotel.DistanceRange.prototype.setMinDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float max_distance = 3;
 * @return {number}
 */
proto.hotel.DistanceRange.prototype.getMaxDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.DistanceRange} returns this
 */
proto.hotel.DistanceRange.prototype.setMaxDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.FilterOptions.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.FilterOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.FilterOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.FilterOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.FilterOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    starRatingsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    accommodationTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    amenitiesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    distanceToCenterList: jspb.Message.toObjectList(msg.getDistanceToCenterList(),
    proto.hotel.DistanceRange.toObject, includeInstance),
    customerRatings: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.FilterOptions}
 */
proto.hotel.FilterOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.FilterOptions;
  return proto.hotel.FilterOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.FilterOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.FilterOptions}
 */
proto.hotel.FilterOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStarRatings(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccommodationTypes(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAmenities(value);
      break;
    case 4:
      var value = new proto.hotel.DistanceRange;
      reader.readMessage(value,proto.hotel.DistanceRange.deserializeBinaryFromReader);
      msg.addDistanceToCenter(value);
      break;
    case 5:
      var value = /** @type {!proto.hotel.CustomerRating} */ (reader.readEnum());
      msg.setCustomerRatings(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.FilterOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.FilterOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.FilterOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.FilterOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarRatingsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      1,
      f
    );
  }
  f = message.getAccommodationTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getAmenitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDistanceToCenterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.hotel.DistanceRange.serializeBinaryToWriter
    );
  }
  f = message.getCustomerRatings();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMinPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMaxPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * repeated float star_ratings = 1;
 * @return {!Array<number>}
 */
proto.hotel.FilterOptions.prototype.getStarRatingsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.setStarRatingsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.addStarRatings = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.clearStarRatingsList = function() {
  return this.setStarRatingsList([]);
};


/**
 * repeated string accommodation_types = 2;
 * @return {!Array<string>}
 */
proto.hotel.FilterOptions.prototype.getAccommodationTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.setAccommodationTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.addAccommodationTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.clearAccommodationTypesList = function() {
  return this.setAccommodationTypesList([]);
};


/**
 * repeated string amenities = 3;
 * @return {!Array<string>}
 */
proto.hotel.FilterOptions.prototype.getAmenitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.setAmenitiesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.addAmenities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.clearAmenitiesList = function() {
  return this.setAmenitiesList([]);
};


/**
 * repeated DistanceRange distance_to_center = 4;
 * @return {!Array<!proto.hotel.DistanceRange>}
 */
proto.hotel.FilterOptions.prototype.getDistanceToCenterList = function() {
  return /** @type{!Array<!proto.hotel.DistanceRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.DistanceRange, 4));
};


/**
 * @param {!Array<!proto.hotel.DistanceRange>} value
 * @return {!proto.hotel.FilterOptions} returns this
*/
proto.hotel.FilterOptions.prototype.setDistanceToCenterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.DistanceRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.DistanceRange}
 */
proto.hotel.FilterOptions.prototype.addDistanceToCenter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.DistanceRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.clearDistanceToCenterList = function() {
  return this.setDistanceToCenterList([]);
};


/**
 * optional CustomerRating customer_ratings = 5;
 * @return {!proto.hotel.CustomerRating}
 */
proto.hotel.FilterOptions.prototype.getCustomerRatings = function() {
  return /** @type {!proto.hotel.CustomerRating} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.hotel.CustomerRating} value
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.setCustomerRatings = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional float min_price = 6;
 * @return {number}
 */
proto.hotel.FilterOptions.prototype.getMinPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.setMinPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float max_price = 7;
 * @return {number}
 */
proto.hotel.FilterOptions.prototype.getMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.FilterOptions} returns this
 */
proto.hotel.FilterOptions.prototype.setMaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.ImportantInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.ImportantInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.ImportantInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.ImportantInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    fees: jspb.Message.getFieldWithDefault(msg, 1, ""),
    optionalFees: jspb.Message.getFieldWithDefault(msg, 2, ""),
    knowBeforeYouGo: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.ImportantInfo}
 */
proto.hotel.ImportantInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.ImportantInfo;
  return proto.hotel.ImportantInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.ImportantInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.ImportantInfo}
 */
proto.hotel.ImportantInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalFees(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKnowBeforeYouGo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.ImportantInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.ImportantInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.ImportantInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.ImportantInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptionalFees();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKnowBeforeYouGo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string fees = 1;
 * @return {string}
 */
proto.hotel.ImportantInfo.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.ImportantInfo} returns this
 */
proto.hotel.ImportantInfo.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string optional_fees = 2;
 * @return {string}
 */
proto.hotel.ImportantInfo.prototype.getOptionalFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.ImportantInfo} returns this
 */
proto.hotel.ImportantInfo.prototype.setOptionalFees = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string know_before_you_go = 3;
 * @return {string}
 */
proto.hotel.ImportantInfo.prototype.getKnowBeforeYouGo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.ImportantInfo} returns this
 */
proto.hotel.ImportantInfo.prototype.setKnowBeforeYouGo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubAmenity.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubAmenity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubAmenity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubAmenity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubAmenity}
 */
proto.hotel.HubAmenity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubAmenity;
  return proto.hotel.HubAmenity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubAmenity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubAmenity}
 */
proto.hotel.HubAmenity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubAmenity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubAmenity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubAmenity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubAmenity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.hotel.HubAmenity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubAmenity} returns this
 */
proto.hotel.HubAmenity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hotel.HubAmenity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubAmenity} returns this
 */
proto.hotel.HubAmenity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.hotel.HubAmenity.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubAmenity} returns this
 */
proto.hotel.HubAmenity.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HubGroupAmenity.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubGroupAmenity.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubGroupAmenity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubGroupAmenity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubGroupAmenity.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amenitiesList: jspb.Message.toObjectList(msg.getAmenitiesList(),
    proto.hotel.HubAmenity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubGroupAmenity}
 */
proto.hotel.HubGroupAmenity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubGroupAmenity;
  return proto.hotel.HubGroupAmenity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubGroupAmenity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubGroupAmenity}
 */
proto.hotel.HubGroupAmenity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 5:
      var value = new proto.hotel.HubAmenity;
      reader.readMessage(value,proto.hotel.HubAmenity.deserializeBinaryFromReader);
      msg.addAmenities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubGroupAmenity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubGroupAmenity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubGroupAmenity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubGroupAmenity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAmenitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.hotel.HubAmenity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.hotel.HubGroupAmenity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubGroupAmenity} returns this
 */
proto.hotel.HubGroupAmenity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string icon = 2;
 * @return {string}
 */
proto.hotel.HubGroupAmenity.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubGroupAmenity} returns this
 */
proto.hotel.HubGroupAmenity.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.hotel.HubGroupAmenity.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubGroupAmenity} returns this
 */
proto.hotel.HubGroupAmenity.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 rank = 4;
 * @return {number}
 */
proto.hotel.HubGroupAmenity.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubGroupAmenity} returns this
 */
proto.hotel.HubGroupAmenity.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated HubAmenity amenities = 5;
 * @return {!Array<!proto.hotel.HubAmenity>}
 */
proto.hotel.HubGroupAmenity.prototype.getAmenitiesList = function() {
  return /** @type{!Array<!proto.hotel.HubAmenity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubAmenity, 5));
};


/**
 * @param {!Array<!proto.hotel.HubAmenity>} value
 * @return {!proto.hotel.HubGroupAmenity} returns this
*/
proto.hotel.HubGroupAmenity.prototype.setAmenitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hotel.HubAmenity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubAmenity}
 */
proto.hotel.HubGroupAmenity.prototype.addAmenities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hotel.HubAmenity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubGroupAmenity} returns this
 */
proto.hotel.HubGroupAmenity.prototype.clearAmenitiesList = function() {
  return this.setAmenitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.BedType.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.BedType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.BedType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BedType.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.BedType}
 */
proto.hotel.BedType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.BedType;
  return proto.hotel.BedType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.BedType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.BedType}
 */
proto.hotel.BedType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.BedType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.BedType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.BedType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BedType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.hotel.BedType.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BedType} returns this
 */
proto.hotel.BedType.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.hotel.BedType.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BedType} returns this
 */
proto.hotel.BedType.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.PartnerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.PartnerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.PartnerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PartnerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.PartnerInfo}
 */
proto.hotel.PartnerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.PartnerInfo;
  return proto.hotel.PartnerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.PartnerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.PartnerInfo}
 */
proto.hotel.PartnerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.PartnerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.PartnerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.PartnerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PartnerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.hotel.PartnerInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PartnerInfo} returns this
 */
proto.hotel.PartnerInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string logo = 2;
 * @return {string}
 */
proto.hotel.PartnerInfo.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PartnerInfo} returns this
 */
proto.hotel.PartnerInfo.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.hotel.PartnerInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PartnerInfo} returns this
 */
proto.hotel.PartnerInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.hotel.PartnerInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PartnerInfo} returns this
 */
proto.hotel.PartnerInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.hotel.PartnerInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PartnerInfo} returns this
 */
proto.hotel.PartnerInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.PriceAdjustmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.PriceAdjustmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.PriceAdjustmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PriceAdjustmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustmentType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.PriceAdjustmentRequest}
 */
proto.hotel.PriceAdjustmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.PriceAdjustmentRequest;
  return proto.hotel.PriceAdjustmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.PriceAdjustmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.PriceAdjustmentRequest}
 */
proto.hotel.PriceAdjustmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.hotel.PriceAdjustmentType} */ (reader.readEnum());
      msg.setAdjustmentType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.PriceAdjustmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.PriceAdjustmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.PriceAdjustmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PriceAdjustmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional PriceAdjustmentType adjustment_type = 1;
 * @return {!proto.hotel.PriceAdjustmentType}
 */
proto.hotel.PriceAdjustmentRequest.prototype.getAdjustmentType = function() {
  return /** @type {!proto.hotel.PriceAdjustmentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.hotel.PriceAdjustmentType} value
 * @return {!proto.hotel.PriceAdjustmentRequest} returns this
 */
proto.hotel.PriceAdjustmentRequest.prototype.setAdjustmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.hotel.PriceAdjustmentRequest.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.PriceAdjustmentRequest} returns this
 */
proto.hotel.PriceAdjustmentRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.hotel.PriceAdjustmentRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PriceAdjustmentRequest} returns this
 */
proto.hotel.PriceAdjustmentRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.PriceAdjustmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.PriceAdjustmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.PriceAdjustmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PriceAdjustmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustmentType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adjustedPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.PriceAdjustmentResponse}
 */
proto.hotel.PriceAdjustmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.PriceAdjustmentResponse;
  return proto.hotel.PriceAdjustmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.PriceAdjustmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.PriceAdjustmentResponse}
 */
proto.hotel.PriceAdjustmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.hotel.PriceAdjustmentType} */ (reader.readEnum());
      msg.setAdjustmentType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdjustedPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.PriceAdjustmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.PriceAdjustmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.PriceAdjustmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PriceAdjustmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdjustedPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional PriceAdjustmentType adjustment_type = 1;
 * @return {!proto.hotel.PriceAdjustmentType}
 */
proto.hotel.PriceAdjustmentResponse.prototype.getAdjustmentType = function() {
  return /** @type {!proto.hotel.PriceAdjustmentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.hotel.PriceAdjustmentType} value
 * @return {!proto.hotel.PriceAdjustmentResponse} returns this
 */
proto.hotel.PriceAdjustmentResponse.prototype.setAdjustmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.hotel.PriceAdjustmentResponse.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.PriceAdjustmentResponse} returns this
 */
proto.hotel.PriceAdjustmentResponse.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.hotel.PriceAdjustmentResponse.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.PriceAdjustmentResponse} returns this
 */
proto.hotel.PriceAdjustmentResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double adjusted_price = 4;
 * @return {number}
 */
proto.hotel.PriceAdjustmentResponse.prototype.getAdjustedPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.PriceAdjustmentResponse} returns this
 */
proto.hotel.PriceAdjustmentResponse.prototype.setAdjustedPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double amount = 5;
 * @return {number}
 */
proto.hotel.PriceAdjustmentResponse.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.PriceAdjustmentResponse} returns this
 */
proto.hotel.PriceAdjustmentResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.SortItem.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.SortItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.SortItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.SortItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    desc: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.SortItem}
 */
proto.hotel.SortItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.SortItem;
  return proto.hotel.SortItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.SortItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.SortItem}
 */
proto.hotel.SortItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDesc(value);
      break;
    case 2:
      var value = /** @type {!proto.hotel.SortItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.SortItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.SortItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.SortItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.SortItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDesc();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bool desc = 1;
 * @return {boolean}
 */
proto.hotel.SortItem.prototype.getDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.SortItem} returns this
 */
proto.hotel.SortItem.prototype.setDesc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional SortItemType type = 2;
 * @return {!proto.hotel.SortItemType}
 */
proto.hotel.SortItem.prototype.getType = function() {
  return /** @type {!proto.hotel.SortItemType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.hotel.SortItemType} value
 * @return {!proto.hotel.SortItem} returns this
 */
proto.hotel.SortItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HotelFilterRequest.repeatedFields_ = [1,2,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HotelFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HotelFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HotelFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ratingsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    accommodationTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    amenitiesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    priceForNight: (f = msg.getPriceForNight()) && proto.hotel.PriceForNight.toObject(includeInstance, f),
    customerRatingsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    minDistanceToCenter: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxDistanceToCenter: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    petFriendly: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    breakfastIncluded: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HotelFilterRequest}
 */
proto.hotel.HotelFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HotelFilterRequest;
  return proto.hotel.HotelFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HotelFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HotelFilterRequest}
 */
proto.hotel.HotelFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRatings(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccommodationTypes(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAmenities(value);
      break;
    case 4:
      var value = new proto.hotel.PriceForNight;
      reader.readMessage(value,proto.hotel.PriceForNight.deserializeBinaryFromReader);
      msg.setPriceForNight(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.hotel.CustomerRating>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCustomerRatings(values[i]);
      }
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinDistanceToCenter(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxDistanceToCenter(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPetFriendly(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBreakfastIncluded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HotelFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HotelFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HotelFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRatingsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      1,
      f
    );
  }
  f = message.getAccommodationTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getAmenitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getPriceForNight();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.hotel.PriceForNight.serializeBinaryToWriter
    );
  }
  f = message.getCustomerRatingsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getMinDistanceToCenter();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMaxDistanceToCenter();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getPetFriendly();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getBreakfastIncluded();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * repeated float ratings = 1;
 * @return {!Array<number>}
 */
proto.hotel.HotelFilterRequest.prototype.getRatingsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.setRatingsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.addRatings = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.clearRatingsList = function() {
  return this.setRatingsList([]);
};


/**
 * repeated string accommodation_types = 2;
 * @return {!Array<string>}
 */
proto.hotel.HotelFilterRequest.prototype.getAccommodationTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.setAccommodationTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.addAccommodationTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.clearAccommodationTypesList = function() {
  return this.setAccommodationTypesList([]);
};


/**
 * repeated string amenities = 3;
 * @return {!Array<string>}
 */
proto.hotel.HotelFilterRequest.prototype.getAmenitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.setAmenitiesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.addAmenities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.clearAmenitiesList = function() {
  return this.setAmenitiesList([]);
};


/**
 * optional PriceForNight price_for_night = 4;
 * @return {?proto.hotel.PriceForNight}
 */
proto.hotel.HotelFilterRequest.prototype.getPriceForNight = function() {
  return /** @type{?proto.hotel.PriceForNight} */ (
    jspb.Message.getWrapperField(this, proto.hotel.PriceForNight, 4));
};


/**
 * @param {?proto.hotel.PriceForNight|undefined} value
 * @return {!proto.hotel.HotelFilterRequest} returns this
*/
proto.hotel.HotelFilterRequest.prototype.setPriceForNight = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.clearPriceForNight = function() {
  return this.setPriceForNight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelFilterRequest.prototype.hasPriceForNight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated CustomerRating customer_ratings = 5;
 * @return {!Array<!proto.hotel.CustomerRating>}
 */
proto.hotel.HotelFilterRequest.prototype.getCustomerRatingsList = function() {
  return /** @type {!Array<!proto.hotel.CustomerRating>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.hotel.CustomerRating>} value
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.setCustomerRatingsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.hotel.CustomerRating} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.addCustomerRatings = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.clearCustomerRatingsList = function() {
  return this.setCustomerRatingsList([]);
};


/**
 * optional float min_distance_to_center = 6;
 * @return {number}
 */
proto.hotel.HotelFilterRequest.prototype.getMinDistanceToCenter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.setMinDistanceToCenter = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float max_distance_to_center = 7;
 * @return {number}
 */
proto.hotel.HotelFilterRequest.prototype.getMaxDistanceToCenter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.setMaxDistanceToCenter = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool pet_friendly = 8;
 * @return {boolean}
 */
proto.hotel.HotelFilterRequest.prototype.getPetFriendly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.setPetFriendly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool breakfast_included = 9;
 * @return {boolean}
 */
proto.hotel.HotelFilterRequest.prototype.getBreakfastIncluded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.HotelFilterRequest} returns this
 */
proto.hotel.HotelFilterRequest.prototype.setBreakfastIncluded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.hotel.BookingStatus = {
  NONE: 0,
  SUCCESS: 1,
  PENDING: 2,
  FAILED: 3,
  CANCELLED: 4,
  DRAFT: 5,
  CONFIRMED: 6,
  REFUNDED: 7,
  EXPIRED: 8,
  IN_PROCESS: 9,
  CANCELING: 10
};

/**
 * @enum {number}
 */
proto.hotel.CustomerRating = {
  CUSTOMERRATINGNONE: 0,
  EXCELLENCE: 1,
  VERYGOOD: 2,
  GOOD: 3,
  ANY: 4
};

/**
 * @enum {number}
 */
proto.hotel.RegionType = {
  REGIONTYPENONE: 0,
  CONTINENT: 1,
  COUNTRY: 2,
  PROVINCESTATE: 3,
  HIGHLEVELREGION: 4,
  MULTICITYVICINITY: 5,
  CITY: 6,
  HEIGHBORHOOD: 7,
  AIRPORT: 8,
  POINTOFINTEREST: 9,
  TRAINSTATION: 10,
  METROSTATION: 11,
  BUSSTATION: 12,
  HOTEL: 13
};

/**
 * @enum {number}
 */
proto.hotel.PriceAdjustmentType = {
  ADJUSTMENT_TYPE_UNSPECIFIED: 0,
  PERCENT: 1,
  AMOUNT: 2
};

/**
 * @enum {number}
 */
proto.hotel.SortItemType = {
  SORT_ITEM_TYPE_NONE: 0,
  SORT_ITEM_TYPE_PRICE: 1,
  SORT_ITEM_TYPE_RATING: 2,
  SORT_ITEM_TYPE_DISTANCEFROMCENTER: 3
};

/**
 * @enum {number}
 */
proto.hotel.InvoiceIssuedStatus = {
  INVOICE_ISSUED_STATUS_NONE: 0,
  INVOICE_ISSUED_STATUS_NOT_ISSUED: 1,
  INVOICE_ISSUED_STATUS_PENDING: 2,
  INVOICE_ISSUED_STATUS_ISSUED: 3
};

goog.object.extend(exports, proto.hotel);
