import { BaseApi, BaseApiOptions } from '@core/grpc-client';
import { PartnerAgentGroupServiceClient } from '@api/partner/web_partner/partner_agent_group_grpc_web_pb';
import {
  GetAgentGroupsReq,
  GetAgentGroupsRes,
  CreateAgentGroupReq,
  GetAgentGroupsReqFilter,
  UpdateAgentGroupReq,
  PartnerAgentGroupCommission,
  PartnerAgentGroupContact,
  GetAgentGroupDetailReq,
  GetAgentGroupDetailRes,
  DepositGroupBalanceReq,
  GetGroupTransactionHistoriesReq,
  GetGroupTransactionHistoriesRes,
  GetGroupTransactionHistoriesReqFilter,
} from '@api/partner/web_partner/partner_agent_group_pb';
import { BasicRes, OrderByItem, PaginationReq } from '@api/base/base_pb';

class PartnerAgentGroupApi extends BaseApi<PartnerAgentGroupServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerAgentGroupServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getAgentGroups = ({
    filter,
    pagination,
    orderByList,
  }: GetAgentGroupsReq.AsObject): Promise<GetAgentGroupsRes.AsObject> => {
    const req = new GetAgentGroupsReq();

    if (filter) {
      const filterObj = new GetAgentGroupsReqFilter();
      filterObj.setCode(filter.code || '');
      filterObj.setQuery(filter.query || '');
      filterObj.setStatus(filter.status);
      filterObj.setShowBalance(filter.showBalance);
      req.setFilter(filterObj);
    }

    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
      req.setPagination(paginationObj);
    }
    if (orderByList) {
      const orderByListObj: OrderByItem[] = orderByList.map((item: any) => {
        const orderByItem = new OrderByItem();
        orderByItem.setField(item.field);
        orderByItem.setDesc(item.desc ?? false);
        return orderByItem;
      });
      req.setOrderByList(orderByListObj);
    }
    return this.grpc<
      GetAgentGroupsReq,
      GetAgentGroupsRes,
      GetAgentGroupsRes.AsObject
    >(this.serviceClient.getAgentGroups, req, {});
  };

  createAgentGroup = ({
    username,
    password,
    contactInfo,
    name,
    commission,
    status,
    logo,
  }: CreateAgentGroupReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new CreateAgentGroupReq();
    req.setUsername(username);
    req.setPassword(password);
    req.setName(name);
    req.setStatus(status);
    req.setLogo(logo);
    if (contactInfo) {
      const contactInfoObject = new PartnerAgentGroupContact();
      contactInfoObject.setName(contactInfo.name);
      contactInfoObject.setEmail(contactInfo.email);
      contactInfoObject.setPhone(contactInfo.phone);
      contactInfoObject.setPhoneCode(contactInfo.phoneCode);
      contactInfoObject.setPhoneNumber(contactInfo.phoneNumber);
      req.setContactInfo(contactInfoObject);
    }
    if (commission) {
      const commissionObj = new PartnerAgentGroupCommission();
      commissionObj.setAmount(commission.amount);
      commissionObj.setPercent(commission.percent);
      req.setCommission(commissionObj);
    }
    return this.grpc<CreateAgentGroupReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.createAgentGroup,
      req,
      {}
    );
  };

  updateAgentGroup = ({
    contactInfo,
    name,
    commission,
    status,
    code,
    logo,
  }: UpdateAgentGroupReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new UpdateAgentGroupReq();
    req.setCode(code);
    req.setName(name);
    req.setStatus(status);
    logo && req.setLogo(logo);
    if (contactInfo) {
      const contactInfoObject = new PartnerAgentGroupContact();
      contactInfoObject.setName(contactInfo.name);
      contactInfoObject.setEmail(contactInfo.email);
      contactInfoObject.setPhone(contactInfo.phone);
      contactInfoObject.setPhoneCode(contactInfo.phoneCode);
      contactInfoObject.setPhoneNumber(contactInfo.phoneNumber);
      req.setContactInfo(contactInfoObject);
    }
    if (commission) {
      const commissionObj = new PartnerAgentGroupCommission();
      commissionObj.setAmount(commission.amount);
      commissionObj.setPercent(commission.percent);
      req.setCommission(commissionObj);
    }

    return this.grpc<UpdateAgentGroupReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateAgentGroup,
      req,
      {}
    );
  };

  getAgentGroupDetail = ({
    code,
    showBalance,
  }: GetAgentGroupDetailReq.AsObject): Promise<GetAgentGroupDetailRes.AsObject> => {
    const req = new GetAgentGroupDetailReq();
    code && req.setCode(code);
    showBalance && req.setShowBalance(showBalance);
    return this.grpc<
      GetAgentGroupDetailReq,
      GetAgentGroupDetailRes,
      GetAgentGroupDetailRes.AsObject
    >(this.serviceClient.getAgentGroupDetail, req, {});
  };

  depositGroupBalance = ({
    code,
    depositAmount,
  }: DepositGroupBalanceReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new DepositGroupBalanceReq();
    code && req.setCode(code);
    depositAmount && req.setDepositAmount(depositAmount);
    return this.grpc<DepositGroupBalanceReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.depositGroupBalance,
      req,
      {}
    );
  };

  getGroupTransactionHistories = ({
    filter,
    pagination,
  }: GetGroupTransactionHistoriesReq.AsObject): Promise<GetGroupTransactionHistoriesRes.AsObject> => {
    const req = new GetGroupTransactionHistoriesReq();

    if (filter) {
      const filterObj = new GetGroupTransactionHistoriesReqFilter();
      filterObj.setCode(filter.code);
      filterObj.setTransactionType(filter.transactionType);
      req.setFilter(filterObj);
    }

    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
      req.setPagination(paginationObj);
    }
    return this.grpc<
      GetGroupTransactionHistoriesReq,
      GetGroupTransactionHistoriesRes,
      GetGroupTransactionHistoriesRes.AsObject
    >(this.serviceClient.getGroupTransactionHistories, req, {});
  };
}

export const partnerAgentGroupServiceClient = new PartnerAgentGroupApi();
export default partnerAgentGroupServiceClient;
