import {
  AddCircleIcon,
  Button,
  Form,
  FormSelect,
  FormSelectSearch,
  SearchLineIcon,
} from '@common-ui';
import { IFlightServiceFee } from '@tixlabs/grpc-client';

import { useServiceFeeSetting } from '@access-trade-admin/hooks/apps';
import {
  DEFAULT_MODAL_CONTENT,
  OPTION_SERVICE_FEE,
} from '@access-trade-admin/utils';

import { useMemo, useState } from 'react';
import {
  ESwitchModal,
  ServiceFeeModal,
  TModalServiceFee,
} from './ServiceFeeModal';
import TableServiceFee from './TableServiceFee';

type Props = {
  //
};

const DEFAULT_MODAL_INFO: TModalServiceFee = {
  ...DEFAULT_MODAL_CONTENT,
  state: ESwitchModal.NONE,
};

export const ListServiceFee = (props: Props) => {
  const [modalInfo, setModalInfo] =
    useState<TModalServiceFee>(DEFAULT_MODAL_INFO);
  const {
    listServiceFee,
    handleAddFlightServiceFee,
    handleUpdateFlightServiceFee,
    airlineOptions,
    airlineObjectByCode,
    pagination,
    filterFormMethods,
    onChangePage,
    handleSearch,
    isLoading,
    deleteFlightServiceFee,
  } = useServiceFeeSetting();

  async function handleSubmit(data: IFlightServiceFee) {
    switch (modalInfo.state) {
      case ESwitchModal.ADD_SERVICE_FEE:
        return handleAddFlightServiceFee(data);

      case ESwitchModal.UPDATE_SERVICE_FEE:
        return handleUpdateFlightServiceFee(data);

      default:
        break;
    }
  }

  const airlineOptionsWithAll = useMemo(
    () => [
      {
        label: 'Tất cả',
        value: '',
      },
      ...airlineOptions,
    ],
    [airlineOptions]
  );
  return (
    <div className='p-3 border rounded space-y-5 bg-white'>
      <div className='py-5 flex border-b justify-between'>
        <Form
          methods={filterFormMethods}
          onSubmit={() => {
            onChangePage(1);
          }}
          className='flex justify-between w-full'>
          <div className='flex space-x-2.5'>
            <FormSelect
              className='min-w-[200px]'
              name='type'
              selectOptions={OPTION_SERVICE_FEE}
              label='Loại chuyến bay'
              placeholder='Chọn loại chuyến bay'
              inputProps={{ isOnlyValue: true, isAllowUncheck: true }}
            />
            <FormSelectSearch
              name='airlineCode'
              label='Hãng hàng không'
              placeholder='Chọn hãng hàng không'
              handleSearch={handleSearch}
              selectOptions={airlineOptionsWithAll}
              labelProps={{ isRequired: true }}
              inputProps={{
                isOnlyValue: true,
              }}
            />

            <div className='flex flex-col justify-end'>
              <Button
                type='submit'
                theme='secondary'
                prefixIcon={<SearchLineIcon />}
                className='font-semibold'>
                Tìm kiếm
              </Button>
            </div>
          </div>
          <div className='flex items-end'>
            <Button
              type='button'
              onClick={() =>
                setModalInfo((pre) => ({
                  ...pre,
                  open: true,
                  state: ESwitchModal.ADD_SERVICE_FEE,
                  closeIcon: true,
                }))
              }
              theme='green'
              prefixIcon={<AddCircleIcon className='w-5 h-5 shrink-0' />}>
              Thêm mới
            </Button>
          </div>
        </Form>
      </div>

      <TableServiceFee
        data={listServiceFee}
        handleUpdateModalInfo={setModalInfo}
        airlineObjectByCode={airlineObjectByCode}
        pagination={{
          pageSize: pagination.pageLimit,
          current: pagination.pageCurrent,
          total: pagination.totalRecord,
          className: 'space-x-2.5',
          onChange: onChangePage,
        }}
        isLoading={isLoading}
      />
      <ServiceFeeModal
        handleSearch={handleSearch}
        handleSubmit={handleSubmit}
        modalInfo={modalInfo}
        handleUpdateModalInfo={setModalInfo}
        airlineOptions={airlineOptionsWithAll}
        airlineObjectByCode={airlineObjectByCode}
        handleDelete={deleteFlightServiceFee}
      />
    </div>
  );
};

export default ListServiceFee;
