/**
 * @fileoverview gRPC-Web generated client stub for hotel.web_partner
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: hotel/web_partner/hotel.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var hotel_booking_pb = require('../../hotel/booking_pb.js')

var base_base_pb = require('../../base/base_pb.js')

var hotel_base_pb = require('../../hotel/base_pb.js')

var hotel_hotel_pb = require('../../hotel/hotel_pb.js')

var hotel_booking_selection_pb = require('../../hotel/booking_selection_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.hotel = {};
proto.hotel.web_partner = require('./hotel_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hotel.web_partner.HotelServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hotel.web_partner.HotelServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.SearchHotelReq,
 *   !proto.hotel.web_partner.SearchHotelRes>}
 */
const methodDescriptor_HotelService_SearchHotel = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/SearchHotel',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.SearchHotelReq,
  proto.hotel.web_partner.SearchHotelRes,
  /**
   * @param {!proto.hotel.web_partner.SearchHotelReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.SearchHotelRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.SearchHotelReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.SearchHotelRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.SearchHotelRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.searchHotel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/SearchHotel',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchHotel,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.SearchHotelReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.SearchHotelRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.searchHotel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/SearchHotel',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchHotel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.SearchDestinationsReq,
 *   !proto.hotel.web_partner.SearchDestinationsRes>}
 */
const methodDescriptor_HotelService_SearchDestinations = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/SearchDestinations',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.SearchDestinationsReq,
  proto.hotel.web_partner.SearchDestinationsRes,
  /**
   * @param {!proto.hotel.web_partner.SearchDestinationsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.SearchDestinationsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.SearchDestinationsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.SearchDestinationsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.SearchDestinationsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.searchDestinations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/SearchDestinations',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchDestinations,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.SearchDestinationsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.SearchDestinationsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.searchDestinations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/SearchDestinations',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchDestinations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetHotelInfoReq,
 *   !proto.hotel.web_partner.GetHotelInfoRes>}
 */
const methodDescriptor_HotelService_GetHotelInfo = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetHotelInfo',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetHotelInfoReq,
  proto.hotel.web_partner.GetHotelInfoRes,
  /**
   * @param {!proto.hotel.web_partner.GetHotelInfoReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetHotelInfoRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetHotelInfoReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetHotelInfoRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetHotelInfoRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getHotelInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetHotelInfo',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelInfo,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetHotelInfoReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetHotelInfoRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getHotelInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetHotelInfo',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetHotelRoomDetailsReq,
 *   !proto.hotel.web_partner.GetHotelRoomDetailsRes>}
 */
const methodDescriptor_HotelService_GetHotelRoomDetails = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetHotelRoomDetails',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetHotelRoomDetailsReq,
  proto.hotel.web_partner.GetHotelRoomDetailsRes,
  /**
   * @param {!proto.hotel.web_partner.GetHotelRoomDetailsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetHotelRoomDetailsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetHotelRoomDetailsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetHotelRoomDetailsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetHotelRoomDetailsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getHotelRoomDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetHotelRoomDetails',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelRoomDetails,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetHotelRoomDetailsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetHotelRoomDetailsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getHotelRoomDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetHotelRoomDetails',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelRoomDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetRecommendedHotelsReq,
 *   !proto.hotel.web_partner.GetRecommendedHotelsRes>}
 */
const methodDescriptor_HotelService_GetRecommendedHotels = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetRecommendedHotels',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetRecommendedHotelsReq,
  proto.hotel.web_partner.GetRecommendedHotelsRes,
  /**
   * @param {!proto.hotel.web_partner.GetRecommendedHotelsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetRecommendedHotelsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetRecommendedHotelsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetRecommendedHotelsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetRecommendedHotelsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getRecommendedHotels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetRecommendedHotels',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetRecommendedHotels,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetRecommendedHotelsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetRecommendedHotelsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getRecommendedHotels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetRecommendedHotels',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetRecommendedHotels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetHotelReviewsReq,
 *   !proto.hotel.web_partner.GetHotelReviewsRes>}
 */
const methodDescriptor_HotelService_GetHotelReviews = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetHotelReviews',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetHotelReviewsReq,
  proto.hotel.web_partner.GetHotelReviewsRes,
  /**
   * @param {!proto.hotel.web_partner.GetHotelReviewsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetHotelReviewsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetHotelReviewsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetHotelReviewsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetHotelReviewsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getHotelReviews =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetHotelReviews',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelReviews,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetHotelReviewsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetHotelReviewsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getHotelReviews =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetHotelReviews',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelReviews);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetBookingDetailRequest,
 *   !proto.hotel.web_partner.GetBookingDetailResponse>}
 */
const methodDescriptor_HotelService_GetBookingDetail = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetBookingDetail',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetBookingDetailRequest,
  proto.hotel.web_partner.GetBookingDetailResponse,
  /**
   * @param {!proto.hotel.web_partner.GetBookingDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetBookingDetailResponse.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetBookingDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetBookingDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetBookingDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getBookingDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetBookingDetail',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingDetail,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetBookingDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetBookingDetailResponse>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getBookingDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetBookingDetail',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetBookingDetailByTokenReq,
 *   !proto.hotel.web_partner.GetBookingDetailResponse>}
 */
const methodDescriptor_HotelService_GetBookingDetailByToken = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetBookingDetailByToken',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetBookingDetailByTokenReq,
  proto.hotel.web_partner.GetBookingDetailResponse,
  /**
   * @param {!proto.hotel.web_partner.GetBookingDetailByTokenReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetBookingDetailResponse.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetBookingDetailByTokenReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetBookingDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetBookingDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getBookingDetailByToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetBookingDetailByToken',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingDetailByToken,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetBookingDetailByTokenReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetBookingDetailResponse>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getBookingDetailByToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetBookingDetailByToken',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingDetailByToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.ListBookingsRequest,
 *   !proto.hotel.web_partner.ListBookingsResponse>}
 */
const methodDescriptor_HotelService_ListBookings = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/ListBookings',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.ListBookingsRequest,
  proto.hotel.web_partner.ListBookingsResponse,
  /**
   * @param {!proto.hotel.web_partner.ListBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.ListBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.ListBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.ListBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.ListBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.listBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ListBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListBookings,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.ListBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.ListBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.listBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ListBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.ExportBookingsReq,
 *   !proto.hotel.web_partner.ExportBookingsRes>}
 */
const methodDescriptor_HotelService_ExportBookings = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/ExportBookings',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.ExportBookingsReq,
  proto.hotel.web_partner.ExportBookingsRes,
  /**
   * @param {!proto.hotel.web_partner.ExportBookingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.ExportBookingsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.ExportBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.ExportBookingsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.ExportBookingsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.exportBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ExportBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ExportBookings,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.ExportBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.ExportBookingsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.exportBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ExportBookings',
      request,
      metadata || {},
      methodDescriptor_HotelService_ExportBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.AddBookingContactInfoReq,
 *   !proto.hotel.web_partner.AddBookingContactInfoRes>}
 */
const methodDescriptor_HotelService_AddBookingContactInfo = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/AddBookingContactInfo',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.AddBookingContactInfoReq,
  proto.hotel.web_partner.AddBookingContactInfoRes,
  /**
   * @param {!proto.hotel.web_partner.AddBookingContactInfoReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.AddBookingContactInfoRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.AddBookingContactInfoReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.AddBookingContactInfoRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.AddBookingContactInfoRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.addBookingContactInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/AddBookingContactInfo',
      request,
      metadata || {},
      methodDescriptor_HotelService_AddBookingContactInfo,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.AddBookingContactInfoReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.AddBookingContactInfoRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.addBookingContactInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/AddBookingContactInfo',
      request,
      metadata || {},
      methodDescriptor_HotelService_AddBookingContactInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.OrderBookingReq,
 *   !proto.hotel.web_partner.OrderBookingRes>}
 */
const methodDescriptor_HotelService_OrderBooking = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/OrderBooking',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.OrderBookingReq,
  proto.hotel.web_partner.OrderBookingRes,
  /**
   * @param {!proto.hotel.web_partner.OrderBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.OrderBookingRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.OrderBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.OrderBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.OrderBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.orderBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/OrderBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_OrderBooking,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.OrderBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.OrderBookingRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.orderBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/OrderBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_OrderBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.RetrieveBookingReq,
 *   !proto.hotel.web_partner.RetrieveBookingRes>}
 */
const methodDescriptor_HotelService_RetrieveBooking = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/RetrieveBooking',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.RetrieveBookingReq,
  proto.hotel.web_partner.RetrieveBookingRes,
  /**
   * @param {!proto.hotel.web_partner.RetrieveBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.RetrieveBookingRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.RetrieveBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.RetrieveBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.RetrieveBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.retrieveBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/RetrieveBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_RetrieveBooking,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.RetrieveBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.RetrieveBookingRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.retrieveBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/RetrieveBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_RetrieveBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.ReserveBookingReq,
 *   !proto.hotel.web_partner.ReserveBookingRes>}
 */
const methodDescriptor_HotelService_ReserveBooking = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/ReserveBooking',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.ReserveBookingReq,
  proto.hotel.web_partner.ReserveBookingRes,
  /**
   * @param {!proto.hotel.web_partner.ReserveBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.ReserveBookingRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.ReserveBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.ReserveBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.ReserveBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.reserveBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ReserveBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_ReserveBooking,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.ReserveBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.ReserveBookingRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.reserveBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ReserveBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_ReserveBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.CancelBookingReq,
 *   !proto.hotel.web_partner.CancelBookingRes>}
 */
const methodDescriptor_HotelService_CancelBooking = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/CancelBooking',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.CancelBookingReq,
  proto.hotel.web_partner.CancelBookingRes,
  /**
   * @param {!proto.hotel.web_partner.CancelBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.CancelBookingRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.CancelBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.CancelBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.CancelBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.cancelBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/CancelBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_CancelBooking,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.CancelBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.CancelBookingRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.cancelBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/CancelBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_CancelBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.PriceCheckReq,
 *   !proto.hotel.web_partner.PriceCheckRes>}
 */
const methodDescriptor_HotelService_PriceCheck = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/PriceCheck',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.PriceCheckReq,
  proto.hotel.web_partner.PriceCheckRes,
  /**
   * @param {!proto.hotel.web_partner.PriceCheckReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.PriceCheckRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.PriceCheckReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.PriceCheckRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.PriceCheckRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.priceCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/PriceCheck',
      request,
      metadata || {},
      methodDescriptor_HotelService_PriceCheck,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.PriceCheckReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.PriceCheckRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.priceCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/PriceCheck',
      request,
      metadata || {},
      methodDescriptor_HotelService_PriceCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetStaticBookingBySessionReq,
 *   !proto.hotel.web_partner.GetStaticBookingBySessionRes>}
 */
const methodDescriptor_HotelService_GetStaticBookingBySession = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetStaticBookingBySession',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetStaticBookingBySessionReq,
  proto.hotel.web_partner.GetStaticBookingBySessionRes,
  /**
   * @param {!proto.hotel.web_partner.GetStaticBookingBySessionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetStaticBookingBySessionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetStaticBookingBySessionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetStaticBookingBySessionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetStaticBookingBySessionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getStaticBookingBySession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetStaticBookingBySession',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetStaticBookingBySession,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetStaticBookingBySessionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetStaticBookingBySessionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getStaticBookingBySession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetStaticBookingBySession',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetStaticBookingBySession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.ReserveBookingSelectionReq,
 *   !proto.hotel.web_partner.ReserveBookingSelectionRes>}
 */
const methodDescriptor_HotelService_ReserveBookingSelection = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/ReserveBookingSelection',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.ReserveBookingSelectionReq,
  proto.hotel.web_partner.ReserveBookingSelectionRes,
  /**
   * @param {!proto.hotel.web_partner.ReserveBookingSelectionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.ReserveBookingSelectionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.ReserveBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.ReserveBookingSelectionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.ReserveBookingSelectionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.reserveBookingSelection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ReserveBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_ReserveBookingSelection,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.ReserveBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.ReserveBookingSelectionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.reserveBookingSelection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ReserveBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_ReserveBookingSelection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.UpsertBookingSelectionReq,
 *   !proto.hotel.web_partner.UpsertBookingSelectionRes>}
 */
const methodDescriptor_HotelService_UpsertBookingSelection = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/UpsertBookingSelection',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.UpsertBookingSelectionReq,
  proto.hotel.web_partner.UpsertBookingSelectionRes,
  /**
   * @param {!proto.hotel.web_partner.UpsertBookingSelectionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.UpsertBookingSelectionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.UpsertBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.UpsertBookingSelectionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.UpsertBookingSelectionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.upsertBookingSelection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/UpsertBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpsertBookingSelection,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.UpsertBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.UpsertBookingSelectionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.upsertBookingSelection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/UpsertBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpsertBookingSelection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.ListBookingSelectionReq,
 *   !proto.hotel.web_partner.ListBookingSelectionRes>}
 */
const methodDescriptor_HotelService_ListBookingSelection = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/ListBookingSelection',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.ListBookingSelectionReq,
  proto.hotel.web_partner.ListBookingSelectionRes,
  /**
   * @param {!proto.hotel.web_partner.ListBookingSelectionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.ListBookingSelectionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.ListBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.ListBookingSelectionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.ListBookingSelectionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.listBookingSelection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ListBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListBookingSelection,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.ListBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.ListBookingSelectionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.listBookingSelection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/ListBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_ListBookingSelection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetBookingSelectionReq,
 *   !proto.hotel.web_partner.GetBookingSelectionRes>}
 */
const methodDescriptor_HotelService_GetBookingSelection = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetBookingSelection',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetBookingSelectionReq,
  proto.hotel.web_partner.GetBookingSelectionRes,
  /**
   * @param {!proto.hotel.web_partner.GetBookingSelectionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetBookingSelectionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetBookingSelectionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetBookingSelectionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getBookingSelection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingSelection,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetBookingSelectionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getBookingSelection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingSelection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.DeleteBookingSelectionReq,
 *   !proto.hotel.web_partner.DeleteBookingSelectionRes>}
 */
const methodDescriptor_HotelService_DeleteBookingSelection = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/DeleteBookingSelection',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.DeleteBookingSelectionReq,
  proto.hotel.web_partner.DeleteBookingSelectionRes,
  /**
   * @param {!proto.hotel.web_partner.DeleteBookingSelectionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.DeleteBookingSelectionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.DeleteBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.DeleteBookingSelectionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.DeleteBookingSelectionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.deleteBookingSelection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/DeleteBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_DeleteBookingSelection,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.DeleteBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.DeleteBookingSelectionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.deleteBookingSelection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/DeleteBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_DeleteBookingSelection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.DeleteRateSelectionReq,
 *   !proto.hotel.web_partner.DeleteRateSelectionRes>}
 */
const methodDescriptor_HotelService_DeleteRateSelection = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/DeleteRateSelection',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.DeleteRateSelectionReq,
  proto.hotel.web_partner.DeleteRateSelectionRes,
  /**
   * @param {!proto.hotel.web_partner.DeleteRateSelectionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.DeleteRateSelectionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.DeleteRateSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.DeleteRateSelectionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.DeleteRateSelectionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.deleteRateSelection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/DeleteRateSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_DeleteRateSelection,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.DeleteRateSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.DeleteRateSelectionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.deleteRateSelection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/DeleteRateSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_DeleteRateSelection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.UpdateBookingSelectionReq,
 *   !proto.hotel.web_partner.UpdateBookingSelectionRes>}
 */
const methodDescriptor_HotelService_UpdateBookingSelection = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/UpdateBookingSelection',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.UpdateBookingSelectionReq,
  proto.hotel.web_partner.UpdateBookingSelectionRes,
  /**
   * @param {!proto.hotel.web_partner.UpdateBookingSelectionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.UpdateBookingSelectionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.UpdateBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.UpdateBookingSelectionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.UpdateBookingSelectionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.updateBookingSelection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/UpdateBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpdateBookingSelection,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.UpdateBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.UpdateBookingSelectionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.updateBookingSelection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/UpdateBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpdateBookingSelection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.hotel.web_partner.GetListCARes>}
 */
const methodDescriptor_HotelService_GetListCA = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetListCA',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  proto.hotel.web_partner.GetListCARes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetListCARes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetListCARes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetListCARes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getListCA =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetListCA',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetListCA,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetListCARes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getListCA =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetListCA',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetListCA);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetRevenueReportReq,
 *   !proto.hotel.web_partner.GetRevenueReportRes>}
 */
const methodDescriptor_HotelService_GetRevenueReport = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetRevenueReport',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetRevenueReportReq,
  proto.hotel.web_partner.GetRevenueReportRes,
  /**
   * @param {!proto.hotel.web_partner.GetRevenueReportReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetRevenueReportRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetRevenueReportReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetRevenueReportRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetRevenueReportRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getRevenueReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetRevenueReport',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetRevenueReport,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetRevenueReportReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetRevenueReportRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getRevenueReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetRevenueReport',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetRevenueReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.UpdateBookingReq,
 *   !proto.hotel.web_partner.UpdateBookingRes>}
 */
const methodDescriptor_HotelService_UpdateBooking = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/UpdateBooking',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.UpdateBookingReq,
  proto.hotel.web_partner.UpdateBookingRes,
  /**
   * @param {!proto.hotel.web_partner.UpdateBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.UpdateBookingRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.UpdateBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.UpdateBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.UpdateBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.updateBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpdateBooking,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.UpdateBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.UpdateBookingRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.updateBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_UpdateBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web_partner.GetIssueInvoiceDetailReq,
 *   !proto.hotel.web_partner.GetIssueInvoiceDetailRes>}
 */
const methodDescriptor_HotelService_GetIssueInvoiceDetail = new grpc.web.MethodDescriptor(
  '/hotel.web_partner.HotelService/GetIssueInvoiceDetail',
  grpc.web.MethodType.UNARY,
  proto.hotel.web_partner.GetIssueInvoiceDetailReq,
  proto.hotel.web_partner.GetIssueInvoiceDetailRes,
  /**
   * @param {!proto.hotel.web_partner.GetIssueInvoiceDetailReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web_partner.GetIssueInvoiceDetailRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web_partner.GetIssueInvoiceDetailReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web_partner.GetIssueInvoiceDetailRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web_partner.GetIssueInvoiceDetailRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web_partner.HotelServiceClient.prototype.getIssueInvoiceDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetIssueInvoiceDetail',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetIssueInvoiceDetail,
      callback);
};


/**
 * @param {!proto.hotel.web_partner.GetIssueInvoiceDetailReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web_partner.GetIssueInvoiceDetailRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web_partner.HotelServicePromiseClient.prototype.getIssueInvoiceDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web_partner.HotelService/GetIssueInvoiceDetail',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetIssueInvoiceDetail);
};


module.exports = proto.hotel.web_partner;

