import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { PaginationReq } from '@api/base/base_pb';
import { ListTransactionReq, ListTransactionRes } from '@api/wallet/web_partnership/wallet_pb';
import { WalletServiceClient } from '@api/wallet/web_partnership/wallet_grpc_web_pb';
import { Filter } from '@api/wallet/transaction_pb';
import { ExportTransactionRes, ExportTransactionReq } from '@api/wallet/web_partnership/wallet_pb';

class WalletTransactionApi extends BaseApi<WalletServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new WalletServiceClient(this.url, null, this.optsDev);
  }

  listTransaction = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListTransactionReq.AsObject): Promise<ListTransactionRes.AsObject> => {
    const req = new ListTransactionReq();
    const filterObj = new Filter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.bookerId && filterObj.setBookerId(filter.bookerId?.trim() || '');
      filter.partnerShopId &&
        filterObj.setPartnerShopId(filter.partnerShopId?.trim() || '');
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.referenceCode &&
        filterObj.setReferenceCode(filter.referenceCode?.trim() || '');
      filterObj.setTransactionType(filter.transactionType);
      filter.transactionSource &&
        filterObj.setTransactionSource(filter.transactionSource);
      filter.productType &&
        filterObj.setProductType(filter.productType);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    return this.grpc<
      ListTransactionReq,
      ListTransactionRes,
      ListTransactionRes.AsObject
    >(this.serviceClient?.listTransaction, req, {});
  };

  exportTransactions = ({
    filter,
  }: ExportTransactionReq.AsObject): Promise<ExportTransactionRes.AsObject> => {
    const req = new ExportTransactionReq();
    const filterObj = new Filter();

    if (filter) {
      filter.bookerId && filterObj.setBookerId(filter.bookerId);
      filter.partnerShopId && filterObj.setPartnerShopId(filter.partnerShopId);
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.referenceCode &&
        filterObj.setReferenceCode(filter.referenceCode?.trim() || '');
      filterObj.setTransactionType(filter.transactionType);
      filter.transactionSource &&
        filterObj.setTransactionSource(filter.transactionSource);
      filter.productType &&
        filterObj.setProductType(filter.productType);
    }

    req.setFilter(filterObj);

    return this.grpc<
      ExportTransactionReq,
      ExportTransactionRes,
      ExportTransactionRes.AsObject
    >(this.serviceClient?.exportTransactions, req, {});
  };
}

export const walletTransactionApiService = new WalletTransactionApi();
export default walletTransactionApiService;
