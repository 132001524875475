import { IBookingFlightPartialRoundtripRes } from '@tixlabs/grpc-client/web-partnership';
import React from 'react';
import { PassengerInfoItemCoordination } from './PassengerInfoItemCoordination';

type Props = {
  bookingFlight: IBookingFlightPartialRoundtripRes;
};

export const PassengerInfoListCoordination = ({ bookingFlight }: Props) => {
  return (
    <div className='flex flex-col rounded-lg bg-white'>
      <h3 className='text-lg font-semibold py-3 px-4 mb-0'>Hành khách</h3>
      {bookingFlight.itinerariesList.map((itinerary, index) => {
        return (
          <PassengerInfoItemCoordination
            key={index}
            passengersList={bookingFlight.passengersList}
          />
        );
      })}
    </div>
  );
};
