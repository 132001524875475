import { Button, FileExcelLineIcon, Form, FormSelect } from '@common-ui';
import { useExportData } from '@core/hooks';
import {
  ICrossCheckItem,
  IFilterReportCrossCheckReq,
  reportService,
} from '@tixlabs/grpc-client/web-partnership';
import {
  useAppMutation,
  useCurrency,
  useTime,
} from '@access-trade-admin/hooks/internals';
import { useUserStore } from '@access-trade-admin/hooks/stores';
import { ONE_YEAR_TIMESTAMP } from '@access-trade-admin/utils';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CrossCheckStats from './CrossCheckStats';
import TableContent from './TableContent';

type Props = {
  //
};

export const CrossCheckCtn = (props: Props) => {
  const { formatMonthYear, formatDateCustom, formatDate } = useTime();

  const { userData } = useUserStore();

  const { formatCurrency } = useCurrency();

  const OPTION_DATE =
    Date.now() - (userData?.createdAt || 0) > 5 * ONE_YEAR_TIMESTAMP
      ? [1, 2, 3, 4, 5].map((_, index) => ({
          label: formatDateCustom('YYYY')(
            Date.now() - index * ONE_YEAR_TIMESTAMP
          ),
          value: new Date(
            Date.now() - index * ONE_YEAR_TIMESTAMP
          ).getFullYear(),
        }))
      : new Array(
          Math.floor(
            (Date.now() - (userData?.createdAt || 0)) / ONE_YEAR_TIMESTAMP
          ) === 0
            ? 1
            : Math.floor(
                (Date.now() - (userData?.createdAt || 0)) / ONE_YEAR_TIMESTAMP
              )
        )
          .fill(0)
          .map((_, index) => ({
            label: formatDateCustom('YYYY')(
              Date.now() - index * ONE_YEAR_TIMESTAMP
            ),
            value: new Date(
              Date.now() - index * ONE_YEAR_TIMESTAMP
            ).getFullYear(),
          }));

  const methods = useForm<IFilterReportCrossCheckReq>({
    defaultValues: {
      date: new Date().getFullYear(),
    },
  });

  const { handleExport, isProcessing } = useExportData<ICrossCheckItem>({
    columns: [
      {
        name: 'Kỳ đối soát',
        key: 'createdAt',
        formatValue: (_, crossCheckItem) => {
          return formatMonthYear(crossCheckItem.date);
        },
      },
      {
        name: 'Tổng phí dịch vụ (2)',
        key: 'commissionArise',
        formatValue: (_, crossCheckItem) => {
          return formatCurrency(crossCheckItem.serviceFee);
        },
      },
      {
        name: 'Tổng Hoa hồng đã xác nhận (3)',
        key: 'approved',
        formatValue: (_, crossCheckItem) => {
          return formatCurrency(crossCheckItem.approved);
        },
      },
      {
        name: 'Tổng lợi nhuận (4)= (2)+(3)',
        key: 'temporaryReview',
        formatValue: (_, crossCheckItem) => {
          return formatCurrency(crossCheckItem.profit);
        },
      },
    ],
    filename: `crosscheck_${formatDate(Date.now())}`,
  });

  const [listCrossCheckItem, setListCrossCheckItem] = useState<
    ICrossCheckItem[]
  >([]);

  const [filterReq, setFilterReq] = useState<IFilterReportCrossCheckReq>({
    date: new Date().getFullYear(),
  });

  const { mutateAsync: reportCrossCheck, isLoading } = useAppMutation({
    mutationKey: ['reportService', 'reportCrossCheck'],
    mutationFn: reportService.reportCrossCheck,
    onSuccess: ({ isSuccess, data }) => {
      if (isSuccess && data) {
        const { itemsList } = data;
        setListCrossCheckItem(itemsList);
      }
    },
  });

  const { mutateAsync: downloadReportCrossCheck } = useAppMutation({
    mutationKey: ['reportService', 'downloadReportCrossCheck'],
    mutationFn: reportService.downloadReportCrossCheck,
  });

  const handleSubmit = async (data: IFilterReportCrossCheckReq) => {
    setFilterReq({
      date: data.date,
    });

    await reportCrossCheck({
      filter: { date: data.date },
    });
  };

  const handleExportCrossCheck = async () => {
    try {
      const { isSuccess, itemsList } = await downloadReportCrossCheck({
        ...filterReq,
      });
      if (isSuccess) {
        await handleExport(itemsList);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const getListCrossCheck = useCallback(async () => {
    await reportCrossCheck({
      filter: filterReq,
    });
  }, []);

  useEffect(() => {
    getListCrossCheck();
  }, []);

  return (
    <div className='flex flex-col gap-y-2.5'>
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        className='flex justify-between px-4 py-3 bg-white rounded-lg'>
        <div className='flex space-x-2.5'>
          <FormSelect
            name='date'
            selectOptions={OPTION_DATE}
            placeholder='Năm'
            label={'Trạng thái'}
            inputProps={{
              isOnlyValue: true,
            }}
            rules={{
              onChange(event) {
                handleSubmit(methods.getValues());
              },
            }}
          />
        </div>
        <div className='flex space-x-2.5'>
          <div className='flex flex-col justify-end'>
            <Button
              size='sm'
              theme='green'
              onClick={handleExportCrossCheck}
              disabled={isProcessing}
              prefixIcon={<FileExcelLineIcon />}
              className='!px-5'>
              <span className='whitespace-nowrap'>
                {isProcessing ? 'Tải về...' : 'Tải về'}
              </span>
            </Button>
          </div>
        </div>
      </Form>

      <CrossCheckStats
        filterCrossCheckStats={{
          date: filterReq.date,
        }}
      />

      <TableContent
        isExistPagination={false}
        listCrossCheckItem={listCrossCheckItem}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CrossCheckCtn;
