/**
 * @fileoverview gRPC-Web generated client stub for partner.web_partner
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: partner/web_partner/partner_user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var base_enum_pb = require('../../base/enum_pb.js')

var partner_base_pb = require('../../partner/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')

var partner_transaction_pb = require('../../partner/transaction_pb.js')
const proto = {};
proto.partner = {};
proto.partner.web_partner = require('./partner_user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partner.PartnerUserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partner.PartnerUserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.GetMeReq,
 *   !proto.partner.web_partner.GetMeRes>}
 */
const methodDescriptor_PartnerUserService_GetMe = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerUserService/GetMe',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.GetMeReq,
  proto.partner.web_partner.GetMeRes,
  /**
   * @param {!proto.partner.web_partner.GetMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partner.GetMeRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partner.GetMeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partner.GetMeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerUserServiceClient.prototype.getMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/GetMe',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_GetMe,
      callback);
};


/**
 * @param {!proto.partner.web_partner.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partner.GetMeRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerUserServicePromiseClient.prototype.getMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/GetMe',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_GetMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.ChangePasswordReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerUserService_ChangePassword = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerUserService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.ChangePasswordReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partner.ChangePasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerUserServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ChangePassword,
      callback);
};


/**
 * @param {!proto.partner.web_partner.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerUserServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.UpdateMeReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerUserService_UpdateMe = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerUserService/UpdateMe',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.UpdateMeReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partner.UpdateMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerUserServiceClient.prototype.updateMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/UpdateMe',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_UpdateMe,
      callback);
};


/**
 * @param {!proto.partner.web_partner.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerUserServicePromiseClient.prototype.updateMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/UpdateMe',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_UpdateMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.CreatePartnerUserReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerUserService_CreatePartnerUser = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerUserService/CreatePartnerUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.CreatePartnerUserReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partner.CreatePartnerUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.CreatePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerUserServiceClient.prototype.createPartnerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/CreatePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_CreatePartnerUser,
      callback);
};


/**
 * @param {!proto.partner.web_partner.CreatePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerUserServicePromiseClient.prototype.createPartnerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/CreatePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_CreatePartnerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.ListPartnerUserReq,
 *   !proto.partner.web_partner.ListPartnerUserRes>}
 */
const methodDescriptor_PartnerUserService_ListPartnerUser = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerUserService/ListPartnerUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.ListPartnerUserReq,
  proto.partner.web_partner.ListPartnerUserRes,
  /**
   * @param {!proto.partner.web_partner.ListPartnerUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partner.ListPartnerUserRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.ListPartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partner.ListPartnerUserRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partner.ListPartnerUserRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerUserServiceClient.prototype.listPartnerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/ListPartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ListPartnerUser,
      callback);
};


/**
 * @param {!proto.partner.web_partner.ListPartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partner.ListPartnerUserRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerUserServicePromiseClient.prototype.listPartnerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/ListPartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ListPartnerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.RetrievePartnerUserReq,
 *   !proto.partner.web_partner.RetrievePartnerUserRes>}
 */
const methodDescriptor_PartnerUserService_RetrievePartnerUser = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerUserService/RetrievePartnerUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.RetrievePartnerUserReq,
  proto.partner.web_partner.RetrievePartnerUserRes,
  /**
   * @param {!proto.partner.web_partner.RetrievePartnerUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partner.RetrievePartnerUserRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.RetrievePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partner.RetrievePartnerUserRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partner.RetrievePartnerUserRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerUserServiceClient.prototype.retrievePartnerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/RetrievePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_RetrievePartnerUser,
      callback);
};


/**
 * @param {!proto.partner.web_partner.RetrievePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partner.RetrievePartnerUserRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerUserServicePromiseClient.prototype.retrievePartnerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/RetrievePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_RetrievePartnerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.UpdateUserReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerUserService_UpdateUser = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerUserService/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.UpdateUserReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partner.UpdateUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.UpdateUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerUserServiceClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_UpdateUser,
      callback);
};


/**
 * @param {!proto.partner.web_partner.UpdateUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerUserServicePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.RetrieveBalanceReq,
 *   !proto.partner.web_partner.RetrieveBalanceRes>}
 */
const methodDescriptor_PartnerUserService_RetrieveBalance = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerUserService/RetrieveBalance',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.RetrieveBalanceReq,
  proto.partner.web_partner.RetrieveBalanceRes,
  /**
   * @param {!proto.partner.web_partner.RetrieveBalanceReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partner.RetrieveBalanceRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.RetrieveBalanceReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partner.RetrieveBalanceRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partner.RetrieveBalanceRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerUserServiceClient.prototype.retrieveBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/RetrieveBalance',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_RetrieveBalance,
      callback);
};


/**
 * @param {!proto.partner.web_partner.RetrieveBalanceReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partner.RetrieveBalanceRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerUserServicePromiseClient.prototype.retrieveBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/RetrieveBalance',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_RetrieveBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.TopUpAgencyReq,
 *   !proto.partner.web_partner.TopUpAgencyRes>}
 */
const methodDescriptor_PartnerUserService_TopUpAgency = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerUserService/TopUpAgency',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.TopUpAgencyReq,
  proto.partner.web_partner.TopUpAgencyRes,
  /**
   * @param {!proto.partner.web_partner.TopUpAgencyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partner.TopUpAgencyRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.TopUpAgencyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partner.TopUpAgencyRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partner.TopUpAgencyRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerUserServiceClient.prototype.topUpAgency =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/TopUpAgency',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_TopUpAgency,
      callback);
};


/**
 * @param {!proto.partner.web_partner.TopUpAgencyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partner.TopUpAgencyRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerUserServicePromiseClient.prototype.topUpAgency =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerUserService/TopUpAgency',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_TopUpAgency);
};


module.exports = proto.partner.web_partner;

