import { convertArrayToObject, getApiErrorMessages } from '@core/utils';
import {
  IAddFlightServiceFeeReq,
  IListFlightServiceFeeReq,
  IUpdateFlightServiceFeeReq,
  serviceFeeService,
} from '@tixlabs/grpc-client/web-partnership';

import {
  EFlightItineraryType,
  IAirlineHub,
  IFlightServiceFee,
} from '@tixlabs/grpc-client';
import { flightApiService } from '@tixlabs/grpc-client/web-partnership';
import { DEFAULT_PAGE_LIMIT } from '@access-trade-admin/utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppMutation, useToast } from '../internals';
import useSearchAirlines from './useSearchAirlines';

type TFormFilter = {
  airlineCode: string;
  type: EFlightItineraryType;
};

export const useServiceFeeSetting = () => {
  const methods = useForm<TFormFilter>({
    defaultValues: {
      airlineCode: '',
      type: EFlightItineraryType.INONE,
    },
  });

  const toast = useToast();
  const [listServiceFee, setListServiceFee] = useState<IFlightServiceFee[]>([]);

  const {
    mutateAsync: fetchListFlightServiceFee,
    data: listFlightServiceFeeRes,
    isLoading,
  } = useAppMutation({
    mutationKey: ['serviceFeeService', 'listFlightServiceFee'],
    mutationFn: serviceFeeService?.listFlightServiceFee,
    onSuccess: (data) => {
      const { isSuccess, errorCode, itemsList } = data;
      if (!isSuccess && errorCode) {
        const errMessage = getApiErrorMessages(errorCode);
        toast.error(errMessage[0]);
      }
      setListServiceFee(itemsList);
    },
  });

  const { mutateAsync: updateFlightServiceFee } = useAppMutation({
    mutationKey: ['serviceFeeService', 'updateFlightServiceFee'],
    mutationFn: serviceFeeService?.updateFlightServiceFee,
  });

  const { mutateAsync: addFlightServiceFee } = useAppMutation({
    mutationKey: ['serviceFeeService', 'addFlightServiceFee'],
    mutationFn: serviceFeeService?.addFlightServiceFee,
  });
  const { mutateAsync: deleteFlightServiceFee } = useAppMutation({
    mutationKey: ['serviceFeeService', 'deleteFlightServiceFee'],
    mutationFn: serviceFeeService?.deleteFlightServiceFee,
  });

  const { handleSearch, airlinesList } = useSearchAirlines();
  const { mutateAsync: requestSearchAirlines, data: airlines } = useAppMutation(
    {
      mutationKey: ['flightApiService', 'searchAirlines'],
      mutationFn: flightApiService?.searchAirlines,
    }
  );

  const pagination = listFlightServiceFeeRes?.pagination || {
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 0,
    totalRecord: 0,
  };

  const getListFlightServiceFee = async (
    reqParams?: IListFlightServiceFeeReq
  ) => {
    await fetchListFlightServiceFee(
      reqParams || {
        filter: {
          airlineCode: '',
          type: EFlightItineraryType.INONE,
        },
        pagination: {
          pageLimit: DEFAULT_PAGE_LIMIT,
          pageNumber: 1,
        },
      }
    );
  };

  const reFetchList = async () => {
    await fetchListFlightServiceFee({
      filter: {
        airlineCode: methods.getValues('airlineCode'),
        type: methods.getValues('type'),
      },
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: pagination.pageCurrent,
      },
    });
  };

  const onChangePage = async (pageNumber: number) => {
    await fetchListFlightServiceFee({
      filter: {
        airlineCode: methods.getValues('airlineCode'),
        type: methods.getValues('type'),
      },
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: pageNumber,
      },
    });
  };

  const handleUpdateFlightServiceFee = async ({
    amount,
    id,
    type,
    vat,
    airlineCode,
  }: IUpdateFlightServiceFeeReq) => {
    try {
      const { errorCode, isSuccess } = await updateFlightServiceFee({
        amount,
        id,
        type,
        vat: 0,
        airlineCode,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      await reFetchList();
      toast.success('Cập nhật thành công');
    } catch (error) {
      const errMessage = getApiErrorMessages(error);
      toast.error(errMessage[0]);
      return Promise.reject(errMessage[0]);
    }
  };

  const handleDeleteServiceFee = async ({ id }: IUpdateFlightServiceFeeReq) => {
    try {
      const { errorCode, isSuccess } = await deleteFlightServiceFee({
        id,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      await reFetchList();
      toast.success('Xóa thành công');
    } catch (error) {
      const errMessage = getApiErrorMessages(error);
      toast.error(errMessage[0]);
      return Promise.reject(errMessage[0]);
    }
  };

  const handleAddFlightServiceFee = async ({
    amount,
    type,
    vat,
    airlineCode,
  }: IAddFlightServiceFeeReq) => {
    try {
      const { errorCode, isSuccess } = await addFlightServiceFee({
        amount,
        type,
        vat,
        airlineCode,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      await reFetchList();
      toast.success('Thêm mới thành công');
    } catch (error) {
      const errMessage = getApiErrorMessages(error);
      toast.error(errMessage[0]);
      return Promise.reject(errMessage[0]);
    }
  };

  useEffect(() => {
    getListFlightServiceFee();
    handleSearch('');
    requestSearchAirlines({
      filter: {
        text: '',
      },
    });
  }, []);

  return {
    isLoading,
    filterFormMethods: methods,
    listServiceFee: listServiceFee || [],
    pagination: listFlightServiceFeeRes?.pagination || {
      pageCurrent: 1,
      pageLimit: 10,
      totalPage: 1,
      totalRecord: 0,
    },
    handleUpdateFlightServiceFee,
    handleAddFlightServiceFee,
    onChangePage,
    airlineObjectByCode: convertArrayToObject<IAirlineHub>(
      airlines?.data?.itemsList || [],
      'code'
    ),
    airlineOptions:
      airlinesList.map((item) => ({
        label: item.name,
        value: item.code,
      })) || [],
    handleSearch,
    deleteFlightServiceFee: handleDeleteServiceFee,
  };
};

export default useServiceFeeSetting;
