import {
  Button,
  ButtonLoading,
  FormGroupRadio,
  FormTextarea,
} from '@common-ui';
import { useNavigate } from 'react-router-dom';

type Props = {
  checkLenghtNote: boolean;
};

const statusOptions = [
  {
    label: 'Chờ xử lý',
    value: '1',
  },
  {
    label: 'Đã hủy, chờ hoàn tiền',
    value: '2',
  },
  {
    label: 'Đã bảo lưu',
    value: '3',
  },
  {
    label: 'Quá hạn xử lý',
    value: '4',
  },
];

export const ProcessingOrderCoordination = ({ checkLenghtNote }: Props) => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col rounded-lg bg-white p-5'>
      <label className='text-lg font-semibold mb-2'>
        Thao tác xử lý đặt chỗ
      </label>
      <FormGroupRadio
        name='status'
        radioOptions={statusOptions}
        // className='flex justify-between'
        labelProps={{ labelClassName: '!min-w-0 cursor-pointer text-sm' }}
        radioClassName='flex-none mr-4 mb-1'
      />
      <div className='flex flex-col mt-5'>
        <label className='text-lg font-semibold mb-2'>Yêu cầu đặc biệt</label>
        <FormTextarea
          name='note'
          className='text-sm'
          placeholder=''
          inputProps={{
            maxLength: 200,
            className: 'h-[110px] text-sm py-2 px-3',
          }}
        />
        <label>{checkLenghtNote ? 'Chỉ được nhập tối đa 200 ký tự' : ''}</label>
      </div>
      <div className='col-span-12 flex justify-center gap-4 my-7'>
        <Button
          className='w-[150px]'
          theme='primary'
          variant='outline'
          onClick={() => navigate('/ticket-coordination')}>
          Quay lại
        </Button>
        <ButtonLoading
          disabled={false}
          className='w-[150px] hover:!bg-[#3429c5]'
          theme='primary'
          type='submit'>
          Xác nhận
        </ButtonLoading>
      </div>
    </div>
  );
};
