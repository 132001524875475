import { ConfigServiceClient } from '@api/airplane/web_partnership/config_grpc_web_pb';
import {
  GetConfigRes,
  UpdateConfigReq,
  GetConfigByOfficeIDReq,
  UpdateConfigByOfficeIDReq,
  CommissionRate,
  SaleServiceFee,
  WalletConfig,
  GetListOfficeRes,
  GetListOfficeV2Req,
} from '@api/airplane/web_partnership/config_pb';

import { BasicRes, Empty } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class ConfigApi extends BaseApi<ConfigServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new ConfigServiceClient(this.url, null, this.optsDev);
  }
  getConfig = (): Promise<GetConfigRes.AsObject> => {
    const req = new Empty();
    return this.grpc<Empty, GetConfigRes, GetConfigRes.AsObject>(
      this.serviceClient.getConfig,
      req,
      {}
    );
  };
  updateConfig = ({ vat }: UpdateConfigReq.AsObject) => {
    const req = new UpdateConfigReq();
    req.setVat(vat);
    return this.grpc<UpdateConfigReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateConfig,
      req,
      {}
    );
  };
  getConfigByOfficeID = ({
    officeId,
  }: GetConfigByOfficeIDReq.AsObject): Promise<GetConfigRes.AsObject> => {
    const req = new GetConfigByOfficeIDReq();
    req.setOfficeId(officeId);
    return this.grpc<
      GetConfigByOfficeIDReq,
      GetConfigRes,
      GetConfigRes.AsObject
    >(this.serviceClient.getConfigByOfficeID, req, {});
  };

  updateConfigByOfficeID = ({
    officeId,
    platformFee,
    commission,
    saleServiceFee,
    walletConfig,
  }: UpdateConfigByOfficeIDReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new UpdateConfigByOfficeIDReq();
    req.setOfficeId(officeId);
    req.setPlatformFee(platformFee);

    const reqCommissionRate = new CommissionRate();
    commission?.airTicket !== undefined &&
      reqCommissionRate.setAirTicket(commission?.airTicket);
    commission?.sim !== undefined && reqCommissionRate.setSim(commission?.sim);
    commission?.visa !== undefined &&
      reqCommissionRate.setVisa(commission?.visa);
    req.setCommission(reqCommissionRate);

    const reqSaleServiceFee = new SaleServiceFee();
    saleServiceFee?.rate !== undefined &&
      reqSaleServiceFee.setRate(saleServiceFee.rate);
    saleServiceFee?.supportRate !== undefined &&
      reqSaleServiceFee.setSupportRate(saleServiceFee.supportRate);
    req.setSaleServiceFee(reqSaleServiceFee);

    const reqWalletConfig = new WalletConfig();
    walletConfig?.advanceBalance !== undefined &&
      reqWalletConfig.setAdvanceBalance(walletConfig.advanceBalance);
    walletConfig?.depositBalance !== undefined &&
      reqWalletConfig.setDepositBalance(walletConfig.depositBalance);
    walletConfig?.guaranteeAmount !== undefined &&
      reqWalletConfig.setGuaranteeAmount(walletConfig.guaranteeAmount);
    req.setWalletConfig(reqWalletConfig);

    return this.grpc<UpdateConfigByOfficeIDReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateConfigByOfficeID,
      req,
      {}
    );
  };

  getListOffice = (): Promise<GetListOfficeRes.AsObject> => {
    const req = new Empty();
    return this.grpc<Empty, GetListOfficeRes, GetListOfficeRes.AsObject>(
      this.serviceClient.getListOffice,
      req,
      {}
    );
  };

  getListOfficeV2 = ({
    excludePartnershipIdsList,
    includePartnershipIdsList
  }: GetListOfficeV2Req.AsObject): Promise<GetListOfficeRes.AsObject> => {
    const req = new GetListOfficeV2Req();
    req.setExcludePartnershipIdsList(excludePartnershipIdsList);
    req.setIncludePartnershipIdsList(includePartnershipIdsList);
    return this.grpc<GetListOfficeV2Req, GetListOfficeRes, GetListOfficeRes.AsObject>(
      this.serviceClient.getListOfficeV2,
      req,
      {}
    );
  };
}

export const serviceConfigApi = new ConfigApi();
export default serviceConfigApi;
