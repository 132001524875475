import {IBookingFlightPartialRoundtripRes } from '@tixlabs/grpc-client/web-partnership';

type Props = {
  bookingFlight: IBookingFlightPartialRoundtripRes;
};

export const ContactInfoCoordination = ({ bookingFlight }: Props) => {
  return (
    <div className='flex flex-col rounded-lg bg-white'>
      <h3 className='text-lg font-semibold py-3 px-4 mb-0'>
        Thông tin liên hệ
      </h3>
      <div className='flex flex-col px-4 py-3'>
        <div className='grid grid-cols-3'>
          <h4 className='mb-0 font-semibold text-neutral-black uppercase py-2'>
            {`${bookingFlight.contactInfo?.lastName}/${bookingFlight.contactInfo?.firstName}`}
          </h4>
          <div className='flex flex-col gap-0.5 py-2'>
            <span className='text-xs leading-normal text-neutral-gray-50'>
              Số điện thoại
            </span>
            <span className='text-neutral-black'>{`(+${bookingFlight.contactInfo?.phoneCode}) ${bookingFlight.contactInfo?.phoneNumber}`}</span>
          </div>
          <div className='flex flex-col gap-0.5 py-2'>
            <span className='text-xs leading-normal text-neutral-gray-50'>
              Email
            </span>
            <span className='text-neutral-black'>
              {bookingFlight.contactInfo?.email}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
