import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { DiscountServiceClient } from '@api/price/web_partnership/discount_grpc_web_pb';

import {
  ListDiscountsReq,
  ListDiscountsRes,
  ListDiscountsFilter,
  DetailDiscountReq,
  DetailDiscountRes,
  CreateDiscountReq,
  CreateDiscountRes,
  UpdateDiscountReq,
  UpdateDiscountRes,
  DeleteDiscountReq,
} from '@api/price/web_partnership/discount_pb';
import { BasicRes, Empty, PaginationReq } from '@api/base/base_pb';

class PriceDiscountServiceApi extends BaseApi<DiscountServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new DiscountServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  listDiscounts = ({
    filter,
    pagination,
  }: ListDiscountsReq.AsObject): Promise<ListDiscountsRes.AsObject> => {
    const req = new ListDiscountsReq();
    const filterObj = new ListDiscountsFilter();
    const paginationObj = new PaginationReq();
    if (filter) {
      filterObj.setOfficeId(filter.officeId);
      filterObj.setType(filter.type);
      filterObj.setLocation(filter.location);
      filterObj.setAccommodationTypeList(filter.accommodationTypeList);
      filterObj.setRatingList(filter.ratingList);
      filterObj.setCountryCodeList(filter.countryCodeList);
      filterObj.setKeywords(filter.keywords);
      req.setFilter(filterObj);
    }

    req.setFilter(filterObj);
    if (pagination) {
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
      req.setPagination(paginationObj);
    }

    return this.grpc<
      ListDiscountsReq,
      ListDiscountsRes,
      ListDiscountsRes.AsObject
    >(this.serviceClient.listDiscounts, req, {});
  };

  detailDiscount = ({
    id,
  }: DetailDiscountReq.AsObject): Promise<DetailDiscountRes.AsObject> => {
    const req = new DetailDiscountReq();
    id && req.setId(id);

    return this.grpc<
      DetailDiscountReq,
      DetailDiscountRes,
      DetailDiscountRes.AsObject
    >(this.serviceClient.detailDiscount, req, {});
  };

  createDiscount = ({
    officeId,
    provider,
    type,
    location,
    accommodationTypeList,
    ratingList,
    countryCodeList,
    hotelName,
    amount,
    percent,
    hotelId,
  }: CreateDiscountReq.AsObject): Promise<CreateDiscountRes.AsObject> => {
    const req = new CreateDiscountReq();
    officeId && req.setOfficeId(officeId);
    provider && req.setProvider(provider);
    type && req.setType(type);
    location && req.setLocation(location);
    accommodationTypeList &&
      req.setAccommodationTypeList(accommodationTypeList);
    ratingList && req.setRatingList(ratingList);
    countryCodeList && req.setCountryCodeList(countryCodeList);
    hotelName && req.setHotelName(hotelName);
    amount && req.setAmount(amount);
    percent && req.setPercent(percent);
    hotelId && req.setHotelId(hotelId);

    return this.grpc<
      CreateDiscountReq,
      CreateDiscountRes,
      CreateDiscountRes.AsObject
    >(this.serviceClient.createDiscount, req, {});
  };

  updateDiscount = ({
    id,
    officeId,
    provider,
    type,
    location,
    accommodationTypeList,
    ratingList,
    countryCodeList,
    hotelName,
    amount,
    percent,
    hotelId,
  }: UpdateDiscountReq.AsObject): Promise<UpdateDiscountRes.AsObject> => {
    const req = new UpdateDiscountReq();
    id && req.setId(id);
    officeId && req.setOfficeId(officeId);
    provider && req.setProvider(provider);
    type && req.setType(type);
    location && req.setLocation(location);
    accommodationTypeList &&
      req.setAccommodationTypeList(accommodationTypeList);
    ratingList && req.setRatingList(ratingList);
    countryCodeList && req.setCountryCodeList(countryCodeList);
    hotelName && req.setHotelName(hotelName);
    amount && req.setAmount(amount);
    percent && req.setPercent(percent);
    hotelId && req.setHotelId(hotelId);

    return this.grpc<
      UpdateDiscountReq,
      UpdateDiscountRes,
      UpdateDiscountRes.AsObject
    >(this.serviceClient.updateDiscount, req, {});
  };

  deleteHidenFee = ({
    idsList,
  }: DeleteDiscountReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new DeleteDiscountReq();
    idsList && req.setIdsList(idsList);

    return this.grpc<DeleteDiscountReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.deleteDiscount,
      req,
      {}
    );
  };

  //   importDiscount =
  //     ({}: ImportDiscountReq.AsObject): Promise<ImportDiscountRes.AsObject> => {
  //       const req = new ImportDiscountReq();
  //       return this.grpc<
  //         ImportDiscountReq,
  //         ImportDiscountRes,
  //         ImportDiscountRes.AsObject
  //       >(this.serviceClient.importDiscount, req, {});
  //     };
}

export const priceConfigDiscountServiceApi = new PriceDiscountServiceApi();
