import { WalletServiceClient } from '@api/hotel/web_partner/wallet_grpc_web_pb';

import { PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import {
  ListTransactionReq,
  ListTransactionRes,
  TransactionFilter,
  ExportTransactionReq,
  ExportTransactionRes,
} from '@api/hotel/web_partner/wallet_pb';

class WalletApi extends BaseApi<WalletServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new WalletServiceClient(this.url, null, this.optsDev);
  }

  listTransaction = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListTransactionReq.AsObject): Promise<ListTransactionRes.AsObject> => {
    const req = new ListTransactionReq();
    const filterObj = new TransactionFilter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.referenceCode &&
        filterObj.setReferenceCode(filter.referenceCode?.trim() || '');
      filterObj.setTransactionType(filter.transactionType);
      filter.transactionSource &&
        filterObj.setTransactionSource(filter.transactionSource);
    }
    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    return this.grpc<
      ListTransactionReq,
      ListTransactionRes,
      ListTransactionRes.AsObject
    >(this.serviceClient?.listTransaction, req, {});
  };

  exportTransactions = ({
    filter,
  }: ExportTransactionReq.AsObject): Promise<ExportTransactionRes.AsObject> => {
    const req = new ExportTransactionReq();
    const filterObj = new TransactionFilter();

    if (filter) {
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.referenceCode &&
        filterObj.setReferenceCode(filter.referenceCode?.trim() || '');
      filterObj.setTransactionType(filter.transactionType);
      filter.transactionSource &&
        filterObj.setTransactionSource(filter.transactionSource);
    }

    req.setFilter(filterObj);

    return this.grpc<
      ExportTransactionReq,
      ExportTransactionRes,
      ExportTransactionRes.AsObject
    >(this.serviceClient?.exportTransactions, req, {});
  };
}

export const hotelWalletApiService = new WalletApi();
export default hotelWalletApiService;
