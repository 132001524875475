import { IPaginationRes } from '@tixlabs/grpc-client';
import { useCurrency, useTime } from '@access-trade-admin/hooks/internals';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import cn from 'classnames';
import { Status } from './Status';
import { ICommissionItem } from '@tixlabs/grpc-client/web-partnership';

type Props = {
  listCommissionOrder: ICommissionItem[];
  isLoading: boolean;
  pagination?: IPaginationRes;
  isExistPagination?: boolean;
  onPageChange?: (page: number) => void;
};

const TitleColumns = ({ title }: { title: string }) => {
  return <span className='text-[#333333]'>{title}</span>;
};

const TableContent = ({
  isLoading,
  listCommissionOrder,
  pagination,
  isExistPagination = true,
  onPageChange,
}: Props) => {
  const { formatDateTime } = useTime();
  const { formatCurrency } = useCurrency();

  const columns: ColumnProps<ICommissionItem>[] = [
    {
      title: () => {
        return <TitleColumns title='Ngày đặt' />;
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        return <span>{formatDateTime(record.createdAt)}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Mã đơn hàng' />;
      },
      dataIndex: 'bookingCode',
      key: 'bookingCode',
      render: (value: string) => {
        return <span className='font-semibold text-primary'>{value}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Tổng tiền' />;
      },
      dataIndex: 'totalMoney',
      key: 'totalMoney',
      render: (value: ICommissionItem['totalMoney']) => {
        if (!value) {
          return <span className='text-primary-50'>-</span>;
        }
        return <span className='text-primary-50'>{formatCurrency(value)}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Phí dịch vụ' />;
      },
      dataIndex: 'serviceFee',
      key: 'serviceFee',
      render: (value: ICommissionItem['serviceFee']) => {
        if (!value) {
          return <span className='text-primary-50'>-</span>;
        }
        return <span className='text-primary-50'>{formatCurrency(value)}</span>;
      },
      className: 'min-w-[150px]',
    },
    {
      title: () => {
        return <TitleColumns title='Hoa hồng' />;
      },
      dataIndex: 'commission',
      key: 'commission',
      render: (value: ICommissionItem['commission']) => {
        if (!value) {
          return <span className='text-primary-50'>-</span>;
        }
        return <span className='text-primary-50'>{formatCurrency(value)}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Trạng thái hoa hồng' />;
      },
      dataIndex: 'status',
      key: 'status',
      render: (value: ICommissionItem['status']) => {
        return <Status status={value} className='' classNameText='' />;
      },
      className: 'min-w-[150px]',
    },
    {
      title: () => {
        return <TitleColumns title='Tổng lợi nhuận' />;
      },
      dataIndex: 'profit',
      key: 'profit',
      render: (value: ICommissionItem['profit']) => {
        return <span>{formatCurrency(value)}</span>;
      },
    },
  ];

  return (
    <div className='p-2.5 bg-white rounded-lg'>
      <Table
        dataSource={listCommissionOrder.map((order, index) => {
          return {
            ...order,
            key: index,
          };
        })}
        columns={columns}
        size='small'
        rowClassName={(record, index) => {
          return cn('font-semibold whitespace-nowrap', {
            'bg-[#F8F8FB]': index % 2 === 0,
          });
        }}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: () => {
        //       navigate(`./${record.bookingCode}`);
        //     },
        //   };
        // }}
        scroll={{ x: 1000 }}
        pagination={
          isExistPagination
            ? {
                pageSize: pagination?.pageLimit || 1,
                current: pagination?.pageCurrent || 1,
                total: pagination?.totalRecord || 1,
                className: 'space-x-2.5',
                onChange: onPageChange,
              }
            : false
        }
        loading={isLoading}
      />
    </div>
  );
};

export default TableContent;
