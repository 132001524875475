export const checkMaxSize = (file: File, maxSize: number): boolean => {
  const fileSizeKiloBytes = file.size / 1024;
  return !(fileSizeKiloBytes > maxSize);
};

export const checkMinSize = (file: File, minSize: number): boolean => {
  const fileSizeKiloBytes = file.size / 1024;
  return !(fileSizeKiloBytes < minSize);
};

export const checkSupportFiles = (
  file: File,
  supportFiles: string[]
): boolean => {
  const extensionFile = file.type;
  if (extensionFile) {
    return supportFiles.includes(extensionFile);
  }

  return false;
};

export const convertUrlToFile = async (url: string) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch file, status: ${response.status}`);
    }

    const contentType = response.headers.get('content-type');

    const blob = await response.blob();
    const file = new File([blob], url, { type: contentType || '' });

    // access file here

    return file;
  } catch (error) {
    console.error('Error fetching file:', error);
  }
};

export const convertBytesToMegaBytes = (sizeBytes: number) => {
  const bytesToMegaBytes = sizeBytes / 1024 ** 2;
  return bytesToMegaBytes;
};

export const checkValidImagePng = async (
  file: File | undefined
): Promise<boolean> => {
  if (!file) return false;
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      // const arr = new Uint8Array(reader.result as ArrayBuffer).subarray(0, 4);
      // let header = '';
      // for (let i = 0; i < arr.length; i++) {
      //   header += arr[i].toString(16);
      // }
      // // PNG file signature: 89504e47 (hex)
      // // JPE file signature: ffd8ffdb (hex)
      // if (header === 'ffd8ffe0') {
      //   // Valid PNG file, you can proceed with the upload
      //   return resolve(true);
      //   // Now you can submit the form or perform other actions
      // } else {
      //   return resolve(false);
      // }

      const validTypes = ['image/png', 'image/jpg', 'image/jpeg'];

      if (validTypes.includes(file?.type)) {
        return resolve(true);
      } else {
        return resolve(false);
      }
    };

    reader.readAsArrayBuffer(file as Blob);
  });
};

export const ONE_KB_TO_B = 1024;
