import { OrderByItem } from '@api/base/base_pb';
import {
    CreateAgentGroupReq,
    UpdateAgentGroupReq,
    GetAgentGroupsReqFilter,
    GetAgentGroupsReq,
    PartnerAgentGroup,
    PartnerAgentGroupContact,
    PartnerAgentGroupCommission,
    PartnerAgentGroupStatus
} from '@api/partner/web_partner/partner_agent_group_pb';

export type EPartnerAgentGroupStatus = PartnerAgentGroupStatus;
export const EPartnerAgentGroupStatus = {
  ...PartnerAgentGroupStatus,
} as const;

export interface ICreateAgentGroupReq extends CreateAgentGroupReq.AsObject {}
export interface IUpdateAgentGroupReq extends UpdateAgentGroupReq.AsObject {}
export interface IGetAgentGroupsReqFilter extends GetAgentGroupsReqFilter.AsObject {}
export interface IGetAgentGroupsReq extends GetAgentGroupsReq.AsObject {}
export interface IPartnerAgentGroup extends PartnerAgentGroup.AsObject {}
export interface IPartnerAgentGroupContact extends PartnerAgentGroupContact.AsObject {}
export interface IPartnerAgentGroupCommission extends PartnerAgentGroupCommission.AsObject {}
export interface IOrderByItem extends OrderByItem.AsObject {}