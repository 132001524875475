import { Button, Form, FormInput } from '@common-ui';
import {
  IRefundBookingReq,
  IRetrieveBookingFlightData,
  flightApiService,
} from '@tixlabs/grpc-client/web-partnership';
import { EBookingStatus } from '@tixlabs/grpc-client';
import ModalContent from '@vemaybay-admin/components/Modal/ModalContent';
import { useAppMutation, useTime } from '@vemaybay-admin/hooks/internals';
import { EModalMode } from '@vemaybay-admin/types';
import {
  BOOKING_DETAIL_STATUS_LABEL,
  EStepRefundBooking,
} from '@vemaybay-admin/utils';
import { Modal } from 'antd';
import cn from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

type Props = {
  //
  bookingFlight: IRetrieveBookingFlightData;
  handleRefundBookingSuccess: () => Promise<void>;
};

const objClassName = {
  [EBookingStatus.COMPLETE]: 'bg-primary-variant-3',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'bg-secondary-1',
  [EBookingStatus.INPROGRESS]: 'bg-secondary-1',
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'bg-black-variant-3',
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: 'bg-black-variant-3',
  [EBookingStatus.BOOKINGSTATUSREFUND]: 'bg-black-variant-3',
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: 'bg-black-variant-3',
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: 'bg-red-1',
  [EBookingStatus.BOOKINGSTATUSNOTCOMPLETE]: 'bg-secondary-1',
};

export const StatusOrder = ({
  bookingFlight,
  handleRefundBookingSuccess,
}: Props) => {
  const { formatTimeDate } = useTime();
  const [stepModal, setStepModal] = useState<EStepRefundBooking>(
    EStepRefundBooking.CONFIRM
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { mutateAsync: refundBooking } = useAppMutation({
    mutationKey: ['flightApiService', 'refundBooking'],
    mutationFn: flightApiService.refundBooking,
    onError: (err) => {
      console.log('oops...', err);
    },
  });
  const methods = useForm<IRefundBookingReq>({
    defaultValues: {
      id: bookingFlight.id,
      note: '',
    },
  });
  const handleRefundBooking = async ({ id, note }: IRefundBookingReq) => {

    const { isSuccess, errorCode } = await refundBooking({
      id,
      note,
    });
    if (isSuccess) {
      // show modal success
      setStepModal(EStepRefundBooking.SUCCESS);
    } else {
      // show modal error code
      setStepModal(EStepRefundBooking.FAILED);
    }
  };
  const contentStatus = () => {
    switch (bookingFlight.status) {
      case EBookingStatus.COMPLETE:
        return (
          <span className='text-common-success font-bold text-xl'>
            {BOOKING_DETAIL_STATUS_LABEL[EBookingStatus.COMPLETE]}
          </span>
        );
      case EBookingStatus.BOOKINGSTATUSPAID:
        return (
          <span className='text-secondary-6 font-bold text-xl'>
            {BOOKING_DETAIL_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSPAID]}
          </span>
        );
      case EBookingStatus.INPROGRESS:
        return (
          <>
            <span className='text-secondary-6 font-bold text-xl text-right'>
              {BOOKING_DETAIL_STATUS_LABEL[EBookingStatus.INPROGRESS]}
            </span>
            <span className='text-neutral-black text-md text-right'>
              Hạn thanh toán: {formatTimeDate(bookingFlight.expiredAt)}
            </span>
          </>
        );
      case EBookingStatus.BOOKINGSTATUSTICKETFAILED:
        return (
          <>
            <span className='text-common-error font-bold text-xl text-right'>
              {
                BOOKING_DETAIL_STATUS_LABEL[
                  EBookingStatus.BOOKINGSTATUSTICKETFAILED
                ]
              }
            </span>
            <Button
              className='text-md !self-end rounded-lg !py-1 !px-2'
              variant='outline'
              onClick={() => {
                setIsOpenModal(true);
              }}>
              Xác nhận hoàn tiền
            </Button>
          </>
        );
      case EBookingStatus.BOOKINGSTATUSFAILED:
        return (
          <>
            <span className='text-neutral-gray-50 font-bold text-xl text-right'>
              {BOOKING_DETAIL_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSFAILED]}
            </span>
            <Button
              className='text-md !self-end rounded-lg !py-1 !px-2'
              variant='outline'
              onClick={() => {
                setIsOpenModal(true);
              }}>
              Xác nhận hoàn tiền
            </Button>
          </>
        );
      case EBookingStatus.BOOKINGSTATUSCANCELLED:
        return (
          <>
            <span className='text-neutral-gray-50 font-bold text-xl'>
              {
                BOOKING_DETAIL_STATUS_LABEL[
                  EBookingStatus.BOOKINGSTATUSCANCELLED
                ]
              }
            </span>
            <span className='text-md text-neutral-6'>
              Lý do: hết thời hạn thanh toán
            </span>
          </>
        );
      case EBookingStatus.BOOKINGSTATUSNOTCOMPLETE:
        return (
          <>
            <span className='text-common-warning font-bold text-xl'>
              {
                BOOKING_DETAIL_STATUS_LABEL[
                  EBookingStatus.BOOKINGSTATUSNOTCOMPLETE
                ]
              }
            </span>
          </>
        );
      case EBookingStatus.BOOKINGSTATUSEXPIRED:
        return (
          <>
            <span className='text-neutral-gray-50 font-bold text-xl'>
              {BOOKING_DETAIL_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSEXPIRED]}
            </span>
            <span className='text-md text-neutral-6'>
              Lý do: hết thời hạn thanh toán
            </span>
          </>
        );
      case EBookingStatus.BOOKINGSTATUSREFUND:
        return (
          <>
            <span className='text-neutral-gray-50 font-bold text-xl'>
              {BOOKING_DETAIL_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSREFUND]}
            </span>
            <span className='text-md text-neutral-6 w-full'>
              {`Đã hoàn tiền lúc ${formatTimeDate(
                bookingFlight.refund?.refundedAt
              )} ${
                bookingFlight.refund?.note
                  ? `- ${bookingFlight.refund.note}`
                  : ''
              }`}
            </span>
          </>
        );
    }
  };

  const generateModalBody = () => {
    switch (stepModal) {
      case EStepRefundBooking.CONFIRM:
        return (
          <ModalContent
            className='w-full'
            mode={EModalMode.CONFIRM}
            content={
              <div className='flex flex-col'>
                <div className='flex flex-col gap-2'>
                  <span className='text-center text-lg font-semibold'>
                    Xác nhận hoàn tiền
                  </span>
                  <span className='text-center'>
                    Sau khi bạn xác nhận, hệ thống sẽ tự động gửi một email
                    thông báo cho khách hàng về việc hoàn tiền
                  </span>
                  <FormInput
                    name='note'
                    placeholder='Nhập ghi chú hệ thống'
                    className='pt-5'
                    rules={{
                      required: 'Nhập ghi chú',
                    }}
                  />
                </div>
                <div className='flex items-center justify-center pt-3 gap-2'>
                  <Button
                    className='flex-1'
                    variant='outline'
                    onClick={() => {
                      setStepModal(EStepRefundBooking.CONFIRM);
                      setIsOpenModal(false);
                    }}>
                    Để sau
                  </Button>
                  <Button
                    className='flex-1'
                    type='submit'
                    isDisabled={methods.formState.isSubmitting}>
                    Xác nhận
                  </Button>
                </div>
              </div>
            }
          />
        );
      case EStepRefundBooking.SUCCESS:
        return (
          <ModalContent
            className='w-full'
            mode={EModalMode.SUCCESS}
            content={
              <div className='flex flex-col'>
                <div className='flex flex-col gap-2'>
                  <span className='text-center text-lg font-semibold'>
                    Xác nhận hoàn tiền thành công
                  </span>
                  <span className='text-center'>
                    Xác nhận thông tin hoàn tiền thành công.
                  </span>
                </div>
                <div className='flex items-center justify-center pt-3'>
                  <Button
                    className='w-1/5'
                    onClick={async () => {
                      setStepModal(EStepRefundBooking.CONFIRM);
                      setIsOpenModal(false);
                      await handleRefundBookingSuccess();
                    }}>
                    Đóng
                  </Button>
                </div>
              </div>
            }
          />
        );
      case EStepRefundBooking.FAILED:
        return (
          <ModalContent
            className='w-full'
            mode={EModalMode.ERROR}
            content={
              <div className='flex flex-col'>
                <div className='flex flex-col gap-2'>
                  <span className='text-center text-lg font-semibold'>
                    Xác nhận yêu cầu hoàn tiền thất bại
                  </span>
                  <span className='text-center'>
                    Xác nhận thông tin hoàn tiền thất bại. Vui lòng thử lại sau.
                  </span>
                </div>
                <Button
                  className='w-1/5'
                  onClick={() => {
                    setStepModal(EStepRefundBooking.CONFIRM);
                    setIsOpenModal(false);
                  }}>
                  Đóng
                </Button>
              </div>
            }
          />
        );
    }
  };

  return (
    <>
      <Modal open={isOpenModal} closeIcon={false} centered={true} footer={null}>
        <Form methods={methods} onSubmit={handleRefundBooking}>
          {generateModalBody()}
        </Form>
      </Modal>
      <div
        className={cn(
          'flex items-center gap-4 px-3 py-4 rounded w-full',
          objClassName[bookingFlight.status]
        )}>
        <div className='flex flex-col gap-2 flex-1'>
          <span className='text-neutral-black'>
            Mã đơn hàng:{' '}
            <span className='font-bold'>{bookingFlight.bookingCode}</span>
          </span>
          <span className='text-neutral-black'>
            Ngày đặt:{' '}
            <span className='font-bold'>
              {formatTimeDate(bookingFlight.createdAt)}
            </span>
          </span>
        </div>
        <div className='flex flex-col gap-2 justify-center flex-end text-end max-w-[50%]'>
          {contentStatus()}
        </div>
      </div>
    </>
  );
};
