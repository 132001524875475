import { convertArrayToObject } from '@core/utils';
import {
  IReportAirlineBookingItem,
  IReportBookingItem,
} from '@tixlabs/grpc-client';

import {
  ECommissionStatus,
  IReportCommissionStatsData,
  reportService,
} from '@tixlabs/grpc-client/web-partnership';

import { TFromToDate } from '@common-ui';
import { ONE_DAY_TIMESTAMP } from '@access-trade-admin/utils';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppMutation, useTime } from '../internals';

type TFormFilter = {
  filterDate: TFromToDate;
  userId: string;
};

export function useDashboard() {
  const { formatDate } = useTime();

  const methods = useForm<TFormFilter>({
    defaultValues: {
      filterDate: {
        startDate: new Date(Date.now() - ONE_DAY_TIMESTAMP * 7),
        endDate: new Date(),
      },
      userId: '',
    },
  });

  const [saveFilter, setSaveFilter] = useState<{ from: number; to: number }>({
    from: Date.now() - ONE_DAY_TIMESTAMP * 7,
    to: Date.now(),
  });

  const [reportBookingData, setReportBookingData] = useState<
    IReportBookingItem[]
  >([]);

  const [reportAirlineBookingData, setReportAirlineBookingData] = useState<
    IReportAirlineBookingItem[]
  >([]);

  const [reportCommissionStatsData, setReportCommissionStatsData] =
    useState<IReportCommissionStatsData>({
      totalCommission: 0,
      approved: 0,
      profit: 0,
      serviceFee: 0,
    });

  // const { mutateAsync: getBookingStats, data: bookingStats } = useAppMutation({
  //   mutationKey: ['reportService', 'currentBookingStats'],
  //   mutationFn: reportService?.currentBookingStats,
  // });

  const { mutateAsync: fetchReportBooking, isLoading: isLoadingReportBooking } =
    useAppMutation({
      mutationKey: ['reportService', 'getReportBooking'],
      mutationFn: reportService?.getReportBooking,
      onSuccess: (data) => {
        if (data.itemsList) {
          setReportBookingData(data.itemsList.sort((a, b) => a.date - b.date));
        }
      },
    });

  const {
    mutateAsync: fetchReportAirlineBooking,
    isLoading: isLoadingReportAirlineBooking,
  } = useAppMutation({
    mutationKey: ['reportService', 'getReportAirlineBooking'],
    mutationFn: reportService?.getReportAirlineBooking,
    onSuccess: (data) => {
      if (data.itemsList) {
        setReportAirlineBookingData(data.itemsList);
      }
    },
  });

  const {
    mutateAsync: reportCommissionStats,
    isLoading: isLoadingReportCommissionStats,
  } = useAppMutation({
    mutationKey: ['reportServicePartnerShip', 'reportCommissionStats'],
    mutationFn: reportService.reportCommissionStats,
    onSuccess(data) {
      if (data.isSuccess && data.data) {
        setReportCommissionStatsData(data.data);
      }
    },
  });

  async function onSubmit(data: TFormFilter) {
    try {
      const startDate = data.filterDate.startDate || new Date();
      const endDate = data.filterDate.endDate || new Date();

      const fromDate = new Date(startDate);
      fromDate.setHours(0, 0, 0, 0);

      const filter = {
        from: fromDate.getTime(),
        to: endDate.getTime() || Date.now(),
      };
      await fetchReportBooking(filter);
      await fetchReportAirlineBooking(filter);
      await reportCommissionStats({
        ...filter,
        bookingCode: '',
        status: ECommissionStatus.CNONE,
      });
      // await getBookingStats({
      //   userId: data.userId,
      // });

      setSaveFilter(filter);
    } catch (error) {}
  }

  useEffect(() => {
    methods.handleSubmit(onSubmit)();
  }, []);

  const reportBookingDataByDate = useMemo(() => {
    if (reportBookingData.length) {
      return convertArrayToObject(
        reportBookingData.map((item) => ({
          ...item,
          formatDate: formatDate(item.date),
        })),
        'formatDate'
      );
    }
  }, [reportBookingData]);

  return {
    filterMethods: methods,
    // partnerUserOptions:
    //   resListPartnerUser?.data?.itemsList?.map((item) => ({
    //     label: `${item.name} (${item.userName || '-'})`,
    //     value: item.id,
    //   })) || [],
    reportCommissionStatsData,
    isLoading:
      isLoadingReportAirlineBooking ||
      isLoadingReportBooking ||
      isLoadingReportCommissionStats,
    reportBookingData,
    reportAirlineBookingData,
    reportBookingDataByDate,
    onSubmit,
    saveFilter,
  };
}

export default useDashboard;
