// source: partner/base.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var validate_validate_pb = require('../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.partner.Company', null, global);
goog.exportSymbol('proto.partner.DCPAmadeus', null, global);
goog.exportSymbol('proto.partner.DCPEV', null, global);
goog.exportSymbol('proto.partner.DCPInternationalEV', null, global);
goog.exportSymbol('proto.partner.DCPLevel', null, global);
goog.exportSymbol('proto.partner.DCPTravelFusion', null, global);
goog.exportSymbol('proto.partner.DCPVietjetAir', null, global);
goog.exportSymbol('proto.partner.DCPVietnamAirlines', null, global);
goog.exportSymbol('proto.partner.DCPs', null, global);
goog.exportSymbol('proto.partner.Domain', null, global);
goog.exportSymbol('proto.partner.Hotel', null, global);
goog.exportSymbol('proto.partner.HotelPriceConditionConfig', null, global);
goog.exportSymbol('proto.partner.HotelProvider', null, global);
goog.exportSymbol('proto.partner.InvoicingInformation', null, global);
goog.exportSymbol('proto.partner.ListItem', null, global);
goog.exportSymbol('proto.partner.OfficeInfo', null, global);
goog.exportSymbol('proto.partner.OfficeStatus', null, global);
goog.exportSymbol('proto.partner.PartnerShopBasicInfo', null, global);
goog.exportSymbol('proto.partner.PartnerShopInfo', null, global);
goog.exportSymbol('proto.partner.Provider', null, global);
goog.exportSymbol('proto.partner.ProviderConfig', null, global);
goog.exportSymbol('proto.partner.Receiver', null, global);
goog.exportSymbol('proto.partner.UserBasicInfo', null, global);
goog.exportSymbol('proto.partner.UserInfo', null, global);
goog.exportSymbol('proto.partner.UserStatus', null, global);
goog.exportSymbol('proto.partner.WebHookCfg', null, global);
goog.exportSymbol('proto.partner.WebhookURLConfig', null, global);
goog.exportSymbol('proto.partner.WebhookUrlCfg', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.ListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.ListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.ListItem.displayName = 'proto.partner.ListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.Provider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.Provider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.Provider.displayName = 'proto.partner.Provider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.Domain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.Domain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.Domain.displayName = 'proto.partner.Domain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.OfficeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.OfficeInfo.repeatedFields_, null);
};
goog.inherits(proto.partner.OfficeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.OfficeInfo.displayName = 'proto.partner.OfficeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.WebhookURLConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.WebhookURLConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.WebhookURLConfig.displayName = 'proto.partner.WebhookURLConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.UserInfo.repeatedFields_, null);
};
goog.inherits(proto.partner.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.UserInfo.displayName = 'proto.partner.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.WebhookUrlCfg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.WebhookUrlCfg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.WebhookUrlCfg.displayName = 'proto.partner.WebhookUrlCfg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.WebHookCfg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.WebHookCfg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.WebHookCfg.displayName = 'proto.partner.WebHookCfg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.ProviderConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.ProviderConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.ProviderConfig.displayName = 'proto.partner.ProviderConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.PartnerShopInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.PartnerShopInfo.repeatedFields_, null);
};
goog.inherits(proto.partner.PartnerShopInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.PartnerShopInfo.displayName = 'proto.partner.PartnerShopInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.HotelPriceConditionConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.HotelPriceConditionConfig.repeatedFields_, null);
};
goog.inherits(proto.partner.HotelPriceConditionConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.HotelPriceConditionConfig.displayName = 'proto.partner.HotelPriceConditionConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.Hotel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.Hotel.repeatedFields_, null);
};
goog.inherits(proto.partner.Hotel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.Hotel.displayName = 'proto.partner.Hotel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.HotelProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.HotelProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.HotelProvider.displayName = 'proto.partner.HotelProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.PartnerShopBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.PartnerShopBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.PartnerShopBasicInfo.displayName = 'proto.partner.PartnerShopBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.DCPs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.DCPs.repeatedFields_, null);
};
goog.inherits(proto.partner.DCPs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.DCPs.displayName = 'proto.partner.DCPs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.DCPTravelFusion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.DCPTravelFusion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.DCPTravelFusion.displayName = 'proto.partner.DCPTravelFusion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.DCPAmadeus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.DCPAmadeus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.DCPAmadeus.displayName = 'proto.partner.DCPAmadeus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.DCPVietnamAirlines = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.DCPVietnamAirlines, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.DCPVietnamAirlines.displayName = 'proto.partner.DCPVietnamAirlines';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.DCPVietjetAir = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.DCPVietjetAir, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.DCPVietjetAir.displayName = 'proto.partner.DCPVietjetAir';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.DCPInternationalEV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.DCPInternationalEV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.DCPInternationalEV.displayName = 'proto.partner.DCPInternationalEV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.DCPEV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.DCPEV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.DCPEV.displayName = 'proto.partner.DCPEV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.UserBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.UserBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.UserBasicInfo.displayName = 'proto.partner.UserBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.Company.displayName = 'proto.partner.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.Receiver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.Receiver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.Receiver.displayName = 'proto.partner.Receiver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.InvoicingInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.InvoicingInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.InvoicingInformation.displayName = 'proto.partner.InvoicingInformation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.ListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.ListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.ListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.ListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.ListItem}
 */
proto.partner.ListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.ListItem;
  return proto.partner.ListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.ListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.ListItem}
 */
proto.partner.ListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.ListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.ListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.ListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.ListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.ListItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.ListItem} returns this
 */
proto.partner.ListItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.partner.ListItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.ListItem} returns this
 */
proto.partner.ListItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.Provider.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.Provider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.Provider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Provider.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.Provider}
 */
proto.partner.Provider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.Provider;
  return proto.partner.Provider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.Provider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.Provider}
 */
proto.partner.Provider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.Provider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.Provider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.Provider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Provider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.Provider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Provider} returns this
 */
proto.partner.Provider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.partner.Provider.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.Provider} returns this
 */
proto.partner.Provider.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.Domain.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.Domain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.Domain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Domain.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.Domain}
 */
proto.partner.Domain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.Domain;
  return proto.partner.Domain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.Domain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.Domain}
 */
proto.partner.Domain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.Domain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.Domain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.Domain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Domain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.Domain.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Domain} returns this
 */
proto.partner.Domain.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.partner.Domain.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.Domain} returns this
 */
proto.partner.Domain.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.OfficeInfo.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.OfficeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.OfficeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.OfficeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.OfficeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    shopName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    providerConfigsList: jspb.Message.toObjectList(msg.getProviderConfigsList(),
    proto.partner.ProviderConfig.toObject, includeInstance),
    domain: (f = msg.getDomain()) && proto.partner.Domain.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    agentCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    managerName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    managerId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 14, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 15, ""),
    saleCode: jspb.Message.getFieldWithDefault(msg, 16, ""),
    referralCode: jspb.Message.getFieldWithDefault(msg, 17, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 18, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 19, ""),
    caCode: jspb.Message.getFieldWithDefault(msg, 20, ""),
    caName: jspb.Message.getFieldWithDefault(msg, 21, ""),
    officeId: jspb.Message.getFieldWithDefault(msg, 22, ""),
    agentPhoneCode: jspb.Message.getFieldWithDefault(msg, 23, ""),
    agentPhoneNumber: jspb.Message.getFieldWithDefault(msg, 24, ""),
    agentEmail: jspb.Message.getFieldWithDefault(msg, 25, ""),
    invoicingInfo: (f = msg.getInvoicingInfo()) && proto.partner.InvoicingInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.OfficeInfo}
 */
proto.partner.OfficeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.OfficeInfo;
  return proto.partner.OfficeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.OfficeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.OfficeInfo}
 */
proto.partner.OfficeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 7:
      var value = new proto.partner.ProviderConfig;
      reader.readMessage(value,proto.partner.ProviderConfig.deserializeBinaryFromReader);
      msg.addProviderConfigs(value);
      break;
    case 8:
      var value = new proto.partner.Domain;
      reader.readMessage(value,proto.partner.Domain.deserializeBinaryFromReader);
      msg.setDomain(value);
      break;
    case 9:
      var value = /** @type {!proto.partner.OfficeStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaleCode(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferralCode(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaCode(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaName(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentPhoneCode(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentPhoneNumber(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentEmail(value);
      break;
    case 26:
      var value = new proto.partner.InvoicingInformation;
      reader.readMessage(value,proto.partner.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.OfficeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.OfficeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.OfficeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.OfficeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProviderConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.partner.ProviderConfig.serializeBinaryToWriter
    );
  }
  f = message.getDomain();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.partner.Domain.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getManagerName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCaCode();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCaName();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(
      23,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAgentEmail();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getInvoicingInfo();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.partner.InvoicingInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string shop_name = 6;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated ProviderConfig provider_configs = 7;
 * @return {!Array<!proto.partner.ProviderConfig>}
 */
proto.partner.OfficeInfo.prototype.getProviderConfigsList = function() {
  return /** @type{!Array<!proto.partner.ProviderConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.ProviderConfig, 7));
};


/**
 * @param {!Array<!proto.partner.ProviderConfig>} value
 * @return {!proto.partner.OfficeInfo} returns this
*/
proto.partner.OfficeInfo.prototype.setProviderConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.partner.ProviderConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.ProviderConfig}
 */
proto.partner.OfficeInfo.prototype.addProviderConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.partner.ProviderConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.clearProviderConfigsList = function() {
  return this.setProviderConfigsList([]);
};


/**
 * optional Domain domain = 8;
 * @return {?proto.partner.Domain}
 */
proto.partner.OfficeInfo.prototype.getDomain = function() {
  return /** @type{?proto.partner.Domain} */ (
    jspb.Message.getWrapperField(this, proto.partner.Domain, 8));
};


/**
 * @param {?proto.partner.Domain|undefined} value
 * @return {!proto.partner.OfficeInfo} returns this
*/
proto.partner.OfficeInfo.prototype.setDomain = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.clearDomain = function() {
  return this.setDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.OfficeInfo.prototype.hasDomain = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional OfficeStatus status = 9;
 * @return {!proto.partner.OfficeStatus}
 */
proto.partner.OfficeInfo.prototype.getStatus = function() {
  return /** @type {!proto.partner.OfficeStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.partner.OfficeStatus} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string agent_code = 10;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string owner_id = 11;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string manager_name = 12;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getManagerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setManagerName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string manager_id = 13;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string logo = 14;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string branch = 15;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string sale_code = 16;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getSaleCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setSaleCode = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.clearSaleCode = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.OfficeInfo.prototype.hasSaleCode = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string referral_code = 17;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getReferralCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setReferralCode = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.clearReferralCode = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.OfficeInfo.prototype.hasReferralCode = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string phone_code = 18;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string phone_number = 19;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string ca_code = 20;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getCaCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setCaCode = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string ca_name = 21;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getCaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setCaName = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string office_id = 22;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string agent_phone_code = 23;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getAgentPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setAgentPhoneCode = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.clearAgentPhoneCode = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.OfficeInfo.prototype.hasAgentPhoneCode = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string agent_phone_number = 24;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getAgentPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setAgentPhoneNumber = function(value) {
  return jspb.Message.setField(this, 24, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.clearAgentPhoneNumber = function() {
  return jspb.Message.setField(this, 24, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.OfficeInfo.prototype.hasAgentPhoneNumber = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string agent_email = 25;
 * @return {string}
 */
proto.partner.OfficeInfo.prototype.getAgentEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.setAgentEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional InvoicingInformation invoicing_info = 26;
 * @return {?proto.partner.InvoicingInformation}
 */
proto.partner.OfficeInfo.prototype.getInvoicingInfo = function() {
  return /** @type{?proto.partner.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, proto.partner.InvoicingInformation, 26));
};


/**
 * @param {?proto.partner.InvoicingInformation|undefined} value
 * @return {!proto.partner.OfficeInfo} returns this
*/
proto.partner.OfficeInfo.prototype.setInvoicingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.OfficeInfo} returns this
 */
proto.partner.OfficeInfo.prototype.clearInvoicingInfo = function() {
  return this.setInvoicingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.OfficeInfo.prototype.hasInvoicingInfo = function() {
  return jspb.Message.getField(this, 26) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.WebhookURLConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.WebhookURLConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.WebhookURLConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.WebhookURLConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastTktDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.WebhookURLConfig}
 */
proto.partner.WebhookURLConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.WebhookURLConfig;
  return proto.partner.WebhookURLConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.WebhookURLConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.WebhookURLConfig}
 */
proto.partner.WebhookURLConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransaction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastTktDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.WebhookURLConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.WebhookURLConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.WebhookURLConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.WebhookURLConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastTktDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string transaction = 1;
 * @return {string}
 */
proto.partner.WebhookURLConfig.prototype.getTransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.WebhookURLConfig} returns this
 */
proto.partner.WebhookURLConfig.prototype.setTransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_tkt_date = 2;
 * @return {string}
 */
proto.partner.WebhookURLConfig.prototype.getLastTktDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.WebhookURLConfig} returns this
 */
proto.partner.WebhookURLConfig.prototype.setLastTktDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.UserInfo.repeatedFields_ = [19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    password: jspb.Message.getFieldWithDefault(msg, 7, ""),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 9, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 11, ""),
    partnerShopId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    status: jspb.Message.getFieldWithDefault(msg, 14, 0),
    webhookUrl: jspb.Message.getFieldWithDefault(msg, 15, ""),
    webhookKey: jspb.Message.getFieldWithDefault(msg, 16, ""),
    shopCode: jspb.Message.getFieldWithDefault(msg, 17, ""),
    shopName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    webhookUrlConfig: (f = msg.getWebhookUrlConfig()) && proto.partner.WebhookURLConfig.toObject(includeInstance, f),
    agentGroup: jspb.Message.getFieldWithDefault(msg, 21, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 22, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.UserInfo}
 */
proto.partner.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.UserInfo;
  return proto.partner.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.UserInfo}
 */
proto.partner.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerShopId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 14:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebhookUrl(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebhookKey(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopCode(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    case 20:
      var value = new proto.partner.WebhookURLConfig;
      reader.readMessage(value,proto.partner.WebhookURLConfig.deserializeBinaryFromReader);
      msg.setWebhookUrlConfig(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentGroup(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPartnerShopId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getWebhookUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getWebhookKey();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getShopCode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getWebhookUrlConfig();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.partner.WebhookURLConfig.serializeBinaryToWriter
    );
  }
  f = message.getAgentGroup();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.partner.UserInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.partner.UserInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string created_by = 4;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string updated_by = 5;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string password = 7;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string profile_picture = 9;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string partnership_id = 10;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string phone = 11;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string partner_shop_id = 12;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getPartnerShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setPartnerShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string user_name = 13;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional UserStatus status = 14;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.UserInfo.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string webhook_url = 15;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getWebhookUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setWebhookUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string webhook_key = 16;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getWebhookKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setWebhookKey = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string shop_code = 17;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getShopCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setShopCode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string shop_name = 18;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated string roles = 19;
 * @return {!Array<string>}
 */
proto.partner.UserInfo.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional WebhookURLConfig webhook_url_config = 20;
 * @return {?proto.partner.WebhookURLConfig}
 */
proto.partner.UserInfo.prototype.getWebhookUrlConfig = function() {
  return /** @type{?proto.partner.WebhookURLConfig} */ (
    jspb.Message.getWrapperField(this, proto.partner.WebhookURLConfig, 20));
};


/**
 * @param {?proto.partner.WebhookURLConfig|undefined} value
 * @return {!proto.partner.UserInfo} returns this
*/
proto.partner.UserInfo.prototype.setWebhookUrlConfig = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.clearWebhookUrlConfig = function() {
  return this.setWebhookUrlConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.UserInfo.prototype.hasWebhookUrlConfig = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string agent_group = 21;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getAgentGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setAgentGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string phone_code = 22;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string phone_number = 23;
 * @return {string}
 */
proto.partner.UserInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserInfo} returns this
 */
proto.partner.UserInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.WebhookUrlCfg.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.WebhookUrlCfg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.WebhookUrlCfg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.WebhookUrlCfg.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastTktDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.WebhookUrlCfg}
 */
proto.partner.WebhookUrlCfg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.WebhookUrlCfg;
  return proto.partner.WebhookUrlCfg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.WebhookUrlCfg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.WebhookUrlCfg}
 */
proto.partner.WebhookUrlCfg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransaction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastTktDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.WebhookUrlCfg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.WebhookUrlCfg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.WebhookUrlCfg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.WebhookUrlCfg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastTktDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string transaction = 1;
 * @return {string}
 */
proto.partner.WebhookUrlCfg.prototype.getTransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.WebhookUrlCfg} returns this
 */
proto.partner.WebhookUrlCfg.prototype.setTransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_tkt_date = 2;
 * @return {string}
 */
proto.partner.WebhookUrlCfg.prototype.getLastTktDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.WebhookUrlCfg} returns this
 */
proto.partner.WebhookUrlCfg.prototype.setLastTktDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.WebHookCfg.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.WebHookCfg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.WebHookCfg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.WebHookCfg.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    urlConfig: (f = msg.getUrlConfig()) && proto.partner.WebhookUrlCfg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.WebHookCfg}
 */
proto.partner.WebHookCfg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.WebHookCfg;
  return proto.partner.WebHookCfg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.WebHookCfg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.WebHookCfg}
 */
proto.partner.WebHookCfg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = new proto.partner.WebhookUrlCfg;
      reader.readMessage(value,proto.partner.WebhookUrlCfg.deserializeBinaryFromReader);
      msg.setUrlConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.WebHookCfg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.WebHookCfg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.WebHookCfg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.WebHookCfg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrlConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.WebhookUrlCfg.serializeBinaryToWriter
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.partner.WebHookCfg.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.WebHookCfg} returns this
 */
proto.partner.WebHookCfg.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.partner.WebHookCfg.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.WebHookCfg} returns this
 */
proto.partner.WebHookCfg.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional WebhookUrlCfg url_config = 3;
 * @return {?proto.partner.WebhookUrlCfg}
 */
proto.partner.WebHookCfg.prototype.getUrlConfig = function() {
  return /** @type{?proto.partner.WebhookUrlCfg} */ (
    jspb.Message.getWrapperField(this, proto.partner.WebhookUrlCfg, 3));
};


/**
 * @param {?proto.partner.WebhookUrlCfg|undefined} value
 * @return {!proto.partner.WebHookCfg} returns this
*/
proto.partner.WebHookCfg.prototype.setUrlConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.WebHookCfg} returns this
 */
proto.partner.WebHookCfg.prototype.clearUrlConfig = function() {
  return this.setUrlConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.WebHookCfg.prototype.hasUrlConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.ProviderConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.ProviderConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.ProviderConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.ProviderConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.ProviderConfig}
 */
proto.partner.ProviderConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.ProviderConfig;
  return proto.partner.ProviderConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.ProviderConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.ProviderConfig}
 */
proto.partner.ProviderConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.ProviderConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.ProviderConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.ProviderConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.ProviderConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.ProviderConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.ProviderConfig} returns this
 */
proto.partner.ProviderConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.partner.ProviderConfig.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.ProviderConfig} returns this
 */
proto.partner.ProviderConfig.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool enabled = 3;
 * @return {boolean}
 */
proto.partner.ProviderConfig.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.ProviderConfig} returns this
 */
proto.partner.ProviderConfig.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.PartnerShopInfo.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.PartnerShopInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.PartnerShopInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.PartnerShopInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.PartnerShopInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    officeId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    webhook: (f = msg.getWebhook()) && proto.partner.WebHookCfg.toObject(includeInstance, f),
    dcps: (f = msg.getDcps()) && proto.partner.DCPs.toObject(includeInstance, f),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    providerConfigsList: jspb.Message.toObjectList(msg.getProviderConfigsList(),
    proto.partner.ProviderConfig.toObject, includeInstance),
    managerId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, 0),
    hotel: (f = msg.getHotel()) && proto.partner.Hotel.toObject(includeInstance, f),
    logo: jspb.Message.getFieldWithDefault(msg, 14, ""),
    email: jspb.Message.getFieldWithDefault(msg, 15, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 16, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 17, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    caName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    caCode: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.PartnerShopInfo}
 */
proto.partner.PartnerShopInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.PartnerShopInfo;
  return proto.partner.PartnerShopInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.PartnerShopInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.PartnerShopInfo}
 */
proto.partner.PartnerShopInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPartnerType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 7:
      var value = new proto.partner.WebHookCfg;
      reader.readMessage(value,proto.partner.WebHookCfg.deserializeBinaryFromReader);
      msg.setWebhook(value);
      break;
    case 8:
      var value = new proto.partner.DCPs;
      reader.readMessage(value,proto.partner.DCPs.deserializeBinaryFromReader);
      msg.setDcps(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 10:
      var value = new proto.partner.ProviderConfig;
      reader.readMessage(value,proto.partner.ProviderConfig.deserializeBinaryFromReader);
      msg.addProviderConfigs(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 12:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 13:
      var value = new proto.partner.Hotel;
      reader.readMessage(value,proto.partner.Hotel.deserializeBinaryFromReader);
      msg.setHotel(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaName(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.PartnerShopInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.PartnerShopInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.PartnerShopInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.PartnerShopInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnerType();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWebhook();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.partner.WebHookCfg.serializeBinaryToWriter
    );
  }
  f = message.getDcps();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.partner.DCPs.serializeBinaryToWriter
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProviderConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.partner.ProviderConfig.serializeBinaryToWriter
    );
  }
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getHotel();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.partner.Hotel.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCaName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCaCode();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owner = 3;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 partner_type = 4;
 * @return {number}
 */
proto.partner.PartnerShopInfo.prototype.getPartnerType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setPartnerType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string code = 5;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string office_id = 6;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional WebHookCfg webhook = 7;
 * @return {?proto.partner.WebHookCfg}
 */
proto.partner.PartnerShopInfo.prototype.getWebhook = function() {
  return /** @type{?proto.partner.WebHookCfg} */ (
    jspb.Message.getWrapperField(this, proto.partner.WebHookCfg, 7));
};


/**
 * @param {?proto.partner.WebHookCfg|undefined} value
 * @return {!proto.partner.PartnerShopInfo} returns this
*/
proto.partner.PartnerShopInfo.prototype.setWebhook = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.clearWebhook = function() {
  return this.setWebhook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.PartnerShopInfo.prototype.hasWebhook = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DCPs dcps = 8;
 * @return {?proto.partner.DCPs}
 */
proto.partner.PartnerShopInfo.prototype.getDcps = function() {
  return /** @type{?proto.partner.DCPs} */ (
    jspb.Message.getWrapperField(this, proto.partner.DCPs, 8));
};


/**
 * @param {?proto.partner.DCPs|undefined} value
 * @return {!proto.partner.PartnerShopInfo} returns this
*/
proto.partner.PartnerShopInfo.prototype.setDcps = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.clearDcps = function() {
  return this.setDcps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.PartnerShopInfo.prototype.hasDcps = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string partnership_id = 9;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated ProviderConfig provider_configs = 10;
 * @return {!Array<!proto.partner.ProviderConfig>}
 */
proto.partner.PartnerShopInfo.prototype.getProviderConfigsList = function() {
  return /** @type{!Array<!proto.partner.ProviderConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.ProviderConfig, 10));
};


/**
 * @param {!Array<!proto.partner.ProviderConfig>} value
 * @return {!proto.partner.PartnerShopInfo} returns this
*/
proto.partner.PartnerShopInfo.prototype.setProviderConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.partner.ProviderConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.ProviderConfig}
 */
proto.partner.PartnerShopInfo.prototype.addProviderConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.partner.ProviderConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.clearProviderConfigsList = function() {
  return this.setProviderConfigsList([]);
};


/**
 * optional string manager_id = 11;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional UserStatus status = 12;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.PartnerShopInfo.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional Hotel hotel = 13;
 * @return {?proto.partner.Hotel}
 */
proto.partner.PartnerShopInfo.prototype.getHotel = function() {
  return /** @type{?proto.partner.Hotel} */ (
    jspb.Message.getWrapperField(this, proto.partner.Hotel, 13));
};


/**
 * @param {?proto.partner.Hotel|undefined} value
 * @return {!proto.partner.PartnerShopInfo} returns this
*/
proto.partner.PartnerShopInfo.prototype.setHotel = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.clearHotel = function() {
  return this.setHotel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.PartnerShopInfo.prototype.hasHotel = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string logo = 14;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string email = 15;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string phone = 16;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string phone_code = 17;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string phone_number = 18;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string ca_name = 19;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getCaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setCaName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string ca_code = 20;
 * @return {string}
 */
proto.partner.PartnerShopInfo.prototype.getCaCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopInfo} returns this
 */
proto.partner.PartnerShopInfo.prototype.setCaCode = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.HotelPriceConditionConfig.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.HotelPriceConditionConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.HotelPriceConditionConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.HotelPriceConditionConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.HotelPriceConditionConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    providerOrderList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.HotelPriceConditionConfig}
 */
proto.partner.HotelPriceConditionConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.HotelPriceConditionConfig;
  return proto.partner.HotelPriceConditionConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.HotelPriceConditionConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.HotelPriceConditionConfig}
 */
proto.partner.HotelPriceConditionConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviderOrder(values[i]);
      }
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.HotelPriceConditionConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.HotelPriceConditionConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.HotelPriceConditionConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.HotelPriceConditionConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getProviderOrderList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional double percent = 1;
 * @return {number}
 */
proto.partner.HotelPriceConditionConfig.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.partner.HotelPriceConditionConfig} returns this
 */
proto.partner.HotelPriceConditionConfig.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.partner.HotelPriceConditionConfig.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.partner.HotelPriceConditionConfig} returns this
 */
proto.partner.HotelPriceConditionConfig.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated int64 provider_order = 3;
 * @return {!Array<number>}
 */
proto.partner.HotelPriceConditionConfig.prototype.getProviderOrderList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.partner.HotelPriceConditionConfig} returns this
 */
proto.partner.HotelPriceConditionConfig.prototype.setProviderOrderList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.partner.HotelPriceConditionConfig} returns this
 */
proto.partner.HotelPriceConditionConfig.prototype.addProviderOrder = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.HotelPriceConditionConfig} returns this
 */
proto.partner.HotelPriceConditionConfig.prototype.clearProviderOrderList = function() {
  return this.setProviderOrderList([]);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.partner.HotelPriceConditionConfig.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.HotelPriceConditionConfig} returns this
 */
proto.partner.HotelPriceConditionConfig.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.Hotel.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.Hotel.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.Hotel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.Hotel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Hotel.toObject = function(includeInstance, msg) {
  var f, obj = {
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    proto.partner.HotelProvider.toObject, includeInstance),
    webhook: (f = msg.getWebhook()) && proto.partner.WebHookCfg.toObject(includeInstance, f),
    defaultLanguage: jspb.Message.getFieldWithDefault(msg, 4, ""),
    priceConditionConfig: (f = msg.getPriceConditionConfig()) && proto.partner.HotelPriceConditionConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.Hotel}
 */
proto.partner.Hotel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.Hotel;
  return proto.partner.Hotel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.Hotel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.Hotel}
 */
proto.partner.Hotel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 2:
      var value = new proto.partner.HotelProvider;
      reader.readMessage(value,proto.partner.HotelProvider.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    case 3:
      var value = new proto.partner.WebHookCfg;
      reader.readMessage(value,proto.partner.WebHookCfg.deserializeBinaryFromReader);
      msg.setWebhook(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultLanguage(value);
      break;
    case 5:
      var value = new proto.partner.HotelPriceConditionConfig;
      reader.readMessage(value,proto.partner.HotelPriceConditionConfig.deserializeBinaryFromReader);
      msg.setPriceConditionConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.Hotel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.Hotel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.Hotel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Hotel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.partner.HotelProvider.serializeBinaryToWriter
    );
  }
  f = message.getWebhook();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.WebHookCfg.serializeBinaryToWriter
    );
  }
  f = message.getDefaultLanguage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPriceConditionConfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.partner.HotelPriceConditionConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool enable = 1;
 * @return {boolean}
 */
proto.partner.Hotel.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.Hotel} returns this
 */
proto.partner.Hotel.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated HotelProvider providers = 2;
 * @return {!Array<!proto.partner.HotelProvider>}
 */
proto.partner.Hotel.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.partner.HotelProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.HotelProvider, 2));
};


/**
 * @param {!Array<!proto.partner.HotelProvider>} value
 * @return {!proto.partner.Hotel} returns this
*/
proto.partner.Hotel.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.partner.HotelProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.HotelProvider}
 */
proto.partner.Hotel.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.partner.HotelProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.Hotel} returns this
 */
proto.partner.Hotel.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * optional WebHookCfg webhook = 3;
 * @return {?proto.partner.WebHookCfg}
 */
proto.partner.Hotel.prototype.getWebhook = function() {
  return /** @type{?proto.partner.WebHookCfg} */ (
    jspb.Message.getWrapperField(this, proto.partner.WebHookCfg, 3));
};


/**
 * @param {?proto.partner.WebHookCfg|undefined} value
 * @return {!proto.partner.Hotel} returns this
*/
proto.partner.Hotel.prototype.setWebhook = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.Hotel} returns this
 */
proto.partner.Hotel.prototype.clearWebhook = function() {
  return this.setWebhook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.Hotel.prototype.hasWebhook = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string default_language = 4;
 * @return {string}
 */
proto.partner.Hotel.prototype.getDefaultLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Hotel} returns this
 */
proto.partner.Hotel.prototype.setDefaultLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional HotelPriceConditionConfig price_condition_config = 5;
 * @return {?proto.partner.HotelPriceConditionConfig}
 */
proto.partner.Hotel.prototype.getPriceConditionConfig = function() {
  return /** @type{?proto.partner.HotelPriceConditionConfig} */ (
    jspb.Message.getWrapperField(this, proto.partner.HotelPriceConditionConfig, 5));
};


/**
 * @param {?proto.partner.HotelPriceConditionConfig|undefined} value
 * @return {!proto.partner.Hotel} returns this
*/
proto.partner.Hotel.prototype.setPriceConditionConfig = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.Hotel} returns this
 */
proto.partner.Hotel.prototype.clearPriceConditionConfig = function() {
  return this.setPriceConditionConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.Hotel.prototype.hasPriceConditionConfig = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.HotelProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.HotelProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.HotelProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.HotelProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.HotelProvider}
 */
proto.partner.HotelProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.HotelProvider;
  return proto.partner.HotelProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.HotelProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.HotelProvider}
 */
proto.partner.HotelProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProvider(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.HotelProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.HotelProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.HotelProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.HotelProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 provider = 1;
 * @return {number}
 */
proto.partner.HotelProvider.prototype.getProvider = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.HotelProvider} returns this
 */
proto.partner.HotelProvider.prototype.setProvider = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partner.HotelProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.HotelProvider} returns this
 */
proto.partner.HotelProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool enable = 3;
 * @return {boolean}
 */
proto.partner.HotelProvider.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.HotelProvider} returns this
 */
proto.partner.HotelProvider.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.PartnerShopBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.PartnerShopBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.PartnerShopBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.PartnerShopBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    code: jspb.Message.getFieldWithDefault(msg, 6, ""),
    officeId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 9, ""),
    email: jspb.Message.getFieldWithDefault(msg, 10, ""),
    caCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    caName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    invoicingInfo: (f = msg.getInvoicingInfo()) && proto.partner.InvoicingInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.PartnerShopBasicInfo}
 */
proto.partner.PartnerShopBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.PartnerShopBasicInfo;
  return proto.partner.PartnerShopBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.PartnerShopBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.PartnerShopBasicInfo}
 */
proto.partner.PartnerShopBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPartnerType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 15:
      var value = new proto.partner.InvoicingInformation;
      reader.readMessage(value,proto.partner.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.PartnerShopBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.PartnerShopBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.PartnerShopBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.PartnerShopBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnerType();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCaCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCaName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getInvoicingInfo();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.partner.InvoicingInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partnership_id = 2;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owner = 3;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 partner_type = 4;
 * @return {number}
 */
proto.partner.PartnerShopBasicInfo.prototype.getPartnerType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setPartnerType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string code = 6;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string office_id = 7;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string logo = 8;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phone = 9;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string email = 10;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string ca_code = 11;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getCaCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setCaCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ca_name = 12;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getCaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setCaName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string phone_code = 13;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string phone_number = 14;
 * @return {string}
 */
proto.partner.PartnerShopBasicInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional InvoicingInformation invoicing_info = 15;
 * @return {?proto.partner.InvoicingInformation}
 */
proto.partner.PartnerShopBasicInfo.prototype.getInvoicingInfo = function() {
  return /** @type{?proto.partner.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, proto.partner.InvoicingInformation, 15));
};


/**
 * @param {?proto.partner.InvoicingInformation|undefined} value
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
*/
proto.partner.PartnerShopBasicInfo.prototype.setInvoicingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.PartnerShopBasicInfo} returns this
 */
proto.partner.PartnerShopBasicInfo.prototype.clearInvoicingInfo = function() {
  return this.setInvoicingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.PartnerShopBasicInfo.prototype.hasInvoicingInfo = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.DCPs.repeatedFields_ = [8,9,10,11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.DCPs.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.DCPs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.DCPs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPs.toObject = function(includeInstance, msg) {
  var f, obj = {
    dcpsAmadeusList: jspb.Message.toObjectList(msg.getDcpsAmadeusList(),
    proto.partner.DCPAmadeus.toObject, includeInstance),
    dcpsTravelFusionList: jspb.Message.toObjectList(msg.getDcpsTravelFusionList(),
    proto.partner.DCPTravelFusion.toObject, includeInstance),
    dcpsVietnamAirlinesList: jspb.Message.toObjectList(msg.getDcpsVietnamAirlinesList(),
    proto.partner.DCPVietnamAirlines.toObject, includeInstance),
    dcpsVietjetAirList: jspb.Message.toObjectList(msg.getDcpsVietjetAirList(),
    proto.partner.DCPVietjetAir.toObject, includeInstance),
    dcpsEvList: jspb.Message.toObjectList(msg.getDcpsEvList(),
    proto.partner.DCPEV.toObject, includeInstance),
    dcpsInternationalEvList: jspb.Message.toObjectList(msg.getDcpsInternationalEvList(),
    proto.partner.DCPInternationalEV.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.DCPs}
 */
proto.partner.DCPs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.DCPs;
  return proto.partner.DCPs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.DCPs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.DCPs}
 */
proto.partner.DCPs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = new proto.partner.DCPAmadeus;
      reader.readMessage(value,proto.partner.DCPAmadeus.deserializeBinaryFromReader);
      msg.addDcpsAmadeus(value);
      break;
    case 9:
      var value = new proto.partner.DCPTravelFusion;
      reader.readMessage(value,proto.partner.DCPTravelFusion.deserializeBinaryFromReader);
      msg.addDcpsTravelFusion(value);
      break;
    case 10:
      var value = new proto.partner.DCPVietnamAirlines;
      reader.readMessage(value,proto.partner.DCPVietnamAirlines.deserializeBinaryFromReader);
      msg.addDcpsVietnamAirlines(value);
      break;
    case 11:
      var value = new proto.partner.DCPVietjetAir;
      reader.readMessage(value,proto.partner.DCPVietjetAir.deserializeBinaryFromReader);
      msg.addDcpsVietjetAir(value);
      break;
    case 12:
      var value = new proto.partner.DCPEV;
      reader.readMessage(value,proto.partner.DCPEV.deserializeBinaryFromReader);
      msg.addDcpsEv(value);
      break;
    case 13:
      var value = new proto.partner.DCPInternationalEV;
      reader.readMessage(value,proto.partner.DCPInternationalEV.deserializeBinaryFromReader);
      msg.addDcpsInternationalEv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.DCPs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.DCPs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.DCPs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDcpsAmadeusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.partner.DCPAmadeus.serializeBinaryToWriter
    );
  }
  f = message.getDcpsTravelFusionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.partner.DCPTravelFusion.serializeBinaryToWriter
    );
  }
  f = message.getDcpsVietnamAirlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.partner.DCPVietnamAirlines.serializeBinaryToWriter
    );
  }
  f = message.getDcpsVietjetAirList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.partner.DCPVietjetAir.serializeBinaryToWriter
    );
  }
  f = message.getDcpsEvList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.partner.DCPEV.serializeBinaryToWriter
    );
  }
  f = message.getDcpsInternationalEvList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.partner.DCPInternationalEV.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DCPAmadeus dcps_amadeus = 8;
 * @return {!Array<!proto.partner.DCPAmadeus>}
 */
proto.partner.DCPs.prototype.getDcpsAmadeusList = function() {
  return /** @type{!Array<!proto.partner.DCPAmadeus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.DCPAmadeus, 8));
};


/**
 * @param {!Array<!proto.partner.DCPAmadeus>} value
 * @return {!proto.partner.DCPs} returns this
*/
proto.partner.DCPs.prototype.setDcpsAmadeusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.partner.DCPAmadeus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.DCPAmadeus}
 */
proto.partner.DCPs.prototype.addDcpsAmadeus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.partner.DCPAmadeus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.DCPs} returns this
 */
proto.partner.DCPs.prototype.clearDcpsAmadeusList = function() {
  return this.setDcpsAmadeusList([]);
};


/**
 * repeated DCPTravelFusion dcps_travel_fusion = 9;
 * @return {!Array<!proto.partner.DCPTravelFusion>}
 */
proto.partner.DCPs.prototype.getDcpsTravelFusionList = function() {
  return /** @type{!Array<!proto.partner.DCPTravelFusion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.DCPTravelFusion, 9));
};


/**
 * @param {!Array<!proto.partner.DCPTravelFusion>} value
 * @return {!proto.partner.DCPs} returns this
*/
proto.partner.DCPs.prototype.setDcpsTravelFusionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.partner.DCPTravelFusion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.DCPTravelFusion}
 */
proto.partner.DCPs.prototype.addDcpsTravelFusion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.partner.DCPTravelFusion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.DCPs} returns this
 */
proto.partner.DCPs.prototype.clearDcpsTravelFusionList = function() {
  return this.setDcpsTravelFusionList([]);
};


/**
 * repeated DCPVietnamAirlines dcps_vietnam_airlines = 10;
 * @return {!Array<!proto.partner.DCPVietnamAirlines>}
 */
proto.partner.DCPs.prototype.getDcpsVietnamAirlinesList = function() {
  return /** @type{!Array<!proto.partner.DCPVietnamAirlines>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.DCPVietnamAirlines, 10));
};


/**
 * @param {!Array<!proto.partner.DCPVietnamAirlines>} value
 * @return {!proto.partner.DCPs} returns this
*/
proto.partner.DCPs.prototype.setDcpsVietnamAirlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.partner.DCPVietnamAirlines=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.DCPVietnamAirlines}
 */
proto.partner.DCPs.prototype.addDcpsVietnamAirlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.partner.DCPVietnamAirlines, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.DCPs} returns this
 */
proto.partner.DCPs.prototype.clearDcpsVietnamAirlinesList = function() {
  return this.setDcpsVietnamAirlinesList([]);
};


/**
 * repeated DCPVietjetAir dcps_vietjet_air = 11;
 * @return {!Array<!proto.partner.DCPVietjetAir>}
 */
proto.partner.DCPs.prototype.getDcpsVietjetAirList = function() {
  return /** @type{!Array<!proto.partner.DCPVietjetAir>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.DCPVietjetAir, 11));
};


/**
 * @param {!Array<!proto.partner.DCPVietjetAir>} value
 * @return {!proto.partner.DCPs} returns this
*/
proto.partner.DCPs.prototype.setDcpsVietjetAirList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.partner.DCPVietjetAir=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.DCPVietjetAir}
 */
proto.partner.DCPs.prototype.addDcpsVietjetAir = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.partner.DCPVietjetAir, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.DCPs} returns this
 */
proto.partner.DCPs.prototype.clearDcpsVietjetAirList = function() {
  return this.setDcpsVietjetAirList([]);
};


/**
 * repeated DCPEV dcps_ev = 12;
 * @return {!Array<!proto.partner.DCPEV>}
 */
proto.partner.DCPs.prototype.getDcpsEvList = function() {
  return /** @type{!Array<!proto.partner.DCPEV>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.DCPEV, 12));
};


/**
 * @param {!Array<!proto.partner.DCPEV>} value
 * @return {!proto.partner.DCPs} returns this
*/
proto.partner.DCPs.prototype.setDcpsEvList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.partner.DCPEV=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.DCPEV}
 */
proto.partner.DCPs.prototype.addDcpsEv = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.partner.DCPEV, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.DCPs} returns this
 */
proto.partner.DCPs.prototype.clearDcpsEvList = function() {
  return this.setDcpsEvList([]);
};


/**
 * repeated DCPInternationalEV dcps_international_ev = 13;
 * @return {!Array<!proto.partner.DCPInternationalEV>}
 */
proto.partner.DCPs.prototype.getDcpsInternationalEvList = function() {
  return /** @type{!Array<!proto.partner.DCPInternationalEV>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.DCPInternationalEV, 13));
};


/**
 * @param {!Array<!proto.partner.DCPInternationalEV>} value
 * @return {!proto.partner.DCPs} returns this
*/
proto.partner.DCPs.prototype.setDcpsInternationalEvList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.partner.DCPInternationalEV=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.DCPInternationalEV}
 */
proto.partner.DCPs.prototype.addDcpsInternationalEv = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.partner.DCPInternationalEV, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.DCPs} returns this
 */
proto.partner.DCPs.prototype.clearDcpsInternationalEvList = function() {
  return this.setDcpsInternationalEvList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.DCPTravelFusion.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.DCPTravelFusion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.DCPTravelFusion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPTravelFusion.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.DCPTravelFusion}
 */
proto.partner.DCPTravelFusion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.DCPTravelFusion;
  return proto.partner.DCPTravelFusion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.DCPTravelFusion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.DCPTravelFusion}
 */
proto.partner.DCPTravelFusion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 4:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.DCPTravelFusion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.DCPTravelFusion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.DCPTravelFusion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPTravelFusion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.partner.DCPTravelFusion.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPTravelFusion} returns this
 */
proto.partner.DCPTravelFusion.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.DCPTravelFusion.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPTravelFusion} returns this
 */
proto.partner.DCPTravelFusion.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string base_url = 3;
 * @return {string}
 */
proto.partner.DCPTravelFusion.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPTravelFusion} returns this
 */
proto.partner.DCPTravelFusion.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DCPLevel level = 4;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.DCPTravelFusion.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.DCPTravelFusion} returns this
 */
proto.partner.DCPTravelFusion.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.DCPAmadeus.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.DCPAmadeus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.DCPAmadeus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPAmadeus.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pseudoCityCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    agentDutyCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    requestorType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    posType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    level: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.DCPAmadeus}
 */
proto.partner.DCPAmadeus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.DCPAmadeus;
  return proto.partner.DCPAmadeus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.DCPAmadeus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.DCPAmadeus}
 */
proto.partner.DCPAmadeus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPseudoCityCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentDutyCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestorType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 8:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.DCPAmadeus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.DCPAmadeus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.DCPAmadeus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPAmadeus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPseudoCityCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAgentDutyCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRequestorType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPosType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.partner.DCPAmadeus.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPAmadeus} returns this
 */
proto.partner.DCPAmadeus.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.DCPAmadeus.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPAmadeus} returns this
 */
proto.partner.DCPAmadeus.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pseudo_city_code = 3;
 * @return {string}
 */
proto.partner.DCPAmadeus.prototype.getPseudoCityCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPAmadeus} returns this
 */
proto.partner.DCPAmadeus.prototype.setPseudoCityCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string agent_duty_code = 4;
 * @return {string}
 */
proto.partner.DCPAmadeus.prototype.getAgentDutyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPAmadeus} returns this
 */
proto.partner.DCPAmadeus.prototype.setAgentDutyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string requestor_type = 5;
 * @return {string}
 */
proto.partner.DCPAmadeus.prototype.getRequestorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPAmadeus} returns this
 */
proto.partner.DCPAmadeus.prototype.setRequestorType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 pos_type = 6;
 * @return {number}
 */
proto.partner.DCPAmadeus.prototype.getPosType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.DCPAmadeus} returns this
 */
proto.partner.DCPAmadeus.prototype.setPosType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string base_url = 7;
 * @return {string}
 */
proto.partner.DCPAmadeus.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPAmadeus} returns this
 */
proto.partner.DCPAmadeus.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional DCPLevel level = 8;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.DCPAmadeus.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.DCPAmadeus} returns this
 */
proto.partner.DCPAmadeus.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.DCPVietnamAirlines.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.DCPVietnamAirlines.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.DCPVietnamAirlines} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPVietnamAirlines.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stationNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pcc: jspb.Message.getFieldWithDefault(msg, 5, ""),
    printerLniata: jspb.Message.getFieldWithDefault(msg, 6, ""),
    printerCountry: jspb.Message.getFieldWithDefault(msg, 7, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 8, ""),
    organization: jspb.Message.getFieldWithDefault(msg, 9, ""),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    level: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.DCPVietnamAirlines}
 */
proto.partner.DCPVietnamAirlines.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.DCPVietnamAirlines;
  return proto.partner.DCPVietnamAirlines.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.DCPVietnamAirlines} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.DCPVietnamAirlines}
 */
proto.partner.DCPVietnamAirlines.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPcc(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrinterLniata(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrinterCountry(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 11:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.DCPVietnamAirlines.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.DCPVietnamAirlines.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.DCPVietnamAirlines} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPVietnamAirlines.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStationNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPcc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrinterLniata();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPrinterCountry();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string account_number = 1;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string station_number = 4;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getStationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setStationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pcc = 5;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getPcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setPcc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string printer_lniata = 6;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getPrinterLniata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setPrinterLniata = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string printer_country = 7;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getPrinterCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setPrinterCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string domain = 8;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string organization = 9;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string base_url = 10;
 * @return {string}
 */
proto.partner.DCPVietnamAirlines.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional DCPLevel level = 11;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.DCPVietnamAirlines.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.DCPVietnamAirlines} returns this
 */
proto.partner.DCPVietnamAirlines.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.DCPVietjetAir.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.DCPVietjetAir.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.DCPVietjetAir} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPVietjetAir.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.DCPVietjetAir}
 */
proto.partner.DCPVietjetAir.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.DCPVietjetAir;
  return proto.partner.DCPVietjetAir.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.DCPVietjetAir} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.DCPVietjetAir}
 */
proto.partner.DCPVietjetAir.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 4:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.DCPVietjetAir.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.DCPVietjetAir.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.DCPVietjetAir} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPVietjetAir.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.partner.DCPVietjetAir.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietjetAir} returns this
 */
proto.partner.DCPVietjetAir.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.DCPVietjetAir.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietjetAir} returns this
 */
proto.partner.DCPVietjetAir.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string base_url = 3;
 * @return {string}
 */
proto.partner.DCPVietjetAir.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPVietjetAir} returns this
 */
proto.partner.DCPVietjetAir.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DCPLevel level = 4;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.DCPVietjetAir.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.DCPVietjetAir} returns this
 */
proto.partner.DCPVietjetAir.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.DCPInternationalEV.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.DCPInternationalEV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.DCPInternationalEV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPInternationalEV.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loginUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 5, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    grantType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    level: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.DCPInternationalEV}
 */
proto.partner.DCPInternationalEV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.DCPInternationalEV;
  return proto.partner.DCPInternationalEV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.DCPInternationalEV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.DCPInternationalEV}
 */
proto.partner.DCPInternationalEV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrantType(value);
      break;
    case 8:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.DCPInternationalEV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.DCPInternationalEV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.DCPInternationalEV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPInternationalEV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoginUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGrantType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string base_url = 1;
 * @return {string}
 */
proto.partner.DCPInternationalEV.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPInternationalEV} returns this
 */
proto.partner.DCPInternationalEV.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string login_url = 2;
 * @return {string}
 */
proto.partner.DCPInternationalEV.prototype.getLoginUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPInternationalEV} returns this
 */
proto.partner.DCPInternationalEV.prototype.setLoginUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.partner.DCPInternationalEV.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPInternationalEV} returns this
 */
proto.partner.DCPInternationalEV.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.partner.DCPInternationalEV.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPInternationalEV} returns this
 */
proto.partner.DCPInternationalEV.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string client_secret = 5;
 * @return {string}
 */
proto.partner.DCPInternationalEV.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPInternationalEV} returns this
 */
proto.partner.DCPInternationalEV.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string client_id = 6;
 * @return {string}
 */
proto.partner.DCPInternationalEV.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPInternationalEV} returns this
 */
proto.partner.DCPInternationalEV.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string grant_type = 7;
 * @return {string}
 */
proto.partner.DCPInternationalEV.prototype.getGrantType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPInternationalEV} returns this
 */
proto.partner.DCPInternationalEV.prototype.setGrantType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional DCPLevel level = 8;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.DCPInternationalEV.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.DCPInternationalEV} returns this
 */
proto.partner.DCPInternationalEV.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.DCPEV.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.DCPEV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.DCPEV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPEV.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.DCPEV}
 */
proto.partner.DCPEV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.DCPEV;
  return proto.partner.DCPEV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.DCPEV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.DCPEV}
 */
proto.partner.DCPEV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 4:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.DCPEV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.DCPEV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.DCPEV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.DCPEV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.partner.DCPEV.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPEV} returns this
 */
proto.partner.DCPEV.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.DCPEV.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPEV} returns this
 */
proto.partner.DCPEV.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string base_url = 3;
 * @return {string}
 */
proto.partner.DCPEV.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.DCPEV} returns this
 */
proto.partner.DCPEV.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DCPLevel level = 4;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.DCPEV.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.DCPEV} returns this
 */
proto.partner.DCPEV.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.UserBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.UserBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.UserBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.UserBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partnerShopId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    shopCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    shopName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.UserBasicInfo}
 */
proto.partner.UserBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.UserBasicInfo;
  return proto.partner.UserBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.UserBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.UserBasicInfo}
 */
proto.partner.UserBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerShopId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 9:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.UserBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.UserBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.UserBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.UserBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartnerShopId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getShopCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partnership_id = 2;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string partner_shop_id = 3;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getPartnerShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setPartnerShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_name = 5;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string profile_picture = 8;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional UserStatus status = 9;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.UserBasicInfo.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string shop_code = 10;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getShopCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setShopCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string shop_name = 11;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone_code = 12;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string phone_number = 13;
 * @return {string}
 */
proto.partner.UserBasicInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.UserBasicInfo} returns this
 */
proto.partner.UserBasicInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.Company}
 */
proto.partner.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.Company;
  return proto.partner.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.Company}
 */
proto.partner.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Company} returns this
 */
proto.partner.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tax_code = 2;
 * @return {string}
 */
proto.partner.Company.prototype.getTaxCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Company} returns this
 */
proto.partner.Company.prototype.setTaxCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.partner.Company.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Company} returns this
 */
proto.partner.Company.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.Receiver.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.Receiver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.Receiver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Receiver.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    note: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.Receiver}
 */
proto.partner.Receiver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.Receiver;
  return proto.partner.Receiver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.Receiver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.Receiver}
 */
proto.partner.Receiver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.Receiver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.Receiver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.Receiver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.Receiver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.Receiver.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Receiver} returns this
 */
proto.partner.Receiver.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.partner.Receiver.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Receiver} returns this
 */
proto.partner.Receiver.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.partner.Receiver.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Receiver} returns this
 */
proto.partner.Receiver.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.partner.Receiver.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Receiver} returns this
 */
proto.partner.Receiver.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string note = 5;
 * @return {string}
 */
proto.partner.Receiver.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.Receiver} returns this
 */
proto.partner.Receiver.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.InvoicingInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.InvoicingInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.InvoicingInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.InvoicingInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyInfo: (f = msg.getCompanyInfo()) && proto.partner.Company.toObject(includeInstance, f),
    receiverInfo: (f = msg.getReceiverInfo()) && proto.partner.Receiver.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.InvoicingInformation}
 */
proto.partner.InvoicingInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.InvoicingInformation;
  return proto.partner.InvoicingInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.InvoicingInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.InvoicingInformation}
 */
proto.partner.InvoicingInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partner.Company;
      reader.readMessage(value,proto.partner.Company.deserializeBinaryFromReader);
      msg.setCompanyInfo(value);
      break;
    case 2:
      var value = new proto.partner.Receiver;
      reader.readMessage(value,proto.partner.Receiver.deserializeBinaryFromReader);
      msg.setReceiverInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.InvoicingInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.InvoicingInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.InvoicingInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.InvoicingInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partner.Company.serializeBinaryToWriter
    );
  }
  f = message.getReceiverInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.partner.Receiver.serializeBinaryToWriter
    );
  }
};


/**
 * optional Company company_info = 1;
 * @return {?proto.partner.Company}
 */
proto.partner.InvoicingInformation.prototype.getCompanyInfo = function() {
  return /** @type{?proto.partner.Company} */ (
    jspb.Message.getWrapperField(this, proto.partner.Company, 1));
};


/**
 * @param {?proto.partner.Company|undefined} value
 * @return {!proto.partner.InvoicingInformation} returns this
*/
proto.partner.InvoicingInformation.prototype.setCompanyInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.InvoicingInformation} returns this
 */
proto.partner.InvoicingInformation.prototype.clearCompanyInfo = function() {
  return this.setCompanyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.InvoicingInformation.prototype.hasCompanyInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Receiver receiver_info = 2;
 * @return {?proto.partner.Receiver}
 */
proto.partner.InvoicingInformation.prototype.getReceiverInfo = function() {
  return /** @type{?proto.partner.Receiver} */ (
    jspb.Message.getWrapperField(this, proto.partner.Receiver, 2));
};


/**
 * @param {?proto.partner.Receiver|undefined} value
 * @return {!proto.partner.InvoicingInformation} returns this
*/
proto.partner.InvoicingInformation.prototype.setReceiverInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.InvoicingInformation} returns this
 */
proto.partner.InvoicingInformation.prototype.clearReceiverInfo = function() {
  return this.setReceiverInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.InvoicingInformation.prototype.hasReceiverInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.partner.UserStatus = {
  NONE: 0,
  ACTIVE: 1,
  INACTIVE: 2
};

/**
 * @enum {number}
 */
proto.partner.OfficeStatus = {
  OFFICESTATUSNONE: 0,
  OFFICESTATUSPENDING: 1,
  OFFICESTATUSACTIVE: 2,
  OFFICESTATUSINACTIVE: 3
};

/**
 * @enum {number}
 */
proto.partner.DCPLevel = {
  DCP_LEVEL_NONE: 0,
  BOOKING: 1,
  ISSUETICKET: 2,
  BOOKINGANDTICKETING: 3
};

goog.object.extend(exports, proto.partner);
