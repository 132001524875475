import { HotelServiceClient } from '@api/hotel/web_partner/hotel_grpc_web_pb';
import {
  ListBookingsFilter,
  ListBookingsRequest,
  ListBookingsResponse,
  GetBookingDetailRequest,
  GetBookingDetailResponse,
  ExportBookingsReq,
  ExportBookingsRes,
  CancelBookingReq,
  CancelBookingRes,
  DeleteBookingSelectionReq,
  DeleteBookingSelectionRes,
  ListBookingSelectionReq,
  ListBookingSelectionRes,
  ReserveBookingReq,
  ReserveBookingRes,
  ReserveBookingSelectionReq,
  ReserveBookingSelectionRes,
  GetBookingDetailByTokenReq,
} from '@api/hotel/web_partner/hotel_pb';
import { BasicRes, Empty, OrderByItem, PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { RoomOccupancyRequest } from '@api/hotel/booking_pb';

class HotelServiceApi extends BaseApi<HotelServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HotelServiceClient(this.url, null, this.optsDev);
  }
  listBookings = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingsRequest.AsObject): Promise<ListBookingsResponse.AsObject> => {
    const req = new ListBookingsRequest();
    const filterObj = new ListBookingsFilter();
    const paginationObj = new PaginationReq();
    if (filter) {
      filter.to && filterObj.setTo(filter.to);
      filter.from && filterObj.setFrom(filter.from);
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setStatusList(filter.statusList);
      filterObj.setNotInStatusesList(filter.notInStatusesList);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    return this.grpc<
      ListBookingsRequest,
      ListBookingsResponse,
      ListBookingsResponse.AsObject
    >(this.serviceClient?.listBookings, req, {});
  };

  getBookingDetail = ({
    orderCode,
  }: GetBookingDetailRequest.AsObject): Promise<GetBookingDetailResponse.AsObject> => {
    const req = new GetBookingDetailRequest();
    req.setOrderCode(orderCode);

    return this.grpc<
      GetBookingDetailRequest,
      GetBookingDetailResponse,
      GetBookingDetailResponse.AsObject
    >(this.serviceClient?.getBookingDetail, req, {});
  };

  exportBookings = ({
    filter,
  }: ExportBookingsReq.AsObject): Promise<ExportBookingsRes.AsObject> => {
    const req = new ExportBookingsReq();
    const filterObj = new ListBookingsFilter();
    if (filter) {
      filter.to && filterObj.setTo(filter.to);
      filter.from && filterObj.setFrom(filter.from);
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setStatusList(filter.statusList);
      filterObj.setNotInStatusesList(filter.notInStatusesList);
    }

    req.setFilter(filterObj);

    return this.grpc<
      ExportBookingsReq,
      ExportBookingsRes,
      ExportBookingsRes.AsObject
    >(this.serviceClient?.exportBookings, req, {});
  };

  cancelBooking = ({
    orderCode,
  }: CancelBookingReq.AsObject): Promise<CancelBookingRes.AsObject> => {
    const req = new CancelBookingReq();
    req.setOrderCode(orderCode);

    return this.grpc<
      CancelBookingReq,
      CancelBookingRes,
      CancelBookingRes.AsObject
    >(this.serviceClient?.cancelBooking, req, {});
  };

  listBookingSelection = ({
    titleQuery,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingSelectionReq.AsObject): Promise<ListBookingSelectionRes.AsObject> => {
    const req = new ListBookingSelectionReq();
    const paginationObj = new PaginationReq();
    paginationObj.setPageLimit(pagination.pageLimit);
    paginationObj.setPageNumber(pagination.pageNumber);

    req.setPagination(paginationObj);
    req.setTitleQuery(titleQuery);
    return this.grpc<
      ListBookingSelectionReq,
      ListBookingSelectionRes,
      ListBookingSelectionRes.AsObject
    >(this.serviceClient.listBookingSelection, req, {});
  };

  deleteBookingSelection = ({
    selectionId,
  }: DeleteBookingSelectionReq.AsObject): Promise<DeleteBookingSelectionRes.AsObject> => {
    const req = new DeleteBookingSelectionReq();
    req.setSelectionId(selectionId);
    return this.grpc<
      DeleteBookingSelectionReq,
      DeleteBookingSelectionRes,
      DeleteBookingSelectionRes.AsObject
    >(this.serviceClient.deleteBookingSelection, req, {});
  };

  reserveBookingSelection = ({
    selectionId,
    hotelId,
    roomId,
    rateId,
    matchKey,
  }: ReserveBookingSelectionReq.AsObject): Promise<ReserveBookingSelectionRes.AsObject> => {
    const req = new ReserveBookingSelectionReq();
    req.setSelectionId(selectionId);
    req.setHotelId(hotelId);
    req.setRoomId(roomId);
    req.setRateId(rateId);
    req.setMatchKey(matchKey);

    return this.grpc<
      ReserveBookingSelectionReq,
      ReserveBookingSelectionRes,
      ReserveBookingSelectionRes.AsObject
    >(this.serviceClient.reserveBookingSelection, req, {});
  };

  getBookingDetailByToken = ({
    accessToken,
  }: GetBookingDetailByTokenReq.AsObject): Promise<GetBookingDetailResponse.AsObject> => {
    const req = new GetBookingDetailByTokenReq();
    req.setAccessToken(accessToken);

    return this.grpc<
      GetBookingDetailByTokenReq,
      GetBookingDetailResponse,
      GetBookingDetailResponse.AsObject
    >(this.serviceClient.getBookingDetailByToken, req, {});
  };
}

export const hotelApiServiceWebPartner = new HotelServiceApi();
export default hotelApiServiceWebPartner;
