import { LayoutErrorBoundary, withSuspense } from '@common-ui';
import {
  DetailCustomer,
  DetailOrder,
  // DetailOrderHotel,
  // ListOrderHotel,
  DetailOrderHotelAuto,
  DetailTicketCoordination,
  ListCustomer,
  ListOrder,
  ListOrderHotelAuto,
  ListTicketCoordination,
  ListRefundHotel,
  DetailRefundHotel
} from '@vemaybay-admin/containers';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthLayout, DashboardLayout } from '../layout';

const Login = withSuspense(lazy(() => import('../pages/Auth/LoginPage')));

const ForgotPassword = withSuspense(
  lazy(() => import('../pages/Auth/ForgotPasswordPage'))
);

const PageNotFound = withSuspense(
  lazy(() => import('../pages/NotFound/NotFound'))
);

const ServiceFeeSetting = withSuspense(
  lazy(() => import('../pages/ServiceFeeSetting/ServiceFeeSetting'))
);
const FinanceConfigPage = withSuspense(
  lazy(() => import('../pages/FinanceConfig'))
);

const CustomerManagement = withSuspense(
  lazy(() => import('../pages/CustomerManagement/CustomerManagement'))
);
const OrderManagement = withSuspense(
  lazy(() => import('../pages/OrderManagement/OrderManagement'))
);

const RefundManagement = withSuspense(
  lazy(() => import('../pages/RefundManagementHotel/RefundManagementHotel'))
);

const AccountPage = withSuspense(
  lazy(() => import('../pages/Account/AccountPage'))
);

const CommissionPage = withSuspense(
  lazy(() => import('../pages/Commission/CommissionPage'))
);

const CrossCheckPage = withSuspense(
  lazy(() => import('../pages/CrossCheck/CrossCheckPage'))
);
const ReferFriendPage = withSuspense(
  lazy(() => import('../pages/ReferFriend/ReferFriendPage'))
);

const DashboardPage = withSuspense(
  lazy(() => import('../pages/Dashboard/DashBoardPage'))
);

// const OrderManagementHotel = withSuspense(
//   lazy(() => import('../pages/OrderManagementHotel/OrderManagementHotel'))
// );

const OrderManagementHotelAuto = withSuspense(
  lazy(
    () => import('../pages/OrderManagementHotelAuto/OrderManagementHotelAuto')
  )
);

const TicketCoordinationPage = withSuspense(
  lazy(() => import('../pages/TicketCoordination/TicketCoordination'))
);

export const routeStructure = [
  {
    hasErrorBoundary: true,
    ErrorBoundary: LayoutErrorBoundary,
    element: <DashboardLayout />,
    path: '/',
    children: [
      {
        index: true,
        element: <Navigate to='dashboard' replace />,
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'user-management',
        element: <CustomerManagement />,
        children: [
          {
            index: true,
            element: <ListCustomer />,
          },
          {
            path: ':customerId',
            element: <DetailCustomer />,
          },
        ],
      },
      {
        path: 'order-management',
        element: <OrderManagement />,
        children: [
          {
            index: true,
            element: <ListOrder />,
          },
          {
            path: ':bookingCode',
            element: <DetailOrder />,
          },
        ],
      },
      {
        path: 'refund-management-hotel',
        element: <RefundManagement />,
        children: [
          {
            index: true,
            element: <ListRefundHotel />,
          },
          {
            path: ':bookingCode',
            element: <DetailRefundHotel />,
          },
        ],
      },
      {
        path: 'service-fee-setting',
        element: <ServiceFeeSetting />,
      },
      {
        path: 'finance-setting',
        element: <FinanceConfigPage />,
      },
      {
        path: 'order-management-hotel',
        element: <OrderManagementHotelAuto />,
        children: [
          {
            index: true,
            element: <ListOrderHotelAuto />,
          },
          {
            path: ':bookingCode',
            element: <DetailOrderHotelAuto />,
          },
        ],
      },

      {
        path: 'account',
        element: <AccountPage />,
      },
      {
        path: 'profit',
        element: <CommissionPage />,
      },
      {
        path: 'crosscheck',
        element: <CrossCheckPage />,
      },
      {
        path: 'referral',
        element: <ReferFriendPage />,
      },
      {
        path: 'ticket-coordination',
        element: <TicketCoordinationPage />,
        children: [
          {
            index: true,
            element: <ListTicketCoordination />,
          },
          {
            path: ':bookingCode',
            element: <DetailTicketCoordination />,
          },
        ],
      },
      { path: '*', element: <PageNotFound /> },
    ],
  },
  {
    hasErrorBoundary: true,
    ErrorBoundary: LayoutErrorBoundary,
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to='/auth/login' replace />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
    ],
  },
  { path: '*', element: <PageNotFound /> },
];
